
import {Sprite} from "./Sprite";
import {Layers} from "../../render/layers/Layers";
import {Inject} from '../../helpers/InjectDectorator';

export class TileSprite extends Sprite {
    @Inject(Layers) private layers: Layers;

    constructor(key: string, path: string = null) {
        super(key, path);
        this.draw = this.draw.bind(this);
    }

    draw(x: number, y: number, spriteIndex = 0, mirrorType = 0, targetCtx: CanvasRenderingContext2D = null) {
        const canvas = this.layers.mapCanvas;
        x -= canvas.canvasLeftOffset;
        y -= canvas.canvasTopOffset;

        if (targetCtx === null) {
            targetCtx = canvas.ctx;
        }

        let dims = this.getSpriteDims(spriteIndex);
        let sx = (dims.x * 32) + (mirrorType * 32);
        let sy = dims.y * 32;
        let sw = 32;
        let sh = 32;
        let dx = x;
        let dy = y;
        let dw = 32;
        let dh = 32;

        targetCtx.drawImage(this.img, sx, sy, sw, sh, dx, dy, dw, dh);
    }
}
