
import {UiPanel} from "../../UiPanel";
import * as Utils from "../../../utils/Utils";
import {CURSORS} from "../../../constants/CURSORS";
import {UiSprite} from "../../sprites/UiSprite";
import {UiComponent} from "../../../services/UiComponentsService";
import {StateService} from "../../../services/StateService";
import {ITextures, Textures} from '../../../controllers/Textures';
import {Inject} from '../../../helpers/InjectDectorator';

const DEFAULT_PARAMS = {
    left: 0,
    top: 0,
    width: 48,
    height: 32
};

@UiComponent()
export class UiSidebarTown extends UiPanel {
    private castleId: number;
    private isSelected: boolean;
    private portraitSprite: UiSprite;

    @Inject(Textures) protected textures: ITextures;
    @Inject(StateService) protected stateService: StateService;

    constructor(params) {
        let state;
        const options = Utils.extend(DEFAULT_PARAMS, params);
        super(options);
        this.draw = this.draw.bind(this);
        const town = this.readParam('town');

        this.portraitSprite = <UiSprite>this.textures.get('towns_portraits_sm');
        if (town.hasFort()) {
            state = 2;
        } else {
            state = 0;
        }
        this.castleId = (((town.race + 1) || 0) * 4) + state;

        this.events
            .on('rightMousedown', () => town.events.dispatch('rightMousedown'));

        this.isSelected = town.isSelected();

        this.stateService.subscribe('*.selectedObject', () => {
            this.isSelected = town.isSelected();
        });
    }

    getCursor() {
        return CURSORS.POINTER;
    }

    draw() {
        let left = this.getLeft();
        let top = this.getTop();

        this.ctx.fillStyle = '#000';
        this.ctx.fillRect(left, top, this.width, this.height);
        this.portraitSprite.draw(left, top, this.castleId, this.ctx);

        if (this.isSelected) {
            this.ctx.lineWidth = 1;
            this.ctx.strokeStyle = '#cc0';
            this.ctx.strokeRect(left + 1.5, top + 1.5, 45, this.height - 3);
        }
    }
}
