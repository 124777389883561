
import {GenericVisitableObject} from "./GenericVisitableObject";
import {IObjectData} from "../../MapObject";
import {Inject} from "../../../helpers/InjectDectorator";
import {ActivePlayer} from "../../../controllers/ActivePlayer";
import {PlayerModel} from "../../player/PlayerModel";

export class RedwoodObservatoryObject extends GenericVisitableObject {

    @Inject(ActivePlayer) activePlayer: PlayerModel;

    recordVisits = true;

    constructor(data: IObjectData, id: number) {
        super(data, id);

        this.events.on('action', hero => {
            this.activePlayer.updateMapForObject(<any>this);
        });
    }

    getScoutingRadius(): number {
        return 20;
    }
}
