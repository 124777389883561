
import {IUiOptions, UiPanel} from "./UiPanel";
import {EventsStorage} from "../models/storages/EventsStorage";
import {inject} from '../helpers/InjectDectorator';
import {UiDragState} from '../controllers/UiDragState';

export interface IDropEvent {
    from: UiPanel;
    to: UiPanel;
    stack: UiPanel[];
}

const uiDragState = inject<UiDragState>(UiDragState);

export class UiPanelDraggableMixin {

    droppable = false;
    options: IUiOptions;
    events: EventsStorage;

    setDropZones(dropZones: UiPanel[]) {
        for (let wnd of uiDragState.dropZones) {
            wnd.droppable = false;
        }
        for (let wnd of dropZones) {
            wnd.droppable = true;
        }

        uiDragState.dropZones = dropZones;
    }

    onMouseUp(e) {
        const isDropZone = uiDragState.dropZones.includes(<UiPanel><any>this);

        if (uiDragState.isDragged && isDropZone) {
            this.events.dispatch('drop', {
                from: uiDragState.isDragged,
                to: this
            });
        }
        
        uiDragState.isDragged = null;
        
        this.setDropZones([]);
    }

    onMouseDown() {
        uiDragState.isDragged = (<UiPanel><any>this);
        this.setDropZones(typeof this.options.dropZones === 'function' ? this.options.dropZones() : []);
    }

    __initDraggableBehavior() {
        const events = (<UiPanel><any>this).events;
        uiDragState.isDragged = null;
        uiDragState.dropZones = [];

        events.on('leftMousedown', this.onMouseDown.bind(this));
        events.on('touchstart', this.onMouseDown.bind(this));
        events.on('leftMouseup', this.onMouseUp.bind(this));
        events.on('touchend', this.onMouseUp.bind(this));
    }
}
