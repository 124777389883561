
import {Maybe} from "../helpers/Maybe";
import {MapObject} from "../models/MapObject";
import {I18nData} from '../controllers/I18nData';
import {Inject} from '../helpers/InjectDectorator';
import {PlayerModel} from '../models/player/PlayerModel';
import {ActivePlayer} from '../controllers/ActivePlayer';

export class TextService {

    @Inject(I18nData) private i18nData: {};
    @Inject(ActivePlayer) private activePlayer: PlayerModel;

    getObjectTip(object: MapObject): string {
        const { type } = object;
        let str = object.tip || `Object [${object.type}]`;
        const ownerColor = object.owner != null
            ? object.owner.color
            : null;

        if (ownerColor) {
            str += ` (${this.i18n(`MESSAGES.STATUSES.OWNED_BY.${ownerColor.toUpperCase()}`)})`;
        }

        if (type === 'generic_visitable') {
            const {selectedObject} = this.activePlayer;
            const heroesVisits = <number[]>object['visits'];

            if (selectedObject && (selectedObject as any).isHero) {
                if (heroesVisits.includes(selectedObject.id)) {
                    str += ' (visited)';
                }
            }
        }

        return str;
    }

    getTileTip(terrain: string): string {
        return this.i18n(`MAP.GROUNDS.${terrain}`) || 'Unknown terrain';
    }

    getFogTip(): string {
        return 'Terra incognita';
    }

    getArtifactName(key: string): string {
        return this.i18n(`MAP.ARTIFACTS.${key}.TITLE`) || 'Unknown artifact';
    }

    getObjectName(key: number): string {
        return this.i18n(`MAP.OBJECTS.${key}.TITLE`) || 'Unknown object';
    }

    getTownName(key: string): string {
        return this.i18n(`MAP.TOWNS.${key}.TITLE`) || 'Unknown town';
    }

    getHeroName(key: string): string {
        return this.i18n(`HEROES.${key}.NAME`) || 'Unknown hero';
    }

    i18n(path: string): any {
        return Maybe(this.i18nData)
            .pluck(path)
            .getOrElse(null);
    }
}