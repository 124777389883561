
import {Property} from "../../helpers/Property";

export class PropertiesStorage {

    props: Map<string, Property> = new Map();

    constructor() {}

    all() {
        return this.props;
    }

    set(name: any, amount?: any) {
        let key, value;
        if (arguments.length === 1) {
            let object = arguments[0];
            for (key in object) {
                value = object[key];
                if (!(value instanceof Property)) {
                    value = new Property(value);
                }
                this.props.set(key, value);
            }
        }

        if (arguments.length === 2) {
            [key, value] = Array.from(arguments);
            if (!(value instanceof Property)) {
                value = new Property(value);
            }
            return this.props.set(key, value);
        }
    }

    get(key) {
        return this.props.get(key);
    }

    getValue(key) {
        const property = this.props.get(key);
        if (property) {
            return property.get();
        } else {
            return null;
        }
    }

    has(key) {
        return this.props.has(key);
    }

    delete(key) {
        return this.props.delete(key);
    }

    watch(key, callback) {
        const property = this.props.get(key);
        if (property) {
            return property.setWatcher(callback);
        }
    }
};