
import * as Utils from "../utils/Utils";
import {Canvas} from "../render/layers/Canvas";
import {Display} from "../render/layers/Display";
import {ResourcesController} from "../controllers/ResourcesController";
import {Inject} from "../helpers/InjectDectorator";

const color = Utils.hexToRgb("#8AC007");
let spinnerLeft = 0;
let spinnerTop = 0;

const prerendered = Utils.makeCtx(40, 40);

export class Spinner {

    @Inject(ResourcesController) private resourcesController: ResourcesController;

    constructor() {
        this.draw = this.draw.bind(this);
    }

    private drawBlackScreen(ctx: CanvasRenderingContext2D) {
        ctx.fillStyle = '#000';
        ctx.fillRect(0, 0, Display.offsetWidth, Display.offsetHeight);
    }

    private drawSpinner() {
        prerendered.fillStyle = '#000';
        prerendered.fillRect(0, 0, 40, 40);

        prerendered.strokeStyle = `rgba(${color.r}, ${color.g}, ${color.b}, .5)`;
        prerendered.lineWidth = 4;

        prerendered.beginPath();
        prerendered.arc(20, 20, 18, 0.5 * Math.PI, Math.PI);
        prerendered.stroke();

        prerendered.beginPath();
        prerendered.arc(20, 20, 18, 1.5 * Math.PI, 0);
        prerendered.stroke();
    }

    private drawPercentCounter(ctx: CanvasRenderingContext2D) {
        const loadProgressRatio = this.resourcesController.loadedCount / this.resourcesController.loaders.length;
        const loadedPercent = (loadProgressRatio * 100) | 0;

        ctx.fillStyle = '#8AC007';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.font = '20px Verdana';
        ctx.fillText(`${loadedPercent}%`, spinnerLeft + 20, spinnerTop + 100);
    }

    prepare({ctx}: Canvas) {
        const {width, height} = ctx.canvas;

        spinnerLeft = (width / 2) - 20;
        spinnerTop = (height / 2) - 20;

        return true;
    }

    draw({ctx}: Canvas) {
        this.drawBlackScreen(ctx);

        prerendered.translate(20, 20);
        prerendered.rotate((15 * Math.PI) / 180);
        prerendered.translate(-20, -20);

        this.drawSpinner();
        ctx.drawImage(prerendered.canvas, spinnerLeft, spinnerTop);
        this.drawPercentCounter(ctx);
    }
}