
import {IObjectData, MapObject} from "../MapObject";
import {ArtifactsStorage, IArtifactData} from "../storages/ArtifactsStorage";
import {TextService} from "../../services/TextService";
import {UiController} from "../../controllers/UiController";
import {ObjectsCollection} from "../ObjectsCollection";
import {ITextures, Textures} from '../../controllers/Textures';
import {Inject} from '../../helpers/InjectDectorator';
import {MapComponent} from '../MapComponent';

const RANDOM_PRESETS = {
    [65]: {},
    [66]: {level: 1},
    [67]: {level: 2},
    [68]: {level: 3},
    [69]: {level: 4},
    [70]: {level: 5}
};

export interface IObjectArtifactData extends IObjectData {
    artifactData: IArtifactData
}

@MapComponent('artifact')
export class ArtifactObject extends MapObject {

    dynamic = true;
    gridDynamic = true;
    renderDynamic = true;

    @Inject(Textures) protected textures: ITextures;
    @Inject(TextService) protected textService: TextService;
    @Inject(ObjectsCollection) protected objectsCollection: ObjectsCollection;

    constructor(data: IObjectArtifactData, id: number) {
        super(data, id);

        this.events.on('action', hero => {
            UiController.statusWindow.artifactMessage(this.objectSubClass);
            hero.artifacts.add(data);
            this.objectsCollection.remove(this);
        });

        this.tip = this.textService.getArtifactName(data.artifactData.type);
        this.iconSprite = this.textures.get('artifacts_portraits_sm');
        this.iconSpriteIndex = data.artifactData.spriteIndex;
    }

    static getRandomData(level = 0) {
        return ArtifactObject.prototype.prepare(<any>{
            object_class: 65 + level,
            coords: [0, 0, 0],
            data: {type: 'artifact'}
        });
    }

    prepare(data: IObjectArtifactData) {
        let artifactData;
        if (RANDOM_PRESETS[data.object_class]) {
            artifactData = ArtifactsStorage.prototype.getRandomData(RANDOM_PRESETS[data.object_class].level);
            data.object_subclass = artifactData.id;
        } else {
            artifactData = ArtifactsStorage.prototype.getDataById(data.object_subclass);
        }
        data.artifactData = artifactData;
        data.texture = artifactData.texture;

        return data;
    }
}