
const getCssStyle = (cursor) => {
    return `url(/public/img/ui/cursors/${cursor}.cur), auto`;
};

export const CURSORS = {
    DEFAULT    : getCssStyle('cr_default'),
    DRAG       : getCssStyle('cr_drag'),
    HERO       : getCssStyle('cr_hero'),
    TOWN       : getCssStyle('cr_town'),
    ATTACK     : getCssStyle('cr_attack'),
    CHANGING   : getCssStyle('cr_changing'),
    HORSE      : getCssStyle('cr_horse'),
    HORSE_ACT  : getCssStyle('cr_horse_a'),
    SHIP       : getCssStyle('cr_ship'),
    SHIP_ACT   : getCssStyle('cr_ship_a'),
    ASHORE     : getCssStyle('cr_ashore'),
    WAIT       : getCssStyle('cr_wait'),
    POINTER    : getCssStyle('cr_pointer'),

    UNIT_GO    : getCssStyle('cr_go'),
    UNIT_FLY   : getCssStyle('cr_fly'),
    UNIT_STOP  : getCssStyle('cr_stop'),
    SHOOT      : getCssStyle('cr_shoot'),
    SHOOT_BREAK: getCssStyle('cr_shoot_break'),
    ATTACK_0   : getCssStyle('melee_attack_0'),
    ATTACK_1   : getCssStyle('melee_attack_1'),
    ATTACK_2   : getCssStyle('melee_attack_2'),
    ATTACK_3   : getCssStyle('melee_attack_3'),
    ATTACK_4   : getCssStyle('melee_attack_4'),
    ATTACK_5   : getCssStyle('melee_attack_5')
};
