
import {IUiOptions, UiPanel} from "../../UiPanel";
import {UiBattleFieldOppositeMixin} from "./UiBattleFieldOppositeMixin";
import {UiBattleFieldGeometryMixin} from "./UiBattleFieldGeometryMixin";
import * as Utils from "../../../utils/Utils";
import {CURSORS} from "../../../constants/CURSORS";
import {UiBattleFieldCreature} from "./UiBattleFieldCreature";
import {UiBattleWindow} from "./UiBattleWindow";
import {CreaturesStorage} from "../../../models/storages/CreaturesStorage";
import {UiComponent} from "../../../services/UiComponentsService";
import {BattleService} from "../../../services/BattleService";
import {BattleFieldCreature} from '../../../models/objects/BattleFieldCreature';
import {Observable} from "rxjs/Observable";
import {Grid} from "../../../helpers/Grid";
import {Mixin} from '../../../utils/Utils';

export interface IUiBattleFieldOptions extends IUiOptions {
    leftSide: CreaturesStorage;
    rightSide: CreaturesStorage;
    alignmentTemplate: string;
}

const DEFAULT_PARAMS = {
    left: 0,
    top: 0,
    width: 744,
    height: 477,
    leftSide: null,
    rightSide: null,
    alignmentTemplate: 'opposite'
};

@UiComponent()
@Mixin(UiBattleFieldOppositeMixin)
@Mixin(UiBattleFieldGeometryMixin)
export class UiBattleField extends UiPanel implements UiBattleFieldOppositeMixin, UiBattleFieldGeometryMixin {
    cellUnderCursor: [number, number] = null;
    targetCell: UiBattleFieldCreature;
    selectedCell: [number, number] = null;
    private unitUnderCursor: any;
    private passableUnderCursor: boolean;
    private blinkingTimer: number;

    stacks: [BattleFieldCreature[], BattleFieldCreature[]];
    parent: UiBattleWindow;
    options: IUiBattleFieldOptions;

    constructor(params: IUiBattleFieldOptions) {
        super(Utils.extend(DEFAULT_PARAMS, params));
        this.isEnemy = this.isEnemy.bind(this);
        this.isFlyAvailable = this.isFlyAvailable.bind(this);

        this.prepareGraphics();

        const [leftStacks, rightStacks] = BattleService.stacks;

        this.placeStacks(leftStacks, rightStacks);

        this.events.on('mousemove', e => {
            const [{x, y}] = Array.from(e.pointers);
            const left = x - this.absoluteLeft;
            const top = y - this.absoluteTop;
            let nearestPoint;

            BattleService.angleUnderCursorAvailable = false;
            this.unitUnderCursor = null;
            this.passableUnderCursor = false;
            this.cellUnderCursor = this.findNearestHexagon(left, top);

            if (this.cellUnderCursor) {
                const [x, y] = this.cellUnderCursor;
                nearestPoint = BattleService.graph.neighborByAngle({x, y}, BattleService.angleUnderCursor);

                this.unitUnderCursor = BattleService.getCell(x, y);
                this.passableUnderCursor = BattleService.passableCells.has(x, y);
                BattleService.angleUnderCursor = this.getAngle(x, y, left, top);

                if (nearestPoint) {
                    const {x, y} = nearestPoint;
                    const nearestCell = BattleService.getCell(x, y);

                    if (BattleService.activeUnit === nearestCell) {
                        BattleService.angleUnderCursorAvailable = true;
                    } else {
                        BattleService.angleUnderCursorAvailable = BattleService.isEmptyField(nearestCell);
                    }
                }
            }

            this.targetCell = null;
            if (BattleService.angleUnderCursorAvailable && !BattleService.activeUnit.isShooting) {
                const [x, y] = Array.from(this.cellUnderCursor);

                if (this.isEnemy(BattleService.getCell(x, y))
                    && BattleService.passableCells.has(x, y)
                    && BattleService.passableCells.has(nearestPoint.x, nearestPoint.y)
                ) {
                    this.targetCell = nearestPoint;
                }
            }

            this.parent.cursor = this.getCursor();
        });

        this.events.on('leftClick', e => {
            const [x, y] = Array.from(this.cellUnderCursor);

            if (!BattleService.passableCells.has(x, y)) {
                return true;
            }

            BattleService.moveOrAttackTo(x, y);
        });

        BattleService.startTurnStream
            .takeUntil(Observable.fromEvent(this.events, 'remove'))
            .subscribe(battleFieldCreature => {
                this.startTurn(battleFieldCreature);
            });

        BattleService.moveUnitStream
            .takeUntil(Observable.fromEvent(this.events, 'remove'))
            .subscribe(({unit, x, y}) => {
                const uiCreature = this.getUICreatureByUnit(unit);

                uiCreature.setPosition(x, y);
            });

        this.setBlinkingTimer();
    }

    // if @getCell(x, y)
    //   @selectedCell = Utils.clone(@cellUnderCursor)
    // else
    //   @selectedCell = null

    getCursor(): string {
        if (this.passableUnderCursor) {
            if (this.isEnemy(this.unitUnderCursor)) {
                if (BattleService.activeUnit.isShooting) {
                    return CURSORS.SHOOT;
                }
                if (BattleService.angleUnderCursorAvailable) {
                    return CURSORS[`ATTACK_${BattleService.angleUnderCursor}`];
                } else {
                    return CURSORS.DEFAULT;
                }
            }

            if (BattleService.activeUnit.isFlying) {
                return CURSORS.UNIT_FLY;
            } else {
                return CURSORS.UNIT_GO;
            }
        }
        return CURSORS.UNIT_STOP;
    }

    private placeOpponentObjects(stacks: BattleFieldCreature[], positions: [number, number][]) {
        for (let position = 0; position < stacks.length; position++) {
            const stackItem = stacks[position];
            const [x, y] = Array.from(positions[position]);
            const battleFieldObject = this.getUiBattlefieldCreature(stackItem);

            BattleService.setCell(x, y, stackItem);
            battleFieldObject.setPosition(x, y);
        }
    }

    private placeStacks(leftStacks: BattleFieldCreature[], rightStacks: BattleFieldCreature[]) {
        const templateFn = this[this.readParam('alignmentTemplate')].bind(this);
        const [leftPositions, rightPositions] = Array.from(templateFn(leftStacks.length, rightStacks.length));

        this.stacks = [leftStacks, rightStacks];

        this.placeOpponentObjects(leftStacks, leftPositions);
        this.placeOpponentObjects(rightStacks, rightPositions);
    }

    private isEmptyField(cellUnit: BattleFieldCreature) {
        return !(cellUnit != null ? cellUnit.isAlive : undefined) || !cellUnit;
    }

    private isEnemy(cellUnit: BattleFieldCreature) {
        return cellUnit && cellUnit.isAlive && (BattleService.activeUnit.side !== cellUnit.side);
    }

    private isFlyAvailable(cellUnit: BattleFieldCreature) {
        return this.isEmptyField(cellUnit) || this.isEnemy(cellUnit);
    }

    private updateCreaturesRenderSort() {
        const childrenArray = [];
        const sortedSet = new Set();

        this.children.forEach((uiCreature: UiBattleFieldCreature) => {
            if (uiCreature.unit.isAlive) {
                return childrenArray.push(uiCreature);
            } else {
                return childrenArray.unshift(uiCreature);
            }
        });

        childrenArray.forEach(sortedSet.add.bind(sortedSet));

        this.children = sortedSet;
    }

    private getUICreatureByUnit(battleFieldCreature: BattleFieldCreature): UiBattleFieldCreature {
        return <UiBattleFieldCreature>Array
            .from(this.children)
            .find(({unit}: UiBattleFieldCreature) => unit === battleFieldCreature);
    }

    private startTurn(unit: BattleFieldCreature) {
        this.updateCreaturesRenderSort();

        this.selectedCell = [unit.x, unit.y];
    }

    draw() {
        let left, top, x, y;
        this.ctx.drawImage(this.boardGraphic, this.absoluteLeft, this.absoluteTop);

        BattleService.passableCells.forEach((item, x, y) => {
            left = this.getHexagonLeft(x, y);
            top = this.getHexagonTop(x, y);
            this.ctx.drawImage(this.passableHexagonGraphic, this.absoluteLeft + left, this.absoluteTop + top);
        });

        if (this.cellUnderCursor) {
            [x, y] = Array.from(this.cellUnderCursor);
            left = this.getHexagonLeft(x, y);
            top = this.getHexagonTop(x, y);
            this.ctx.drawImage(this.hoverHexagonGraphic, this.absoluteLeft + left, this.absoluteTop + top);
        }

        if (this.targetCell) {
            ({x, y} = this.targetCell);
            left = this.getHexagonLeft(x, y);
            top = this.getHexagonTop(x, y);
            this.ctx.drawImage(this.selectedHexagonGraphic, this.absoluteLeft + left, this.absoluteTop + top);
        }

        if (this.selectedCell) {
            [x, y] = Array.from(this.selectedCell);
            left = this.getHexagonLeft(x, y);
            top = this.getHexagonTop(x, y);

            this.ctx.globalAlpha = this.blinkingTimer / 10;
            this.ctx.drawImage(this.selectedHexagonGraphic, this.absoluteLeft + left, this.absoluteTop + top);
            this.ctx.globalAlpha = 1;
        }

        this.children.forEach(wnd => wnd.draw());
    }

    private setBlinkingTimer() {
        this.blinkingTimer = 0;
        let bubbling = true;

        Observable.timer(0, 70)
            .takeUntil(Observable.fromEvent(this.events, 'remove'))
            .subscribe(() => {
                if (bubbling) {
                    this.blinkingTimer++;
                    if (this.blinkingTimer > 9) {
                        bubbling = false
                    }
                } else {
                    this.blinkingTimer--;
                    if (this.blinkingTimer < 1) {
                        bubbling = true
                    }
                }
            })
    }

    lineUpStacks(count: number, x: number): [number, number][] { return null }
    opposite(leftCount: number, rightCount: number): [[number, number][], [number, number][]] { return null }

    hexagonGraphic: HTMLCanvasElement;
    hoverHexagonGraphic: HTMLCanvasElement;
    passableHexagonGraphic: HTMLCanvasElement;
    selectedHexagonGraphic: HTMLCanvasElement;
    boardGraphic: HTMLCanvasElement;

    hexagonsPoints: Grid<[number, number]>;

    getAngle(hexagonX: number, hexagonY: number, left: number, top: number): number { return null }
    findNearestHexagon(x: number, y: number): [number, number] { return null }
    makeHexagon(): CanvasRenderingContext2D { return null }
    prepareHexagon(): HTMLCanvasElement { return null }
    prepareSelectedHexagon(): HTMLCanvasElement { return null }
    preparePassableHexagon(): HTMLCanvasElement { return null }
    prepareHoverHexagon(): HTMLCanvasElement { return null }
    getHexagonLeft(x: number, y: number): number { return null }
    getHexagonTop(x: number, y: number): number { return null }
    forEachBoard(callback: Function) { return null }
    prepareBoard(): HTMLCanvasElement { return null }
    getHexagonPoints(): Grid<[number, number]> { return null }
    getUiBattlefieldCreature(battleFieldCreature: BattleFieldCreature): UiBattleFieldCreature { return null }
    prepareGraphics() { return null }
}
