
import {AnimatedSprite} from "./AnimatedSprite";
import * as Utils from "../../utils/Utils";
import {Layers} from "../../render/layers/Layers";
import {Inject} from '../../helpers/InjectDectorator';

export class ObjectSprite extends AnimatedSprite {

    @Inject(Layers) protected layers: Layers;

    constructor(key: string, path: string = null) {
        super(key, path);
        this.draw = this.draw.bind(this);
    }

    draw(x: number, y: number, spriteIndex = 0, targetCtx: CanvasRenderingContext2D = null) {
        let {objectsCanvas, mapCanvas} = this.layers;

        x -= mapCanvas.canvasLeftOffset;
        y -= mapCanvas.canvasTopOffset;

        let ctx = targetCtx || objectsCanvas.ctx;
        spriteIndex = spriteIndex || this.spriteIndex;
        let dims = this.getSpriteDims(spriteIndex);
        let dx = (x - this.width) + 32;
        let dy = (y - this.height) + 32;

        if ((this.spriteWidth > 1) || (this.spriteHeight > 1)) {
            let sx = dims.x * this.width;
            let sy = dims.y * this.height;
            let sw = this.width;
            let sh = this.height;
            let dw = this.width;
            let dh = this.height;

            return ctx.drawImage(this.img, sx, sy, sw, sh, dx, dy, dw, dh);
        } else {
            return ctx.drawImage(this.img, dx, dy);
        }
    }

    duplicate(): ObjectSprite {
        return new ObjectSprite(this.key, this.path);
    }

    setFlagColor(img: HTMLImageElement, tr: number, tg: number, tb: number): string {
        let ctx = Utils.makeCtx(img.width, img.height);
        ctx.drawImage(img, 0, 0);

        let data = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
        let channels = data['data'];
        let dataLength = data.width * data.height * 4;

        let i = 0;
        while (i < dataLength) {
            let r = channels[i];
            let g = channels[i+1];
            let b = channels[i+2];
            let a = channels[i+3];

            if ((r === 255) && (g === 255) && (b === 0)) {
                r = tr;
                g = tg;
                b = tb;
            }

            channels[i] = r;
            channels[i+1] = g;
            channels[i+2] = b;
            channels[i+3] = a;

            i += 4;
        }

        ctx.clearRect(0, 0, data.width, data.height);
        ctx.putImageData(data, 0, 0);

        let dataUrl = ctx.canvas.toDataURL();
        ctx = null;

        return dataUrl;
    }
}
