
import {UiController} from "../../../controllers/UiController";
import {GenericVisitableObject} from "./GenericVisitableObject";

export class MarlettoTowerObject extends GenericVisitableObject {

    recordVisits = true;

    constructor(data, id) {
        super(data, id);

        this.events.on('action', (hero) => {
            hero.properties.get('defence').push(1);
            UiController.alert('Defence +1');
        });
    }
}
    