
import {PropertiesStorage} from "./PropertiesStorage";
import {Property} from "../../helpers/Property";
import * as Utils from "../../utils/Utils";
import {ResourcesController} from "../../controllers/ResourcesController";
import {Events} from "../../controllers/Events";
import {HeroObject, IObjectHeroData} from "../objects/hero/HeroObject";
import {UiLevelUpWindow} from "../../views/windows/UiLevelUpWindow";
import {UiController} from "../../controllers/UiController";
import {ObjectsCollection} from "../ObjectsCollection";
import {Inject} from "../../helpers/InjectDectorator";

interface IHeroClassDataValues {
    start: number;
    chance: number;
    chance_10: number;
}

export interface IHeroClassData {
    name: string;
    attack: IHeroClassDataValues;
    defence: IHeroClassDataValues;
    power: IHeroClassDataValues;
    knowledge: IHeroClassDataValues;
}

const MAX_LEVEL = 100;
const LEVELS = __range__(1, MAX_LEVEL, true);
const BASE_MOVEPOINTS = [
    1300,
    1360,
    1430,
    1500,
    1560,
    1630,
    1700,
    1760,
    1830,
    1900,
    1960,
    2000
];

const LOGISTICS_LEVEL = [
    1.0,
    1.1,
    1.2,
    1.3
];

const NAVIGATION_LEVEL = [
    1500,
    2250,
    3000,
    3750
];

export class HeroPropertiesStorage extends PropertiesStorage {

    @Inject(ResourcesController) private resourcesController: ResourcesController;
    @Inject(ObjectsCollection) protected objectsCollection: ObjectsCollection;

    constructor(private hero: HeroObject) {
        super();

        let LEVEL_EXPERIENCE_MAP = [0];
        for (let level of Array.from(LEVELS)) {
            LEVEL_EXPERIENCE_MAP.push(this.getMaxExperienceForLevel(level));
        }

        this.initPrimarySkills(hero);
        this.initProperties(hero);
        this.refillMovePoints();
        this.refillManaPoints();

        this.watch('experience', value => {
            const currentHeroLevel = this.getValue('level');
            return (() => {
                const result = [];
                for (let level = 0; level < LEVEL_EXPERIENCE_MAP.length; level++) {
                    const minExpValue = LEVEL_EXPERIENCE_MAP[level];
                    const maxExpValue = LEVEL_EXPERIENCE_MAP[level + 1];
                    let item;
                    if (minExpValue <= value && value < maxExpValue) {
                        if (level > currentHeroLevel) {
                            item = this.get('level').set(level);
                        }
                    }
                    result.push(item);
                }
                return result;
            })();
        });

        this.watch('level', value => UiController.modal(UiLevelUpWindow, hero));

        Events.on('game.startPlayerTurn', activePlayer => {
            if (activePlayer.is(hero.owner)) {
                this.refillMovePoints();
                this.refillManaPoints();
            }
        });
    }

    private pipeMovePointsFactors(hero: HeroObject, value: number) {
        if (hero.pathFinder.getGridType() === 'water') {
            // navigation factor
            const level = hero.secondary.get('NAVIGATION')|0;
            value = NAVIGATION_LEVEL[level];

            // lighthouses factor
            let lighthousesCount = 0;
            this.objectsCollection.forEach('lighthouse', object => {
                if (hero.owner.is(object.owner)) {
                    lighthousesCount++;
                }
            });
            value += lighthousesCount * 500;
        }
        if (hero.pathFinder.getGridType() === 'earth') {
            // logistics factor
            if (hero.secondary.has('LOGISTICS')) {
                const level = hero.secondary.get('LOGISTICS');
                value *= LOGISTICS_LEVEL[level];
            }
        }
        return value;
    }

    getMaxMovePoints(): number {
        const property = this.get('movePoints');
        const slowestCreatureSpeed = Math.min(this.hero.creatures.getSlowestSpeed(), BASE_MOVEPOINTS.length - 1);
        let baseValue = BASE_MOVEPOINTS[slowestCreatureSpeed];
        baseValue = this.pipeMovePointsFactors(this.hero, baseValue);

        return Math.max(baseValue, property.get());
    }

    private refillMovePoints() {
        const property = this.get('movePoints');
        const finalValue = this.getMaxMovePoints();

        property.set(finalValue);
    }

    private refillManaPoints() {
        const property = this.get('manaPoints');
        const increasingValue = 1;
        const maxValue = this.getValue('knowledge') * 10;
        if (property.get() < maxValue) {
            property.push(increasingValue);
        }
    }

    private getMaxExperienceForLevel(level: number) {
        let result = 0;
        let i = 1;
        while (i < level) {
            if (i < 13) {
                result += 1000;
                if (i > 2) {
                    result += (i - 2) * 200;
                }
                if (i > 9) {
                    result += (i - 8) * 100;
                }
            } else {
                result += Math.floor(result * 0.2);
            }
            i++;
        }
        return result;
    }

    private initProperties(hero: HeroObject) {
        this.set('experience', new Property(500));

        this.set('movePoints', new Property(0));
        const maxManaPoints = this.getValue('knowledge') * 10;
        this.set('manaPoints', new Property(maxManaPoints));

        const moraleProperty = new Property(3, 6);
        moraleProperty.effects.add(() => hero.creatures.moraleBonus);
        this.set('morale', moraleProperty);

        const luckProperty = new Property(3, 6);
        luckProperty.effects.add(() => hero.secondary.get('LUCK') || 0);
        this.set('luck', luckProperty);

        return this.set('level', new Property(1, MAX_LEVEL));
    }

    private getPrimarySkillStartupValue(heroData: IObjectHeroData, classData: IHeroClassData, skillName: string) {
        const primarySkills = heroData.data && heroData.data.primary_skills;

        if (primarySkills) {
            return primarySkills[skillName];
        }
        return classData[skillName].start;
    }

    getPrimarySkillFactor(hero: HeroObject, skillName: string): number {
        let heroClassName = hero.data.config.class;
        let heroesClassesData = this.resourcesController.storage.get('heroesClassesData');
        let classData = <IHeroClassData>Utils.find(heroesClassesData, item => item.name === heroClassName);

        if (this.getValue('level') < 10) {
            return classData[skillName].chance;
        } else {
            return classData[skillName].chance_10;
        }
    }

    private initPrimarySkills(hero: HeroObject) {
        const heroClassName = hero.data.config.class;
        const heroesClassesData = this.resourcesController.storage.get('heroesClassesData');
        const classData = <IHeroClassData>Utils.find(heroesClassesData, item => item.name === heroClassName);

        const get = this.getPrimarySkillStartupValue.bind(this, hero.data, classData);

        this.set('attack', new Property(get('attack')));
        this.set('defence', new Property(get('defence')));
        this.set('power', new Property(get('power')));
        this.set('knowledge', new Property(get('knowledge')));
    }

    levelUp(count: number = 1) {
        const currentHeroLevel = this.getValue('level');
        const maxExperience = this.getMaxExperienceForLevel(currentHeroLevel + count);
        const experience = this.get('experience').get();

        this.get('experience').push(maxExperience - experience);
    }
}

function __range__(left, right, inclusive) {
    let range = [];
    let ascending = left < right;
    let end = !inclusive ? right : ascending ? right + 1 : right - 1;
    for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
        range.push(i);
    }
    return range;
}