
import {IObjectData, MapObject} from "../MapObject";
import {PlayerModel} from "../player/PlayerModel";
import {COLORS} from "../../constants/COLORS";
import {HeroObject} from "./hero/HeroObject";
import {StateService} from "../../services/StateService";
import {Players} from '../../controllers/Players';
import {Inject} from '../../helpers/InjectDectorator';

export class OwnershipObject extends MapObject {

    dynamic = true;
    renderDynamic = true;
    ownerId: number;
    owner: PlayerModel;

    @Inject(Players) protected players: Map<number, PlayerModel>;
    @Inject(StateService) protected stateService: StateService;

    constructor(data: IObjectData<any>, id: number) {
        super(data, id);

        if (this.data.data) {
            this.ownerId = this.data.data.owner;
        }

        this.owner = this.players.get(this.ownerId) || null;

        this.setOwner(this.owner, true);
    }

    setOwner(owner: PlayerModel, silent = false) {
        if (owner) {
            this.owner = owner;
            this.ownerId = this.owner.colorIndex;
        } else {
            this.owner = null;
            this.ownerId = undefined;
        }

        this.waitForOriginalImage().then(() => {
            this.updateFlagColor();
        });
        this.events.dispatch('setOwner', {owner: this.owner});

        if (silent === false) {
            this.stateService.set(`objects.${this.id}.data.owner`, this.ownerId);
        }
    }

    getScoutingRadius() {
        return 5;
    }

    private waitForOriginalImage(): Promise<void> {
        if (this.originalImage.complete) {
            return Promise.resolve();
        }
        return new Promise((resolve) => {
            this.originalImage.onload = <any>resolve;
        });
    }

    private updateFlagColor() {
        if ((<HeroObject><any>this).isHero) {
            return true;
        }
        let r = 100;
        let g = 100;
        let b = 100;

        if (this.owner) {
            [r, g, b] = Array.from(COLORS[this.owner.color.toUpperCase()]);
        }

        this.sprite.img.src = this.sprite.setFlagColor(this.originalImage, r, g, b);
    }
}
