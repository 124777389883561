
import * as Utils from "../../utils/Utils";
import {Display} from "../../render/layers/Display";
import {UiSidebar} from "./sidebar/UiSidebar";
import {UiResourcesPanel} from "./UiResourcesPanel";
import {Events} from "../../controllers/Events";
import {Pointer} from "../../models/Pointer";
import {UiController} from "../../controllers/UiController";
import {Layers} from "../../render/layers/Layers";
import {UiSprite} from "../sprites/UiSprite";
import {CommonComponentsModule, GameComponentsModule, View} from "../ComponentsModules";
import {Canvas} from "../../render/layers/Canvas";
import {IAbstractView} from "../IAbstractView";
import {Inject} from "../../helpers/InjectDectorator";
import {ActivePlayer} from "../../controllers/ActivePlayer";
import {PlayerModel} from "../../models/player/PlayerModel";
import {UiWindow} from "../common/UiWindow";
import {FocusedWindow} from "../../controllers/FocusedWindow";
import {ITextures, Textures} from '../../controllers/Textures';

const FOOTER = 25;
const BORDER = 8;

const prerenderCache = Utils.makeCtx(Display.offsetWidth, Display.offsetHeight);

@View([CommonComponentsModule, GameComponentsModule])
export class UiView implements IAbstractView {
    sidebarHidden: boolean;
    topLeft: Pointer;
    topRight: Pointer;
    bottomLeft: Pointer;
    bottomRight: Pointer;

    ui = null;

    @Inject(ActivePlayer) protected activePlayer: PlayerModel;
    @Inject(FocusedWindow) protected focusedWindow: UiWindow;
    @Inject(Textures) protected textures: ITextures;
    @Inject(Layers) private layers: Layers;
    private ctx: CanvasRenderingContext2D;
    private uiSidebar: UiSidebar;
    private uiResources: UiResourcesPanel;

    constructor() {
        this.initSizes = this.initSizes.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
    }

    private initSizes() {
        const {width, height} = this.ctx.canvas;

        if (!this.isMobile && this.uiSidebar.hidden) {
            this.uiSidebar.show();
            this.sidebarHidden = false;
        }
        if (this.isMobile && !this.uiSidebar.hidden) {
            this.uiSidebar.hide();
            this.sidebarHidden = true;
        }

        this.topLeft = new Pointer(BORDER, BORDER);
        this.topRight = new Pointer(width - this.uiSidebar.WIDTH - BORDER, BORDER);
        this.bottomLeft = new Pointer(BORDER, height - FOOTER);
        this.bottomRight = new Pointer(width - this.uiSidebar.WIDTH - BORDER, height - FOOTER);
        this.uiSidebar.__update();
        this.prerender();
    }

    private drawBorders(left: number, top: number, width: number, height: number) {
        this.ctx.lineWidth = 1;
        this.ctx.strokeStyle = '#392715';
        this.ctx.strokeRect(left + 0.5, top + 0.5, width - 1, height - 1);

        this.ctx.strokeStyle = '#b59442';
        this.ctx.strokeRect(left + 1.5, top + 1.5, width - 3, height - 3);

        this.ctx.strokeStyle = '#e7ce8c';
        this.ctx.strokeRect(left + 2.5, top + 2.5, width - 5, height - 5);
    }

    private getGemSpriteIndex(colorIndex: number, cornerIndex: number): number {
        return (colorIndex << 2) + cornerIndex;
    }

    private drawCornerGems() {
        const cornerGemSprite = <UiSprite>this.textures.get('corner_gems');
        const cornerSize = cornerGemSprite.width;
        const left = this.topLeft.x;
        const top = this.topLeft.y;
        const right = this.topRight.x - cornerSize;
        const bottom = this.bottomLeft.y - cornerSize;
        const {colorIndex} = this.activePlayer;

        cornerGemSprite.draw(left, top, this.getGemSpriteIndex(colorIndex, 0), this.ctx);
        cornerGemSprite.draw(right, top, this.getGemSpriteIndex(colorIndex, 1), this.ctx);
        cornerGemSprite.draw(left, bottom, this.getGemSpriteIndex(colorIndex, 2), this.ctx);
        cornerGemSprite.draw(right, bottom, this.getGemSpriteIndex(colorIndex, 3), this.ctx);
    }

    private drawMapCorners() {
        const {width, height} = this.ctx.canvas;
        const panelColoredBg = this.textures.get('panel_colored_bg');

        this.ctx.fillStyle = this.ctx.createPattern(panelColoredBg.img, 'repeat');
        this.ctx.fillRect(3, 3, 5, height - 22 - 3);
        this.ctx.fillRect(3, 3, width - this.uiSidebar.WIDTH - 3, 5);
        this.ctx.fillRect(width - this.uiSidebar.WIDTH - 8, 3, 5, height - 22 - 3);

        this.drawBorders(5, 5, width - this.uiSidebar.WIDTH - 10, height - 29);

        this.drawCornerGems();
    }

    get isMobile(): boolean {
        return Display.offsetWidth < 800;
    }

    prepare({ctx}: Canvas) {
        this.ctx = ctx;
        this.uiSidebar = new UiSidebar();
        this.uiResources = new UiResourcesPanel();

        this.initSizes();
        Events
            .on('touchstart', this.handleMouseMove)
            .on('mousemove', this.handleMouseMove)
            .on('resize', this.initSizes);
    }

    getWindowWidth(): number {
        return this.ctx.canvas.width;
    }

    getMapWidth(): number {
        return this.isMobile
            ? this.ctx.canvas.width
            : this.ctx.canvas.width - this.uiSidebar.WIDTH;
    }

    getWindowHeight(): number {
        return this.ctx.canvas.height;
    }

    private prerender() {
        prerenderCache.canvas.width = Display.offsetWidth;
        prerenderCache.canvas.height = Display.offsetHeight;

        this.ctx = prerenderCache;
        this.staticDraw();
        this.ctx = this.layers.uiCanvas.ctx;
    }

    staticDraw() {
        const {width, height} = this.ctx.canvas;

        this.drawMapCorners();
        this.uiResources.useCtx(this.ctx, function() {this.staticDraw()});
        this.drawBorders(0, 0, width, height);
    }

    draw(canvas: Canvas) {
        this.ctx.drawImage(prerenderCache.canvas, 0, 0);
        this.uiSidebar.draw();
        this.uiResources.draw();
    }

    private handleMouseMove() {
        const {width, height} = this.ctx.canvas;
        const mousePos = Events.getMousePos();
        const mapWidth = width - this.uiSidebar.WIDTH;
        const mapHeight = height - FOOTER;
        const focusedWindow = <UiSidebar | UiResourcesPanel><any>this.focusedWindow;

        if ((focusedWindow === null) || (focusedWindow === this.uiSidebar) || (focusedWindow === this.uiResources)) {
            if (mousePos.x > mapWidth) {
                UiController.setFocus(this.uiSidebar);
            } else if (mousePos.y > mapHeight) {
                UiController.setFocus(this.uiResources);
            } else {
                UiController.setFocus(null);
            }
        }
    }

    showSidebar() {
        this.sidebarHidden = false;
        this.uiSidebar.show();
        this.layers.setGreyscale(true);
    }

    hideSidebar() {
        this.sidebarHidden = true;
        this.uiSidebar.hide();
        this.layers.setGreyscale(false);
    }
}
