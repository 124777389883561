
import {UiWindow} from "./common/UiWindow";
import {UiButton} from "./common/UiButton";
import {UiSpriteButton} from "./common/UiSpriteButton";
import {UiGroup} from "./common/UiGroup";
import {UiMap} from "./game/UiMap";
import {UiMapsListItem} from "./mainMenu/UiMapsListItem";
import {UiResourcesPanel} from "./game/UiResourcesPanel";
import {UiStatusWindow} from "./game/statusWindow/UiStatusWindow";
import {UiSidebar} from "./game/sidebar/UiSidebar";
import {UiSidebarHero} from "./game/sidebar/UiSidebarHero";
import {UiSidebarTown} from "./game/sidebar/UiSidebarTown";
import {UiBattleField} from "./game/battle/UiBattleField";
import {UiRepeat} from "./common/UiRepeat";
import {UiCheckbox} from "./common/UiCheckbox";
import {UiGarissonStack} from "./common/UiGarissonStack";
import {UiImage} from "./common/UiImage";
import {UiScrollView} from "./common/UiScrollView";
import {UiText} from "./common/UiText";
import {UiArtifactsStack} from "./common/UiArtifactsStack";
import {UiSavedGameItem} from "./game/UiSavedGameItem";

export const CommonComponentsModule = {
    UiWindow,
    UiButton,
    UiSpriteButton,
    UiGroup,
    UiRepeat,
    UiCheckbox,
    UiImage,
    UiScrollView,
    UiText
};

export const GameComponentsModule = {
    UiMap,
    UiSavedGameItem,
    UiResourcesPanel,
    UiStatusWindow,
    UiSidebar,
    UiSidebarHero,
    UiSidebarTown,
    UiBattleField,
    UiGarissonStack,
    UiArtifactsStack
};

export const MenuComponentsModule = {
    UiMapsListItem
};

export function View (components) {
    return function (target) {
        target['__preloadComponents'] = components;
    }
}