
import {Layers} from "../render/layers/Layers";
import {UiStatusWindow} from "../views/game/statusWindow/UiStatusWindow";
import {UiTipWindow} from "../views/windows/UiTipWindow";
import {UiAlertWindow} from "../views/windows/UiAlertWindow";
import {UiWindow} from "../views/common/UiWindow";
import {Canvas} from "../render/layers/Canvas";
import {UiSprite} from "../views/sprites/UiSprite";
import {IAbstractView} from "../views/IAbstractView";
import {FocusedWindow} from "./FocusedWindow";
import {Inject, setInject} from "../helpers/InjectDectorator";
import {ActiveView} from './ActiveView';

class UiControllerSingleton {

    statusWindow: UiStatusWindow;

    private rootWindowsList: Set<UiWindow> = new Set();
    private modalStack = [];
    @Inject(FocusedWindow) protected focusedWindow: UiWindow;
    @Inject(ActiveView) private activeView: IAbstractView;
    @Inject(Layers) private layers: Layers;

    constructor() {
        this.prepare = this.prepare.bind(this);
        this.draw = this.draw.bind(this);
    }

    bootstrap(uiView: IAbstractView) {
        setInject(ActiveView, uiView);
    }

    prepare(canvas: Canvas) {
        this.activeView.prepare(canvas);
    }

    createTipWindow(text: string, iconSprite: UiSprite = null, iconSpriteIndex: number = 0): UiWindow {
        return this.modal(UiTipWindow, text, iconSprite, iconSpriteIndex);
    }

    alert(text: string): UiWindow {
        return this.modal(UiAlertWindow, text);
    }

    modal(Constructor: any, ...params): UiWindow {
        const wnd = new (Function.bind.apply(Constructor, arguments));

        this.rootWindowsList.add(wnd);
        this.modalStack.unshift(wnd);
        this.setFocus(wnd);

        if (wnd.options.modal) {
            this.setMapGreyscale(true);
        }

        return wnd;
    }

    remove(uiObject) {
        if (this.modalStack.indexOf(uiObject) !== -1) {
            this.modalStack.splice(this.modalStack.indexOf(uiObject), 1);

            if (this.modalStack.length) {
                this.setFocus(this.modalStack[0]);
            } else {
                this.setFocus(this.activeView.ui);

                if (uiObject.options.modal) {
                    this.setMapGreyscale(false);
                }
            }
        } else if ((this.focusedWindow && this.focusedWindow.id) === uiObject.id) {
            this.setFocus(this.activeView.ui);

            if (uiObject.options.modal) {
                this.setMapGreyscale(false);
            }
        }

        uiObject.removeChildren();
        uiObject.events.dispatch('remove');
        uiObject.events.detachAll();

        if (typeof uiObject.remove === 'function') {
            uiObject.remove();
        }

        const parentWindowList = (uiObject.parent && uiObject.parent.children) || this.rootWindowsList;

        parentWindowList.delete(uiObject);
    }

    clear() {
        this.rootWindowsList.forEach(this.remove.bind(this));

        setInject(FocusedWindow, null);
    }

    setFocus(windowObject) {
        setInject(FocusedWindow, windowObject);
    }

    setMapGreyscale(isSet: boolean) {
        this.layers.setGreyscale(isSet);
    }

    draw(canvas: Canvas) {
        canvas.clear();
        this.activeView.draw();

        this.rootWindowsList.forEach(wnd => wnd.draw());
    }
}

export const UiController = new UiControllerSingleton();
