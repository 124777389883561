
import {Events} from "../../../controllers/Events";
import {HeroObject} from "./HeroObject";

export class HeroGoState {

    constructor(private hero: HeroObject) {
        Events.on('mousedown', () => {
            if (this.isActive()) {
                this.hero.owner.stopCommands();
            }
        });
    }

    isActive() {
        return this.hero.state === 'GO';
    }

    handleInput() {
        const keys = Events.getKeys();

        if (keys.size) {
            this.hero.owner.stopCommands();
        }
    }
}