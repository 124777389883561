
function isObject(obj): boolean {
    return obj && ("object" === typeof obj);
}

export function groupBy(array: any[], groupByField: string): {} {
    let item;
    let result = {};
    if (isObject(array)) {
        for (let key in array) {
            item = array[key];
            if (item[groupByField] == null) {
                item[groupByField] = null;
            }
            if (result[item[groupByField]] == null) {
                result[item[groupByField]] = [];
            }
            result[item[groupByField]].push(item);
        }
    } else {
        for (item of Array.from(array)) {
            if (item[groupByField] == null) {
                item[groupByField] = null;
            }
            if (result[groupByField] == null) {
                result[groupByField] = [];
            }
            result[groupByField].push(item);
        }
    }
    return result;
}

export function shuffle(array: any[]): any[] {
    let result = array.slice(0);
    let i = result.length - 1;
    while (i > 0) {
        let j = Math.floor(Math.random() * (i + 1));
        let temp = result[i];
        result[i] = result[j];
        result[j] = temp;
        i--;
    }
    return result;
}

export function some(array: any[], count: number = 1): any[] {
    if (count < 2) {
        let ind;
        return [array[(ind = Math.floor(Math.random() * array.length))]];
    } else {
        return shuffle(array).slice(0, count);
    }
}

export function something(array: any[]): any {
    return some(array, 1)[0];
}

export function forEach<T>(collection: T, callback: Function): T {
    let item;
    if (Array.isArray(collection)) {
        for (item of collection) {
            if (callback(item) === false) { break; }
        }
    }
    if (typeof collection === 'object') {
        for (let key in collection) {
            item = collection[key];
            if (callback(item, key) === false) { break; }
        }
    }
    return collection;
}

export function find(collection: any[] | {}, filterFunction: Function): any {
    let result = null;
    forEach(collection, function(item) {
        if (filterFunction(item)) {
            result = item;
            return false;
        }
    });
    return result;
}

export function filter(collection: any[] | {}, filterFunction: Function): any[] {
    let result = [];
    forEach(collection, function(item, index) {
        if (filterFunction(item, index)) { return result.push(item); }
    });
    return result;
}

export function isArray(any: any[]): boolean {
    return Array.isArray(any);
}

function leftPad(string: string): string {
    return Array(8 - string.length).fill('0').join('') + string;
}

export function readAsBinaryMask(array: number[]): string {
    const result = array.reduce((memo: string, current: number) => {
        return memo + leftPad(current.toString(2));
    }, '');
    return result.split('').reverse().join('');
}

export function filterByBinaryMask(array: any[], mask: string): any[] {
    let result = [];
    for (let index = 0; index < mask.length; index++) {
        let bit = mask[index];
        if (Number(bit)) {
            result.push(array[index]);
        }
    }
    return result;
}

export function readAsString(array: number[]): string {
    let normalize = function(value) {
        if (value > 192) {
            return value + 848;
        } else {
            return value;
        }
    };
    return array.reduce(((memo, current) => memo + String.fromCharCode(normalize(current))), '');
}