
import * as Utils from "../../utils/Utils";
import {Grid} from "../../helpers/Grid";
import {MapObject} from "../MapObject";
import {TilesCollection} from "../map/TilesCollection";
import {ObjectsCollection} from "../ObjectsCollection";
import {StateService} from "../../services/StateService";
import {MapDataReader} from '../map/MapDataReader';
import {Inject} from '../../helpers/InjectDectorator';
import {MapData} from '../../controllers/MapData';

const ACTION = 'a';
const IMPASSABLE = 'i';
const PASSABLE = 'p';
const TERRAIN = 't';
const ROAD = 'r';

export interface IGridStorageQueryResult {
    actions: string[];
    impassable: string[];
    passable: string[];
    terrain: string;
    road: string;
    fog: boolean;
}

export class GridStorage {

    static: Grid = null;
    dynamic: Grid = null;

    @Inject(MapData) private mapData: MapDataReader;
    @Inject(StateService) private stateService: StateService;
    @Inject(ObjectsCollection) private objectsCollection: ObjectsCollection;

    init() {
        this.static = this.getStaticGrid();
        this.dynamic = this.getDynamicGrid();
    }

    update() {
        this.dynamic = this.getDynamicGrid();
    }

    updatePoints(p1x: number, p1y: number, p2x: number, p2y: number, object: MapObject, object2: MapObject) {
        if (object2 == null) {
            object2 = null;
        }

        this.dynamic.set(p1x, p1y, object2 != null ? object2.id : undefined);

        if (!object.hidden) {
            this.dynamic.set(p2x, p2y, object.id);
        }
    }

    clear() {
        this.static.clear();
        this.dynamic.clear();
    }

    get(x: number, y: number): IGridStorageQueryResult {
        const dynamicObj = this.dynamic.get(x, y);
        const staticGrid = this.getCellData(this.static, x, y);
        const dynamicSection = [];

        if (dynamicObj != null) {
            dynamicSection.push(dynamicObj);
        }

        return {
            actions: dynamicSection.concat(staticGrid.actions),
            impassable: dynamicSection.concat(staticGrid.impassable),
            passable: staticGrid.passable,
            terrain: staticGrid.terrain,
            road: staticGrid.road,
            fog: false
        };
    }

    private getStaticGrid(): Grid {
        const {size} = this.mapData.props;
        const z = this.stateService.get('center.z');
        const grid = new Grid(size);

        TilesCollection.forEach(z, (...args) => {
            let [x, y, z, terrain, isMoorable, sprite, mirrorType, riverType, riverSpriteIndex, riverMirrorType, roadType] = Array.from(args[0]);

            grid.set(x, y, `${TERRAIN}:${terrain},${ROAD}:${+Boolean(~~roadType)}`);
        });

        this.objectsCollection.static.forEach((object) => {
            if (object.z !== z) {
                return;
            }

            this.addObject(object, grid);
        });

        return grid;
    }

    private pushItemToCell(grid: Grid, item: string, x: number, y: number) {
        const items = (grid.get(x, y) || '').split(',');

        items.push(item);
        grid.set(x, y, items.join());
    }

    private unshiftItemToCell(grid: Grid, item: string, x: number, y: number) {
        const items = (grid.get(x, y) || '').split(',');

        items.unshift(item);
        grid.set(x, y, items.join());
    }

    private addObject(object: MapObject, grid: Grid) {
        if (object.hidden) {
            return;
        }

        const rect = object.getRect();

        Utils.forEachRect(rect, (x: number, y: number) => {
            let left = (x * 32) + 1;
            let top = (y * 32) + 1;

            let addItem = this.pushItemToCell;

            if (object.isHero || object.isTown) {
                addItem = this.unshiftItemToCell;
            }

            if (object.isActionInPoint(left, top)) {
                addItem(grid, `${ACTION}:${object.id}`, x, y);

                return;
            }

            if (object.isPointInside(left, top)) {
                addItem(grid, `${IMPASSABLE}:${object.id}`, x, y);

                return;
            }
            if (object.type === 'passable_terrain') {
                addItem(grid, `${PASSABLE}:${object.id}`, x, y);
            }
        });
    }

    private getDynamicGrid(): Grid {
        const {size} = this.mapData.props;
        const z = this.stateService.get('center.z');
        const grid = new Grid(size);

        this.objectsCollection.dynamic.forEach((object: MapObject) => {
            if (object.z !== z) {
                return;
            }
            if (object.hidden) {
                return;
            }

            const {x, y, id} = object;

            if (object.isHero || object.isBoat) {
                return grid.set(x - 1, y, id);
            } else {
                return grid.set(x, y, id);
            }
        });

        return grid;
    }

    private getCellData(grid: Grid, x: number, y: number): IGridStorageQueryResult {
        let items = (grid.get(x, y) || '').split(',');
        let result = {
            actions: [],
            impassable: [],
            passable: [],
            terrain: null,
            road: null,
            fog: false
        };

        for (let item of items) {
            if (item[0] === ACTION) {
                result.actions.push(item.substr(2));
            }
            if (~[ACTION, IMPASSABLE].indexOf(item[0])) {
                result.impassable.push(item.substr(2));
            }
            if (~[PASSABLE].indexOf(item[0])) {
                result.passable.push(item.substr(2));
            }
            if (~[TERRAIN].indexOf(item[0])) {
                result.terrain = item.substr(2);
            }
            if (~[ROAD].indexOf(item[0])) {
                result.road = item.substr(2);
            }
        }

        return result;
    }
}
