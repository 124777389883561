
import {UiWindow} from "../common/UiWindow";
import {Events} from "../../controllers/Events";
import * as Utils from "../../utils/Utils";
import {TextService} from "../../services/TextService";
import {CreaturesStorage} from "../../models/storages/CreaturesStorage";
import {MonsterObject} from "../../models/objects/MonsterObject";
import {IAbstractView} from '../IAbstractView';
import {Inject, inject} from '../../helpers/InjectDectorator';
import {ActiveView} from '../../controllers/ActiveView';

export class UiCreatureTipWindow extends UiWindow {

    @Inject(TextService) protected textService: TextService;

    constructor(object: MonsterObject, options = {}) {
        let windowWidth = 298;
        let windowHeight = 311;
        const activeView = inject<IAbstractView>(ActiveView);

        let mousePos = Events.getMousePos();
        let maxLeft = ~~(windowWidth / 2) + 30;
        let maxRight = activeView.getWindowWidth() - maxLeft;
        let maxTop = ~~(windowHeight / 2) + 30;
        let maxBottom = activeView.getWindowHeight() - maxTop;
        let centerLeft = Math.min(Math.max(mousePos.x, maxLeft), maxRight);
        let centerTop = Math.min(Math.max(mousePos.y, maxTop), maxBottom);

        let left = ~~(centerLeft - (windowWidth / 2));
        let top = ~~(centerTop - (windowHeight / 2));

        super(Utils.extend({
                left,
                top,
                width: windowWidth,
                height: windowHeight,
                centered: false,
                borders: false,
                background: 'creature_popup_bg',
                hideCursor: true
            }
            , options)
        );

        this.scope = {
            name: this.textService.i18n(`MONSTERS.${object.creatureId}.PLURAL`),
            data: CreaturesStorage.prototype.getCreatureData(object.creatureId),
            object
        };

        setTimeout(() => {
            this.setMarkup('game/popups/creature.xml');
        });
    }
}