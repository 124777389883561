
/* virtual class to make simple links to often user resources */
export class HeroesData {

}

export interface IHeroSecondaryConfig {
    level: number;
    name: string;
}

export interface IHeroStackConfig {
    low: string;
    high: string;
    type: string;
}

export interface IHeroConfig {
    id: number;
    name: string;
    race: number;
    stacks: IHeroStackConfig[];
    class: string;
    spec: string;
    skill1: IHeroSecondaryConfig;
    skill2: IHeroSecondaryConfig;
}

export interface IHeroesData {
    [key: string]: IHeroConfig
}