
import {GenericVisitableObject} from "./GenericVisitableObject";
import {UiController} from "../../../controllers/UiController";

export class GardenOfRevelationObject extends GenericVisitableObject {

    recordVisits = true;

    constructor(data, id) {
        super(data, id);

        this.events.on('action', (hero) => {
            hero.properties.get('knowledge').push(1);
            UiController.alert('Knowledge +1');
        });
    }
}
