
export const TERRAINS = {
    DIRT: 0,
    SAND: 1,
    GRASS: 2,
    SNOW: 3,
    SWAM: 4,
    STONES: 5,
    DUNGEON: 6,
    LAVA: 7,
    WATER: 8,
    EDGE: 9
};
