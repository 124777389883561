
import * as Utils from "../utils/Utils";
import {TextService} from "./TextService";
import {Events} from "../controllers/Events";
import {UiController} from "../controllers/UiController";
import {StateService} from './StateService';
import {ICreatureData, MonstersData} from "../controllers/MonstersData";
import {Inject} from "../helpers/InjectDectorator";

const MAX_DAY = 7;
const MAX_WEEK = 4;
const MAX_MONTH = 12;

const MONSTERS_OF_MONTH = [
    "Griffin",
    "Pegasus",
    "Wraith",
    "Harpy",
    "Lizardman",
    "SerpentFly",
    "Gog",
    "Centaur",
    "WolfRider",
    "Troglodyte",
    "Gremlin",
    "Hobgoblin"
];

interface IWeekMonth {
    value?: number;
    type: string;
    data?: {
        name?: string;
        monster?: {
            id: number;
        };
        amount?: number;
    }
}

interface IDay {
    value: number;
}

export class TimeService {
    month: IWeekMonth;
    week: IWeekMonth;
    day: IDay;

    @Inject(StateService) private stateService: StateService;

    private RANDOMIZE_MONTH = {
        [40]: this.getMonsterGrowthMonth.bind(this),
        [50]: this.getEmptyMonth.bind(this),
        [10]: this.getPlagueMonth.bind(this)
    };

    private RANDOMIZE_WEEK = {
        [25]: this.getMonsterGrowthWeek.bind(this),
        [75]: this.getEmptyWeek.bind(this)
    };

    @Inject(MonstersData) private monstersData: ICreatureData[];
    @Inject(TextService) private textService: TextService;

    isFirstWeek(): boolean {
        return this.week.value === 1;
    }

    isFirstDay(): boolean {
        return this.day.value === 1;
    }

    getText(): string {
        return `Day: ${this.day.value}, Week: ${this.week.value}, Month: ${this.month.value}`;
    }

    bootstrap() {
        if (this.stateService.get('time.day')) {
            this.day = this.stateService.get('time.day');
            this.week = this.stateService.get('time.week');
            this.month = this.stateService.get('time.month');
        } else {
            this.day = {value: 1};
            this.week = Utils.extend({value: 1}, this.getEmptyWeek());
            this.month = Utils.extend({value: 1}, this.getEmptyMonth());
        }

        this.stateService.set('time', {
            day: this.day,
            week: this.week,
            month: this.month
        });

        Events.on('game.newTurn', () => {
            this.day.value++;
            if (this.day.value > MAX_DAY) {
                this.week.value++;
                this.day.value = 1;
                if (this.week.value > MAX_WEEK) {
                    this.week.value = 1;
                    this.month.value++;
                    this.onNewMonthStart();
                } else {
                    this.onNewWeekStart();
                }
            }

            this.stateService.set('time.day', this.day);
        });
    }

    private onNewWeekStart() {
        this.week = this.getRandomWeek(this.week);

        this.stateService.set('time.week', this.week);

        this.showNewWeekMessage();
    }

    private onNewMonthStart() {
        this.month = this.getRandomMonth(this.month);

        this.stateService.set('time.month', this.month);

        this.showNewMonthMessage();
    }

    private showNewWeekMessage() {
        let monster, msg;

        if (this.week.type === 'growth') {
            monster = this.textService.i18n(`MONSTERS.${this.week.data.monster.id}.SINGULAR`);
            msg = `Astrologers proclaim week of the ${monster}. ${monster} is growthed +5 in all dwellings produces it`;
        }

        if (this.week.type === 'empty') {
            monster = this.week.data.name;
            msg = `Astrologers proclaim week of the ${monster}. Maybe it's good`;
        }

        UiController.alert(msg);
    }

    private showNewMonthMessage() {
        let monster, msg;

        if (this.week.type === 'growth') {
            monster = this.textService.i18n(`MONSTERS.${this.week.data.monster.id}.SINGULAR`);
            msg = `Astrologers proclaim month of the ${monster}. Production of ${monster} is increased`;
        }

        if (this.week.type === 'empty') {
            monster = this.week.data.name;
            msg = `Astrologers proclaim month of the ${monster}. Maybe it's good`;
        }

        if (this.week.type === 'plague') {
            monster = this.week.data.name;
            msg = "Astrologers proclaim month of PLAGUE! All gone bad...";
        }

        UiController.alert(msg);
    }

    private getPlagueMonth(): IWeekMonth {
        return {type: 'plague'};
    };

    private getRandomMonth(month): IWeekMonth {
        return Utils.extend({value: month.value}, Utils.randomize(this.RANDOMIZE_MONTH));
    }

    private getRandomWeek(week): IWeekMonth {
        return Utils.extend({value: week.value}, Utils.randomize(this.RANDOMIZE_WEEK));
    }

    private getEmptyWeek(): IWeekMonth {
        return {
            type: 'empty',
            data: {
                name: Utils.something(this.textService.i18n('MESSAGES.TIME.WEEK_NAMES'))
            }
        };
    };

    private getMonsterGrowthWeek(): IWeekMonth {
        let monsters = Utils.filter(this.monstersData, item => ~item.race);
        let monstersByLevel = Utils.groupBy(monsters, 'level');
        let invertedKeys = Object.keys(monstersByLevel).map(this.normalizeValue).reverse();
        let invertedMonstersByLevel = {};
        let i = 0;
        for (let key in monstersByLevel) {
            let value = monstersByLevel[key];
            invertedMonstersByLevel[invertedKeys[i]] = value;
            i++;
        }

        return {
            type: 'growth',
            data: {
                monster: Utils.something(Utils.randomize(invertedMonstersByLevel)),
                amount: 5
            }
        };
    };

    private getEmptyMonth(): IWeekMonth {
        return {
            type: 'empty',
            data: {
                name: Utils.something(this.textService.i18n('MESSAGES.TIME.MONTH_NAMES'))
            }
        };
    };

    private getMonsterGrowthMonth(): IWeekMonth {
        const monsters = Utils.filter(this.monstersData, item => {
            return ~MONSTERS_OF_MONTH.indexOf(item.type);
        });
        const {id} = <ICreatureData>Utils.something(monsters);

        return {
            type: 'growth',
            data: {
                monster: {id}
            }
        };
    };

    private normalizeValue(aIValue): number {
        return parseFloat(aIValue) || 0;
    }
}