
import {DIRECTIONS} from "./DIRECTIONS";

export const ANIMATIONS = {
    DEFAULT_MAP_OBJECT: {
        "IDLE": [0, 1, 2, 3, 4, 5, 6, 7]
    },

    HERO: {
        [`${DIRECTIONS.NN}_IDLE`]: [0],
        [`${DIRECTIONS.NE}_IDLE`]: [1],
        [`${DIRECTIONS.EE}_IDLE`]: [2],
        [`${DIRECTIONS.SE}_IDLE`]: [3],
        [`${DIRECTIONS.SS}_IDLE`]: [4],
        [`${DIRECTIONS.SW}_IDLE`]: [5],
        [`${DIRECTIONS.WW}_IDLE`]: [6],
        [`${DIRECTIONS.NW}_IDLE`]: [7],
        [`${DIRECTIONS.NN}_GO`]: [8 , 16, 24, 32, 40, 48, 56, 64],
        [`${DIRECTIONS.NE}_GO`]: [9 , 17, 25, 33, 41, 49, 57, 65],
        [`${DIRECTIONS.EE}_GO`]: [10, 18, 26, 34, 42, 50, 58, 66],
        [`${DIRECTIONS.SE}_GO`]: [11, 19, 27, 35, 43, 51, 59, 67],
        [`${DIRECTIONS.SS}_GO`]: [12, 20, 28, 36, 44, 52, 60, 68],
        [`${DIRECTIONS.SW}_GO`]: [13, 21, 29, 37, 45, 53, 61, 69],
        [`${DIRECTIONS.WW}_GO`]: [14, 22, 30, 38, 46, 54, 62, 70],
        [`${DIRECTIONS.NW}_GO`]: [15, 23, 31, 39, 47, 55, 63, 71]
    }
};