
import {UiPanel} from "../../UiPanel";
import {IStatusWindowState} from "./IStatusWindowState";
import { UiSprite } from "../../sprites/UiSprite";
import { StateService } from "../../../services/StateService";
import { UiStatusWindow } from "./UiStatusWindow";
import {ITextures, Textures} from '../../../controllers/Textures';
import {Inject} from '../../../helpers/InjectDectorator';

export class NewDayScreen extends UiPanel implements IStatusWindowState {
    stateParams: { [key: string]: any };
    parent: UiStatusWindow;

    private textLeftPosition = 0;
    private textTopPosition = 0;
    private newDaySprite: UiSprite;
    private dayValue = 0;

    @Inject(Textures) protected textures: ITextures;
    @Inject(StateService) protected stateService: StateService;

    prepare() {
        this.textLeftPosition = this.width / 2;
        this.textTopPosition = 30;

        this.newDaySprite = <UiSprite>this.textures.get('new_day');
        this.newDaySprite.setAnimationOnce('IDLE').animationPromise.then(() => {
            this.newDaySprite.spriteIndex = 9;

            setTimeout(() => {
                this.parent.setState('SELECTED_OBJECT');
            }, 1000);
        });

        this.dayValue = this.stateService.get('time.day.value');
    }

    exit() {}

    draw() {
        this.newDaySprite.draw(this.absoluteLeft + 7, this.absoluteTop + 12);

        this.text({
            text: `Day ${this.dayValue}`,
            left: this.textLeftPosition,
            top: this.textTopPosition,
            align: 'center',
            fontSize: '16px',
            color: '#FFFFFF'
        });
    }
}