
export class EventsStorage {

    private callbacksMap: Map<string, Set<Function>> = new Map();
    private oneBindedCallbacks: WeakSet<Function> = new WeakSet();
    onDispatch: Function = () => true;

    constructor() {}

    private getOrCreateCallbacks(type: string): Set<Function> {
        if (!this.callbacksMap.has(type)) {
            this.callbacksMap.set(type, new Set());
        }
        return this.callbacksMap.get(type);
    }

    on(type: string, callback: Function) {
        const callbacks = this.getOrCreateCallbacks(type);
        callbacks.add(callback);
        return this;
    }

    one(type: string, callback: Function) {
        this.on(type, callback);
        this.oneBindedCallbacks.add(callback);
        return this;
    }

    off(type: string) {
        this.callbacksMap.delete(type);
        return this;
    }

    setOnDispatch(onDispatch: Function) {
        this.onDispatch = onDispatch;
        return this;
    }

    dispatch(type: string, eventData?: {}) {
        if (!this.onDispatch(type, eventData)) {
            return;
        }

        let broken = false;
        const callbacks = this.getOrCreateCallbacks(type);

        callbacks.forEach(callback => {
            if (broken) {
                return;
            }
            const result = callback(eventData);
            broken = result === false;

            if (this.oneBindedCallbacks.has(callback)) {
                setTimeout(callbacks.delete.bind(callbacks, callback));
            }
        });

        return this;
    }

    detachAll() {
        this.callbacksMap.clear();
        return this;
    }
};
