
import {Pointer} from "../models/Pointer";
import {Astar} from "../helpers/Astar";
import {Graph} from "../helpers/Graph";
import {Layers} from "../render/layers/Layers";
import {MapModel} from "../models/map/MapModel";
import {TilesCollection} from "../models/map/TilesCollection";
import {ObjectsCollection} from "../models/ObjectsCollection";
import {Grid} from "../helpers/Grid";
import {GraphNode} from "../helpers/GraphNode";
import {Rect} from "../helpers/Rect";
import {HeroObject} from "../models/objects/hero/HeroObject";
import {StateService} from "./StateService";
import * as Utils from "../utils/Utils";
import {Inject} from '../helpers/InjectDectorator';

const GREEN_CROSS = 48;
const RED_CROSS = 49;

export interface IPathItem {
    point: Pointer;
    spriteIndex: number;
    direction: number;
    movementCost: number;
    available: boolean;
}

export interface IPath extends Array<IPathItem> {
    totalCost?: number;
}

interface IPathFindingGrids {
    earth: Graph;
    water: Graph;
    swim: Graph;
}

class PathFinderServiceSingleton {

    grids: IPathFindingGrids[] = [];

    @Inject(Layers) private layers: Layers;
    @Inject(StateService) private stateService: StateService;
    @Inject(ObjectsCollection) private objectsCollection: ObjectsCollection;

    bootstrap() {
        this.initGrids();
    }

    gridDebug(type: string) {
        const z = this.stateService.get('center.z');
        const graph = this.grids[z][type];
        const { ctx } = this.layers.mapCanvas;
        ctx.strokeStyle = 'rgba(0, 255, 0, 1)';
        ctx.fillStyle = 'rgba(255, 0, 0, .5)';
        const mapSize = MapModel.size;
        let x = mapSize;
        let y = mapSize;

        while (x--) {
            while (y--) {
                const rx = x * 32;
                const ry = y * 32;
                const cell = graph.grid.get(x, y);

                if (cell && cell.weight) {
                    ctx.strokeRect(rx, ry, 30, 30);
                } else {
                    ctx.fillRect(rx, ry, 30, 30);
                }
            }
            y = mapSize;
        }
    }

    updateByMask(maskGrid: Grid) {
        const z = this.stateService.get('center.z');

        this.updateByGridTypeAndMask('earth', this.grids[z]['earth'], maskGrid, z);
        this.updateByGridTypeAndMask('water', this.grids[z]['water'], maskGrid, z);
        this.updateByGridTypeAndMask('swim', this.grids[z]['swim'], maskGrid, z);
    }

    private updateGridRect(gridType: string, graph: Graph, rect: Rect, z: number) {
        Utils.forEachRect(rect, (x, y) => {
            if (TilesCollection.has(x, y, z)) {
                const weight = MapModel.getPassability(gridType, x, y);
                const cell = graph.grid.get(x, y);

                cell.weight = weight;
            }
        });
    }

    private getPathPoints(sX: number, sY: number, dX: number, dY: number, heroObject: HeroObject): GraphNode[] {
        let enterPoints;

        const z = this.stateService.get('center.z');
        const gridType = heroObject.pathFinder.getGridType();
        const graph = this.grids[z][gridType];
        const from = graph.grid.get(sX, sY);
        const to = graph.grid.get(dX, dY);
        const {actions} = MapModel.get(dX, dY, z);
        const objectId = parseInt(actions.shift());

        if (objectId) {
            const object = this.objectsCollection.getById(objectId);

            if (object) {
                enterPoints = object.getEnterPoints(graph.grid, dX, dY, from);

                if ((object as any).isMonster && enterPoints.length) {
                    const betterPath = this.findBetterPathThroughPoints(enterPoints, point => {
                        point.weight = MapModel.tryPathToPos(gridType, point.x, point.y, z);
                        const result = Astar.search(graph, from, point);
                        point.weight = MapModel.getPassability(gridType, point.x, point.y, z);

                        return result;
                    });

                    if (betterPath) {
                        return betterPath.concat(to);
                    }
                }
            }
        }

        if (to) {
            to.weight = MapModel.tryPathToPos(gridType, dX, dY, z);
        }

        let path = Astar.search(graph, from, to);

        if (to) {
            to.weight = MapModel.getPassability(gridType, dX, dY, z);
        }

        if ((path.length > 1) && enterPoints && enterPoints.length) {
            if (!enterPoints.includes(path[path.length - 2])) {

                const betterPath = this.findBetterPathThroughPoints(enterPoints, point => {
                    return Astar.search(graph, from, point);
                });

                if (betterPath) {
                    path = <any>betterPath.concat(to);
                } else {
                    path.length = 0;
                }

                let pathVariants = enterPoints.map(point => Astar.search(graph, from, point));
                pathVariants = pathVariants.filter(item => item.length).sort((a, b) => a.weight > b.weight);

                if (pathVariants.length) {
                    path = pathVariants[0].concat([to]);
                } else {
                    path.length = 0;
                }
            }
        }

        return <any>path;
    }

    updateRect(rectObject: Rect) {
        const z = this.stateService.get('center.z');

        this.updateGridRect('earth', this.grids[z]['earth'], rectObject, z);
        this.updateGridRect('water', this.grids[z]['water'], rectObject, z);
        this.updateGridRect('swim', this.grids[z]['swim'], rectObject, z);
    }

    getPath(sX: number, sY: number, dX: number, dY: number, heroObject: HeroObject, costEvalFunction = null): IPath {
        const points = this.getPathPoints(sX, sY, dX, dY, heroObject);
        let prevPathItem = new Pointer(sX, sY);
        const path: IPath = <IPath>[];
        path.totalCost = 0;
        let movePoints = heroObject.properties.getValue('movePoints');

        for (let index = 0; index < points.length; index++) {
            let movementCost, spriteIndex, toDirection;
            const point = points[index];
            const pathItem = new Pointer(point.x, point.y);

            if (index === (points.length - 1)) {
                toDirection = prevPathItem.getDirection(pathItem.x, pathItem.y);

                if (movePoints) {
                    spriteIndex = GREEN_CROSS;
                } else {
                    spriteIndex = RED_CROSS;
                }
            } else {
                const nextPathItem = points[index + 1];
                let fromDirection = prevPathItem.getDirection(pathItem.x, pathItem.y);
                toDirection = pathItem.getDirection(nextPathItem.x, nextPathItem.y);

                spriteIndex = this.getSpriteIndex(fromDirection, toDirection, movePoints);
            }

            if (costEvalFunction) {
                movementCost = costEvalFunction(point, point.x, point.y) * this.getMovementCost(toDirection);
            }

            path.push({
                point: pathItem,
                spriteIndex,
                direction: toDirection,
                movementCost,
                available: Boolean(movePoints)
            });

            path.totalCost += movementCost;
            movePoints = Math.max(movePoints - movementCost, 0);
            prevPathItem = pathItem;
        }

        return path;
    }

    private findBetterPathThroughPoints(points: GraphNode[], getPath: (point: GraphNode) => IPath): GraphNode[] {
        return points
            .map(point => <any>getPath(point))
            .filter(path => path.length)
            .sort((a, b) => a.weight < b.weight ? 1 : -1)
            .pop();
    }

    private getGrid(type: string, z: number): Graph {
        const mapSize = MapModel.size;
        const graph = new Graph(mapSize, mapSize);

        const rect = <Rect>{
            left: 0,
            top: 0,
            right: mapSize,
            bottom: mapSize
        };
        this.updateGridRect(type, graph, rect, z);

        return graph;
    }

    private updateByGridTypeAndMask(gridType: string, graph: Graph, maskGrid: Grid<GraphNode>, z: number) {
        maskGrid.forEach((item, x, y) => {
            if (TilesCollection.has(x, y, z)) {
                const cell = graph.grid.get(x, y);

                if (cell) {
                    cell.weight = MapModel.getPassability(gridType, x, y);
                }
            }
        });
    }

    private getMovementCost(direction): number {
        if (direction % 2) {
            return 141;
        } else {
            return 100;
        }
    }

    private getSpriteIndex(from, to, movePoints): number {
        let spriteIndex;

        if ((from === 7) && ((to === 0) || (to === 1))) {
            from = -1;
        }
        if (((from === 0) || (from === 1)) && (to === 7)) {
            from = 8;
        }

        if (from < to) {
            spriteIndex = to + 16;
        }
        if (from === to) {
            spriteIndex = to + 8;
        }
        if (from > to) {
            spriteIndex = to;
        }
        if (!movePoints) {
            // shift path item icon to same red item
            spriteIndex += 24;
        }
        return spriteIndex;
    }

    private initLevelGrids(z: number) {
        this.grids[z] = {
            earth: this.getGrid('earth', z),
            water: this.getGrid('water', z),
            swim: this.getGrid('swim', z)
        };
    }

    private initGrids() {
        this.initLevelGrids(0);
        if (MapModel.props.has_caves) {
            this.initLevelGrids(1);
        }
    }
}

export const PathFinderService = new PathFinderServiceSingleton();