
import {PlayerModel} from "../models/player/PlayerModel";

export class CommandHelper {

    owner: PlayerModel;
    done = false;

    private executeFn() { return true; }
    private undoFn() { return true; }

    setExecute(executeFn: () => any) {
        this.executeFn = executeFn;
    }
    setUndo(undoFn: () => any) {
        this.undoFn = undoFn;
    }

    execute() {
        this.done = true;
        return this.executeFn();
    }

    undo() {
        this.done = false;
        return this.undoFn();
    }
}