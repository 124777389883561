
import {IUiOptions, UiPanel} from "../UiPanel";
import * as Utils from "../../utils/Utils";
import {UiComponent} from "../../services/UiComponentsService";

export interface IUiScrollViewOptions extends IUiOptions {
    updatesOn?: string;
}

const DEFAULT_PARAMS = <IUiScrollViewOptions>{
    left: 0,
    top: 0,
    updatesOn: null
};

@UiComponent()
export class UiRepeat extends UiPanel {

    options: IUiScrollViewOptions;

    constructor(params: IUiScrollViewOptions) {
        super(Utils.extend(DEFAULT_PARAMS, params));
        this.draw = this.draw.bind(this);
    }

    get repeatItems(): any[] {
        return this.readParam('items');
    }

    calculateWidth(): number {
        let {width} = this;

        this.children.forEach(wnd => {
            const left = wnd.absoluteLeft - this.absoluteLeft;
            const wndWidth = wnd.width || this.parent.width;

            width = Math.max(this.width, left + wndWidth);
        });

        return width
    }

    calculateHeight(): number {
        let {height} = this;

        this.children.forEach(wnd => {
            const top = wnd.absoluteTop - this.absoluteTop;
            const wndHeight = wnd.height || this.parent.height;

            height = Math.max(this.height, top + wndHeight);
        });

        return height
    }

    draw() {
        this.children.forEach(wnd => wnd.draw());
    }
}