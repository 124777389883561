
import {EventsStorage} from "./storages/EventsStorage";
import {TextService} from "../services/TextService";
import {Events} from "../controllers/Events";
import * as Utils from "../utils/Utils";
import {CURSORS} from "../constants/CURSORS";
import {UiController} from "../controllers/UiController";
import {PlayerModel} from "./player/PlayerModel";
import {IMapObjectRenderTree} from "../render/RenderTree";
import {Grid} from "../helpers/Grid";
import {ObjectSprite} from "../views/sprites/ObjectSprite";
import {HeroObject} from "./objects/hero/HeroObject";
import {StateService} from "../services/StateService";
import {Inject} from "../helpers/InjectDectorator";
import {ActivePlayer} from "../controllers/ActivePlayer";
import {ITextures, Textures} from '../controllers/Textures';
import {IRect} from '../helpers/Rect';

interface IBaseObjectData {
    type: string;
}

export interface IObjectData<T = IBaseObjectData> {
    coords: [number, number, number];
    texture: string;
    object_class: number;
    object_subclass: number;

    above: number;
    hidden: boolean;

    data: T;
    config: any;

    actions: number[];
    passability: number[];

    quantity?: number;
    monsterData?: any;
}

export class MapObject {
    draggable = false;
    x = 0;
    y = 0;
    z = 0;
    objectLeft = 0;
    objectTop = 0;
    sprite: ObjectSprite;
    iconSprite = null;
    iconSpriteIndex: number;
    originalImage = new Image();
    dynamic = false;
    gridDynamic = false;
    renderDynamic = false;
    canGoInActionPos = false;
    isSelectable = false;
    // true: hero can enter object only from south, getWest or east sides
    // false: hero can enter object from any side
    restrictedPoints = false;

    events: EventsStorage = new EventsStorage();
    id: number;
    data: IObjectData<any>;
    hidden: boolean;
    objectClass: number;
    objectSubClass: number;
    objectDef: string;
    above: number;
    type: string;
    tip: string;
    owner: PlayerModel;
    renderTree: IMapObjectRenderTree;
    zIndex: number;

    isHero = false;
    isTown = false;
    isBoat = false;

    @Inject(ActivePlayer) protected activePlayer: PlayerModel;
    @Inject(Textures) protected textures: ITextures;
    @Inject(TextService) protected textService: TextService;
    @Inject(StateService) protected stateService: StateService;

    get passableMode(): boolean { return false }

    constructor(data: IObjectData<any>, id: number) {
        this.setSprite = this.setSprite.bind(this);
        this.draw = this.draw.bind(this);

        this.id = id;
        this.data = data;
        this.hidden = Boolean(data.hidden);
        this.objectClass = data.object_class;
        this.objectSubClass = data.object_subclass;
        this.objectDef = data.texture;
        this.above = data.above;
        this.type = data.data.type;
        this.tip = this.textService.getObjectName(this.objectClass);

        [this.x, this.y, this.z] = this.data.coords = data.coords || [0, 0, 0];

        this.setLeft(this.x * 32);
        this.setTop(this.y * 32);

        this.prepareSprite();

        this.events.on('rightMousedown', e => {
            let text = this.textService.getObjectTip(this);
            let uiTipWindow = UiController.createTipWindow(text, this.iconSprite, this.iconSpriteIndex);

            Events.one('rightMouseup', () => UiController.remove(uiTipWindow));

            return false;
        });

        this.events.on('leftClick', e => {
            if (this.isSelected()) {
                return true;
            }

            if (this.isSelectable && this.owner && this.owner.is(this.activePlayer)) {
                this.owner.selectObject(this.id);
                return false;
            }
        });

        this.events.on('remove', e => {
            if (!this.renderTree) {
                return false;
            }

            this.renderTree.tree.removeItem(this.renderTree.left, this.renderTree.top);

            this.stateService.remove(`objects.${this.id}`);
            Events.dispatch('dynamicObjectsChanged');
        });
    }

    is(object: MapObject): boolean {
        return this.id === object.id;
    }

    getCoords(): {x: number, y: number, z: number} {
        return {x: this.x, y: this.y, z: this.z};
    }

    getCenter(): {x: number, y: number, z: number} {
        return {
            x: this.objectLeft - (this.sprite.width >> 1),
            y: this.objectTop - (this.sprite.height >> 1),
            z: this.z
        };
    }

    setLeft(left: number): HeroObject {
        this.objectLeft = ~~(left);
        return <HeroObject><any>this;
    }

    getLeft(): number {
        return this.objectLeft;
    }

    setTop(top: number): HeroObject {
        this.objectTop = ~~(top);
        return <HeroObject><any>this;
    }

    getTop(): number {
        return this.objectTop;
    }

    getRect(): IRect {
        return {
            left: (this.x - this.getWidth()) + 1,
            top: (this.y - this.getHeight()) + 1,
            right: this.x + 1,
            bottom: this.y + 1
        };
    }

    isPointInside(x: number, y: number): boolean {
        let minX = ((this.x - this.getWidth()) + 1) * 32;
        let minY = ((this.y - this.getHeight()) + 1) * 32;
        let maxX = (this.x + 1) * 32;
        let maxY = (this.y + 1) * 32;
        if (minX < x && x < maxX && minY < y && y < maxY) {
            let insideX = Math.floor((x - minX) / 32);
            let insideY = Math.floor((y - minY) / 32);
            return !this.getPassability(insideX, insideY);
        } else {
            return false;
        }
    }

    isActionInPoint(x: number, y: number): number {
        let minX = ((this.x - this.getWidth()) + 1) * 32;
        let minY = ((this.y - this.getHeight()) + 1) * 32;
        let maxX = (this.x + 1) * 32;
        let maxY = (this.y + 1) * 32;
        if (minX < x && x < maxX && minY < y && y < maxY) {
            let insideX = Math.floor((x - minX) / 32);
            let insideY = Math.floor((y - minY) / 32);
            return this.getAction(insideX, insideY);
        } else {
            return null;
        }
    }

    setSprite(sprite: ObjectSprite) {
        this.sprite = sprite;
        this.originalImage.src = this.sprite.img.src;
        if (this.sprite.getAnimation('IDLE')) {
            this.sprite.setAnimation('IDLE');
        }
    }

    getSprite(): ObjectSprite {
        return this.sprite;
    }


    getProperty(propertyName: string): any {
        return this.data.data && this.data.data[propertyName];
    }

    isSelected(): boolean {
        return this.activePlayer.selectedObject && this.activePlayer.selectedObject.is(this);
    }

    draw(ctx: CanvasRenderingContext2D) {
        this.sprite.draw(this.objectLeft, this.objectTop, null, ctx);
    }


    getCursor(): string {
        return CURSORS.DEFAULT;
    }

    getActionCursor(): string {
        return CURSORS.HORSE_ACT;
    }

    prepare(objectData: IObjectData) {
        return objectData;
    }

    getEnterPoints(grid: Grid, x: number, y: number, from: MapObject = null): any[] {
        if (this.restrictedPoints) {
            return [
                grid.get(x - 1, y),
                grid.get(x - 1, y + 1),
                grid.get(x, y + 1),
                grid.get(x + 1, y + 1),
                grid.get(x + 1, y)
            ].filter(item => item && item.weight || item === from);
        } else {
            return [];
        }
    }

    private getWidth(): number {
        return this.sprite.width >> 5;
    }

    private getHeight(): number {
        return this.sprite.height >> 5;
    }

    private getPassability(x: number, y: number): number {
        let collIndex = (8 - this.getWidth()) + x;
        let lineIndex = (6 - this.getHeight()) + y;
        return Utils.hexToBytesArr(this.data.passability[lineIndex])[collIndex];
    }

    private getAction(x: number, y: number): number {
        let collIndex = (8 - this.getWidth()) + x;
        let lineIndex = (6 - this.getHeight()) + y;
        return Utils.hexToBytesArr(this.data.actions[lineIndex])[collIndex];
    }

    private prepareSprite() {
        let sprite = <ObjectSprite>this.textures.get(this.objectDef.toLowerCase());
        const shouldBeDuplicated = sprite.isUsed && sprite.settings.singleUse;
        const DYNAMIC_RENDER_TYPES = ['garrison', 'town', 'resource_generator', 'dwelling', 'lighthouse', 'shipyard'];

        if (shouldBeDuplicated || DYNAMIC_RENDER_TYPES.includes(this.type)) {
            sprite = sprite.duplicate();
        }
        sprite.isUsed = true;

        this.setSprite(sprite);
    }
}