
import {IUiOptions, UiPanel} from "../UiPanel";
import * as Utils from "../../utils/Utils";
import {UiSprite} from "../sprites/UiSprite";
import {UiComponent} from "../../services/UiComponentsService";
import {ITextures, Textures} from '../../controllers/Textures';
import {Inject} from '../../helpers/InjectDectorator';

export interface IUiImageOptions extends IUiOptions {
    sprite?: UiSprite | string;
    spriteIndex?: number;
    selectable?: boolean;
}

const DEFAULT_PARAMS = {
    left: 0,
    top: 0,
    sprite: null,
    spriteIndex: 0
};

@UiComponent()
export class UiImage extends UiPanel {

    options: IUiImageOptions;
    sprite: UiSprite;
    data: [number, number] = [-1, -1];

    @Inject(Textures) protected textures: ITextures;

    constructor(params: IUiImageOptions) {
        super(Utils.extend(DEFAULT_PARAMS, params));

        this.draw = this.draw.bind(this);
        this.sprite = this.readParam('sprite');
        if (typeof this.sprite === 'string') {
            this.sprite = <UiSprite>this.textures.get(<string>this.sprite);
        }
        this.width = (this.options.width = this.sprite ? this.sprite.width : 0);
        this.height = (this.options.height = this.sprite ? this.sprite.height : 0);

        this.options.spriteIndex = parseInt(this.readParam('spriteIndex'));
    }

    draw() {
        let left = this.getLeft();
        let top = this.getTop();
        this.sprite.draw(left, top, this.options.spriteIndex, this.ctx);

        if (this.selected) {
            this.ctx.lineWidth = 1;
            this.ctx.strokeStyle = '#cc0';
            this.ctx.strokeRect(left + .5, top + .5, this.width - 1, this.height - 1);
        }

        if (this.droppable) {
            this.ctx.setLineDash([4, 4]);
            this.ctx.lineWidth = 1;
            this.ctx.strokeStyle = 'rgba(125, 125, 0, 0.8)';
            this.ctx.strokeRect(left + .5, top + .5, this.width - 1, this.height - 1);

            return this.ctx.setLineDash([0, 0]);
        }
    }
};
