
import {UiWindow} from "../common/UiWindow";
import {Display} from "../../render/layers/Display";

const DEFAULT_PARAMS = {
    width: Math.min(620, Display.offsetWidth),
    height: Math.min(595, Display.offsetHeight),
    modal: true
};

export class UiSpellBookWindow extends UiWindow {

    constructor() {
        super(DEFAULT_PARAMS);

        this.scope =
            {close: () => this.removeWnd()};

        this.setMarkup('game/modals/spellBook.xml');
    }
}
