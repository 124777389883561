
import * as Utils from "../../utils/Utils";
import { Maybe } from "../../helpers/Maybe";
import {ICreatureData, MonstersData} from "../../controllers/MonstersData";
import {inject} from "../../helpers/InjectDectorator";
import {IHeroStackConfig} from "../../controllers/HeroesData";

interface ICreaturesStorage {
    [key: number]: [number, number];
}

const EMPTY_STORAGE = <ICreaturesStorage>{
    [0]: [-1, 0],
    [1]: [-1, 0],
    [2]: [-1, 0],
    [3]: [-1, 0],
    [4]: [-1, 0],
    [5]: [-1, 0],
    [6]: [-1, 0]
};

const EMPTY = -1;

export class CreaturesStorage {

    storage: ICreaturesStorage;
    isNative = false;
    moraleBonus = 0;

    constructor(private targetRace: number = null) {
        this.storage = Utils.clone(EMPTY_STORAGE);
    }

    isEmpty(): boolean {
        let result = true;

        for (let key in this.storage) {
            const stack = this.storage[key];

            if (~stack[0]) {
                result = false;
                break;
            }
        }

        return result;
    }

    set(position: number, creatureId: number, quantity: number) {
        this.__set(position, creatureId, quantity);
        this.updateFactors();
    }

    forEach(callback: (position: number, creatureId: number, quantity: number) => void) {
        for (let key in this.storage) {
            const [creatureId, quantity] = this.storage[key];

            if (~creatureId) {
                callback(parseInt(key, 10), creatureId, quantity);
            }
        }
    }

    private __set(position: number, creatureId: number = EMPTY, quantity: number = 0) {
        this.storage[position] = [creatureId, quantity];
    }

    fill(stacks: [number, number][]) {
        for (let position = 0; position < stacks.length; position++) {
            if (!stacks[position]) {
                this.__set(position, -1, 0);

                continue;
            }

            const [creatureId, quantity] = stacks[position];

            this.__set(position, creatureId, quantity);
        }

        this.updateFactors();
    }

    clear() {
        for (let position in this.storage) {
            this.__set(parseInt(position), EMPTY);
        }

        this.updateFactors();
    }

    getSlowestSpeed(): number {
        let speedArr = [];

        for (let index in this.storage) {
            let creatureData;
            let [creatureId, quantity] = this.storage[index];
            if (creatureData = this.getCreatureData(creatureId)) {
                speedArr.push(creatureData.damage.speed || 0);
            }
        }

        return Math.min.apply(this, speedArr) || 0;
    }

    getAICost(): number {
        let result = 0;

        for (let index in this.storage) {
            let [creatureId, quantity] = this.storage[index];
            result += this.getCreatureAICost(creatureId) * quantity;
        }

        return result;
    }

    getDamageExperience(): number {
        let result = 0;

        for (let index in this.storage) {
            let [creatureId, quantity] = this.storage[index];
            result += this.getCreatureHealth(creatureId) * quantity;
        }

        return result;
    }

    getCreatureData(creatureId: number): ICreatureData {
        let creaturesData = inject<ICreatureData[]>(MonstersData);

        return Utils.find(creaturesData, monster => parseInt(monster.id) === creatureId) || null;
    }

    getCreatureHealth(creatureId: number): number {
        let creatureItem;
        if ((creatureItem = this.getCreatureData(creatureId))) {
            return parseInt(creatureItem.damage.hitPoints)|0;
        } else {
            return 0;
        }
    }

    getCreatureAICost(creatureId: number): number {
        const creatureItem = this.getCreatureData(creatureId);

        if (creatureItem) {
            return creatureItem.aICost|0;
        } else {
            return 0;
        }
    }

    fromConfig(config: [number, number][]) {
        for (let position = 0; position < config.length; position++) {
            let [creatureId, quantity] = config[position];
            if (~creatureId && quantity) {
                this.__set(position, creatureId, quantity);
            }
        }

        this.updateFactors();
    }

    randomizeFromConfig(creaturesConfig: IHeroStackConfig[]) {
        const creaturesData = inject<ICreatureData[]>(MonstersData);

        const config = creaturesConfig
            .map(({type, low, high}) => {
                const creatureItem = <ICreatureData>Utils.find(creaturesData, creature => creature.type === type);
                const quantity = Utils.something(__range__(parseInt(low), parseInt(high), true));

                return {creatureItem, quantity};
            })
            .filter(({creatureItem, quantity}) => {
                const attributes = Maybe(creatureItem).pluck('attributes').getOrElse([]);

                return creatureItem && !attributes.includes('SIEGE_WEAPON');
            })
            .sort((a, b) => a.creatureItem.aICost > b.creatureItem.aICost ? 1 : -1)
            .map<[number, number]>(({creatureItem, quantity}) => [creatureItem.id, quantity]);

        this.fromConfig(config);
    }

    randomizeForTown(townRace: number) {
        const creaturesData = inject<ICreatureData[]>(MonstersData);

        const creatures = Utils.filter(creaturesData, item => {
            return this.filterRandomTownGarrison(item, townRace);
        });

        const iterable = Utils.some(creatures, 2);

        for (let position = 0; position < iterable.length; position++) {
            let creatureItem = iterable[position];
            this.__set(position, creatureItem.id, creatureItem.growth);
        }

        this.updateFactors();
    }

    private filterRandomTownGarrison(item: ICreatureData, race: number): boolean {
        return (item.race === race)
            && (item.level <= 4)
            && (item.upgradeId !== -1);
    }

    transfer(army: CreaturesStorage): boolean {
        let storageCopy = Utils.clone<ICreaturesStorage>(this.storage);

        for (let position in army.storage) {
            let [creatureId, quantity] = army.storage[position];
            if (!(~creatureId && quantity)) {
                continue;
            }
            if (storageCopy[position][0] === creatureId) {
                storageCopy[position][1] += quantity;
            } else {
                let found = false;
                for (let newPosition in storageCopy) {
                    let stack = storageCopy[newPosition];
                    if ((stack[0] === creatureId) || (stack[0] === EMPTY)) {
                        storageCopy[newPosition][0] = creatureId;
                        storageCopy[newPosition][1] += quantity;
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    return false;
                }
            }
        }

        army.clear();
        this.storage = storageCopy;
        this.updateFactors();

        return true;
    }

    private getRacesList(): Set<number> {
        const races = new Set<number>();

        for (let index in this.storage) {
            let creatureData;
            let [creatureId, quantity] = this.storage[index];
            if ((creatureId !== -1) && (creatureData = this.getCreatureData(creatureId))) {
                races.add(creatureData.race);
            }
        }

        return races;
    }

    private getAbilitiesList(): Set<string> {
        const abilities = new Set<string>();

        for (let index in this.storage) {
            let [creatureId, quantity] = this.storage[index];
            let creatureData = this.getCreatureData(creatureId);

            if (creatureId !== -1 && creatureData) {
                (creatureData.attributes || '')
                    .split('|')
                    .map(str => str.trim())
                    .map(abilities.add.bind(abilities));
            }
        }

        return abilities;
    }

    private updateFactors() {
        let moraleBonus = 0;
        let races = this.getRacesList();
        let abilities = this.getAbilitiesList();
        let hasSingleRace = races.size === 1;
        this.isNative = hasSingleRace && races.has(this.targetRace);

        if (hasSingleRace) {
            moraleBonus++;
        }
        if (races.size > 2) {
            moraleBonus -= races.size - 2;
        }
        if (abilities.has('const_raises_morale')) {
            moraleBonus++;
        }
        if (abilities.has('IS_UNDEAD')) {
            moraleBonus--;
        }
        this.moraleBonus = moraleBonus;
    }
}

function __range__(left, right, inclusive) {
    let range = [];
    let ascending = left < right;
    let end = !inclusive ? right : ascending ? right + 1 : right - 1;
    for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
        range.push(i);
    }
    return range;
}