
import {ObjectsCollection} from "../ObjectsCollection";
import {CreaturesStorage} from "../storages/CreaturesStorage";
import {BattleService, BattleSide} from "../../services/BattleService";
import {inject} from "../../helpers/InjectDectorator";

export class ObjectAttackableMixin {

    creatures: CreaturesStorage;

    onLoseBattle() {
        inject<ObjectsCollection>(ObjectsCollection).remove(this);
    }

    startBattleWith(opposite: BattleSide) {
        BattleService.startBattle(opposite, this);

        BattleService.promise.then(winner => {
            const thisObject = this as any;
            const isWins = winner === thisObject;

            if (isWins) {
                this.transferWinnerProfitIfItPossible(opposite, thisObject);
                opposite.onLoseBattle();
            } else {
                this.transferWinnerProfitIfItPossible(thisObject, opposite);
                thisObject.onLoseBattle();
            }
        });
    }

    transferWinnerProfitIfItPossible(fromSide: BattleSide, winner: BattleSide) {
        if ((winner as any).properties) {
            const experience = fromSide.creatures.getDamageExperience();

            (winner as any).properties.get('experience').push(experience);

            const stackIndex = BattleService.left === winner ? 0 : 1;
            const stacks = BattleService.stacks[stackIndex];
            const newStacks = Array(8);

            stacks.forEach(creature => {
                const creatureId = creature.quantity ? creature.creatureId : -1;

                newStacks[creature.position] = [creatureId, creature.quantity];
            });

            winner.creatures.fill(newStacks);
        }
    }
}