
import * as Utils from "../utils/Utils";
import {TimeService} from "../services/TimeService";
import {ObjectsCollection} from "./ObjectsCollection";
import {HeroObject, IObjectHeroData} from "./objects/hero/HeroObject";
import {Inject} from "../helpers/InjectDectorator";
import {ActivePlayer} from "../controllers/ActivePlayer";
import {PlayerModel} from "./player/PlayerModel";
import {MapDataReader} from './map/MapDataReader';
import {MapData} from '../controllers/MapData';
import {HeroesData, IHeroConfig, IHeroesData} from "../controllers/HeroesData";

class HeroesCollectionSingleton {

    available: IHeroConfig[] = [];
    tavern: [HeroObject, HeroObject] = [null, null];
    used: Set<number>;

    @Inject(ActivePlayer) private activePlayer: PlayerModel;
    @Inject(MapData) private mapData: MapDataReader;
    @Inject(HeroesData) private heroesData: IHeroesData;
    @Inject(TimeService) private timeService: TimeService;
    @Inject(ObjectsCollection) private objectsCollection: ObjectsCollection;

    bootstrap() {
        this.available = this.getAvailable();
        this.used = new Set<number>();
    }

    getAvailable(): IHeroConfig[] {
        const availableHeroesMask = Utils.readAsBinaryMask(this.mapData.props.restrictions.heroes.reverse());

        if (availableHeroesMask) {
            return Utils.filter(this.heroesData, (item, index) => Number(availableHeroesMask[index]));
        } else {
            return Utils.filter(this.heroesData, () => true);
        }
    }

    initTavern() {
        if (this.timeService.isFirstDay()) {
            this.tavern = [this.hire(this.activePlayer.race), this.hire()];
        } else {
            this.tavern = [this.hire(), this.hire()];
        }
    }

    randomizeTavern(index, race = null) {
        this.tavern[index] = this.hire(race);
    }

    getRandom(race: number = null): IHeroConfig {
        let filtered;
        if (race !== null) {
            filtered = Utils.filter(this.available, hero => hero.race === race);
        } else {
            filtered = this.available;
        }
        return Utils.something(filtered);
    }

    register(heroId: number) {
        this.used.add(heroId);
        const heroData = this.getData(heroId);
        this.available.splice(this.available.indexOf(heroData), 1);
    }

    dismiss(heroId: number) {
        this.used.delete(heroId);
        this.available.push(this.getData(heroId));
    }

    private hire(race: number = null): HeroObject {
        const heroData = this.getRandom(race);

        return this.reserve(heroData);
    }

    private reserve(hero: IHeroConfig): HeroObject {
        const heroData = this.objectsCollection.getData(<IObjectHeroData>{
            hidden: true,
            above: 0,
            coords: [1, 1, 0],
            object_class: 34,
            object_subclass: 0,
            texture: 'ah00_e.def',
            data: {
                type: 'hero',
                person: hero.id
            }
        });

        return <HeroObject>this.objectsCollection.spawnDynamicObject(heroData);
    }

    private getData(id: number): IHeroConfig {
        return this.heroesData[id] || null;
    }
}

export const HeroesCollection = new HeroesCollectionSingleton();
