
import {Rect} from "../helpers/Rect";

export interface IMapObjectRenderTree {
    left: number;
    top: number;
    tree: RenderTree;
}

interface IDrawer {
    (canvas: HTMLCanvasElement): void;
}

export class RenderTree {

    tree: {[key: number]: Map<number, IDrawer>} = {};

    private min = 0;
    private absMaxX = 0;
    private absMaxY = 0;
    private drawers = 0;
    private total = 0;

    constructor(private vertOffset = 0, private horOffset = 0) {}

    removeItem(index: number, zIndex: number) {
        this.tree[zIndex] && this.tree[zIndex].delete(index);
    }

    move(index: number, zIndex: number, toIndex: number, toZIndex: number): IMapObjectRenderTree {
        const sourceBranch = this.getOrCreateBranch(zIndex);
        const destBranch = this.getOrCreateBranch(toZIndex);
        const drawer = sourceBranch.get(index);

        if (drawer) {
            sourceBranch.delete(index);
            while (destBranch.has(toIndex)) {
                toIndex++;
            }
            destBranch.set(toIndex, drawer);
        }
        return {
            left: toIndex,
            top: toZIndex,
            tree: this
        };
    }

    add(zIndex: number = 0, index: number = 0, callback: () => void): IMapObjectRenderTree {
        const destBranch = this.getOrCreateBranch(zIndex);

        if (destBranch.has(index)) {
            return this.add(zIndex, index + 1, callback);
        }
        destBranch.set(index, callback);
        this.min = Math.min(zIndex, this.min);
        this.absMaxX = Math.max(index, this.absMaxX);
        this.absMaxY = Math.max(zIndex, this.absMaxY);
        this.total++;

        return {
            left: index,
            top: zIndex,
            tree: this
        };
    }

    clear() {
        this.tree = {};
        this.min = 0;
        this.absMaxX = 0;
        this.absMaxY = 0;
        this.total = 0;
    }

    drawRect(rect: Rect, canvas: CanvasRenderingContext2D = null) {
        this.drawers = 0;
        const drawer = this.runDrawer.bind(this, rect, canvas);
        const top = Math.min(rect.top, rect.bottom);
        const bottom = Math.max(rect.top, rect.bottom);

        for (let y = top; y <= bottom; y++) {
            this.tree[y] && this.tree[y].forEach(drawer);
        }
    }

    run(canvasLeft: number, canvasTop: number) {
        const rect = <Rect>{
            left: ~~Math.max(-canvasLeft - this.horOffset, -this.horOffset),
            right: ~~Math.min(this.absMaxX, -canvasLeft + window.innerWidth + this.horOffset + this.min),
            top: ~~Math.max(-canvasTop - this.vertOffset, -this.vertOffset),
            bottom: ~~Math.min(this.absMaxY, -canvasTop + window.innerHeight + this.vertOffset + this.min)
        };

        this.drawRect(rect);
    }

    private runDrawer(rect: Rect, canvas: HTMLCanvasElement, drawer: IDrawer, x: number) {
        if ((x >= rect.left) && (x <= rect.right)) {
            this.drawers++;
            drawer(canvas);
        }
    }

    private getOrCreateBranch(index: number): Map<number, IDrawer> {
        return this.tree[index] = this.tree[index] || new Map();
    }
}