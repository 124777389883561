
import {OwnershipObject} from "./OwnershipObject";
import {UiController} from "../../controllers/UiController";
import {Pointer} from "../Pointer";
import {CURSORS} from "../../constants/CURSORS";
import {MapModel} from "../map/MapModel";
import {ObjectsCollection} from "../ObjectsCollection";
import {IObjectData} from "../MapObject";
import {ResourcesController} from "../../controllers/ResourcesController";
import {HeroObject} from "./hero/HeroObject";
import {UiShipyardWindow} from "../../views/windows/UiShipyardWindow";
import {Inject} from "../../helpers/InjectDectorator";
import {ActivePlayer} from "../../controllers/ActivePlayer";
import {PlayerModel} from "../player/PlayerModel";
import {MapComponent} from '../MapComponent';

@MapComponent('shipyard')
export class ShipyardObject extends OwnershipObject {

    restrictedPoints = true;
    spawnPoints: Pointer[] = [];

    @Inject(ActivePlayer) protected activePlayer: PlayerModel;
    @Inject(ObjectsCollection) protected objectsCollection: ObjectsCollection;
    @Inject(ResourcesController) protected resourcesController: ResourcesController;

    constructor(data: IObjectData, id: number) {
        super(data, id);

        this.doAction = this.doAction.bind(this);

        this.events.on('leftClick', () => {
            if (this.isClickable()) {
                UiController.modal(UiShipyardWindow, this, this.activePlayer);
            }
        });

        this.spawnPoints = [
            new Pointer(this.x - 2, this.y - 1),
            new Pointer(this.x - 1, this.y - 1),
            new Pointer(this.x + 1, this.y - 1),
            new Pointer(this.x + 2, this.y - 1),
            new Pointer(this.x - 2, this.y + 1),
            new Pointer(this.x - 1, this.y + 1),
            new Pointer(this.x + 1, this.y + 1),
            new Pointer(this.x + 2, this.y + 1)
        ];
    }

    doAction(hero: HeroObject) {
        this.setOwner(hero.owner);
        UiController.modal(UiShipyardWindow, this, hero.owner);
        return true;
    }

    isClickable(): boolean {
        if (this.owner) {
            return this.owner.is(this.activePlayer);
        }
        return false;
    }

    getCursor(): string {
        if (this.isClickable()) {
            return CURSORS.SHIP_ACT;
        } else {
            return CURSORS.DEFAULT;
        }
    }

    buy() {
        let objectsData = <any>this.resourcesController.storage.get('objectsData');
        let boatData = <IObjectData>objectsData.boat;
        let point = this.findAvailablePoint();

        if (point) {
            if (this.activePlayer.resources.pay({gold: 1500})) {
                boatData.coords = [point.x, point.y, this.z];
                this.objectsCollection.spawnDynamicObject(boatData);
            } else {
                setTimeout(() => UiController.alert("You don't have enough money!"));
            }
        } else {
            setTimeout(() => UiController.alert("Can't spawn a boat!"));
        }
    }

    findAvailablePoint(): Pointer {
        for (let point of Array.from(this.spawnPoints)) {
            if (!MapModel.getWaterPassability(point.x - 1, point.y, this.z)) {
                continue;
            }
            return point;
        }
        return null;
    }
};
