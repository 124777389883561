
import {OwnershipObject} from "./OwnershipObject";
import {MapComponent} from '../MapComponent';

@MapComponent('lighthouse')
export class LightHouseObject extends OwnershipObject {

    canGoInActionPos = false;

    constructor(data, id) {
        super(data, id);

        this.events.on('action', hero => {
            this.setOwner(hero.owner);
        });
    }
}
