
import {UiController} from "./UiController";
import {ResourcesController} from "./ResourcesController";
import {Render} from "../render/Render";
import {ControllerHelper} from "../helpers/ControllerHelper";
import {Events} from "./Events";
import {UiGameOptionsView} from "../views/mainMenu/UiGameOptionsView";
import {StateService} from "../services/StateService";
import {UiWindow} from "../views/common/UiWindow";
import {inject, Inject} from "../helpers/InjectDectorator";
import {FocusedWindow} from "./FocusedWindow";

export class GameOptionsController extends ControllerHelper {

    ui = UiController;
    @Inject(FocusedWindow) protected focusedWindow: UiWindow;
    @Inject(StateService) protected stateService: StateService;
    @Inject(Render) private render: Render;

    constructor() {
        super();

        UiController.bootstrap(UiGameOptionsView);

        [
            'leftMousedown',
            'leftMouseup',
            'leftClick',
            'mouseWheel',
            'rightMousedown',
            'rightMouseup',
            'rightClick',
            'drag',
            'dragEnd'
        ].forEach(eventType =>
            Events.on(eventType, (e) => {
                if (this.focusedWindow) {
                    this.focusedWindow.events.dispatch(eventType, e);
                }
            })
        );

        this.render.setSource({'ui': [UiController.prepare, UiController.draw]});
    }

    static resolve(params: any) {
        const stateService = inject<StateService>(StateService);
        const mapUrl = stateService.get('mapUrl');
        const resourcesController = inject<ResourcesController>(ResourcesController);

        return [
            resourcesController.loadHeroesData(),
            resourcesController.loadMapData(mapUrl)
        ];
    }
}