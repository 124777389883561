
import {IUiOptions, UiPanel} from "../UiPanel";
import * as Utils from "../../utils/Utils";
import {UI_STATES} from "../../constants/UI_STATES";
import {Events} from "../../controllers/Events";
import {Maybe} from "../../helpers/Maybe";
import {CURSORS} from "../../constants/CURSORS";
import {UiComponent} from "../../services/UiComponentsService";
import {IAbstractView} from '../IAbstractView';
import {ActiveView} from '../../controllers/ActiveView';
import {Inject} from '../../helpers/InjectDectorator';

interface IUiCheckboxOptions extends IUiOptions {
    text?: string;
    group?: string;
    checked?: boolean;
}

const DEFAULT_PARAMS = <IUiCheckboxOptions>{
    left: 0,
    top: 0,
    text: null
};

const SIZE = 14;
const PADDING = 5;

@UiComponent()
export class UiCheckbox extends UiPanel {
    checked: Boolean;

    private state: number;
    private group: string;
    private required: boolean;
    private textLabel: string;

    options: IUiCheckboxOptions;

    @Inject(ActiveView) protected activeView: IAbstractView;

    constructor(params: IUiCheckboxOptions) {
        super(Utils.extend(DEFAULT_PARAMS, params));

        this.draw = this.draw.bind(this);
        this.setEnabled = this.setEnabled.bind(this);

        this.state = UI_STATES.IDLE;
        this.checked = Boolean(this.readParam('checked'));
        this.group = this.readParam('group') || null;
        this.required = this.readParam('required') || false;
        this.textLabel = this.readParam('text') || '';

        if (this.group) {
            this.id = `group-${this.group}-${this.id}`;
        }

        this.events
            .on('leftClick', () => {
                if (this.isDisabled()) { return false; }
                this.toggle();
                return true;
            })
            .on('leftMousedown', () => {
                if (this.isDisabled()) { return false; }
                this.setState(UI_STATES.ACTIVE);
                Events.one('leftMouseup', () => {
                    return this.setState(UI_STATES.IDLE);
                });
                return true;
            })
            .on('rightMousedown', () => {
                if (this.isDisabled()) { return false; }
                return true;
            });
    }

    toggle() {
        if (this.group) {
            const element = Maybe(this.activeView.ui).getOrElse(this.parent);

            if (element) {
                element.queryAll(`group-${this.group}`).forEach(item => {
                    if (!item.is(this)) { return item.checked = false; }
                });
            }
        }
        if (this.required) {
            this.checked = true;
        } else {
            this.checked = !this.checked;
        }
    }

    __drawCheckbox() {
        this.ctx.strokeStyle = this.__getTextColor();
        this.ctx.fillStyle = '#f8e57e';
        const left = this.absoluteLeft + PADDING;
        const top = this.absoluteTop + ((this.height - SIZE) >> 1);
        this.ctx.strokeRect(left, top, SIZE, SIZE);
        if (this.checked) {
            this.ctx.fillRect(left + 3, top + 3, SIZE - 6, SIZE - 6);
        }
    }

    __getTextColor() {
        if (this.disabled) {
            return '#999999';
        } else {
            return '#FFFFFF';
        }
    }

    getCursor() {
        return CURSORS.POINTER;
    }

    draw() {
        if (this.hidden) {
            return;
        }
        let centerTop = this.height >> 1;

        if (this.state === UI_STATES.ACTIVE) {
            centerTop += 1;
        }

        if (this.isHovered()) {
            this.ctx.fillStyle = 'rgba(0, 0, 0, 0.25)';
            this.ctx.fillRect(this.absoluteLeft, this.absoluteTop, this.width, this.height);
        }

//      UiPanel::draw.call(@)

        this.__drawCheckbox();

        this.text({
            text: this.textLabel,
            left: SIZE + 10,
            top: centerTop,
            color: this.__getTextColor(),
            align: 'left'
        });
    }

    setState(state) {
        this.state = state;
    }

    getState() {
        return this.state;
    }

    setEnabled(value) {
        if (!!value) {
            this.setState(UI_STATES.IDLE);
        } else {
            this.setState(UI_STATES.DISABLED);
        }
    }

    isDisabled(): boolean {
        return this.state === UI_STATES.DISABLED;
    }
};