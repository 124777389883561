
import * as Utils from "../../utils/Utils";
import {Router} from "../../controllers/Router";
import {UiComponent} from "../../services/UiComponentsService";
import {StateService} from "../../services/StateService";
import {UiGameItem} from "../common/UiGameItem";
import {Inject} from '../../helpers/InjectDectorator';

const DEFAULT_PARAMS = {
    left: 0,
    top: 0,
    height: 50,
    map: null,
    repository: null
};

@UiComponent()
export class UiMapsListItem extends UiGameItem {

    @Inject(Router) protected router: Router;
    @Inject(StateService) protected stateService: StateService;

    constructor(params) {
        super(Utils.extend(DEFAULT_PARAMS, params));

        const map = this.readParam('map');
        const repository = this.readParam('repository');

        this.events.on('leftClick', () => {
            this.stateService.set('mapUrl', repository.url + map.path);
            this.stateService.set('props', map.props);
            this.router.go('game_options');
        });
    }
}