
import * as Utils from "../utils/Utils";
import {EventsStorage} from "../models/storages/EventsStorage";
import {Events} from "../controllers/Events";
import {UrlParamsService} from "../services/UrlParamsService";
import {CURSORS} from "../constants/CURSORS";
import {UiSprite} from "./sprites/UiSprite";
import {Layers} from "../render/layers/Layers";
import {UiPanelQueryMixin} from "./UiPanelQueryMixin";
import {UiPanelLayoutMixin} from "./UiPanelLayoutMixin";
import {UiPanelDrawingUtilsMixin} from "./UiPanelDrawingUtilsMixin";
import {UiPanelDraggableMixin} from "./UiPanelDraggableMixin";
import {UiWindow} from "./common/UiWindow";
import {UiComponent} from "../services/UiComponentsService";
import {TemplateParser} from "./TemplateParser";
import {ITextures, Textures} from '../controllers/Textures';
import {Inject} from '../helpers/InjectDectorator';
import {Mixin} from '../utils/Utils';
import {UiDragState} from '../controllers/UiDragState';

let panelsCounter = 0;

interface IUiEvent {
    stack?: any[];
}

export type IOptionalLazy<T> = (() => T) | T;

export interface IUiOptions {
    left?: IOptionalLazy<number>;
    right?: IOptionalLazy<number>;
    top?: IOptionalLazy<number>;
    bottom?: IOptionalLazy<number>;
    width?: IOptionalLazy<number>;
    height?: IOptionalLazy<number>;

    parent?: UiPanel;
    scope?: {};

    selectable?: boolean;
    draggable?: boolean;
    dropZones?: () => any[] | any[];

    events?: {[key: string]: (e: any) => void};

    pattern?: string;
    background?: string;
    tipKey?: string;
}

const DEFAULT_PARAMS = <IUiOptions>{
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    pattern: null,
    background: null,
    parent: null,
    selectable: false,

    draggable: false,
    dropZones: null,

    events: {}
};

@UiComponent()
@Mixin(UiPanelDrawingUtilsMixin)
@Mixin(UiPanelDraggableMixin)
@Mixin(UiPanelLayoutMixin)
@Mixin(UiPanelQueryMixin)
export class UiPanel
    implements UiPanelDrawingUtilsMixin, UiPanelDraggableMixin, UiPanelLayoutMixin, UiPanelQueryMixin {

    droppable: boolean;
    left: number;
    right: number;
    top: number;
    bottom: number;
    width: number;
    height: number;
    absoluteLeft: number;
    absoluteTop: number;

    options: IUiOptions;
    sprite: UiSprite;
    patternFill: CanvasPattern;
    ctx: CanvasRenderingContext2D;
    rootCtx: CanvasRenderingContext2D;
    scope: any = {};

    events = new EventsStorage();
    parent: UiPanel;
    rootContext: UiPanel;
    canHaveChildren = true;
    children = new Set<UiPanel>();

    get repeatItems(): any[] { return null }

    id: string;
    disabled: boolean;
    hidden: boolean;
    selected: boolean;

    @Inject(Textures) protected textures: ITextures;
    @Inject(TemplateParser) protected templateParser: TemplateParser;
    @Inject(UrlParamsService) protected urlParamsService: UrlParamsService;
    @Inject(UiDragState) public uiDragState: UiDragState;
    @Inject(Layers) protected layers: Layers;

    constructor(params: IUiOptions) {
        this.draw = this.draw.bind(this);
        this.__handleChildrenEvent = this.__handleChildrenEvent.bind(this);
        this.options = Utils.extend(DEFAULT_PARAMS, params);
        let canvas = this.layers.uiCanvas;
        this.ctx = canvas.ctx;
        this.rootCtx = canvas.ctx;

        this.__initLayout();

        let bgPatternName = this.options.pattern || 'panel_bg';
        let spriteName = this.options.background || bgPatternName;

        this.events.setOnDispatch(this.__handleChildrenEvent);

        if (this.options.events instanceof Object) {
            for (let eventName in this.options.events) {
                let callback = this.options.events[eventName];
                this.events.on(eventName, callback);
            }
        }

        this.sprite = <UiSprite>this.textures.get(spriteName);
        if (!this.options.background) {
            this.patternFill = this.ctx.createPattern(this.sprite.img, 'repeat');
        }

        if (this.options.parent) {
            this.setParent(this.options.parent);
        }

        this.rootContext = (this.parent != null ? this.parent.rootContext : undefined) || this;
        if (Utils.isObject(this.options.scope)) {
            this.rootContext = this;
            if (this.parent !== undefined) {
                this.scope = Object.setPrototypeOf(this.options.scope, this.parent.rootContext.scope);
            } else {
                this.scope = this.options.scope;
            }
        }

        this.id = this.readParam('id') || `ui-${panelsCounter++}`;
        this.disabled = Boolean(this.readParam('disabled'));
        this.hidden = Boolean(this.readParam('hidden'));
        this.events.on('parent.updateSizes', this.__update.bind(this));
        Events.on('resize', this.__update.bind(this));

        if (this.readParam('selectable')) {
            this.selected = false;
        }

        if (this.urlParamsService.params.hasOwnProperty('uiDebug')) {
            Events.on('leftClick', () => {
                let mousePos = Events.getMousePos();
                if (this.isPointInside(mousePos.x, mousePos.y)) {
                    let x = mousePos.x - this.absoluteLeft;
                    let y = mousePos.y - this.absoluteTop;
                    let object = this;
                    console.group(`{${object.constructor.name}} ${object.id} (mouse on:[${x}, ${y}])`);
                    console.log(object);
                    setTimeout(() => console.groupEnd());
                }

                return true;
            });
        }

        this.__update();
        if (this.options.draggable) { this.__initDraggableBehavior(); }
    }

    readParam(paramName: string): any {
        let value = this.options[paramName];
        if (typeof value === 'function') {
            return value.call(this, this.parent || this);
        } else {
            return this.options[paramName];
        }
    }

    setCtx(ctx: CanvasRenderingContext2D) {
        return this.ctx = ctx;
    }

    setParent(parent) {
        this.parent = parent;
        this.parent.children.add(this);
        this.ctx = this.parent.ctx;
        setTimeout(this.parent.events.dispatch.bind(this.parent.events, 'children.update', this.parent));
        return this;
    }

    removeChildren() {
        this.children.forEach((wnd: UiWindow) => {
            wnd.removeChildren();
            wnd.events.dispatch('remove');
            // return (typeof wnd.remove === 'function' ? wnd.remove() : undefined);
        });
        this.children = new Set();
        return this.events.dispatch('children.update', this);
    }

    addChild(windowObject: UiPanel) {
        windowObject.setParent(this);
        this.children.add(windowObject);
        this.events.dispatch('children.update', this);
        setTimeout(() => windowObject.__update());
        return windowObject;
    }

    draw() {
        if (this.hidden) {
            return;
        }
        let absoluteLeft = this.getLeft();
        let absoluteTop = this.getTop();

        if (this.options.background) {
            this.ctx.drawImage(this.sprite.img, absoluteLeft, absoluteTop);
        } else {
            this.ctx.fillStyle = this.patternFill;
            this.ctx.fillRect(absoluteLeft, absoluteTop, this.width, this.height);
        }

        this.children.forEach(wnd => wnd.draw());
    }

    getCursor(): string {
        if (this.uiDragState.isDragged) {
            return CURSORS.DRAG;
        }

        const hoveredChild = Array.from(this.children).reverse().find(child => child.isHovered());

        if (hoveredChild) {
            return hoveredChild.getCursor();
        }

        return CURSORS.DEFAULT;
    }

    __handleChildrenEvent(type: string, eventData: IUiEvent = {}) {
        if (eventData.stack == null) {
            eventData.stack = [];
        }
        if (this.hidden) {
            return false;
        }
        let wnd = this;
        // TODO
        // some strange bug: ui events maybe call from self window
        if ((eventData.stack[0] && eventData.stack[0].id) === wnd.id) {
            return false;
        }
        eventData.stack.unshift(wnd);
        let mousePos = Events.getMousePos();
        if (Events.isMouseEvent(type) && !this.isPointInside(mousePos.x, mousePos.y)) {
            return false;
        }
        this.children.forEach(wnd => {
            if (!wnd.disabled) {
                return wnd.events.dispatch(type, eventData);
            }
        });
        return true;
    }

    isHovered(): boolean {
        const  mousePos = Events.getMousePos();

        return this.isPointInside(mousePos.x, mousePos.y);
    }

    is(wnd: UiPanel): boolean {
        return this.id === wnd.id;
    }

    setMarkup(filename: string): Promise<void> {
        return this.templateParser.setMarkup(this, filename);
    }

    line(params): this {
        return undefined;
    }

    rect(params): this {
        return undefined;
    }

    text(params): this {
        return undefined;
    }

    icon(params): this {
        return undefined;
    }

    useCtx(targetCtx, callback): this {
        return undefined;
    }

    __initDraggableBehavior(): any {
        return undefined;
    }

    __initLayout(): any {
        return undefined;
    }

    isPointInside(x: number, y: number): boolean {
        return undefined;
    }

    getChildInPoint(x: number, y: number): UiPanel {
        return undefined;
    }

    setMetrics(left: number, top: number, width: number, height: number): any {
        return undefined;
    }

    setWidth(width: number): any {
        return undefined;
    }

    setHeight(height: number): any {
        return undefined;
    }

    setLeft(left: number): any {
        return undefined;
    }

    setTop(top: number): any {
        return undefined;
    }

    getWidth(): number {
        return undefined;
    }

    getHeight(): number {
        return undefined;
    }

    getLeft(): number {
        return undefined;
    }

    getTop(): number {
        return undefined;
    }

    calculateLeft(): number {
        return undefined;
    }

    calculateWidth(): number {
        return undefined;
    }

    calculateTop(): number | any {
        return undefined;
    }

    calculateHeight(): number {
        return undefined;
    }

    __updateChildren(): any {
        return undefined;
    }

    __update(): any {
        return undefined;
    }

    query(selector: string): UiPanel {
        return undefined;
    }

    queryAll(selector: string): UiPanel[] {
        return undefined;
    }

    setDropZones(dropZones) {}

    onMouseUp(e): any {
        return undefined;
    }

    onMouseDown(): any {
        return undefined;
    }

    wndMatches(wnd: UiPanel, selector: string): boolean {
        return undefined;
    }

    queryByChildren(wnd: UiPanel, selector: string): UiPanel[] {
        return undefined;
    }
}