
import {GenericVisitableObject} from "./GenericVisitableObject";

export class MagicSpringObject extends GenericVisitableObject {

    recordVisits = true;
    clearVisitsEveryWeek = true;

    constructor(data, id) {
        super(data, id);

        this.events.on('action', (hero) => {
            const amount = Math.max(hero.properties.getValue('knowledge') * 20, hero.properties.getValue('manaPoints'));
            hero.properties.get('manaPoints').set(amount);
        });
    }
}
