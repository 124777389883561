
import {IObjectData, MapObject} from "../MapObject";
import {ResourcesStorage} from "../storages/ResourcesStorage";
import {IResourceGeneratorType, RESOURCES} from "../../constants/RESOURCES";
import * as Utils from "../../utils/Utils";
import {UiController} from "../../controllers/UiController";
import {ObjectsCollection} from "../ObjectsCollection";
import {ITextures, Textures} from '../../controllers/Textures';
import {Inject} from '../../helpers/InjectDectorator';
import {MapComponent} from '../MapComponent';

const RANDOM_RESOURCE = 76;

@MapComponent('resource')
export class ResourceObject extends MapObject {

    dynamic = true;
    gridDynamic = true;
    renderDynamic = true;
    resources = new ResourcesStorage();
    resource: IResourceGeneratorType;

    @Inject(Textures) protected textures: ITextures;
    @Inject(ObjectsCollection) protected objectsCollection: ObjectsCollection;

    constructor(data: IObjectData, id: number) {
        super(data, id);

        this.resource = RESOURCES[this.objectSubClass];
        this.tip = this.resource.tip;

        const count = (this.data.quantity || Utils.some(this.resource.random)[0]) * this.resource.rate;
        this.resources.set(this.resource.name, count);

        this.iconSprite = this.textures.get('resources_portraits_lg');
        this.iconSpriteIndex = this.objectSubClass;

        this.events.on('action', hero => {
            UiController.statusWindow.resourceTransferMessage(this.resources);
            this.resources.transfer(hero.owner.resources);
            this.objectsCollection.remove(this);
        });
    }

    prepare(data: IObjectData): IObjectData {
        if (data.object_class === RANDOM_RESOURCE) {
            data.object_subclass = Math.floor(Math.random() * 7);
        }
        data.texture = RESOURCES[data.object_subclass].texture;
        return data;
    }
}
