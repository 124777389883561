
import {IUiOptions, UiPanel} from "../UiPanel";
import * as Utils from "../../utils/Utils";
import {UiSprite} from "../sprites/UiSprite";
import {CursorService} from "../../services/CursorService";
import {CURSORS} from "../../constants/CURSORS";
import {Events} from "../../controllers/Events";
import {UiController} from "../../controllers/UiController";
import {UiButton} from "./UiButton";
import {UiText} from "./UiText";
import {UiComponent} from "../../services/UiComponentsService";
import {ITextures, Textures} from '../../controllers/Textures';
import {Inject} from '../../helpers/InjectDectorator';
import {IAbstractView} from '../IAbstractView';
import {ActiveView} from '../../controllers/ActiveView';

export interface IUiWindowOptions extends IUiOptions {
    centered?: boolean;
    hideCursor?: boolean;
    shadow?: boolean;
    borders?: boolean;
    modal?: boolean;
}

const DEFAULT_PARAMS = <IUiWindowOptions>{
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    shadow: true,
    centered: true,
    borders: true,
    pattern: null,
    modal: false,
    hideCursor: false
};

@UiComponent()
export class UiWindow extends UiPanel {

    bordersSprite: UiSprite;
    spriteSize: number;

    promise: Promise<any>;

    blocks = [];
    blocksStep = 20;

    options: IUiWindowOptions;
    prerenderCache: CanvasRenderingContext2D;

    private closeCallback: () => void;

    resolve: (value?: any) => void;
    reject: (reason?: any) => void;

    @Inject(Textures) protected textures: ITextures;
    @Inject(ActiveView) protected activeView: IAbstractView;
    @Inject(CursorService) protected cursorService: CursorService;

    constructor(params: IUiWindowOptions) {
        super(Utils.extend(DEFAULT_PARAMS, params));

        this.draw = this.draw.bind(this);
        this.addTextBlock = this.addTextBlock.bind(this);

        this.bordersSprite = <UiSprite>this.textures.get('dialog_borders');
        this.spriteSize = this.bordersSprite.width;

        this.closeCallback = null;

        this.events.on('resize', () => {
            this.__prerender();
        });

        Events.on('resize', () => {
            if (this.options.centered) {
                this.centerWindow();
            }
        });
        this.__prerender();

        this.promise = new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
        this.promise.catch(function(result) {
            if (result instanceof Error) {
                return console.error(result);
            } else {
                return true;
            }
        });

        if (this.options.centered) {
            this.centerWindow();
        }

        if (this.options.hideCursor) {
            setTimeout(() => this.cursorService.hide());

            this.events.on('remove', () => this.cursorService.show());
        }

        this.events.on('mousemove', Utils.debounce(this.debouncedMouseMove.bind(this), 50));
    }

    private centerWindow() {
        const mainWindowWidth = this.activeView.getMapWidth();
        const mainWindowHeight = this.activeView.getWindowHeight();
        const centerLeft = mainWindowWidth >> 1;
        const centerTop = mainWindowHeight >> 1;
        const left = Math.max(centerLeft - (this.width >> 1), 0);
        const top = Math.max(centerTop - (this.height >> 1), 0);

        this.setLeft(left);
        this.setTop(top);
    }

    private debouncedMouseMove() {
        const {x, y} = Events.getMousePos();
        const childInPoint = this.getChildInPoint(x, y);
        return childInPoint ? childInPoint.getCursor() : CURSORS.DEFAULT;
    }

    removeWnd() {
        UiController.remove(this);
        return (typeof this.closeCallback === 'function' ? this.closeCallback() : undefined);
    }

    private drawShadow(ctx) {
        ctx.fillStyle = "rgba(0, 0, 0, 0.4)";
        ctx.fillRect(this.absoluteLeft + 4, this.absoluteTop + 4, this.width, this.height);
        ctx.fillRect(this.absoluteLeft + 5, this.absoluteTop + 5, this.width, this.height);
    }

    private drawBorders(ctx) {
        const right = this.absoluteLeft + this.width;
        const bottom = this.absoluteTop + this.height;

        // draw corners
        this.bordersSprite.draw(this.absoluteLeft, this.absoluteTop, 0, ctx);
        this.bordersSprite.draw(right - this.spriteSize, this.absoluteTop, 2, ctx);
        this.bordersSprite.draw(this.absoluteLeft, bottom - this.spriteSize, 9, ctx);
        this.bordersSprite.draw(right - this.spriteSize, bottom - this.spriteSize, 11, ctx);

        //top border
        this.bordersSprite.getSpriteFrame(1).then(img => {
            ctx.setTransform(1, 0, 0, 1, 0, 0);
            ctx.translate(-this.absoluteLeft, -this.absoluteTop);
            ctx.translate(this.absoluteLeft, this.absoluteTop);
            ctx.fillStyle = ctx.createPattern(img, 'repeat');
            ctx.fillRect(this.spriteSize, 0, this.width - (this.spriteSize << 1), 32);
            ctx.setTransform(1, 0, 0, 1, 0, 0);
        });

        //bottom border
        this.bordersSprite.getSpriteFrame(10).then(img => {
            ctx.setTransform(1, 0, 0, 1, 0, 0);
            ctx.translate(-this.absoluteLeft, -this.absoluteTop);
            ctx.translate(this.absoluteLeft, bottom - 64);
            ctx.fillStyle = ctx.createPattern(img, 'repeat');
            ctx.fillRect(this.spriteSize, 0, this.width - (this.spriteSize << 1), 64);
            ctx.setTransform(1, 0, 0, 1, 0, 0);
        });

        //left border
        this.bordersSprite.getSpriteFrame(3).then(img => {
            ctx.setTransform(1, 0, 0, 1, 0, 0);
            ctx.translate(-this.absoluteLeft, -this.absoluteTop);
            ctx.translate(this.absoluteLeft, this.absoluteTop + 64);
            ctx.fillStyle = ctx.createPattern(img, 'repeat');
            ctx.fillRect(0, 0, 64, this.height - (this.spriteSize << 1));
            ctx.setTransform(1, 0, 0, 1, 0, 0);
        });

        //right border
        this.bordersSprite.getSpriteFrame(5).then(img => {
            ctx.setTransform(1, 0, 0, 1, 0, 0);
            ctx.translate(-this.absoluteLeft, -this.absoluteTop);
            ctx.translate(right - 64, this.absoluteTop + 64);
            ctx.fillStyle = ctx.createPattern(img, 'repeat');
            ctx.fillRect(0, 0, 64, this.height - (this.spriteSize << 1));
            ctx.setTransform(1, 0, 0, 1, 0, 0);
        });
    }

    __prerender() {
        this.prerenderCache = Utils.makeCtx(this.width + 5, this.height + 5);
        this.useCtx(this.prerenderCache, function() {
            this.ctx.setTransform(1, 0, 0, 1, 0, 0);
            this.ctx.translate(-this.absoluteLeft, -this.absoluteTop);
            this.staticDraw();
        });
    }

    staticDraw() {
        if (this.options.shadow) { this.drawShadow(this.ctx); }
        UiPanel.prototype.draw.call(this);
        if (this.options.borders) { return this.drawBorders(this.ctx); }
    }

    draw() {
        this.ctx.drawImage(this.prerenderCache.canvas, this.absoluteLeft, this.absoluteTop);

        this.children.forEach(wnd => {
            wnd.draw();
        });
    }

    onClose(__closeCallback) {
        this.closeCallback = __closeCallback;
    }

    addButtonsFooter(buttonsArray) {
        const buttonHeight = 30;
        const buttonWidth = 110;
        const buttonMargin = 30;
        const count = buttonsArray.length;
        const buttonPanelWidth = (count * buttonWidth) + ((count - 1) * buttonMargin);

        const doAction = action => {
            action();
            return this.removeWnd();
        };

        for (let index = 0; index < buttonsArray.length; index++) {
            const button = buttonsArray[index];
            const leftOffset = (index * buttonWidth) + (index * buttonMargin);
            const buttonLeft = ((<number>this.options.width - buttonPanelWidth) >> 1) + leftOffset;
            new UiButton({
                left: buttonLeft,
                top: this.blocksStep,
                width: buttonWidth,
                height: buttonHeight,
                text: button.title,
                parent: this,
                events: {
                    'leftClick': doAction.bind(this, button.action)
                }
            });
        }

        this.blocksStep += buttonHeight + 30;
        this.setHeight(this.blocksStep);
        return this;
    }

    addTextBlock(text) {
        let textBlock = new UiText({
            text,
            top: this.blocksStep + 20,
            left: 0,
            align: 'center',
            color: '#FFF',
            width: this.options.width,
            parent: this
        });

        this.blocksStep += textBlock.getHeight() + 30;
        this.setHeight(this.blocksStep);
        return this;
    }
};