
type IPropertyEffectSetter = () => number

export class Property {
    private base: number = 0;
    private maxValue: number = 0;
    private minValue: number = 0;
    private watchers: Function[] = [];

    effects: Set<IPropertyEffectSetter> = new Set();

    constructor(base = 0, max = null, min = 0) {
        this.base = base;
        this.maxValue = max;
        this.minValue = min;
    }

    private getEffectsSummary() {
        let result = 0;
        this.effects.forEach(getAmount => result += getAmount());
        return result;
    }

    private setBase(amount) {
        if (this.maxValue === null) {
            this.base = Math.max(amount, this.minValue);
        } else {
            this.base = Math.max(Math.min(amount, this.maxValue), this.minValue);
        }
        this.runWatchers();
    }

    setMax(max) {
        this.maxValue = max;
    }

    setMin(min) {
        this.minValue = min;
    }

    set(amount) {
        this.setBase(amount);
    }

    push(amount) {
        this.setBase(this.base + amount);
    }

    max() {
        this.setBase(this.maxValue);
    }

    get(): number {
        return this.base + this.getEffectsSummary();
    }

    setWatcher(callback: Function) {
        this.watchers.push(callback);
    }

    private runWatchers() {
        const value = this.base + this.getEffectsSummary();
        this.watchers.map((fn) =>
            (typeof fn === 'function' ? fn(value, this) : undefined));
    }
}
