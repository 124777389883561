
import {GenericVisitableObject} from "./GenericVisitableObject";
import {UiController} from "../../../controllers/UiController";
import {IObjectData} from "../../MapObject";
import {HeroObject} from "../hero/HeroObject";

export class StarAxisObject extends GenericVisitableObject {

    recordVisits = true;

    constructor(data: IObjectData, id: number) {
        super(data, id);

        this.events.on('action', (hero: HeroObject) => {
            hero.properties.get('power').push(1);
            UiController.alert('Power +1');
        });
    }
}
