
import {DIRECTIONS} from "../../../constants/DIRECTIONS";
import {HERO_STATES} from "../../../constants/HERO_STATES";
import {Events} from "../../../controllers/Events";
import {ObjectSprite} from "../../../views/sprites/ObjectSprite";
import {HeroGoState} from "./HeroGoState";
import {HeroIdleState} from "./HeroIdleState";
import {Render} from "../../../render/Render";
import {MapModel} from "../../map/MapModel";
import {ShipObject} from "../ShipObject";
import {HeroObject} from "./HeroObject";
import {inject} from '../../../helpers/InjectDectorator';

export class HeroStateMixin {

    isMoving = false;
    stateController = null;
    state: string;
    direction = DIRECTIONS.EE;
    defaultAnimation = `${DIRECTIONS.EE}_IDLE`;
    transport: ShipObject;
    STATES: {};
    defaultSprite: ObjectSprite;
    sprite: ObjectSprite;

    __initStates() {

        this.STATES = {
            [HERO_STATES.GO]: {
                controller: new HeroGoState(<HeroObject><any>this),
                animationSuffix: 'GO'
            },

            [HERO_STATES.IDLE]: {
                controller: new HeroIdleState(<HeroObject><any>this),
                animationSuffix: 'IDLE'
            }
        };

        this.setState(HERO_STATES.IDLE);
        this.defaultSprite = this.sprite;
        if (this.defaultSprite) {
            this.defaultSprite.setAnimation(this.defaultAnimation);
        }

        (<HeroObject><any>this).events
            .on('move', () => this.isMoving = true)
            .on('moveEnd', () => this.isMoving = false);
    }

    setState(state: string): HeroObject {
        this.state = state;
        this.stateController = this.STATES[this.state].controller;
        this.sprite.setAnimation(`${this.direction}_${this.STATES[this.state].animationSuffix}`);
        Events.dispatch('hero.changeState');
        return <HeroObject><any>this;
    }

    setDirection(direction) {
        this.direction = direction;
        return this;
    }

    handleInput() {
        return this.stateController.handleInput();
    }

    is(object) {
        return ((<HeroObject><any>this).id === object.id) || ((this.transport && this.transport.id) === object.id);
    }

    __combinePositions(from, to) {
        from.setLeft(to.getLeft());
        from.setTop(to.getTop());
        from.x = to.x;
        return from.y = to.y;
    }

    __centerCameraToHero() {
        const {objectLeft, objectTop, z} = <HeroObject><any>this;
        const render = inject<Render>(Render);

        render.setCenter(objectLeft - 32, objectTop - 16, z);
    }

    attachTransport(transport) {
        const {x, y} = (<HeroObject><any>this);
        this.transport = transport;
        (<HeroObject><any>this).setSprite(this.transport.sprite);
        this.transport.sprite.setAnimation(`${this.direction}_IDLE`);
        this.__combinePositions(this, this.transport);
        MapModel.updatePoints(x - 1, y, this.transport.x - 1, this.transport.y, (<HeroObject><any>this));
        this.__centerCameraToHero();
    }

    detachTransport() {
        if (this.transport) {
            this.transport.sprite.setAnimation(`${this.direction}_IDLE`);
            (<HeroObject><any>this).setSprite(this.defaultSprite);
            this.defaultSprite.setAnimation(`${this.direction}_GO`);
            this.transport.setPassenger(null);
            this.updateTransport();
            this.transport = null;
        }
    }

    updateTransport() {
        if (this.transport) {
            return this.__combinePositions(this.transport, this);
        }
    }
}
