
import {UiController} from "./UiController";
import {SceneController} from "./SceneController";
import {ResourcesController} from "./ResourcesController";
import {MapModel} from "../models/map/MapModel";
import {Render} from "../render/Render";
import {ControllerHelper} from "../helpers/ControllerHelper";
import {MapDrawer} from "../render/MapDrawer";
import {HeroesCollection} from "../models/HeroesCollection";
import {Events} from "./Events";
import {UiView} from "../views/game/UiView";
import {StateService} from "../services/StateService";
import {IMapDataPlayer, MapDataReader, SPRITE_INDEXES} from "../models/map/MapDataReader";
import * as Utils from "../utils/Utils";
import {IObjectData} from "../models/MapObject";
import {inject, Inject} from "../helpers/InjectDectorator";
import {ActivePlayer} from "./ActivePlayer";
import {PlayerModel} from "../models/player/PlayerModel";
import {MapData} from './MapData';

export class GameController extends ControllerHelper {

    ui = UiController;

    @Inject(MapDrawer) private mapDrawer: MapDrawer;
    @Inject(ActivePlayer) private activePlayer: PlayerModel;
    @Inject(ResourcesController) private resourcesController: ResourcesController;
    @Inject(StateService) private stateService: StateService;
    @Inject(MapData) private mapData: MapDataReader;
    @Inject(Render) private render: Render;
    @Inject(UiView) protected uiView: UiView;

    constructor() {
        super();

        this.mapDrawer.clear();
        HeroesCollection.bootstrap();
        this.updateMapDataFromState();
        MapModel.bootstrap();
        this.resourcesController
            .loadMapTextures()
            .then(() => {
                SceneController.bootstrap();
                Events.dispatch('texturesLoaded');
                HeroesCollection.initTavern();
                UiController.bootstrap(this.uiView);

                this.render.setSource({
                    'terrain': [this.mapDrawer.prepareTerrain, this.mapDrawer.drawTerrain],
                    'objects': [this.mapDrawer.prepareObjects, this.mapDrawer.drawObjects],
                    'ui': [UiController.prepare, UiController.draw]
                });
            });

        this._z = this.stateService.get('center.z');
        this.stateService.subscribe('center.z', () => {
            this._z = this.stateService.get('center.z');

            if (!this.zInit) {
                this.zInit = true;
                return;
            }

            MapModel.update();
            this.mapDrawer.update();
            this.activePlayer.updateMapFog();
        });
    }

    private _z: number;
    private zInit: boolean;

    get z(): number {
        return this._z;
    }

    static resolve() {
        const mapUrl = inject<StateService>(StateService).get('mapUrl');
        const resourcesController = inject<ResourcesController>(ResourcesController);

        return [
            resourcesController.loadGameUiTextures(),
            resourcesController.loadObjectsData(),
            resourcesController.loadMonstersData(),
            resourcesController.loadHeroesData(),
            resourcesController.loadArtifactsData(),
            resourcesController.loadMapData(mapUrl)
        ];
    }

    private updatePlayersData(mapData: MapDataReader) {
        const players = <IMapDataPlayer[]>this.stateService.get('players') || [];
        const objects =  mapData.objects;

        players.forEach((srcPlayer: IMapDataPlayer) => {
            const player = mapData.players.find(item => item.color === srcPlayer.color);

            Object.assign(player, srcPlayer);

            player.race = srcPlayer.selectedRace > -1
                ? srcPlayer.selectedRace
                : Utils.something(player.availableRaces);

            if (srcPlayer.selectedHero !== SPRITE_INDEXES.RANDOM_HERO) {
                const firstHeroIndex = objects.findIndex(object => {
                    return object.data.type === 'hero' && object.data['owner'] === player.index;
                });

                if (firstHeroIndex === -1) {
                    return;
                }

                this.stateService.set(`objects.${firstHeroIndex}.data.person`, srcPlayer.selectedHero);
                this.stateService.set(`objects.${firstHeroIndex}.object_class`, 69);

                objects[firstHeroIndex].data['person'] = srcPlayer.selectedHero;
                objects[firstHeroIndex].object_class = 69;
            }
        });
    }

    private updateObjectsData(mapData: MapDataReader) {
        const objects =  <{[key: number]: IObjectData}>this.stateService.get('objects') || {};

        Utils.forEach(objects, (item: IObjectData, key: number) => {
            if (item === null) {
                mapData.objects[key] = null;
                return;
            }

            if (Utils.isObject(item.data)) {
                Object.assign(mapData.objects[key].data, item.data);
            }

            if (Utils.isArray(item.coords)) {
                mapData.objects[key].coords = item.coords;
            }
        });
    }

    private updateMapDataFromState() {
        this.updatePlayersData(this.mapData);
        this.updateObjectsData(this.mapData);

        this.resourcesController.storage.set('mapData', this.mapData);
    }
}