
import {CommandHelper} from "../helpers/CommandHelper";
import {Render} from "../render/Render";
import {ObjectsCollection} from "../models/ObjectsCollection";
import {Inject} from '../helpers/InjectDectorator';

export class SelectObjectCommand extends CommandHelper {

    @Inject(Render) private render: Render;
    @Inject(ObjectsCollection) protected objectsCollection: ObjectsCollection;

    constructor({id}) {
        super();

        const object = this.objectsCollection.getById(+id);
        const owner = object.owner;

        let prevSelectedObject = owner.getSelectedObject();

        let selectObject = (object) => {
            owner.setSelectedObject(object);

            const {objectLeft, objectTop, z} = object;

            this.render.setCenter(objectLeft - 32, objectTop - 16, z);
        };

        this.setExecute(() => selectObject(object));

        this.setUndo(() => selectObject(prevSelectedObject));
    }
};
