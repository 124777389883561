
import {IObjectData, MapObject} from "../MapObject";
import {ObjectAttackableMixin} from "./ObjectAttackableMixin";
import {TextService} from "../../services/TextService";
import {ObjectsCollection} from "../ObjectsCollection";
import {CreaturesStorage} from "../storages/CreaturesStorage";
import {TilesCollection} from "../map/TilesCollection";
import * as Utils from "../../utils/Utils";
import {CURSORS} from "../../constants/CURSORS";
import {Grid} from "../../helpers/Grid";
import {BattleSide} from "../../services/BattleService";
import {StateService} from '../../services/StateService';
import {ITextures, Textures} from '../../controllers/Textures';
import {Inject} from '../../helpers/InjectDectorator';
import {ICreatureData, MonstersData} from "../../controllers/MonstersData";
import {Mixin} from '../../utils/Utils';
import {MapComponent} from '../MapComponent';

const WEEK_GROWTH = .1;

const RANDOM_PRESETS = {
    [71]: {},
    [72]: {level: 1},
    [73]: {level: 2},
    [74]: {level: 3},
    [75]: {level: 4},
    [162]: {level: 5},
    [163]: {level: 6},
    [164]: {level: 7}
};

const STARTUP_QUANTITY_BY_LEVEL = {
    [0]: [30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50],
    [1]: [30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50],
    [2]: [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40],
    [3]: [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
    [4]: [15, 16, 17, 18, 19, 20, 21, 22, 23],
    [5]: [10, 11, 12, 13, 14, 15, 16, 17],
    [6]: [5, 6, 7, 8, 9, 10, 11],
    [7]: [1, 2, 3, 4, 5]
};

const monstersCache = {};

interface IObjectCreatureData extends IObjectData {
    monsterData: ICreatureData;
    quantity: number;
}

@MapComponent('monster')
@Mixin(ObjectAttackableMixin)
export class MonsterObject extends MapObject implements ObjectAttackableMixin {

    dynamic = true;
    gridDynamic = true;
    renderDynamic = true;
    isMonster = true;

    creatureId: number;
    creatures: CreaturesStorage;
    @Inject(Textures) protected textures: ITextures;
    @Inject(ObjectsCollection) protected objectsCollection: ObjectsCollection;
    @Inject(MonstersData) protected monstersData: ICreatureData[];
    @Inject(StateService) protected stateService: StateService;
    @Inject(TextService) protected textService: TextService;

    constructor(data: IObjectData, id: number) {
        super(data, id);

        this.draw = this.draw.bind(this);
        this.creatureId = this.objectSubClass;

        this.tip = this.data.quantity + ' ' + this.textService.i18n(`MONSTERS.${this.creatureId}.PLURAL`);
        this.iconSprite = this.textures.get('creatures_portraits_md');
        this.iconSpriteIndex = this.data.monsterData.spriteId;

        this.setAttackableArea(this.id);
        this.events.on('remove', () => {
            this.setAttackableArea(null);
        });

        this.events.on('action', hero => {
            this.checkSkipBattle(hero.creatures).then(isSkip => {
                if (isSkip) {
                    this.objectsCollection.remove(this);
                } else {
                    this.creatures = this.lineUpStacks(hero.creatures);
                    this.startBattleWith(hero);
                }
            });
        });

        this.stateService.subscribe('time.week', () => {
            this.data.quantity += Math.ceil(this.data.quantity * WEEK_GROWTH);
            this.tip = this.data.quantity + ' ' + this.textService.i18n(`MONSTERS.${this.creatureId}.PLURAL`);
        });
    }

    private setAttackableArea(value: number) {
        [
            [-1, -1], [+0, -1], [+1, -1],
            [-1, +0],           [+1, +0],
            [-1, +1], [+0, +1], [+1, +1]

        ].forEach(([tX, tY]) => {
            const x = this.x + tX;
            const y = this.y + tY;

            if ((x < 0) || (y < 0)) {
                return;
            }
            TilesCollection.setAttackable(x, y, this.z, value);
        });
    }

    private checkSkipBattle(oppositeStacks: CreaturesStorage): Promise<boolean> {
        return new Promise((resolve) => {
            const ratio = this.getOppositeRatio(oppositeStacks);
            // if ratio > 4
            //   UiController.modal(UiConfirmWindow, runAwayText).promise
            // else
            //   resolve(false)
            resolve(false);
        });
    }

    private getOppositeRatio(oppositeStacks: CreaturesStorage): number {
        const oppositeAICost = oppositeStacks.getAICost();
        const thisAICost = CreaturesStorage.prototype.getCreatureAICost(this.creatureId) * this.data.quantity;
        return oppositeAICost / thisAICost;
    }

    private getStacksCountByRatio(ratio: number): number {
        let stackNumber = 1;
        if (ratio <= 0.5) {
            stackNumber = 7;
        }
        if (ratio > 0.5) {
            stackNumber = 6;
        }
        if (ratio > 0.67) {
            stackNumber = 5;
        }
        if (ratio > 1) {
            stackNumber = 4;
        }
        if (ratio > 1.5) {
            stackNumber = 3;
        }
        if (ratio > 2) {
            stackNumber = 2;
        }
        if (ratio >= 3) {
            stackNumber = 1;
        }

        return Utils.randomize({
            [40]() { return Math.max(Math.min(stackNumber + Utils.something([-1, 1]), 7), 1); },
            [60]() { return stackNumber; }
        });
    }

    private buildStacks(stackNumber: number): [number, number][] {
        const stacks = [];
        let leftQuantity = this.data.quantity;
        let stackQuantity = Math.ceil(leftQuantity / stackNumber);
        while (leftQuantity > 0) {
            let quantity = Math.min(stackQuantity, leftQuantity);
            leftQuantity -= quantity;
            stacks.push([this.creatureId, quantity]);
        }

        if ((stackNumber > 1) && ~this.data.monsterData.upgradeId) {
            // in 50% chance make central stack upgraded, if monsters is upgradable
            if (Utils.something([1, 0])) {
                stacks[stackNumber >> 1][0] = this.data.monsterData.upgradeId;
            }
        }
        return stacks;
    }

    private lineUpStacks(oppositeStacks): CreaturesStorage {
        const ratio = this.getOppositeRatio(oppositeStacks);
        const stackNumber = this.getStacksCountByRatio(ratio);
        const stacks = this.buildStacks(stackNumber);
        const creatures = new CreaturesStorage();

        creatures.fill(stacks);

        return creatures;
    }

    getActionCursor(): string {
        return CURSORS.ATTACK;
    }

    draw(ctx: CanvasRenderingContext2D) {
        this.sprite.draw(this.objectLeft, this.objectTop, this.data.monsterData.spriteId, ctx);
    }

    getEnterPoints(grid: Grid, x: number, y: number, from: MapObject) {
        return [
            grid.get(x - 1, y - 1),
            grid.get(x, y - 1),
            grid.get(x + 1, y - 1),
            grid.get(x - 1, y),
            grid.get(x - 1, y + 1),
            grid.get(x, y + 1),
            grid.get(x + 1, y + 1),
            grid.get(x + 1, y)
        ].filter(item => item);
    }

    prepare(data: IObjectCreatureData): IObjectCreatureData {
        let monsterData;

        if (RANDOM_PRESETS[data.object_class]) {
            const targetLevel = RANDOM_PRESETS[data.object_class].level;
            let filtered = monstersCache[targetLevel];
            if (!filtered) {
                filtered = Utils.filter(this.monstersData, monster => parseInt(monster.level) === targetLevel);
                monstersCache[targetLevel] = filtered;
            }
            monsterData = Utils.something(filtered);
            data.object_subclass = parseInt(monsterData.id);
        } else {
            monsterData = Utils.find(this.monstersData, monster => parseInt(monster.id) === data.object_subclass);
        }
        data.monsterData = monsterData;
        data.quantity = Utils.something(STARTUP_QUANTITY_BY_LEVEL[monsterData.level]);
        data.texture = 'creatures_sm';

        return data;
    }

    onLoseBattle(): any {
        return undefined;
    }

    startBattleWith(opposite): boolean {
        return undefined;
    }

    transferWinnerProfitIfItPossible(fromSide: BattleSide, toSide: BattleSide) {}
}