import {CampfireObject} from "./CampfireObject";
import {ChestObject} from "./ChestObject";
import {IObjectData} from "../../MapObject";
import {GenericTreasureObject} from "./GenericTreasureObject";
import {MapComponent} from '../../MapComponent';

const CONSTRUCTORS = {
    [12]: CampfireObject,
    [101]: ChestObject
};

@MapComponent('generic_treasure')
export class GenericTreasureFabric {

    static isFabric = true;

    static getData(data: IObjectData) {
        const constructor = CONSTRUCTORS[data.object_class] || GenericTreasureObject;

        return constructor.prototype.prepare(data);
    }

    static get(data: IObjectData, id: number) {
        const constructor = CONSTRUCTORS[data.object_class] || GenericTreasureObject;

        return new constructor(data, id);
    }
}
