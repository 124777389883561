
import {HeroObject} from "./HeroObject";
import {KEYS} from "../../../constants/KEYS";
import {DIRECTIONS} from "../../../constants/DIRECTIONS";
import {Events} from "../../../controllers/Events";

export class HeroIdleState {

    private keys = new Set();

    constructor(private hero: HeroObject) {
        this.hero = hero;
    }

    getMovementDirection(): number {
        if (this.keys.size === 2) {
            // NE
            if (this.keys.has(KEYS.K_W) && this.keys.has(KEYS.K_D)) {
                return DIRECTIONS.NE;
            }

            // SE
            if (this.keys.has(KEYS.K_S) && this.keys.has(KEYS.K_D)) {
                return DIRECTIONS.SE;
            }

            // NW
            if (this.keys.has(KEYS.K_W) && this.keys.has(KEYS.K_A)) {
                return DIRECTIONS.NW;
            }

            // SW
            if (this.keys.has(KEYS.K_S) && this.keys.has(KEYS.K_A)) {
                return DIRECTIONS.SW;
            }
        }

        if (this.keys.size === 1) {
            // NN
            if (this.keys.has(KEYS.K_W)) {
                return DIRECTIONS.NN;
            }

            // EE
            if (this.keys.has(KEYS.K_D)) {
                return DIRECTIONS.EE;
            }

            // SS
            if (this.keys.has(KEYS.K_S)) {
                return DIRECTIONS.SS;
            }

            // WWs
            if (this.keys.has(KEYS.K_A)) {
                return DIRECTIONS.WW;
            }
        }
    }

    handleMovementInput() {
        const direction = this.getMovementDirection();
        this.hero.owner.moveHeroToDirection(direction);
    }

    handleInput() {
        this.keys = Events.getKeys();
        this.handleMovementInput();
    }
}
