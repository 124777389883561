
import {OwnershipObject} from "../OwnershipObject";
import {Events} from "../../../controllers/Events";
import {UiController} from "../../../controllers/UiController";
import {HeroesCollection} from "../../HeroesCollection";
import {CURSORS} from "../../../constants/CURSORS";
import {COLORS} from "../../../constants/COLORS";
import * as Utils from "../../../utils/Utils";
import {CreaturesStorage} from "../../storages/CreaturesStorage";
import {SecondaryStorage} from "../../storages/SecondaryStorage";
import {MapDrawer} from "../../../render/MapDrawer";
import {IObjectData, MapObject} from "../../MapObject";
import {HeroStateMixin} from "./HeroStateMixin";
import {HeroStoragesMixin} from "./HeroStoragesMixin";
import {HeroPathfinder} from "./HeroPathfinder";
import {ObjectAttackableMixin} from "../ObjectAttackableMixin";
import {UiHeroTipWindow} from "../../../views/windows/UiHeroTipWindow";
import {UiHeroWindow} from "../../../views/windows/UiHeroWindow";
import {ShipObject} from "../ShipObject";
import {ObjectSprite} from "../../../views/sprites/ObjectSprite";
import {HeroPropertiesStorage} from "../../storages/HeroPropertiesStorage";
import {CatapultObject} from "../battlefield/CatapultObject";
import {BattleSide} from "../../../services/BattleService";
import {HeroArtifactsStorage} from './HeroArtifactsStorage';
import {inject, Inject} from "../../../helpers/InjectDectorator";
import {ActivePlayer} from "../../../controllers/ActivePlayer";
import {PlayerModel} from "../../player/PlayerModel";
import {ITextures, Textures} from '../../../controllers/Textures';
import {MapDataReader} from '../../map/MapDataReader';
import {MapData} from '../../../controllers/MapData';
import {HeroesData, IHeroConfig, IHeroesData} from "../../../controllers/HeroesData";
import {ITownData, TownsData} from "../../../controllers/TownsData";
import {Mixin} from '../../../utils/Utils';
import {MapComponent} from '../../MapComponent';

const RANDOM_HERO = 70;

export interface IArtifactsStorage {
    backpack: number[],
    spellbook: number,
    headwear: number,
    neck: number,
    shoulders: number,
    right_hand: number,
    left_hand: number,
    torso: number,
    right_ring: number,
    left_ring: number,
    feet: number,
    misc1: number,
    misc2: number,
    misc3: number,
    misc4: number,
    misc5: number,
    device1: number,
    device2: number,
    device3: number,
    device4: number
}

interface IObjectHeroArtifactsData {
    worn: IArtifactsStorage
}

interface IObjectHeroDataData {
    person?: number;
    owner?: number;
    primary_skills?: any;
    secondary_skills?: any;
    artifacts?: IObjectHeroArtifactsData;
    creatures?: [number, number][];
    spells?: any;
    type: string;
}

export interface IObjectHeroData extends IObjectData<IObjectHeroDataData> {
    config: IHeroConfig;
    raceConfig: ITownData;
}

@MapComponent('hero')
@Mixin(ObjectAttackableMixin)
@Mixin(HeroStateMixin)
@Mixin(HeroStoragesMixin)
export class HeroObject extends OwnershipObject implements ObjectAttackableMixin, HeroStateMixin, HeroStoragesMixin {

    isMoving: boolean;
    stateController: any;
    state: string;
    direction: number;
    defaultAnimation: string;
    transport: ShipObject;
    STATES: {};
    defaultSprite: ObjectSprite;
    movementType = 'earth';
    properties: HeroPropertiesStorage;
    race: number;
    catapult: CatapultObject;
    artifacts: HeroArtifactsStorage;

    isSelectable = true;
    isHero = true;
    isTown = false;
    gridDynamic = true;

    name: string;
    creatures: CreaturesStorage;
    secondary: SecondaryStorage;

    pathFinder: HeroPathfinder;

    @Inject(MapDrawer) private mapDrawer: MapDrawer;
    @Inject(ActivePlayer) protected activePlayer: PlayerModel;
    @Inject(Textures) protected textures: ITextures;
    @Inject(MapData) protected mapData: MapDataReader;
    @Inject(HeroesData) protected heroesData: IHeroesData;

    constructor(heroData: IObjectHeroData, id: number) {
        super(heroData, id);

        this.pathFinder = new HeroPathfinder(this);

        this.__initStates();
        this.__initStorages(this);
        this.init();

        Events.on('game.startPlayerTurn', activePlayer => {
            if (activePlayer.is(this.owner)) {
                this.pathFinder.updatePath();
            }
        });

        this.events
            .on('focus', () => this.pathFinder.updatePath())
            .off('rightMousedown')
            .on('rightMousedown', () => {
                const uiTipWindow = UiController.modal(UiHeroTipWindow, this);
                Events.one('rightMouseup', () => UiController.remove(uiTipWindow));
                return false;
            })
            .on('leftClick', () => {
                if (this.isSelected()) {
                    this.showModal();
                }
            })
            .on('dblClick', () => this.showModal())

            .on('action', hero => {
                if (this.owner.is(hero.owner)) {
                    this.showModal();
                } else {
                    this.startBattleWith(hero);
                }
            })
            .on('remove', e => {
                HeroesCollection.dismiss(this.data.data.person);
                setTimeout(() => {
                    const isActivePlayerHero = this.activePlayer.is(this.owner);

                    this.setOwner(null);

                    if (isActivePlayerHero) {
                        this.activePlayer.selectMainObject();
                    }
                });
            });
    }

    showModal() {
        UiController.modal(UiHeroWindow, this);
    }

    init() {
        this.iconSprite = this.textures.get('heroes_portraits_lg');
        this.iconSpriteIndex = this.data.data.person;

        if (this.data.data.name) {
            this.name = Utils.readAsString(this.data.data.name);
        }

        return HeroesCollection.register(this.data.data.person);
    }

    getCursor() {
        return CURSORS.HERO;
    }

    getActionCursor() {
        if (this.activePlayer.is(this.owner)) {
            return CURSORS.CHANGING;
        } else {
            return CURSORS.ATTACK;
        }
    }

    draw(ctx) {
        let x, y;
        if (this.hidden) { return false; }

        if (this.isSelected()) {
            x = this.mapDrawer.left(this.objectLeft) - 16;
            y = this.mapDrawer.top(this.objectTop) + 22;
            const radius = 25;

            ctx.beginPath();
            ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
            ctx.arc(x, y - 1, radius - 5, 0, 2 * Math.PI, false);
            ctx.strokeStyle = 'rgba(255, 180, 5, 0.8)';
            ctx.stroke();
        }

        this.sprite.draw(this.objectLeft, this.objectTop);

        if (this.owner) {
            x = (this.mapDrawer.left(this.objectLeft) - 32) + 8;
            y = (this.mapDrawer.top(this.objectTop) - 32) + 8;
            const rgb = COLORS[this.owner.color.toUpperCase()];
            ctx.fillStyle = `rgb(${rgb.join()})`;
            ctx.fillRect(x, y, 12, 6);

            ctx.strokeStyle = "black";
            return ctx.strokeRect(x - .5, y - .5, 13, 7);
        }
    }

    lineUpStacks(oppositeStacks) {
        return this.creatures;
    }

    __fixSpawnedInTown(heroData: IObjectData) {
        Utils.forEach(this.mapData.objects, (objectData: IObjectData) => {
            if (!objectData) {
                return;
            }

            let {coords, data} = objectData;

            if ((data.type === 'town') && (coords.toString() === heroData.coords.toString())) {
                heroData.coords[0]--;
            }
        });
    }

    getScoutingRadius() {
        let baseValue = 5;
        if (this.secondary.has('SCOUTING')) {
            baseValue += this.secondary.get('SCOUTING');
        }
        return baseValue;
    }

    getSpeciality() {
        return this.data.config.spec;
    }

    prepare(data: IObjectHeroData) {
        const townsData = inject<ITownData[]>(TownsData);

        if (data.object_class === RANDOM_HERO) {
            const player = this.mapData.players[data.data != null ? data.data.owner : undefined];

            if (player) {
                data.data.person = HeroesCollection.getRandom(player.race).id;
            } else {
                data.data.person = ~~(Math.random() * 156);
            }
        }

        data.config = this.heroesData[data.data.person];
        data.raceConfig = townsData[data.config.race];
        data.texture = 'ah00_e.def';
        this.__fixSpawnedInTown(data);
        return data;
    }

    data: any;

    sprite: ObjectSprite;

    __initStates() { return null }
    setState(state: string): HeroObject { return null }
    setDirection(direction): this { return null }
    handleInput() { return null }
    is(object: MapObject): boolean { return null }
    __combinePositions(from, to) { return null }
    __centerCameraToHero() { return null }
    attachTransport(transport) { return null }
    detachTransport() { return null }
    updateTransport() { return null }

    __initStorages(hero: HeroObject) {}

    onLoseBattle() {}
    startBattleWith(opposite: BattleSide) {}
    transferWinnerProfitIfItPossible(fromSide: BattleSide, toSide: BattleSide) {}
}
