
import {ResourcesStorage} from "../../storages/ResourcesStorage";
import {GenericVisitableObject} from "./GenericVisitableObject";
import {UiController} from "../../../controllers/UiController";
import {IObjectData} from "../../MapObject";
import {HeroObject} from "../hero/HeroObject";
import {StateService} from '../../../services/StateService';
import {Inject} from "../../../helpers/InjectDectorator";

export class WaterWheelObject extends GenericVisitableObject {

    recordVisits = true;
    clearVisitsEveryWeek = true;
    resources = new ResourcesStorage();

    @Inject(StateService) protected stateService: StateService;

    constructor(data: IObjectData, id: number) {
        super(data, id);

        this.initResources = this.initResources.bind(this);

        this.events.on('action', hero => {
            if (this.hasResources()) {
                UiController.statusWindow.resourceTransferMessage(this.resources);
                this.flushResources(hero);
                return true;
            } else {
                return false;
            }
        });

        this.initResources();

        this.stateService.subscribe('time.week', () => {
            this.initResources();
        });
    }

    initResources() {
        this.resources.set({gold: 1000});
    }

    flushResources(hero: HeroObject) {
        this.resources.transfer(hero.owner.resources);
    }

    hasResources(): boolean {
        return !this.resources.isEmpty();
    }
}
