
import {UiPanel} from "../../UiPanel";
import {IStatusWindowState} from "./IStatusWindowState";
import { UiStatusWindow } from "./UiStatusWindow";

export class MessageScreen extends UiPanel implements IStatusWindowState {
    stateParams: { [key: string]: any };
    parent: UiStatusWindow;

    private iconLeft: number;
    private textTop: any;
    private textLeftPosition = 0;

    prepare() {
        const {iconSprite} = this.stateParams;

        this.textLeftPosition = this.width / 2;

        if (iconSprite) {
            let iconHeight = iconSprite.height;
            this.iconLeft = Math.floor((this.width - iconSprite.width) / 2);
            this.textTop = 55 + iconHeight;
        } else {
            this.textTop = this.height / 2;
        }

        setTimeout(() => {
            this.parent.setState('SELECTED_OBJECT');
        }, 5000);
    }

    exit() {}

    draw() {
        const {text, iconSprite, iconSpriteIndex} = this.stateParams;

        if (iconSprite) {
            this.icon({
                left: this.iconLeft,
                top: 40,
                sprite: iconSprite,
                spriteIndex: iconSpriteIndex
            });
        }

        this.text({
            text,
            left: this.textLeftPosition,
            top: this.textTop,
            align: 'center',
            color: '#FFFFFF'
        });
    }
}