
import {HttpService} from "./HttpService";
import { Inject } from "../helpers/InjectDectorator";

export class DataService {

    @Inject(HttpService) private httpService: HttpService;

    getObjectsData(repositoryUrl: string): Promise<any> {
        return this.httpService.load({
            url: `${repositoryUrl}files/objects.json`,
            type: 'json'
        });
    }

    getArtifactsData(repositoryUrl: string): Promise<any> {
        return this.httpService.load({
            url: `${repositoryUrl}files/artifacts.json`,
            type: 'json'
        });
    }

    getHeroesData(repositoryUrl: string): Promise<any> {
        return this.httpService.load({
            url: `${repositoryUrl}files/heroes.json`,
            type: 'json'
        });
    }

    getMonstersData(repositoryUrl: string): Promise<any> {
        return this.httpService.load({
            url: `${repositoryUrl}files/monsters.json`,
            type: 'json'
        });
    }

    getTownsData(repositoryUrl: string): Promise<any> {
        return this.httpService.load({
            url: `${repositoryUrl}files/towns.json`,
            type: 'json'
        });
    }

    getMapData(mapUrl: string): Promise<any> {
        return this.httpService.load({
            url: mapUrl,
            type: 'json'
        });
    }

    getMapsList(repositoryUrl: string): Promise<any> {
        return this.httpService.load({
            url: `${repositoryUrl}maps.json`,
            type: 'json'
        });
    }

    getI18n(key: string, repositoryUrl: string): Promise<any> {
        return this.httpService.load({
            url: `${repositoryUrl}files/i18n/${key}.json`,
            type: 'json'
        });
    }

    getMarkup(repositoryUrl: string): Promise<any> {
        return this.httpService.load({
            url: `${repositoryUrl}markup/markup.json`,
            type: 'json'
        });
    }

    getHeroesClassesData(repositoryUrl: string): Promise<any> {
        return this.httpService.load({
            url: `${repositoryUrl}files/heroes_classes.json`,
            type: 'json'
        });
    }
}