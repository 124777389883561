
import {Render} from "../render/Render";
import {Spinner} from "../views/Spinner";
import {ResourcesController} from "./ResourcesController";
import {CursorService} from "../services/CursorService";
import {CURSORS} from "../constants/CURSORS";
import {Events} from "./Events";
import {Layers} from "../render/layers/Layers";
import {inject, Inject} from '../helpers/InjectDectorator';

const resourcesController = inject<ResourcesController>(ResourcesController);

const BASIC_RESOLVERS = [
    resourcesController.loadI18nData(),
    resourcesController.loadUiTextures(),
    resourcesController.loadTownsData(),
    resourcesController.loadMarkup()
];

export class Router {

    states: any;

    promise: Promise<any[]>;
    controller: any;
    params: any;

    @Inject(Layers) private layers: Layers;
    @Inject(Render) private render: Render;
    @Inject(Spinner) private spinner: Spinner;
    @Inject(CursorService) private cursorService: CursorService;
    @Inject(ResourcesController) private resourcesController: ResourcesController;

    constructor() {
        this.promise = Promise.all(BASIC_RESOLVERS);
        this.controller = null;
        this.cursorService.set(CURSORS.WAIT);
    }

    go(stateName: string, params = {}) {
        this.showGlobalSpinner();

        this.params = params;
        const newController = this.getControllerByStateName(stateName);

        this.resetUiAndEvents();

        this.resolveController(newController, params)
            .then(() => {
                this.resourcesController.clearLoaders();
                this.controller = new newController();
            });
    }

    private getControllerByStateName(stateName: string): any {
        const newController = this.states[stateName];

        if (!newController) {
            throw new Error(`StateController: no state named ${stateName}`);
        }

        return newController;
    }

    private resetUiAndEvents() {
        Events.detachAll();

        if (this.controller && this.controller.ui) {
            this.controller.ui.clear();
        }

        this.layers.attachEvents();
    }

    private showGlobalSpinner() {
        this.resourcesController.clearLoaders();
        this.render.setSource({'ui': [this.spinner.prepare, this.spinner.draw]});
        this.cursorService.set(CURSORS.WAIT);
    }

    private resolveController(controller: any, params: {}): Promise<any[]> {
        const resolvers = controller.resolve(params);

        if (resolvers.length) {
            return Promise.all(resolvers);
        }

        return Promise.resolve([]);
    }
}