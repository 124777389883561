
import {DIRECTIONS} from "../constants/DIRECTIONS";

export class Pointer {

    x: number = 0;
    y: number = 0;

    constructor(firstArg, secondArg) {
        this.getDirection = this.getDirection.bind(this);
        this.is = this.is.bind(this);
        this.moveTo = this.moveTo.bind(this);
        if (Array.isArray(firstArg)) {
            [this.x, this.y] = Array.from(firstArg);
        } else {
            this.x = firstArg;
            this.y = secondArg;
        }
    }

    getDirection(dX, dY) {
        if ((this.y > dY) && (this.x === dX)) {
            return DIRECTIONS.NN;
        }
        if ((this.y > dY) && (this.x < dX)) {
            return DIRECTIONS.NE;
        }
        if ((this.y === dY) && (this.x < dX)) {
            return DIRECTIONS.EE;
        }
        if ((this.y < dY) && (this.x < dX)) {
            return DIRECTIONS.SE;
        }
        if ((this.y < dY) && (this.x === dX)) {
            return DIRECTIONS.SS;
        }
        if ((this.y < dY) && (this.x > dX)) {
            return DIRECTIONS.SW;
        }
        if ((this.y === dY) && (this.x > dX)) {
            return DIRECTIONS.WW;
        }
        if ((this.y > dY) && (this.x > dX)) {
            return DIRECTIONS.NW;
        }
        return DIRECTIONS.NN;
    }

    is(x, y) {
        return (this.x === x) && (this.y === y);
    }

    moveTo(direction, distance = 1) {
        if (direction === DIRECTIONS.NN) {
            this.y -= distance;
        }
        if (direction === DIRECTIONS.NE) {
            this.y -= distance;
            this.x += distance;
        }
        if (direction === DIRECTIONS.EE) {
            this.x += distance;
        }
        if (direction === DIRECTIONS.SE) {
            this.x += distance;
            this.y += distance;
        }
        if (direction === DIRECTIONS.SS) {
            this.y += distance;
        }
        if (direction === DIRECTIONS.SW) {
            this.x -= distance;
            this.y += distance;
        }
        if (direction === DIRECTIONS.WW) {
            this.x -= distance;
        }
        if (direction === DIRECTIONS.NW) {
            this.x -= distance;
            this.y -= distance;
        }
        return this;
    }
};