import {UiBattleWindow} from "../views/game/battle/UiBattleWindow";
import {UiController} from "../controllers/UiController";
import {HeroObject} from "../models/objects/hero/HeroObject";
import {MonsterObject} from "../models/objects/MonsterObject";
import * as Utils from "../utils/Utils";
import {Subject} from "rxjs/Subject";
import {Grid} from "../helpers/Grid";
import {ObjectAttackableMixin} from "../models/objects/ObjectAttackableMixin";
import {BattleFieldCreature} from "../models/objects/BattleFieldCreature";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {HexagonalGraph} from "../helpers/HexagonalGraph";
import {Astar} from "../helpers/Astar";
import {Maybe} from "../helpers/Maybe";
import {BattleAIService} from "./BattleAIService";
import {IPath} from './PathFinderService';
import {Inject} from "../helpers/InjectDectorator";
import {ActivePlayer} from "../controllers/ActivePlayer";
import {PlayerModel} from "../models/player/PlayerModel";

export type BattleSide = HeroObject | MonsterObject | ObjectAttackableMixin;

interface IUnitMove {
    unit: BattleFieldCreature,
    x: number,
    y: number;
}

const MORALE_CHANCE = {
    [0]: -25,
    [1]: -16.6,
    [2]: -8.3,
    [3]: 0,
    [4]: 4.2,
    [5]: 8.3,
    [6]: 12.5
};

export class BattleServiceSingleton {

    promise: Promise<BattleSide>;
    startTurnStream = new BehaviorSubject<BattleFieldCreature>(null);
    endBattleStream = new Subject();
    nextRoundStream = new Subject();
    moveUnitStream = new Subject<IUnitMove>();

    queue: BattleFieldCreature[] = [];
    stacks: [BattleFieldCreature[], BattleFieldCreature[]] = [[], []];
    activeUnit: BattleFieldCreature;

    angleUnderCursor: number = -1;
    angleUnderCursorAvailable = false;

    fieldData = new Grid<BattleFieldCreature>(15);
    graph = new HexagonalGraph(15, 11);
    passableCells = new Grid<boolean>(15);

    left: BattleSide;
    right: BattleSide;

    @Inject(ActivePlayer) private activePlayer: PlayerModel;
    @Inject(BattleAIService) private battleAIService: BattleAIService;

    startBattle(left: BattleSide, right: BattleSide) {
        this.left = left;
        this.right = right;

        this.promise = UiController.modal(UiBattleWindow, left, right).promise
            .then(winner => {
                return winner === 'left'
                    ? left
                    : right;
            });

        this.stacks[0] = this.getBattlefieldStacks(left, 'left');
        this.stacks[1] = this.getBattlefieldStacks(right, 'right');
        this.queue = this.getQueue();

        this.clearFieldData();
        this.startNextRound();
        this.onStartTurn(this.queue[0]);
    }

    shiftUnit(): BattleFieldCreature {
        const currentUnit = this.queue.shift();

        currentUnit.hadTurn = true;

        this.queue.push(currentUnit);

        return this.queue[0];
    }

    popUnit(): BattleFieldCreature {
        const currentUnit = this.queue.pop();

        currentUnit.hadTurn = true;

        this.queue.unshift(currentUnit);

        return this.queue[0];
    }

    hasNotPlayedUnits(): boolean {
        return !!this.queue.filter(({hadTurn}: BattleFieldCreature) => hadTurn).length;
    }

    isLeftStacksDied(): boolean {
        return !this.stacks[0].filter(({isAlive}: BattleFieldCreature) => isAlive).length;
    }

    isRightStacksDied(): boolean {
        return !this.stacks[1].filter(({isAlive}: BattleFieldCreature) => isAlive).length;
    }

    getCell(x: number, y: number): BattleFieldCreature {
        return this.fieldData.get(x, y);
    }

    setCell(x: number, y: number, object: BattleFieldCreature) {
        this.fieldData.set(x, y, object);
    }

    findPath(fromX: number, fromY: number, toX: number, toY: number): IPath {
        const from = this.graph.grid.get(fromX, fromY);
        const to = this.graph.grid.get(toX, toY);

        return Astar.search(this.graph, from, to);
    }

    findPassablePoints(activeUnit: BattleFieldCreature, filterFunction: (item: BattleFieldCreature) => boolean): [number, number][] {
        const result = [];

        this.fieldData
            .filter(filterFunction)
            .forEach((item, x, y) => { this.graph.setWeight(x, y, 1) })
            .forEach((cellUnit, x, y) => {
                const path = this.findPath(activeUnit.x, activeUnit.y, x, y);

                if (path.length && (path.length <= activeUnit.speed)) {
                    result.push([x, y]);
                }
            });

        return result;
    }

    getPassableCells(): Grid<boolean> {
        const result = new Grid<boolean>(15);

        if (this.activeUnit.isFlying) {
            this.findPassablePoints(this.activeUnit, this.isFlyAvailable.bind(this))
                .forEach(([x, y]) => {
                    result.set(x, y, true);
                });
        } else {
            this.findPassablePoints(this.activeUnit, this.isEmptyField.bind(this))
                .forEach(([x, y]) => {
                    result.set(x, y, true);
                });
                this.findPassablePoints(this.activeUnit, this.isEnemy.bind(this))
                .forEach(([x, y]) => {
                    result.set(x, y, true);
                });
        }
        if (this.activeUnit.isShooting) {
            this.fieldData
                .filter(this.isEnemy.bind(this))
                .forEach((item, x, y) => {
                    result.set(x, y, true);
                });
        }

        return result;
    }

    moveOrAttackTo(x: number, y: number) {
        const cellUnit = this.getCell(x, y);

        if (this.isEmptyField(cellUnit)) {
            this.moveTo(this.activeUnit, x, y);
            this.onEndTurn();
        } else {
            if (this.activeUnit.isShooting) {
                this.shootingAttack(this.activeUnit, cellUnit);
                this.onEndTurn();
            } else {
                if (this.angleUnderCursorAvailable) {
                    this.moveAndAttack(this.activeUnit, x, y);
                    this.onEndTurn();
                }
            }
        }
    }

    shootingAttack(fromUnit: BattleFieldCreature, toUnit: BattleFieldCreature) {
        if (fromUnit.side === toUnit.side) {
            return false;
        }

        toUnit.shootingHitFrom(fromUnit);

        if (fromUnit.hasTwoAttacks) {
            toUnit.shootingHitFrom(fromUnit);
        }
    }

    skipTurn() {
        this.onEndTurn()
    }

    waitTurn() {
        this.onEndTurn()
    }

    onStartTurn(activeUnit: BattleFieldCreature) {
        this.graph.clearWeight();

        this.activeUnit = activeUnit;
        this.passableCells = this.getPassableCells();

        this.callUnitLuckEvents(this.activeUnit);
        this.startTurnStream.next(this.activeUnit);

        if (!activeUnit.isControllable) {
            setTimeout(() => {
                this.battleAIService.makeTurn(this);
            }, 100);
        }
    }

    onEndTurn() {
        this.updateQueue();

        if (this.hasWinner()) {
            const side = this.getWinnerSide();

            if (side) {
                this.endBattleStream.next(side);
            }

            return;
        }

        this.activeUnit.updateEffects();
        this.callUnitMoraleEvents(this.activeUnit);

        let nextUnit = this.shiftUnit();

        if (nextUnit.hasEffect('SKIP_TURN')) {
            nextUnit = this.shiftUnit();
        }

        if (this.activeUnit.hasEffect('EXTRA_TURN')) {
            nextUnit = this.popUnit();
        }

        if (!this.hasNotPlayedUnits()) {
            this.startNextRound();
        }

        this.onStartTurn(nextUnit);
    }

    moveTo(unit: BattleFieldCreature, x: number, y: number) {
        if (!this.isEmptyField(this.getCell(x, y))) {
            return false;
        }

        const oldX = unit.x;
        const oldY = unit.y;

        this.setCell(oldX, oldY, null);
        this.setCell(x, y, unit);

        this.moveUnitStream.next({
            unit, x, y
        });
    }

    moveAndAttack(unit: BattleFieldCreature, x: number, y: number) {
        if (this.isEmptyField(this.getCell(x, y))) {
            return;
        }

        const nearestPoint = this.graph.neighborByAngle({x, y}, this.angleUnderCursor);
        this.moveTo(unit, nearestPoint.x, nearestPoint.y);
        this.meleeAttack(unit, this.getCell(x, y));
    }

    meleeAttack(fromUnit: BattleFieldCreature, toUnit: BattleFieldCreature) {
        if (fromUnit.side === toUnit.side) {
            return false;
        }

        toUnit.meleeHitFrom(fromUnit);

        if (toUnit.canResponseTo(fromUnit)) {
            fromUnit.responseHitFrom(toUnit);
            toUnit.hasResponse = false;
        }

        if (fromUnit.hasTwoAttacks) {
            toUnit.meleeHitFrom(fromUnit);
        }
    }

    isEmptyField(cellUnit: BattleFieldCreature): boolean {
        return !(cellUnit != null ? cellUnit.isAlive : undefined) || !cellUnit;
    }

    isEnemy(cellUnit: BattleFieldCreature): boolean {
        return cellUnit && cellUnit.isAlive && (this.activeUnit.side !== cellUnit.side);
    }

    private isFlyAvailable(cellUnit: BattleFieldCreature): boolean {
        return this.isEmptyField(cellUnit) || this.isEnemy(cellUnit);
    }

    private clearFieldData() {
        let x = 15;

        while (x--) {
            let y = 11;

            while (y--) {
                this.fieldData.set(x, y, null);
            }
        }
    }

    private callUnitMoraleEvents(unit) {
        if (unit.hasEffect('BLOCK_EXTRA_TURN')) {
            return;
        }

        const moraleFactor = MORALE_CHANCE[unit.getMoraleValue()];
        const activateEvent = Utils.probability(moraleFactor);

        if ((moraleFactor < 0) && activateEvent) {
            unit.addEffect('SKIP_TURN', 1);
        }

        if ((moraleFactor > 0) && activateEvent) {
            unit.addEffect('EXTRA_TURN', 1);
        }
    }

    private callUnitLuckEvents(unit: BattleFieldCreature) {
        const luckFactor = MORALE_CHANCE[unit.getLuckValue()];
        const activateEvent = Utils.probability(luckFactor);

        if ((luckFactor > 0) && activateEvent) {
            unit.addEffect('LUCK', 1);
        }
    }

    private hasWinner(): boolean {
        return this.isLeftStacksDied() || this.isRightStacksDied();
    }

    private getWinnerSide(): 'left' | 'right' | null {
        if (this.isLeftStacksDied()) {
            return 'right';
        } else if (this.isRightStacksDied()) {
            return 'left';
        } else {
            return null;
        }
    }

    private getQueue(): BattleFieldCreature[] {
        const units = this.stacks[0].concat(this.stacks[1]);

        return units.sort((a, b) => {
            if (a.data.damage.speed > b.data.damage.speed) {
                return -1;
            }

            return 1;
        });
    }

    private updateQueue() {
        this.queue = this.queue.filter(({isAlive}) => isAlive);
    }

    private getBattlefieldStacks(owner: BattleSide, side: string): BattleFieldCreature[] {
        const result: BattleFieldCreature[] = [];

        owner.creatures.forEach((position, creatureId, quantity) => {
            const creature = new BattleFieldCreature(owner, side, creatureId, quantity, position);
            const sideOwner = Maybe(owner).pluck('owner').getOrElse(null);

            creature.isControllable = this.activePlayer.is(sideOwner);

            result.push(creature);
        });

        return result;
    }

    private startNextRound() {
        this.queue.forEach(item => item.hadTurn = false);
        this.nextRoundStream.next();
    }
}

export const BattleService = new BattleServiceSingleton();