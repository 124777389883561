import * as Utils from "../../utils/Utils";
import {HeroObject} from "./hero/HeroObject";
import {BattleSide} from "../../services/BattleService";
import {CreaturesStorage} from "../storages/CreaturesStorage";
import {ICreatureData} from "../../controllers/MonstersData";

export class BattleFieldCreature {

    creatureId: number;
    data: ICreatureData;
    owner: BattleSide;
    quantity: number;
    position: number;
    side: string;
    speed: number;
    lastUnitHealth: number;
    shotsLeft: number;
    isAlive = true;
    isShooting: boolean;
    isFlying: boolean;
    isControllable = false;
    hasTwoAttacks = false;
    hasResponse = true;
    hadTurn = false;
    x = 0;
    y = 0;

    private effects: [string, number][] = [];

    constructor(owner: BattleSide, side: string, creatureId: number, quantity: number, position: number) {
        this.owner = owner;
        this.side = side;
        this.creatureId = creatureId;
        this.quantity = quantity;
        this.position = position;
        this.data = CreaturesStorage.prototype.getCreatureData(creatureId);

        this.lastUnitHealth = this.data.damage.hitPoints;
        this.speed = this.data.damage.speed;
        this.shotsLeft = this.data.damage.shots;

        this.isShooting = this.data.attributes
            ? this.data.attributes.includes("SHOOTING_ARMY")
            : false;

        this.isFlying = this.data.attributes
            ? this.data.attributes.includes("FLYING_ARMY")
            : false;

        this.hasTwoAttacks = this.data.attributes
            ? this.data.attributes.includes("const_two_attacks")
            : false;
    }

    get canShoot(): boolean {
        return this.isShooting && !!this.shotsLeft;
    }

    get canGo(): boolean {
        return true;
    }

    canResponseTo(unit: BattleFieldCreature): boolean {
        return this.isAlive && this.hasResponse;
    }

    getAttackValue(): number {
        if (this.owner instanceof HeroObject) {
            return this.data.damage.attack + this.owner.properties.getValue('attack');
        } else {
            return this.data.damage.attack;
        }
    }

    getDefenseValue(): number {
        if (this.owner instanceof HeroObject) {
            return this.data.damage.defense + this.owner.properties.getValue('defence');
        } else {
            return this.data.damage.defense;
        }
    }

    getMoraleValue(): number {
        if (this.owner instanceof HeroObject) {
            return this.owner.properties.getValue('morale');
        } else {
            return 3;
        }
    }

    getLuckValue(): number {
        if (this.owner instanceof HeroObject) {
            return this.owner.properties.getValue('luck');
        } else {
            return 3;
        }
    }

    getHitValue(fromCreature: BattleFieldCreature, isShooting = false): number {
        const [hitFrom, hitTo] = Array.from(fromCreature.data.damage.melee);
        const baseHitValue = Utils.something(__range__(hitFrom, hitTo, true)) * fromCreature.quantity;
        const baseHitFactor = this.getBasicHitFactor(fromCreature, baseHitValue);
        const attackFactor = this.getAttackFactor(fromCreature, baseHitValue, isShooting);
        const luckFactor = this.getLuckFactor(fromCreature, baseHitValue, baseHitFactor);
        const defenseFactor = this.getDefenseFactor(fromCreature);
        const result = (baseHitValue + baseHitFactor + attackFactor + luckFactor) * (1 - defenseFactor);

        return Math.floor(result);
    }

    meleeHitFrom(fromCreature: BattleFieldCreature) {
        const value = this.getHitValue(fromCreature, false);

        this.decreaseHitPoints(value);
    }

    responseHitFrom(fromCreature: BattleFieldCreature) {
        const value = this.getHitValue(fromCreature, false) >> 1;

        this.decreaseHitPoints(value);
    }

    shootingHitFrom(fromCreature: BattleFieldCreature) {
        if (fromCreature.shotsLeft > 0) {
            const value = this.getHitValue(fromCreature, true);

            this.decreaseHitPoints(value);

            fromCreature.shotsLeft--;
        }
    }

    updateEffects() {
        this.effects = this.effects.filter(item => item[1] = item[1] - 1);
    }

    hasEffect(name: string): boolean {
        return !!this.effects.find(([effectName]) => effectName === name);
    }

    addEffect(name: string, turnsCount = 1) {
        this.effects.push([name, turnsCount]);
    }

    removeEffect(name: string) {
        const index = this.effects.findIndex(([itemName]) => itemName === name);
        this.effects.splice(index, 1);
    }

    private remove() {
        this.isAlive = false;
    }

    private decreaseHitPoints(hitPoints: number) {
        if (this.lastUnitHealth <= hitPoints) {

            hitPoints -= this.lastUnitHealth;
            const decreasedQuantity = Math.ceil(hitPoints / this.data.damage.hitPoints);
            hitPoints -= (decreasedQuantity - 1) * this.data.damage.hitPoints;
            this.quantity -= decreasedQuantity;

            if (this.quantity > 0) {
                this.lastUnitHealth = this.data.damage.hitPoints;
            }

            if (hitPoints < 0) {
                hitPoints = 0;
            }
        }

        this.lastUnitHealth -= hitPoints;

        this.addEffect('HIT');
        setTimeout(() => this.removeEffect('HIT'), 500);

        if ((this.quantity <= 0) || ((this.quantity === 1) && (this.lastUnitHealth === 0))) {
            this.quantity = 0;
            this.lastUnitHealth = 0;

            this.remove();
        }
    }

    private getBasicHitFactor(fromCreature: BattleFieldCreature, baseHitValue: number): number {
        let result;
        const attack = fromCreature.getAttackValue();
        const defense = this.getDefenseValue();
        if (attack > defense) {
            result = (attack - defense) * 0.05 * baseHitValue;
        } else {
            result = (attack - defense) * 0.025 * baseHitValue;
        }

        return Math.floor(result);
    }

    private getSpecialityFactor(owner: HeroObject, specialityName: string, specialityMultiplier: number): number {
        let result = 0;
        let secondaryLevel = 0;
        let specialityFactor = 0;
        const heroLevel = owner.properties.getValue('level');
        secondaryLevel = owner.secondary.get(specialityName) || 0;

        if (owner.getSpeciality() === specialityName) {
            specialityFactor = 1 + (0.05 * heroLevel);
        }

        if (secondaryLevel) {
            result = secondaryLevel * specialityMultiplier;
        }

        if (specialityFactor) {
            result *= specialityFactor;
        }

        return Math.floor(result);
    }

    private getAttackFactor(fromCreature: BattleFieldCreature, baseHitValue: number, isShooting: boolean): number {
        let result = 0;
        const attack = fromCreature.getAttackValue();
        const defense = this.getDefenseValue();

        if (fromCreature.owner instanceof HeroObject) {
            let specialityFactor = 0;

            if (isShooting) {
                specialityFactor = this.getSpecialityFactor(fromCreature.owner, 'ARCHERY', 0.1);
            } else {
                specialityFactor = this.getSpecialityFactor(fromCreature.owner, 'OFFENSE', 0.1);
            }

            if (attack > defense) {
                result = specialityFactor * baseHitValue;
            } else {
                let baseHitFactor = this.getBasicHitFactor(fromCreature, baseHitValue);
                result = specialityFactor * (baseHitValue + baseHitFactor);
            }
        }

        return Math.floor(result);
    }

    private getDefenseFactor(fromCreature: BattleFieldCreature): number {
        let result = 0;

        if (fromCreature.owner instanceof HeroObject) {
            result = this.getSpecialityFactor(fromCreature.owner, 'ARMORER', 0.05);
        }

        return result;
    }

    private getLuckFactor(fromCreature: BattleFieldCreature, baseHitValue: number, baseHitFactor: number): number {
        let result = 0;
        const attack = fromCreature.getAttackValue();
        const defense = this.getDefenseValue();

        if (this.hasEffect('LUCK')) {
            if (attack > defense) {
                result = baseHitValue;
            } else {
                result = baseHitValue + baseHitFactor;
            }
        }

        return Math.floor(result);
    }
}

function __range__(left, right, inclusive) {
    let range = [];
    let ascending = left < right;
    let end = !inclusive ? right : ascending ? right + 1 : right - 1;
    for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
        range.push(i);
    }
    return range;
}