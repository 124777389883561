
import {IObjectData, MapObject} from "../MapObject";
import {ObjectsCollection} from "../ObjectsCollection";
import {MapComponent} from '../MapComponent';
import {Inject} from "../../helpers/InjectDectorator";

@MapComponent('quest_guard')
export class QuestGuardObject extends MapObject {

    dynamic = true;
    gridDynamic = true;
    renderDynamic = true;

    @Inject(ObjectsCollection) protected objectsCollection: ObjectsCollection;

    constructor(data: IObjectData, id: number) {
        super(data, id);

        this.events.on('action', hero => {
            this.objectsCollection.remove(this);
        });
    }
}
