
export interface IResourceGeneratorType {
    name: string;
    tip: string;
    texture: string;
    rate: number;
    random: number[];
}

export const RESOURCES: {[key: number]: IResourceGeneratorType} = {
    [0]: {
        name: 'wood',
        tip: 'Some wood',
        texture: 'avtwood0.def',
        rate: 1,
        random: [5, 6, 7, 8, 9, 10]
    },
    [1]: {
        name: 'mercury',
        tip: 'Some mercury',
        texture: 'avtmerc0.def',
        rate: 1,
        random: [3, 4, 5, 6]
    },
    [2]: {
        name: 'stone',
        tip: 'Some stone',
        texture: 'avtore0.def',
        rate: 1,
        random: [5, 6, 7, 8, 9, 10]
    },
    [3]: {
        name: 'sulfur',
        tip: 'Some sulfur',
        texture: 'avtsulf0.def',
        rate: 1,
        random: [3, 4, 5, 6]
    },
    [4]: {
        name: 'crystals',
        tip: 'Some crystal',
        texture: 'avtcrys0.def',
        rate: 1,
        random: [3, 4, 5, 6]
    },
    [5]: {
        name: 'gems',
        tip: 'Some gems',
        texture: 'avtgems0.def',
        rate: 1,
        random: [3, 4, 5, 6]
    },
    [6]: {
        name: 'gold',
        tip: 'Some gold',
        texture: 'avtgold0.def',
        rate: 1000,
        random: [.5, .75, 1]
    }
};
  
