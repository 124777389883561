
import {UiPanel} from "../UiPanel";
import * as Utils from "../../utils/Utils";
import {UI_STATES} from "../../constants/UI_STATES";
import {Events} from "../../controllers/Events";
import {CURSORS} from "../../constants/CURSORS";
import {IUiTextOptions} from "./UiText";
import {UiComponent} from "../../services/UiComponentsService";

const DEFAULT_PARAMS = <IUiTextOptions>{
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    text: null
};

@UiComponent()
export class UiButton extends UiPanel {

    options: IUiTextOptions;

    private state: number;
    private shadowPath: Path2D;
    private lightPath: Path2D;

    constructor(params: IUiTextOptions) {
        super(Utils.extend(DEFAULT_PARAMS, params));

        this.draw = this.draw.bind(this);
        this.setEnabled = this.setEnabled.bind(this);

        this.state = UI_STATES.IDLE;

        this.events
            .on('leftClick', () => {
                return !this.isDisabled();
            })
            .on('mousemove', () => {
                if (this.isDisabled()) {
                    return false;
                }
                if (Events.isDrag()) {
                    this.setState(UI_STATES.IDLE);
                }
            })
            .on('leftMousedown', () => {
                if (this.isDisabled()) {
                    return false;
                }
                this.setState(UI_STATES.ACTIVE);
                Events.one('leftMouseup', () => {
                    this.setState(UI_STATES.IDLE);
                });
            })
            .on('rightMousedown', () => {
                return !this.isDisabled();
            })
            .on('parent.updateSizes', () => {
                this.__initPaths();
            });

        this.__initPaths();
    }

    __initPaths() {
        const borderWidth = 3;
        this.shadowPath = new Path2D(`\
            M${this.width + this.absoluteLeft} ${this.absoluteTop}
            v${this.height} 
            h-${this.width} 
            l${borderWidth} -${borderWidth} 
            h${this.width - (borderWidth<<1)} 
            v-${this.height - (borderWidth<<1)} Z\
        `);

        this.lightPath = new Path2D(`\
            M${this.absoluteLeft} ${this.height + this.absoluteTop}
            v-${this.height} 
            h${this.width} 
            l-${borderWidth} ${borderWidth} 
            h-${this.width - (borderWidth<<1)} 
            v${this.height - (borderWidth<<1)} Z\
        `);
    }

    private drawBorders() {
        this.ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
        this.ctx.fill(this.shadowPath);

        this.ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';
        this.ctx.fill(this.lightPath);

        this.ctx.lineWidth = 1;
        if (this.state === UI_STATES.ACTIVE) {
            this.ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
        } else {
            this.ctx.strokeStyle = '#B1AA64';
        }
        this.ctx.strokeRect(this.absoluteLeft + 0.5, this.absoluteTop + 0.5, this.width, this.height);
    }

    private getTextColor() {
        if (this.disabled) {
            return '#999999';
        } else {
            return '#FFFFFF';
        }
    }

    getCursor(): string {
        return CURSORS.POINTER;
    }

    draw() {
        let centerLeft = this.width >> 1;
        let centerTop = this.height >> 1;

        if (this.state === UI_STATES.ACTIVE) {
            centerTop += 1;
        }

        UiPanel.prototype.draw.call(this);
        this.drawBorders();

        this.text({
            text: this.options.text,
            left: centerLeft,
            top: centerTop,
            color: this.getTextColor(),
            align: 'center'
        });
    }

    setState(state) {
        this.state = state;
    }

    getState(): number {
        return this.state;
    }

    setEnabled(value) {
        if (!!value) {
            return this.setState(UI_STATES.IDLE);
        } else {
            return this.setState(UI_STATES.DISABLED);
        }
    }

    isDisabled(): boolean {
        return this.state === UI_STATES.DISABLED;
    }
};