
import {Display} from "../../render/layers/Display";
import {UiWindow} from "../common/UiWindow";
import {ShipyardObject} from "../../models/objects/ShipyardObject";
import {PlayerModel} from "../../models/player/PlayerModel";

const DEFAULT_PARAMS = {
    width: Math.min(400, Display.offsetWidth - 50),
    height: 150
};

export class UiShipyardWindow extends UiWindow {

    private shipyard: ShipyardObject;
    private player: PlayerModel;

    constructor(shipyard, player) {
        super(DEFAULT_PARAMS);
        this.shipyard = shipyard;
        this.player = player;

        this.addTextBlock('Are you sure want to buy a boat?');
        this.addButtonsFooter([
            {
                title: 'Close',
                action: () => true
            },
            {
                title: 'Buy (1500 gold)',
                action: () => this.buyBoat()
            }
        ]);
    }

    private buyBoat() {
        this.shipyard.buy();
        this.removeWnd();
    }
};
