
import {UiWindow} from "../common/UiWindow";
import * as Utils from "../../utils/Utils";
import {TextService} from "../../services/TextService";
import {SPECIALITIES} from "../../constants/SPECIALITIES";
import {ObjectsCollection} from "../../models/ObjectsCollection";
import {Display} from "../../render/layers/Display";
import {Inject} from "../../helpers/InjectDectorator";
import {ActivePlayer} from "../../controllers/ActivePlayer";
import {PlayerModel} from "../../models/player/PlayerModel";

const DEFAULT_PARAMS = {
    width: Math.min(672, Display.offsetWidth),
    height: Math.min(586, Display.offsetHeight),
    borders: false,
    background: 'hero_wnd',
    modal: true
};

const SECONDARY_LEVELS = {
    [1]: 'Basic',
    [2]: 'Advanced',
    [3]: 'Expert'
};

const SECONDARY_POSITIONS = [
    [0, 257],
    [143, 257],
    [0, 305],
    [143, 305],
    [0, 353],
    [143, 353],
    [0, 401],
    [143, 401]
];

export class UiHeroWindow extends UiWindow {

    @Inject(ActivePlayer) protected activePlayer: PlayerModel;
    @Inject(TextService) protected textService: TextService;
    @Inject(ObjectsCollection) protected objectsCollection: ObjectsCollection;

    constructor(hero, options = {}) {
        super(Utils.extend(DEFAULT_PARAMS, options));

        let heroLevel = hero.properties.getValue('level');
        let heroClass = hero.data.config.class;
        let heroKnowledge = hero.properties.getValue('knowledge');

        let getSecondaries = () => {
            let result = [];
            let index = 0;
            hero.secondary.forEach((level, skill) => {
                let [left, top] = Array.from(SECONDARY_POSITIONS[index++]);

                result.push({
                    spriteIndex: hero.secondary.getSpriteIndex(skill, level),
                    level: SECONDARY_LEVELS[level],
                    title: this.textService.i18n(`SKILLS.SECONDARY.${skill}.SHORT`),
                    left,
                    top
                });
            });
            return result;
        };

        this.scope = {
            hero: {
                name: hero.name || this.textService.getHeroName(hero.iconSpriteIndex),
                secondLine: `${heroLevel} Level ${heroClass}`,
                iconSpriteIndex: hero.iconSpriteIndex,

                attack: hero.properties.getValue('attack'),
                deffence: hero.properties.getValue('defence'),
                power: hero.properties.getValue('power'),
                knowledge: hero.properties.getValue('knowledge'),
                manapoints: hero.properties.getValue('manaPoints') + '/' + (heroKnowledge * 10),
                experience: hero.properties.getValue('experience'),
                speciality: {
                    spriteIndex: SPECIALITIES.indexOf(hero.data.config.spec) || 0,
                    title: this.textService.i18n(`SKILLS.SPECIALITY.${hero.data.config.spec}.SHORT`)
                },
                artifacts: hero.artifacts,
                creatures: hero.creatures
            },

            secondaries: getSecondaries(),

            getHeroes: () => {
                return this.activePlayer.heroes.filter(({hidden}) => !hidden);
            },

            setHero: hero => {
                if (hero.is(this)) { return false; }
                this.resolve();
                this.removeWnd();
                hero.owner.selectObject(hero.id);
                hero.showModal();
            },

            getOwnerColorIndex() {
                if (hero.owner) {
                    return hero.owner.colorIndex;
                } else {
                    return 8;
                }
            },

            dismiss: () => {
                this.objectsCollection.remove(hero);
                this.resolve();
                this.removeWnd();
            },

            close: () => {
                this.resolve();
                this.removeWnd();
            }
        };

        this.setMarkup('game/modals/heroWindow.xml');
    }
}
 