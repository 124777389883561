
import {BattleService, BattleServiceSingleton} from "./BattleService";
import {BattleFieldCreature} from "../models/objects/BattleFieldCreature";

export class BattleAIService {

    makeTurn(service: BattleServiceSingleton) {
        if (service.activeUnit.canShoot) {
            this.findEnemyAndShoot(service);
            service.onEndTurn();

            return;
        }

        if (service.activeUnit.canGo) {
            const passableEnemies = this.getPassableEnemies(service);

            if (passableEnemies.length) {
                this.goToEnemyAndHit(service, passableEnemies);
            } else {
                this.goToEnemy(service, this.getAliveEnemies(service));
            }

            service.onEndTurn();

            return;
        }

        service.skipTurn();
    }

    private getSmallestHealthUnit(enemiesArray: BattleFieldCreature[]): BattleFieldCreature {
        const [unit] = enemiesArray.sort((a, b) => {
            if (a.lastUnitHealth > b.lastUnitHealth) {
                return 1;
            } else {
                return -1;
            }
        });

        return unit;
    }

    private getPassableEnemies(service: BattleServiceSingleton): BattleFieldCreature[] {
        const availableEnemies = service.fieldData.filter((unit, x, y) => {
            return service.passableCells.has(x, y) && !service.isEmptyField(unit) && service.isEnemy(unit);
        });

        return <BattleFieldCreature[]>availableEnemies.getRawData().filter(item => item);
    }

    private getAliveEnemies(service: BattleServiceSingleton): BattleFieldCreature[] {
        const availableEnemies = service.fieldData.filter((unit) => {
            return !service.isEmptyField(unit) && service.isEnemy(unit);
        });

        return <BattleFieldCreature[]>availableEnemies.getRawData().filter(item => item);
    }

    private goToEnemyAndHit(service: BattleServiceSingleton, enemiesArray: BattleFieldCreature[]) {
        const unit = this.getSmallestHealthUnit(enemiesArray);

        const {x, y} = service.activeUnit;
        const [, nearest] = <{x: number, y: number}[]><any>service.findPath(x, y, unit.x, unit.y).reverse();

        if (nearest) {
            service.moveTo(service.activeUnit, nearest.x, nearest.y);
        }

        service.meleeAttack(service.activeUnit, unit);
    }

    private goToEnemy(service: BattleServiceSingleton, enemiesArray: BattleFieldCreature[]) {
        const unit = this.getSmallestHealthUnit(enemiesArray);

        const {x, y} = service.activeUnit;
        const path = <{x: number, y: number}[]><any>service.findPath(x, y, unit.x, unit.y);
        const [nearest] = path.filter(({x, y}) => service.passableCells.has(x, y)).reverse();

        if (nearest) {
            service.moveTo(service.activeUnit, nearest.x, nearest.y);
        }
    }

    private findEnemyAndShoot(service: BattleServiceSingleton) {
        const enemies = this.getAliveEnemies(service);
        const unit = this.getSmallestHealthUnit(enemies);

        service.shootingAttack(service.activeUnit, unit);
    }
}