
import {RESOURCES} from "../../constants/RESOURCES";
import {PropertiesStorage} from "./PropertiesStorage";
import {Property} from "../../helpers/Property";
import * as Utils from "../../utils/Utils";

interface IResourcesMap {
    wood?: number;
    mercury?: number;
    stone?: number;
    sulfur?: number;
    crystals?: number;
    gems?: number;
    gold?: number;
}

export class ResourcesStorage extends PropertiesStorage {
    constructor(params: Object = {}) {
        super();

        Utils.forEach(RESOURCES, resource => {
            this.set(resource.name, new Property(params[resource.name] || 0));
        });
    }

    private getResourceCountInSummary(ind: number, summaryGoldValue: number, count: number): number {
        const settings = RESOURCES[ind];
        const amount = summaryGoldValue / 1000 / count;

        for (let randomValue of settings.random) {
            if (randomValue > amount) {
                return randomValue * settings.rate;
            }
        }
        return 0;
    }

    private getRandomResources(goldValue: number, count: number): IResourcesMap {
        const resourceIndexes = Utils.some(Object.keys(RESOURCES), count - 1);
        resourceIndexes.unshift(6);
        const result: IResourcesMap = {};

        for (let resourceInd of Array.from(resourceIndexes)) {
            result[RESOURCES[resourceInd].name] = this.getResourceCountInSummary(resourceInd, goldValue, count);
        }
        return result;
    }

    isEmpty(): boolean {
        let result = true;

        this.all().forEach(property => {
            if (property.get()) {
                result = false;
            }
        });
        return result;
    }

    hasResources(resources: IResourcesMap = null): boolean {
        if (!resources) {
            return true;
        }
        for (let key in resources) {
            let amount = resources[key];
            let value = parseInt(amount) || 0;
            if (this.getValue(key) < value) {
                return false;
            }
        }
        return true;
    }

    pay(resources: IResourcesMap = null): boolean {
        if (this.hasResources(resources)) {
            for (let key in resources) {
                const amount = resources[key];
                this.get(key).push(-amount);
            }
            return true;
        }
        return false;
    }

    transfer(storage: ResourcesStorage, resources: IResourcesMap = null): boolean {
        if (!resources) {
            resources = {};
            this.all().forEach((value, key) => resources[key] = value);
        }
        if (!this.hasResources(resources)) {
            return false;
        }
        for (let key in resources) {
            const property = resources[key];
            if (property) {
                const value = property instanceof Property
                    ? property.get()
                    : parseInt(property) || 0;
                const fromProperty = this.get(key);
                if (fromProperty) {
                    fromProperty.push(-value);
                }
                const toProperty = storage.get(key);
                if (toProperty) {
                    toProperty.push(value);
                }
            }
        }
        return true;
    }

    randomize(goldValue: number) {
        Utils.randomize({
            [27]: () => this.set({gold: goldValue}),
            [26]: () => this.set(this.getRandomResources(goldValue, 2))
        });
    }

    asTextString(): string {
        const resourcesStrings = [];

        this.all().forEach((property, resourceName) => {
            const value = property instanceof Property
                ? property.get()
                : parseInt(property) || 0;

            if (value) {
                resourcesStrings.push(`${value} ${resourceName}`);
            }
        });

        return resourcesStrings.join(', ');
    }

    asObject(): {[key: string]: number} {
        const result = {};

        this.all().forEach((property, resourceName) => {
            const value = property instanceof Property
                ? property.get()
                : parseInt(property);

            if (value) {
                result[resourceName] = value;
            }
        });

        return result;
    }
}