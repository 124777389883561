
import {ANIMATIONS} from "./ANIMATIONS";

const OBJ_DIR = 'img/objects/sprites';
const TERRAIN_DIR = 'img/terrain';
const UI_DIR = 'img/ui';
const EFFECTS_DIR = 'img/effects';

export interface ISpriteAnimationOptions {
    [key: string]: number[];
}

export interface ISpriteOptions {
    path: string;
    width: number;
    height: number;
    animations?: ISpriteAnimationOptions;
    animationSpeed?: number;
    singleUse?: boolean;
}

const DEFAULT_HERO = <ISpriteOptions>{
    path: `${OBJ_DIR}/ah00_.png`,
    width: 96,
    height: 64,
    animations: ANIMATIONS.HERO,
    animationSpeed: 1,
    singleUse: true
};

export const SPRITES = {
    "ah00_e.def": DEFAULT_HERO,

    "ah12_e.def": DEFAULT_HERO,

    "ah13_e.def": DEFAULT_HERO,

    "ah14_e.def": DEFAULT_HERO,

    "ah15_e.def": DEFAULT_HERO,

    "ah03_e.def": DEFAULT_HERO,

    "ahrandom.def": DEFAULT_HERO,


    "AVXboat0.def": {
        path: `${OBJ_DIR}/ship02.png`,
        width: 96,
        height: 64,
        animations: ANIMATIONS.HERO,
        animationSpeed: 1,
        singleUse: true
    },

    "AVXboat1.def": {
        path: `${OBJ_DIR}/ship02.png`,
        width: 96,
        height: 64,
        animations: ANIMATIONS.HERO,
        animationSpeed: 1,
        singleUse: true
    },

    "AVXboat2.def": {
        path: `${OBJ_DIR}/ship02.png`,
        width: 96,
        height: 64,
        animations: ANIMATIONS.HERO,
        animationSpeed: 1,
        singleUse: true
    },

    "AVMwndd0.def": {
        path: `${OBJ_DIR}/AVMwndd0.png`,
        width: 160,
        height: 96,
        animations: ANIMATIONS.DEFAULT_MAP_OBJECT,
        animationSpeed: .3
    },

    "AVTchst0.def": {
        path: `${OBJ_DIR}/AVTchst0.png`,
        width: 64,
        height: 32,
        animations: ANIMATIONS.DEFAULT_MAP_OBJECT,
        animationSpeed: .3
    },

    "adcfra.def": {
        path: `${OBJ_DIR}/adcfra.png`,
        width: 32,
        height: 32,
        animations: ANIMATIONS.DEFAULT_MAP_OBJECT,
        animationSpeed: .3
    },

    "creatures_sm": {
        path: "img/objects/creatures/sm.png",
        width: 64,
        height: 64
    },

    "corner_gems": {
        path: `${UI_DIR}/sprites/cornerGems.png`,
        width: 46,
        height: 46
    },

    "panel_bg": {
        path: `${UI_DIR}/panelBg.jpg`,
        width: 128,
        height: 128
    },

    "panel_colored_bg": {
        path: `${UI_DIR}/panelColoredBg.jpg`,
        width: 256,
        height: 256
    },

    "dialog_borders": {
        path: `${UI_DIR}/dialogBorders.png`,
        width: 64,
        height: 64
    },

    "path_arrow": {
        path: `${UI_DIR}/sprites/pathArrow.png`,
        width: 32,
        height: 32
    },

    "heroes_portraits_lg": {
        path: `${UI_DIR}/portraits/heroes/lg.png`,
        width: 58,
        height: 64
    },

    "heroes_portraits_sm": {
        path: `${UI_DIR}/portraits/heroes/sm.png`,
        width: 48,
        height: 32
    },

    "resources_portraits_lg": {
        path: `${UI_DIR}/portraits/resources/lg.png`,
        width: 82,
        height: 93
    },

    "resources_portraits_sm": {
        path: `${UI_DIR}/portraits/resources/sm.png`,
        width: 20,
        height: 18
    },

    "artifacts_portraits_md": {
        path: `${UI_DIR}/portraits/artifacts/md.png`,
        width: 58,
        height: 64
    },

    "artifacts_portraits_sm": {
        path: `${UI_DIR}/portraits/artifacts/sm.png`,
        width: 44,
        height: 44
    },

    "towns_portraits_sm": {
        path: `${UI_DIR}/portraits/castles/sm.jpg`,
        width: 48,
        height: 32
    },

    "towns_portraits_lg": {
        path: `${UI_DIR}/portraits/castles/lg.png`,
        width: 58,
        height: 64
    },

    "creatures_portraits_sm": {
        path: `${UI_DIR}/portraits/creatures/sm.png`,
        width: 32,
        height: 32
    },

    "creatures_portraits_md": {
        path: `${UI_DIR}/portraits/creatures/md.jpg`,
        width: 58,
        height: 64
    },

    "primary_portraits_md": {
        path: `${UI_DIR}/portraits/primary/md.png`,
        width: 42,
        height: 42
    },

    "secondary_portraits_sm": {
        path: `${UI_DIR}/portraits/skills/sm.png`,
        width: 44,
        height: 44
    },

    "mana_points": {
        path: `${UI_DIR}/rightPanel/manaPoints.jpg`,
        width: 6,
        height: 30
    },

    "move_points": {
        path: `${UI_DIR}/rightPanel/movePoints.jpg`,
        width: 6,
        height: 30
    },

    "res_bar_bg": {
        path: `${UI_DIR}/aresbar_m.jpg`,
        width: 256,
        height: 22
    },

    "city_btn": {
        path: `${UI_DIR}/rightPanel/cityBtn.jpg`,
        width: 32,
        height: 32
    },

    "bottom_level_btn": {
        path: `${UI_DIR}/rightPanel/bottomLevelBtn.jpg`,
        width: 32,
        height: 32
    },

    "day_end_btn": {
        path: `${UI_DIR}/rightPanel/dayEndBtn.jpg`,
        width: 64,
        height: 32
    },

    "function_btn": {
        path: `${UI_DIR}/rightPanel/functionBtn.jpg`,
        width: 32,
        height: 32
    },

    "go_btn": {
        path: `${UI_DIR}/rightPanel/goBtn.jpg`,
        width: 32,
        height: 32
    },

    "hero_btn": {
        path: `${UI_DIR}/rightPanel/heroBtn.jpg`,
        width: 64,
        height: 32
    },

    "magic_btn": {
        path: `${UI_DIR}/rightPanel/magicBtn.jpg`,
        width: 32,
        height: 32
    },

    "schedule_btn": {
        path: `${UI_DIR}/rightPanel/scheduleBtn.jpg`,
        width: 32,
        height: 32
    },

    "sleep_btn": {
        path: `${UI_DIR}/rightPanel/sleepBtn.jpg`,
        width: 32,
        height: 32
    },

    "system_btn": {
        path: `${UI_DIR}/rightPanel/systemBtn.jpg`,
        width: 32,
        height: 32
    },

    "top_level_btn": {
        path: `${UI_DIR}/rightPanel/topLevelBtn.jpg`,
        width: 32,
        height: 32
    },

    "wake_btn": {
        path: `${UI_DIR}/rightPanel/wakeBtn.jpg`,
        width: 32,
        height: 32
    },


    "town_modal_bg": {
        path: `${UI_DIR}/modal/town/bg.jpg`,
        width: 800,
        height: 374
    },

    "town_modal_footer": {
        path: `${UI_DIR}/modal/town/footer.png`,
        width: 800,
        height: 226
    },

    "town_type_icon": {
        path: `${UI_DIR}/modal/town/building-icon.png`,
        width: 38,
        height: 38
    },

    "town_modal": {
        path: `${UI_DIR}/modal/town/modal.png`,
        width: 194,
        height: 186
    },

    "hero_modal": {
        path: `${UI_DIR}/modal/hero/modal.png`,
        width: 194,
        height: 186
    },

    "hero_wnd": {
        path: `${UI_DIR}/modal/hero/bg.png`,
        width: 672,
        height: 586
    },

    "player_flag": {
        path: `${UI_DIR}/playerFlag.png`,
        width: 58,
        height: 64
    },

    "tavern_modal_bg": {
        path: `${UI_DIR}/modal/tavern/modal.png`,
        width: 395,
        height: 504
    },

    "spellbook_modal_bg": {
        path: `${UI_DIR}/modal/spellbook/modal.png`,
        width: 620,
        height: 595
    },

    "creature_popup_bg": {
        path: `${UI_DIR}/modal/creature/popup.png`,
        width: 298,
        height: 311
    },


    "AVA0129.def": {
        path: `img/objects/ava0129.png`,
        width: 64,
        height: 32,
        animations: ANIMATIONS.DEFAULT_MAP_OBJECT,
        animationSpeed: .3
    },

    "AVA0130.def": {
        path: `img/objects/ava0130.png`,
        width: 64,
        height: 32,
        animations: ANIMATIONS.DEFAULT_MAP_OBJECT,
        animationSpeed: .3
    },

    "AVA0131.def": {
        path: `img/objects/ava0131.png`,
        width: 64,
        height: 32,
        animations: ANIMATIONS.DEFAULT_MAP_OBJECT,
        animationSpeed: .3
    },

    "AVA0132.def": {
        path: `img/objects/ava0132.png`,
        width: 64,
        height: 32,
        animations: ANIMATIONS.DEFAULT_MAP_OBJECT,
        animationSpeed: .3
    },

    "AVA0133.def": {
        path: `img/objects/ava0133.png`,
        width: 64,
        height: 32,
        animations: ANIMATIONS.DEFAULT_MAP_OBJECT,
        animationSpeed: .3
    },

    "AVA0134.def": {
        path: `img/objects/ava0134.png`,
        width: 64,
        height: 32,
        animations: ANIMATIONS.DEFAULT_MAP_OBJECT,
        animationSpeed: .3
    },

    "AVA0135.def": {
        path: `img/objects/ava0135.png`,
        width: 64,
        height: 32,
        animations: ANIMATIONS.DEFAULT_MAP_OBJECT,
        animationSpeed: .3
    },

    "AVA0136.def": {
        path: `img/objects/ava0136.png`,
        width: 64,
        height: 32,
        animations: ANIMATIONS.DEFAULT_MAP_OBJECT,
        animationSpeed: .3
    },

    "AVA0137.def": {
        path: `img/objects/ava0137.png`,
        width: 64,
        height: 32,
        animations: ANIMATIONS.DEFAULT_MAP_OBJECT,
        animationSpeed: .3
    },

    "AVA0138.def": {
        path: `img/objects/ava0138.png`,
        width: 64,
        height: 32,
        animations: ANIMATIONS.DEFAULT_MAP_OBJECT,
        animationSpeed: .3
    },

    "AVA0139.def": {
        path: `img/objects/ava0139.png`,
        width: 64,
        height: 32,
        animations: ANIMATIONS.DEFAULT_MAP_OBJECT,
        animationSpeed: .3
    },

    "AVA0140.def": {
        path: `img/objects/ava0140.png`,
        width: 64,
        height: 32,
        animations: ANIMATIONS.DEFAULT_MAP_OBJECT,
        animationSpeed: .3
    },

    "AVA0141.def": {
        path: `img/objects/ava0141.png`,
        width: 64,
        height: 32,
        animations: ANIMATIONS.DEFAULT_MAP_OBJECT,
        animationSpeed: .3
    },

    "main_menu_bg": {
        path: `${UI_DIR}/mainmenubg.jpg`,
        width: 1920,
        height: 1080
    },

    "version_icons": {
        path: `${UI_DIR}/portraits/versions.png`,
        width: 32,
        height: 24
    },

    "victory_conditions": {
        path: `${UI_DIR}/portraits/victoryConditions.png`,
        width: 32,
        height: 24
    },

    "lose_conditions": {
        path: `${UI_DIR}/portraits/loseConditions.png`,
        width: 32,
        height: 24
    },

    "fog": {
        path: `${TERRAIN_DIR}/fog.png`,
        width: 32,
        height: 32
    },

    "specialities_sm": {
        path: `${UI_DIR}/portraits/speciality/sm.jpg`,
        width: 32,
        height: 32
    },

    "specialities_md": {
        path: `${UI_DIR}/portraits/speciality/md.jpg`,
        width: 44,
        height: 44
    },

    "battlefield_0_0": {
        path: `${UI_DIR}/battleFields/0_0.jpg`,
        width: 800,
        height: 556
    },

    "battlefield_0_1": {
        path: `${UI_DIR}/battleFields/0_1.jpg`,
        width: 800,
        height: 556
    },

    "battlefield_0_2": {
        path: `${UI_DIR}/battleFields/0_2.jpg`,
        width: 800,
        height: 556
    },

    "battlefield_1_0": {
        path: `${UI_DIR}/battleFields/1_0.jpg`,
        width: 800,
        height: 556
    },

    "battlefield_1_1": {
        path: `${UI_DIR}/battleFields/1_1.jpg`,
        width: 800,
        height: 556
    },

    "battlefield_2_0": {
        path: `${UI_DIR}/battleFields/2_0.jpg`,
        width: 800,
        height: 556
    },

    "battlefield_2_1": {
        path: `${UI_DIR}/battleFields/2_1.jpg`,
        width: 800,
        height: 556
    },

    "battlefield_2_2": {
        path: `${UI_DIR}/battleFields/2_2.jpg`,
        width: 800,
        height: 556
    },

    "battlefield_3_0": {
        path: `${UI_DIR}/battleFields/3_0.jpg`,
        width: 800,
        height: 556
    },

    "battlefield_3_1": {
        path: `${UI_DIR}/battleFields/3_1.jpg`,
        width: 800,
        height: 556
    },

    "battlefield_4_0": {
        path: `${UI_DIR}/battleFields/4_0.jpg`,
        width: 800,
        height: 556
    },

    "battlefield_5_0": {
        path: `${UI_DIR}/battleFields/5_0.jpg`,
        width: 800,
        height: 556
    },

    "battlefield_5_1": {
        path: `${UI_DIR}/battleFields/5_1.jpg`,
        width: 800,
        height: 556
    },

    "battlefield_5_2": {
        path: `${UI_DIR}/battleFields/5_2.jpg`,
        width: 800,
        height: 556
    },

    "battlefield_6_0": {
        path: `${UI_DIR}/battleFields/6_0.jpg`,
        width: 800,
        height: 556
    },

    "battlefield_7_0": {
        path: `${UI_DIR}/battleFields/7_0.jpg`,
        width: 800,
        height: 556
    },

    "battlefield_8_0": {
        path: `${UI_DIR}/battleFields/8_0.jpg`,
        width: 800,
        height: 556
    },

    "battlefield_8_1": {
        path: `${UI_DIR}/battleFields/8_1.jpg`,
        width: 800,
        height: 556
    },

    "battlefield_8_2": {
        path: `${UI_DIR}/battleFields/8_2.jpg`,
        width: 800,
        height: 556
    },

    "morale_up_effect": {
        path: `${EFFECTS_DIR}/morale_up.png`,
        width: 94,
        height: 127,
        animations: {
            IDLE: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]
        }
    },

    "morale_down_effect": {
        path: `${EFFECTS_DIR}/morale_down.png`,
        width: 90,
        height: 125,
        animations: {
            IDLE: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]
        }
    },

    "luck_effect": {
        path: `${EFFECTS_DIR}/luck.png`,
        width: 50,
        height: 97,
        animations: {
            IDLE: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]
        }
    },

    "new_day": {
        path: `${UI_DIR}/sprites/new_day.png`,
        width: 175,
        height: 166,
        animationSpeed: .5,
        animations: {
            IDLE: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
        }
    }
};
