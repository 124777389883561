
import {OwnershipObject} from "./OwnershipObject";
import {MapComponent} from '../MapComponent';
import {ICreatureData, MonstersData} from "../../controllers/MonstersData";
import {Inject} from "../../helpers/InjectDectorator";
import {ITextures, Textures} from "../../controllers/Textures";
import {StateService} from "../../services/StateService";
import {CreaturesStorage} from "../storages/CreaturesStorage";
import {HeroObject} from "./hero/HeroObject";
import {ObjectAttackableMixin} from "./ObjectAttackableMixin";
import {Mixin} from "../../utils/Utils";
import {BattleSide} from "../../services/BattleService";
import {UiController} from '../../controllers/UiController';
import {UiCreatureWindow} from '../../views/windows/UiCreatureWindow';

@MapComponent('dwelling')
@Mixin(ObjectAttackableMixin)
export class DwellingObject extends OwnershipObject implements ObjectAttackableMixin {

    creatures = new CreaturesStorage();
    canGoInActionPos = false;

    private creature: ICreatureData;

    @Inject(MonstersData) protected monstersData: ICreatureData[];
    @Inject(StateService) protected stateService: StateService;
    @Inject(Textures) protected textures: ITextures;

    constructor(data, id) {
        super(data, id);

        this.creature = this.monstersData.find(data => data.dwelling === this.objectSubClass);

        if (this.creature) {
            this.iconSprite = this.textures.get('creatures_portraits_md');
            this.iconSpriteIndex = this.creature.spriteId;
        }

        this.events.on('action', hero => {
            this.setOwner(hero.owner);

            if (this.creature) {
                switch (this.creature.level) {
                    case 1:
                        this.tryTransferForFree(hero);
                        break;
                    case 2:
                    case 3:
                    case 4:
                        this.openWindow();
                        break;
                    case 5:
                    case 6:
                    case 7:
                        this.tryToAttackOrOpenWindow();
                        break;
                }
            }
        });

        this.refillCreatures();

        this.stateService.subscribe('time.week', () => {
            this.refillCreatures();
        });

        this.events.on('leftClick', hero => {
            console.log('dwelling', this.objectClass, this.objectSubClass, this.creature);
        });
    }

    onLoseBattle() {}
    startBattleWith(opposite: BattleSide) {}
    transferWinnerProfitIfItPossible(fromSide: BattleSide, winner: BattleSide) {}

    private tryTransferForFree(hero: HeroObject) {
        hero.creatures.transfer(this.creatures);
    }

    private openWindow() {
        console.log('openWindow TBD');
        UiController.modal(UiCreatureWindow, {
            creatures: this.creatures
        })
    }

    private tryToAttackOrOpenWindow() {
        console.log('tryToAttackOrOpenWindow TBD');
        UiController.modal(UiCreatureWindow, {
            creatures: this.creatures
        })
    }

    private refillCreatures() {
        if (this.creature) {
            this.creatures.set(0, this.creature.id, this.creature.growth);
        }
    }
}
