
import {GenericVisitableObject} from "./GenericVisitableObject";

export class MagicWellObject extends GenericVisitableObject {

    recordVisits = true;
    clearVisitsEveryDay = true;

    constructor(data, id) {
        super(data, id);

        this.events.on('action', (hero) => {
            const amount = Math.max(hero.properties.getValue('knowledge') * 10, hero.properties.getValue('manaPoints'));
            hero.properties.get('manaPoints').set(amount);
        });
    }
}
    