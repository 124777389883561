
import {Display} from "../../render/layers/Display";
import {UiWindow} from "../common/UiWindow";

export class UiAlertWindow extends UiWindow {

    constructor(text) {
        super({
            width: Math.min(400, Display.offsetWidth - 50),
            height: 150
        });

        this.addTextBlock(text);
        this.addButtonsFooter([
            {
                title: 'Ok',
                action() {
                    return true;
                }
            }
        ]);
    }
}
