
import {IObjectData, MapObject} from "../../MapObject";
import {Events} from "../../../controllers/Events";
import {UiController} from "../../../controllers/UiController";
import {HeroObject} from "../hero/HeroObject";
import {StateService} from "../../../services/StateService";
import {Inject} from "../../../helpers/InjectDectorator";

export class GenericVisitableObject extends MapObject {

    recordVisits = false;
    clearVisitsEveryDay = false;
    clearVisitsEveryWeek = false;
    restrictedPoints = true;

    visits: number[] = [];

    @Inject(StateService) protected stateService: StateService;

    constructor(data: IObjectData, id: number) {
        super(data, id);

        this.isVisitedBy = this.isVisitedBy.bind(this);

        this.stateService.subscribe('time.week', () => {
            if (this.clearVisitsEveryWeek) {
                this.visits = [];

                this.stateService.remove(`objects.${this.id}.data.visits`);
            }
        });

        Events.on('game.newTurn', () => {
            if (this.clearVisitsEveryDay) {
                this.visits = [];

                this.stateService.remove(`objects.${this.id}.data.visits`);
            }
        });

        this.events.on('action', hero => {
            if (this.recordVisits) {
                if (this.isVisitedBy(hero)) {
                    if (this.clearVisitsEveryWeek) {
                        UiController.alert('Your hero already visit this place at this week');
                    } else if (this.clearVisitsEveryDay) {
                        UiController.alert('Your hero already visit this place at this day');
                    } else {
                        UiController.alert('Your hero already visit this place in this game');
                    }
                    return false;
                } else {
                    if (hero && hero.id) {
                        this.visits.push(hero.id);

                        this.stateService.set(`objects.${this.id}.data.visits`, this.visits);
                    }
                    return true;
                }
            }
            return true;
        });
    }

    isVisitedBy(hero: HeroObject): boolean {
        return this.visits.includes(hero.id);
    }
}
