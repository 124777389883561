
import {Display} from "../../render/layers/Display";
import {UiWindow} from "../common/UiWindow";
import {TextService} from "../../services/TextService";
import {IMapDataPlayer, SPRITE_INDEXES} from "../../models/map/MapDataReader";
import * as Utils from "../../utils/Utils";
import {HeroesCollection} from "../../models/HeroesCollection";
import {UiScrollView} from "../common/UiScrollView";
import {StateService} from "../../services/StateService";
import {IAbstractView} from '../IAbstractView';
import {ActiveView} from '../../controllers/ActiveView';
import {Inject} from '../../helpers/InjectDectorator';

const DEFAULT_PARAMS = {
    width: Math.min(290, Display.offsetWidth),
    height: 325,
    modal: true
};

export class UiTownHeroWindow extends UiWindow {

    scope: any;

    @Inject(ActiveView) protected activeView: IAbstractView;
    @Inject(TextService) protected textService: TextService;
    @Inject(StateService) protected stateService: StateService;

    constructor(player: IMapDataPlayer) {
        super(DEFAULT_PARAMS);

        this.scope = {
            player,
            playerTitle: this.textService.i18n(`UI.PLAYERS.${player.color.toUpperCase()}`),

            towns: [],
            heroes: [],
            benefits: [],

            close: () => {
                this.removeWnd();
                this.resolve(player);
            }
        };

        this.scope.towns = this.getTowns(player);
        this.scope.selectedTown = this.scope.towns[0];
        this.scope.heroes = this.getHeroes(player, this.scope.selectedTown);

        this.setMarkup('game/modals/townHeroWindow.xml').then(() => {
            this.bindTownsSelect();
            this.bindHeroesSelect();
        });
    }

    private updateSelectMark(items, selectedIndex) {
        items.forEach((item, index) => item.selected = index === selectedIndex);
    }

    private updateLabel(element, key) {
        const label = element.query('label');
        label.options.text = this.textService.i18n(key) || 'Random';
    }

    bindTownsSelect() {
        let element = this.query('towns-select');
        let selectedIndex = this.scope.towns.indexOf(this.scope.player.selectedRace);
        this.attachScrollerSelect(element, 'towns', selectedIndex, selectedIndex => {
            this.updateSelectMark(element.queryAll('item'), selectedIndex);
            let selected = this.scope.towns[selectedIndex];
            this.updateLabel(element, `MAP.TOWNS.${selected}.TITLE`);
            this.scope.heroes = this.getHeroes(this.scope.player, selected);

            this.setRace(selected);

            this.events.dispatch('updateHeroesSelect');
            const scrollView = <UiScrollView>this.query('heroes-select').query('scroll');
            scrollView.scrollTo(0, 0);
        });
    }

    bindHeroesSelect() {
        let element = this.query('heroes-select');
        let selectedIndex = this.scope.heroes.indexOf(this.scope.player.selectedHero);
        this.attachScrollerSelect(element, 'heroes', selectedIndex, selectedIndex => {
            this.updateSelectMark(element.queryAll('item'), selectedIndex);
            let selected = this.scope.heroes[selectedIndex];

            this.setPerson(selected);
            this.updateLabel(element, `HEROES.${selected}.NAME`);
        });
    }

    attachScrollerSelect(element, collection, selectedIndex, selectItem) {
        const scroll = element.query('scroll');
        selectItem(selectedIndex);
        scroll.scrollTo(0, -selectedIndex * 32);
        scroll.events.on('scroll', ({offsetTop}) => {
            let scrolledIndex = -Math.floor((offsetTop + 16) / 32);
            let itemIndex = Math.min(Math.max(scrolledIndex, 0), this.scope[collection].length - 1);
            scroll.offsetTop = -itemIndex * 32;
            selectItem(itemIndex);
        });
    }

    getRace(townSpriteIndex: number): number {
        return ((townSpriteIndex - 2) >> 2) - 1;
    }

    getTownSpriteIndex(race: number): number {
        return ((race + 1) * 4) + 2;
    }

    private getTowns(player) {
        let result = [];
        switch (player.mainTownSpriteIndex) {

            case SPRITE_INDEXES.RANDOM_TOWN:
                result = Utils.clone(player.availableRaces);
                if (result.length > 1) {
                    result.unshift(this.getRace(SPRITE_INDEXES.RANDOM_TOWN));
                }
                break;

            case SPRITE_INDEXES.NO_TOWN:
                result.push(this.getRace(SPRITE_INDEXES.NO_TOWN));
                break;

            default:
                result.push(this.getRace(player.mainTownSpriteIndex));
        }

        return result;
    }

    private getHeroes(player, town): number[] {
        let result = [];
        switch (player.mainHeroSpriteIndex) {

            case SPRITE_INDEXES.RANDOM_HERO:
                if ((town === undefined) || (town === -1)) {
                    return [SPRITE_INDEXES.RANDOM_HERO];
                }
                let availableHeroes = HeroesCollection.getAvailable();
                result = availableHeroes
                    .filter(({race}) => race === town)
                    .map(({id}) => id);
                if (result.length > 1) {
                    result.unshift(SPRITE_INDEXES.RANDOM_HERO);
                }
                break;

            case SPRITE_INDEXES.NO_HERO:
                result.push(SPRITE_INDEXES.NO_HERO);
                break;

            default:
                result.push(player.mainHeroSpriteIndex);
        }

        return result;
    }

    private setRace(selected: number) {
        this.scope.player.selectedRace = selected;

        this.writeToPlayerState('selectedRace', selected);
    }

    private setPerson(selected: number) {
        this.scope.player.selectedHero = selected;

        this.writeToPlayerState('selectedHero', selected);
    }

    private writeToPlayerState(param: string, value: number) {
        this.stateService.forEach('players', (player: IMapDataPlayer) => {
            if (player.color === this.scope.player.color) {
                player[param] = value;
            }
        });
    }
}

