
import {UiWindow} from "../common/UiWindow";
import {Events} from "../../controllers/Events";
import * as Utils from "../../utils/Utils";
import {TextService} from "../../services/TextService";
import {HeroObject} from "../../models/objects/hero/HeroObject";
import {IAbstractView} from '../IAbstractView';
import {Inject, inject} from '../../helpers/InjectDectorator';
import {ActiveView} from '../../controllers/ActiveView';

export class UiHeroTipWindow extends UiWindow {

    @Inject(TextService) private textService: TextService;

    constructor(hero: HeroObject, options = {}) {
        let windowWidth = 194;
        let windowHeight = 186;
        const activeView = inject<IAbstractView>(ActiveView);

        let mousePos = Events.getMousePos();
        let maxLeft = ~~(windowWidth / 2) + 30;
        let maxRight = activeView.getWindowWidth() - maxLeft;
        let maxTop = ~~(windowHeight / 2) + 30;
        let maxBottom = activeView.getWindowHeight() - maxTop;
        let centerLeft = Math.min(Math.max(mousePos.x, maxLeft), maxRight);
        let centerTop = Math.min(Math.max(mousePos.y, maxTop), maxBottom);

        let left = ~~(centerLeft - (windowWidth / 2));
        let top = ~~(centerTop - (windowHeight / 2));

        super(Utils.extend({
                left,
                top,
                width: windowWidth,
                height: windowHeight,
                centered: false,
                borders: false,
                background: 'hero_modal',
                hideCursor: true
            }
            , options)
        );

        this.scope = {
            hero: {
                name: hero.name || this.textService.getHeroName(hero.iconSpriteIndex.toString()),
                iconSpriteIndex: hero.iconSpriteIndex,

                attack: hero.properties.getValue('attack'),
                deffence: hero.properties.getValue('defence'),
                power: hero.properties.getValue('power'),
                knowledge: hero.properties.getValue('knowledge'),
                manapoints: hero.properties.getValue('manaPoints'),
                experience: hero.properties.getValue('experience'),
                speciality: hero.data.config.spec,
                artifacts: hero.artifacts,
                creatures: hero.creatures
            },

            getStorages() {
                return [hero.creatures];
            }
        };
        setTimeout(() => {
            this.setMarkup('game/popups/hero.xml');
        })
    }
}

