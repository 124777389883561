import {IRect} from '../helpers/Rect';

export * from './ArrayUtils';
export * from './ColorsUtils';
export * from './ObjectsUtils';

export function makeCtx(width: number, height: number): CanvasRenderingContext2D {
    let canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    return canvas.getContext('2d');
}

export function execTime(callback: () => any): number {
    let t = performance.now();
    callback();
    return performance.now() - t;
}

export function capitalize(value: string): string {
    if ((value != null ? value.length : undefined)) {
        return value[0].toUpperCase() + value.substr(1);
    } else {
        return value;
    }
}

export function distance(x1: number, y1: number, x2: number, y2: number): number {
    return Math.sqrt(((x2 -= x1) * x2) + ((y2 -= y1) * y2));
}

export function debounce(func: () => void, wait: number = 0, immediate = false): () => void {
    let timeout = null;

    return function() {
        let args = arguments;

        let later = () => {
            timeout = null;

            if (!immediate) {
                return func.apply(this, args);
            }
        };

        let callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) {
            return func.apply(this, args);
        }
    }.bind(this);
}

export function hexToBytesArr(hex: number): number[] {
    return hex.toString(2).split('').reverse().map(Number);
}

export function randomize(data: {[key: number]: () => any}): any {
    let factorsSummary = 0;
    const cases = [];

    for (let factor in data) {
        let fn = data[factor];
        cases.push({
            min: factorsSummary,
            max: (factorsSummary += parseInt(factor)),
            callback: fn
        });
    }

    const random = ~~(Math.random() * factorsSummary);

    for (let item of Array.from(cases)) {
        if ((item.min <= random) && (random <= item.max)) {
            if (typeof item.callback === 'function') {
                return item.callback();
            }
            return item.callback;
        }
    }
}

export function probability(percent: number): boolean {
    let random = Math.random() * 100;

    return Math.abs(percent) > random;
}

export function forEachRect(rect: IRect, iteratee: (x: number, y: number) => void) {
    const top = Math.min(rect.top, rect.bottom);
    const bottom = Math.max(rect.top, rect.bottom);
    const left = Math.min(rect.left, rect.right);
    const right = Math.max(rect.left, rect.right);

    for (let y = top; y <= bottom; y++) {
        for (let x = left; x <= right; x++) {
            iteratee(x, y);
        }
    }
}

export function getBase64Image(img: HTMLImageElement): string {
    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    return canvas.toDataURL("image/png");
}

export function mixin(dest: any, src: any) {
    for (let attrname in src.prototype) {
        dest.prototype[attrname] = src.prototype[attrname];
    }
}

export function consoleLog(...messages: (string[] | any)[]) {
    let color, message;
    const parts = [''];

    for (let item of Array.from(messages)) {
        if (Array.isArray(item)) {
            [message, color] = Array.from(item);
            parts[0] += `%c ${message}`;
        }
    }

    for (let item of Array.from(messages)) {
        if (Array.isArray(item)) {
            [message, color] = Array.from(item);
            parts.push(`background: transparent; color: #${color}`);
        } else {
            parts.push(item);
        }
    }

    console.log.apply(console, parts);
}

export function Mixin (destinationObject: any) {
    return function (target) {
        Object.getOwnPropertyNames(destinationObject.prototype).forEach(name => {
            target.prototype[name] = destinationObject.prototype[name];
        });
    }
}