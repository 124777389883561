
import {MapObject} from "../MapObject";
import * as Utils from "../../utils/Utils";
import {ObjectsCollection} from "../ObjectsCollection";
import {TeleportGateMixin} from "./TeleportGateMixin";
import {Mixin} from '../../utils/Utils';
import {MapComponent} from '../MapComponent';
import {inject} from "../../helpers/InjectDectorator";

@MapComponent('subterranean_gate')
@Mixin(TeleportGateMixin)
export class SubterraneanGateObject extends MapObject implements TeleportGateMixin {
    targetGateClass: number;
    restrictedPoints = true;

    constructor(data, id) {
        super(data, id);

        this.initGate();

        this.findGate = this.findSubterraneanGate;
    }

    findSubterraneanGate() {
        const availableGates = [];

        inject<ObjectsCollection>(ObjectsCollection).forEach('subterranean_gate', gate => {
            if (gate.z !== this.z) {
                return availableGates.push(gate);
            }
        });

        if (availableGates.length) {
            availableGates.sort((a, b) => {
                const distanceA = Utils.distance(a.x, a.y, this.x, this.y);
                const distanceB = Utils.distance(b.x, b.y, this.x, this.y);

                if (distanceA > distanceB) {
                    return 1;
                } else {
                    return - 1;
                }
            });
        }
        return availableGates[0] || null;
    };

    initGate() {}
    findGate(): MapObject { return null }
    isGateMatches(gate: MapObject): boolean { return null }
    getActionPointOffset(): number { return null }
    transferTo(hero, gate) {}
}