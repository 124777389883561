
import {RenderTree} from "./RenderTree";
import {MapModel} from "../models/map/MapModel";
import {PathFinderService} from "../services/PathFinderService";
import {UrlParamsService} from "../services/UrlParamsService";
import * as Utils from "../utils/Utils";
import {Grid} from "../helpers/Grid";
import {Events} from "../controllers/Events";
import {Rect} from "../helpers/Rect";
import {ObjectsCollection} from "../models/ObjectsCollection";
import {Canvas} from "./layers/Canvas";
import {HeroObject} from "../models/objects/hero/HeroObject";
import {MapObject} from "../models/MapObject";
import {TilesCollection} from "../models/map/TilesCollection";
import {TileSprite} from "../views/sprites/TileSprite";
import {UiSprite} from "../views/sprites/UiSprite";
import {StateService} from "../services/StateService";
import {Layers} from './layers/Layers';
import {ITextures, Textures} from '../controllers/Textures';
import {Inject} from '../helpers/InjectDectorator';
import {ActivePlayer} from "../controllers/ActivePlayer";
import {PlayerModel} from "../models/player/PlayerModel";

const TERRAIN_SECTION_SIZE = 8;
const TILE_SIZE = 32;
const MAX_OBJECT_HEIGHT = 6 * TILE_SIZE;
const OBJECTS_SECTION_SIZE = 20 * TILE_SIZE;

export class MapDrawer {

    private terrainSections: Grid<HTMLCanvasElement>;
    private mapCanvas: Canvas;
    private objectsCanvas: Canvas;
    private needFullRedraw = true;

    private terrainRenderTree = new RenderTree(TILE_SIZE, TILE_SIZE);
    private objectsRenderTree = new RenderTree(MAX_OBJECT_HEIGHT, OBJECTS_SECTION_SIZE);
    @Inject(Textures) private textures: ITextures;
    @Inject(ActivePlayer) protected activePlayer: PlayerModel;
    @Inject(ObjectsCollection) protected objectsCollection: ObjectsCollection;
    @Inject(UrlParamsService) protected urlParamsService: UrlParamsService;
    @Inject(StateService) private stateService: StateService;
    @Inject(Layers) private layers: Layers;

    constructor() {
        this.prepareTerrain = this.prepareTerrain.bind(this);
        this.prepareObjects = this.prepareObjects.bind(this);
        this.drawTerrain = this.drawTerrain.bind(this);
        this.drawObjects = this.drawObjects.bind(this);
    }

    clear() {
        this.terrainRenderTree.clear();
        this.objectsRenderTree.clear();
    }

    left(left: number): number {
        return left - this.mapCanvas.canvasLeftOffset;
    }

    x(x: number): number {
        return this.left(x << 5);
    }

    top(top: number): number {
        return top - this.mapCanvas.canvasTopOffset;
    }

    y(y: number): number {
        return this.top(y << 5);
    }

    getMapOffset() {
        return {
            left: this.mapCanvas.left,
            top: this.mapCanvas.top
        };
    }

    prepareTerrain(mapCanvasObject: Canvas) {
        this.mapCanvas = mapCanvasObject;

        this.makeTerrainRenderTree();
        this.makeTerrainSections();
    }

    prepareObjects(objectsCanvasObject: Canvas) {
        this.objectsCanvas = objectsCanvasObject;

        Events.on('canvas.updated', () => this.needFullRedraw = true);

        Events.on('dynamicObjectSpawned', object => {
            this.addObjectToRender(object, this.objectsCanvas);
        });

        this.makeObjectsRenderTree();
    }

    private makeTerrainRenderTree() {
        const z = this.stateService.get('center.z');
        const terrainSprites = this.getTerrainSprites();

        this.terrainRenderTree.clear();

        TilesCollection.forEach(z, ([x, y, z, terrain, isMoorable, sprite, mirrorType]) => {
            this.addGroundTile(x, y, terrainSprites[terrain], sprite, mirrorType);
        });

        TilesCollection.forEach(z, ([x, y, z, terrain, isMoorable, sprite, mirrorType, riverType, riverSpriteIndex, riverMirrorType, roadType, roadSpriteIndex, roadMirrorType]) => {
            this.addRiverTile(x, y, riverType, riverSpriteIndex, riverMirrorType);
            this.addRoadTile(x, y, roadType, roadSpriteIndex, roadMirrorType);
        });
    }

    private getTerrainSprites(): TileSprite[] {
        const sprites = [];
        let count = 10;

        while (count--) {
            sprites[count] = this.textures.get(`terrain_${count}`);
        }

        return sprites;
    }

    private addGroundTile(x: number, y: number, sprite: TileSprite, spriteIndex: number, mirrorType: number) {
        const left = x * TILE_SIZE;
        const top = y * TILE_SIZE;

        this.terrainRenderTree.add(top, left, sprite.draw.bind(this, left, top, spriteIndex, mirrorType));
    }

    private addRiverTile(x: number, y: number, type: number, spriteIndex: number, mirrorType: number) {
        if (!type) {
            return;
        }

        const left = x * TILE_SIZE;
        const top = y * TILE_SIZE;
        const sprite = this.textures.get(`river_${type}`);

        this.terrainRenderTree.add(top + 1, left, sprite.draw.bind(this, left, top, spriteIndex, mirrorType));
    }

    private addRoadTile(x: number, y: number, type: number, spriteIndex: number, mirrorType: number) {
        if (!type) {
            return;
        }

        const left = x * TILE_SIZE;
        const top = y * TILE_SIZE;
        const sprite = this.textures.get(`road_${type}`);

        this.terrainRenderTree.add(top + 48, left, sprite.draw.bind(this, left, top + 16, spriteIndex, mirrorType));
    }

    private makeTerrainSections() {
        const maxSize = Math.ceil(MapModel.size / TERRAIN_SECTION_SIZE);
        const sectionCanvasSize = TERRAIN_SECTION_SIZE * TILE_SIZE;
        this.terrainSections = new Grid(maxSize);

        for (let yOffset = 0; yOffset <= maxSize; yOffset++) {
            for (let xOffset = 0; xOffset <= maxSize; xOffset++) {
                this.addTerrainSection(xOffset, yOffset, sectionCanvasSize);
            }
        }

        const z = this.stateService.get('center.z');

        this.objectsCollection.static.forEach(object => {
            if (object.z !== z) {
                return;
            }
            if (this.canDrawObjectOnTerrain(object)) {
                this.drawObjectOnTerrain(object);
            }
        });
    }

    private canDrawObjectOnTerrain(object: MapObject): boolean {
        return object.type === 'passable_terrain';
    }

    private addTerrainSection(xOffset: number, yOffset: number, size: number) {
        const ctx = Utils.makeCtx(size, size);

        const left = xOffset * TERRAIN_SECTION_SIZE * TILE_SIZE;
        const top = yOffset * TERRAIN_SECTION_SIZE * TILE_SIZE;
        const sectionLeftOffset = left - this.mapCanvas.canvasLeftOffset;
        const sectionTopOffset = top - this.mapCanvas.canvasTopOffset;
        const rect = <Rect>{
            left,
            top,
            right: left + (TERRAIN_SECTION_SIZE * TILE_SIZE),
            bottom: top + (TERRAIN_SECTION_SIZE * TILE_SIZE) + TILE_SIZE
        };

        ctx.translate(-(sectionLeftOffset), -(sectionTopOffset));
        this.terrainRenderTree.drawRect(rect, ctx);

        this.terrainSections.set(xOffset, yOffset, ctx.canvas);
    }

    private drawObjectOnTerrain(object: MapObject) {
        const sprite = object.getSprite();

        const spriteWidth = sprite.width || 0;
        const spriteHeight = sprite.height || 0;
        const spriteLeft = ((object.x * TILE_SIZE) - spriteWidth) + 32;
        const spriteTop = ((object.y * TILE_SIZE) - spriteHeight) + 32;

        this.terrainSections.forEach((sectionCanvas, sectLeft, sectTop) => {
            const sectSize = TERRAIN_SECTION_SIZE * TILE_SIZE;
            sectLeft = sectLeft * sectSize;
            sectTop = sectTop * sectSize;

            if (spriteLeft < (sectLeft + sectSize)
                && (spriteLeft + spriteWidth) > sectLeft
                && spriteTop < (sectTop + sectSize)
                && (spriteHeight + spriteTop) > sectTop
            ) {
                const sectionCtx = sectionCanvas.getContext('2d');
                sprite.draw(object.x * TILE_SIZE, object.y * TILE_SIZE, 0, sectionCtx);
            }
        });
    }

    drawTerrain(canvas: Canvas) {
        if (!this.needFullRedraw) {
            return false;
        }

        canvas.clear();
        canvas.ctx.translate(-canvas.canvasLeftOffset, -canvas.canvasTopOffset);

        const sectionSize = (TERRAIN_SECTION_SIZE * TILE_SIZE);

        this.terrainSections.forEach((sectionCanvas, xOffset, yOffset) => {
            const drawLeft = xOffset * sectionSize;
            const drawTop = yOffset * sectionSize;

            canvas.ctx.drawImage(sectionCanvas, drawLeft, drawTop);
        });

        if (this.urlParamsService.params.hasOwnProperty('gridDebug')) {
            const type = this.urlParamsService.params.gridDebug || 'earth';

            PathFinderService.gridDebug(type);
        }

        this.needFullRedraw = false;
    }

    private addObjectToRender(object: MapObject, canvas: Canvas) {
        const top = object.y * TILE_SIZE;
        const left = object.x * TILE_SIZE;
        let zIndex = top - TILE_SIZE;

        if (['impassable_terrain'].indexOf(object.type) !== -1) {
            zIndex -= 1;
        }
        if (['hero', 'boat', 'generic_treasure', 'resource'].indexOf(object.type) !== -1) {
            zIndex += 1;
        }

        object.zIndex = zIndex;
        object.renderTree = this.objectsRenderTree.add(zIndex, left, object.draw.bind(this, canvas.ctx));
    }

    private makeObjectsRenderTree() {
        this.objectsRenderTree.clear();
        const z = this.stateService.get('center.z');

        this.objectsCollection.forEach(object => {
            if (object.z !== z) {
                return;
            }
            if (this.canDrawObjectOnTerrain(object)) {
                return;
            }
            this.addObjectToRender(object, this.objectsCanvas);
        });
    }

    private drawHeroPath(hero: HeroObject) {
        if (!hero || hero.z !== this.stateService.get('center.z')) {
            return;
        }
        if (hero.pathFinder.items) {
            const uiArrowSprite = <UiSprite>this.textures.get('path_arrow');

            for (let pathItem of hero.pathFinder.items) {
                const left = pathItem.point.x << 5;
                const top = pathItem.point.y << 5;

                uiArrowSprite.draw(left, top, pathItem.spriteIndex);
            }
        }
    }

    drawObjects(canvas: Canvas) {
        const {left, top} = this.layers.mapCanvas;
        const {selectedObject} = this.activePlayer;

        canvas.clear();
        this.objectsRenderTree.run(left, top);

        if (selectedObject.isHero) {
            this.drawHeroPath(<HeroObject>selectedObject);
        }

        MapModel.fog.draw(canvas);
    }

    update() {
        this.clear();
        this.prepareTerrain(this.mapCanvas);
        this.prepareObjects(this.objectsCanvas);
        this.needFullRedraw = true;
    }
}