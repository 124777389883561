import * as Utils from "../../../utils/Utils";
import {Grid} from "../../../helpers/Grid";
import {UiBattleFieldCreature} from "./UiBattleFieldCreature";
import {IUiBattleFieldOptions, UiBattleField} from "./UiBattleField";
import {BattleFieldCreature} from '../../../models/objects/BattleFieldCreature';
import {UiPanel} from "../../UiPanel";

const HEXAGON_ANGLE = 0.523598776;
const FIELD_WIDTH = 15;
const FIELD_HEIGHT = 11;
const SIDE_LENGTH = 28;

const hexHeight = Math.round(Math.sin(HEXAGON_ANGLE) * SIDE_LENGTH);
const hexRadius = Math.round(Math.cos(HEXAGON_ANGLE) * SIDE_LENGTH);
const hexRectangleHeight = Math.round(SIDE_LENGTH + (2 * hexHeight));
const hexRectangleWidth = Math.round(2 * hexRadius);

export class UiBattleFieldGeometryMixin {
    hexagonGraphic: HTMLCanvasElement;
    hoverHexagonGraphic: HTMLCanvasElement;
    passableHexagonGraphic: HTMLCanvasElement;
    selectedHexagonGraphic: HTMLCanvasElement;
    boardGraphic: HTMLCanvasElement;

    cellUnderCursor: [number, number];
    targetCell: UiBattleFieldCreature;
    selectedCell: [number, number];
    options: IUiBattleFieldOptions;

    hexagonsPoints: Grid<[number, number]>;

    getAngle(hexagonX: number, hexagonY: number, left: number, top: number): number {
        const centerX = this.getHexagonLeft(hexagonX, hexagonY) + hexRadius;
        const centerY = this.getHexagonTop(hexagonX, hexagonY) + hexRadius;
        let degrees = (Math.atan2(top - centerY, left - centerX) * 180) / Math.PI;
        degrees = ((~~degrees) + 510) % 360;

        return ~~(degrees / 60);
    }

    findNearestHexagon(x: number, y: number): [number, number] {
        let result = null;

        if (!this.hexagonsPoints) {
            this.hexagonsPoints = this.getHexagonPoints();
        }

        this.hexagonsPoints.forEach((value, hx, hy) => {
            const [x2, y2] = Array.from(value);
            const distance = Utils.distance(x, y, x2, y2);

            if (distance <= hexRadius) {
                result = [hx, hy];
            }
        });

        return result;
    }

    makeHexagon(): CanvasRenderingContext2D {
        let x = 0;
        let y = 0;
        let ctx = Utils.makeCtx(hexRectangleWidth, hexRectangleHeight);
        ctx.beginPath();
        ctx.moveTo(x + hexRadius, y);
        ctx.lineTo(x + hexRectangleWidth, y + hexHeight);
        ctx.lineTo(x + hexRectangleWidth, y + hexHeight + SIDE_LENGTH);
        ctx.lineTo(x + hexRadius, y + hexRectangleHeight);
        ctx.lineTo(x, y + SIDE_LENGTH + hexHeight);
        ctx.lineTo(x, y + hexHeight);
        ctx.closePath();
        return ctx;
    }

    prepareHexagon(): HTMLCanvasElement {
        let ctx = this.makeHexagon();
        ctx.strokeStyle = 'rgba(118, 255, 5, 0.6)';
        ctx.stroke();

        return ctx.canvas;
    }

    prepareSelectedHexagon(): HTMLCanvasElement {
        let ctx = this.makeHexagon();
        ctx.fillStyle = '#ccbf00';
        ctx.lineWidth = 1.5;
        ctx.fill();
        ctx.lineWidth = 1;

        return ctx.canvas;
    }

    preparePassableHexagon(): HTMLCanvasElement {
        let ctx = this.makeHexagon();
        ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
        ctx.fill();

        return ctx.canvas;
    }

    prepareHoverHexagon(): HTMLCanvasElement {
        let ctx = this.makeHexagon();
        ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
        ctx.fill();

        return ctx.canvas;
    }

    getHexagonLeft(x: number, y: number): number {
        let left;
        let rowMargin = ((y + 1) % 2) * hexRadius;
        return left = (x * hexRectangleWidth) + rowMargin;
    }

    getHexagonTop(x: number, y: number): number {
        return y * (SIDE_LENGTH + hexHeight);
    }

    forEachBoard(callback: Function) {
        let y = FIELD_HEIGHT;
        while (y--) {
            let x = FIELD_WIDTH;
            while (x--) {
                let left = this.getHexagonLeft(x, y);
                let top = this.getHexagonTop(x, y);
                callback(left, top, x, y);
            }
        }
    }

    prepareBoard(): HTMLCanvasElement {
        let { width } = this.options;
        let { height } = this.options;
        let ctx = Utils.makeCtx(<number>width, <number>height);
        this.forEachBoard((left, top) => {
            return ctx.drawImage(this.hexagonGraphic, left, top);
        });
        return ctx.canvas;
    }

    getHexagonPoints(): Grid<[number, number]> {
        const result = new Grid(16);

        this.forEachBoard((left, top, x, y) => {
            const cx = (left + (hexRectangleHeight / 2)) - 6;
            const cy = top + (hexRectangleWidth / 2) + 4;

            return result.set(x, y, [cx, cy]);
        });

        return result;
    }

    getUiBattlefieldCreature(battleFieldCreature: BattleFieldCreature): UiBattleFieldCreature {
        return new UiBattleFieldCreature({
            parent: <UiPanel><any>this,
            unit: battleFieldCreature,
            cellWidth: hexRectangleWidth,
            cellHeight: hexRectangleHeight
        });
    }

    prepareGraphics() {
        this.hexagonGraphic = this.prepareHexagon();
        this.hoverHexagonGraphic = this.prepareHoverHexagon();
        this.passableHexagonGraphic = this.preparePassableHexagon();
        this.selectedHexagonGraphic = this.prepareSelectedHexagon();
        this.boardGraphic = this.prepareBoard();
    }
}