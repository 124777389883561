
import {IUiOptions, UiPanel} from "../../UiPanel";
import * as Utils from "../../../utils/Utils";
import {CreaturesStorage} from "../../../models/storages/CreaturesStorage";
import {UiController} from "../../../controllers/UiController";
import {UiCreatureTipWindow} from "../../windows/UiCreatureTipWindow";
import {Events} from "../../../controllers/Events";
import {UiSprite} from "../../sprites/UiSprite";
import {UiBattleField} from "./UiBattleField";
import {UiComponent} from "../../../services/UiComponentsService";
import {BattleFieldCreature} from '../../../models/objects/BattleFieldCreature';
import {BattleService, BattleSide} from '../../../services/BattleService';
import {Observable} from 'rxjs/Observable';
import {ITextures, Textures} from '../../../controllers/Textures';
import {Inject} from '../../../helpers/InjectDectorator';
import {ICreatureData} from "../../../controllers/MonstersData";

export interface IUiBattleFieldCreatureOptions extends IUiOptions {
    unit: BattleFieldCreature;
    cellWidth: number;
    cellHeight: number;
}

const DEFAULT_PARAMS = <IUiBattleFieldCreatureOptions>{
    left: 0,
    top: 0,
    width: 28,
    height: 42,

    unit: null,
    side: null,
    creatureId: -1,
    quantity: 0,
    cellWidth: 0,
    cellHeight: 0
};

@UiComponent()
export class UiBattleFieldCreature extends UiPanel {

    options: IUiBattleFieldCreatureOptions;
    side: string;
    owner: BattleSide;
    parent: UiPanel;
    unit: BattleFieldCreature;
    x: number;
    y: number;

    private data: ICreatureData;
    private spriteLeftMargin: number;
    private spriteTopMargin: number;
    private activeEffectSprite: UiSprite;
    private hitTexture: CanvasRenderingContext2D;

    @Inject(Textures) protected textures: ITextures;

    constructor(params: IUiBattleFieldCreatureOptions) {
        super(Utils.extend(DEFAULT_PARAMS, params));

        this.draw = this.draw.bind(this);

        this.unit = this.options.unit;
        this.width = (this.options.width = this.options.cellWidth);
        this.height = (this.options.height = this.options.cellHeight);
        this.side = this.unit.side;
        this.owner = this.unit.owner;
        this.data = CreaturesStorage.prototype.getCreatureData(this.unit.creatureId);

        this.sprite = <UiSprite>this.textures.get('creatures_portraits_sm');
        this.hitTexture = this.makeHitEffectTexture();

        this.spriteLeftMargin = (this.options.cellWidth - 32) >> 1;
        this.spriteTopMargin = (this.options.cellHeight - 32) >> 1;

        this.events.on('leftClick', () => true);

        this.events.on('rightMousedown', () => {
            const uiTipWindow = UiController.modal(UiCreatureTipWindow, this.unit);
            Events.one('rightMouseup', () => UiController.remove(uiTipWindow));
            return false;
        });

        BattleService.startTurnStream
            .takeUntil(Observable.fromEvent(this.events, 'remove'))
            .subscribe(() => {
                if (this.unit.hasEffect('EXTRA_TURN')) {
                    this.unit.addEffect('BLOCK_EXTRA_TURN');
                    this.playEffect('morale_up_effect');
                }
                if (this.unit.hasEffect('SKIP_TURN')) {
                    this.playEffect('morale_down_effect');
                }
                if (this.unit.hasEffect('LUCK')) {
                    this.playEffect('luck_effect');
                }
            })

        BattleService.nextRoundStream
            .takeUntil(Observable.fromEvent(this.events, 'remove'))
            .subscribe(() => {
                this.unit.hasResponse = true;
            });
    }

    playEffect(name: string) {
        this.activeEffectSprite = <UiSprite>this.textures.get(name);
        this.activeEffectSprite
            .setAnimationOnce('IDLE').animationPromise
            .then(() => this.activeEffectSprite = null);
    }

    private drawQuantityCounter() {
        const centerLeft = this.options.cellWidth >> 1;
        const top = this.options.cellHeight - 15;
        const absoluteLeft = (this.absoluteLeft + centerLeft) - 15;
        const absoluteTop = this.absoluteTop + top;

        const healthPercentWidth = (this.unit.lastUnitHealth / this.unit.data.damage.hitPoints) * 30;

        this.ctx.fillStyle = this.getHealthBarColor();
        this.ctx.fillRect(absoluteLeft, absoluteTop + 15, healthPercentWidth, 2);

        this.ctx.strokeStyle = '#998c38';
        this.ctx.fillStyle = '#4F2982';
        this.ctx.fillRect(absoluteLeft, absoluteTop, 30, 13);
        this.ctx.strokeRect(absoluteLeft + .5, absoluteTop + .5, 29, 12);

        this.text({
            left: centerLeft,
            top: top + 6,
            color: '#fff',
            text: this.unit.quantity,
            align: 'center',
            fontSize: '9px'
        });
    }

    private drawActiveEffectSprite() {
        let sprite = this.activeEffectSprite;
        let left = this.absoluteLeft - ((sprite.width - this.width) >> 1);
        let top = this.absoluteTop - sprite.height;

        this.activeEffectSprite.draw(left, top);
    }

    draw() {
        if (this.unit.isAlive) {
            this.drawCreatureSprite();
            this.drawQuantityCounter();
        } else {
            this.ctx.globalAlpha = 0.3;
            this.drawCreatureSprite();
            this.ctx.globalAlpha = 1;
        }
        if (this.activeEffectSprite) {
            this.drawActiveEffectSprite();
        }
        if (this.unit.hasEffect('HIT')) {
            this.drawHitEffect();
        }
    }

    setPosition(x: number, y: number) {
        this.x = x;
        this.y = y;
        this.setLeft((<UiBattleField><any>this.parent).getHexagonLeft(this.x, this.y));
        this.setTop((<UiBattleField><any>this.parent).getHexagonTop(this.x, this.y));

        this.unit.x = x;
        this.unit.y = y;
    }

    private getHealthBarColor(): string {
        const percent = (this.unit.lastUnitHealth / this.unit.data.damage.hitPoints) * 100;

        if (percent > 75) {
            return '#35B437';
        } else if (percent > 50) {
            return '#d9c823';
        } else if (percent > 50) {
            return '#ea8416';
        }
        return '#d92c25';
    }

    private makeHitEffectTexture(): CanvasRenderingContext2D {
        const ctx = Utils.makeCtx(30, 30);

        ctx.fillStyle = 'rgba(255, 0, 0, 0.7)';

        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(15, 12);
        ctx.lineTo(30, 0);
        ctx.lineTo(18, 15);
        ctx.lineTo(30, 30);
        ctx.lineTo(15, 18);
        ctx.lineTo(0, 30);
        ctx.lineTo(12, 15);
        ctx.closePath();

        ctx.fill();

        return ctx;
    }

    private drawHitEffect() {
        const centerLeft = this.options.cellWidth >> 1;
        const top = 5;
        const absoluteLeft = (this.absoluteLeft + centerLeft) - 15;
        const absoluteTop = this.absoluteTop + top;

        this.ctx.drawImage(this.hitTexture.canvas, absoluteLeft, absoluteTop);
    }

    private drawCreatureSprite() {
        const left = this.absoluteLeft + this.spriteLeftMargin;

        if (this.side === 'right') {
            this.ctx.translate((left * 2) + 32, 0);
            this.ctx.scale(-1, 1);
        }

        this.sprite.draw(left, this.absoluteTop + this.spriteTopMargin, this.data.spriteId);

        this.ctx.setTransform(1, 0, 0, 1, 0, 0);
    }
}