
import {Display} from "./Display";
import * as Utils from "../../utils/Utils";
import {Events} from "../../controllers/Events";

const BASE_OFFSET = 64;

let normalize = value => (value >> 5) << 5;

export class Canvas {

    private layer: HTMLElement;
    canvas: HTMLCanvasElement;
    ctx: CanvasRenderingContext2D;

    left: number = 0;
    top: number = 0;
    canvasLeftOffset: number = 0;
    canvasTopOffset: number = 0;

    constructor(private fixed: boolean) {

        this.create();
        Display.appendChild(this.layer);

        Utils.watch(this, 'left', (left) => this.updateLeft(left));
        Utils.watch(this, 'top', (top) => this.updateTop(top));

        this.update();
        this.attachEvents();
    }

    attachEvents() {
        window.addEventListener('resize', e => this.update());
    }

    clear() {
        this.ctx.setTransform(1, 0, 0, 1, 0, 0);
        this.canvas.width = this.canvas.width;
    }

    setGreyscale(isSet: boolean) {
        let value = isSet ? 'grayscale(100%)' : '';

        Object.assign(this.layer.style, {
            webkitFilter: value,
            msFilter: value,
            filter: value
        });
    }

    setSize(mapSize: number) {
        const size = mapSize << 5;

        Object.assign(this.layer.style, {
            width: `${size}px`,
            height: `${size}px`,
            border: '1px solid #8c8459',
            overflow: 'hidden'
        });
    }

    private create() {
        this.canvas = document.createElement('canvas');
        this.canvas.style.position = 'absolute';
        this.ctx = this.canvas.getContext('2d');

        this.layer = document.createElement('div');
        this.layer.appendChild(this.canvas);

        return Object.assign(this.layer.style, {
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: '0',
            top: '0'
        });
    }

    private update() {
        const width = Display.offsetWidth;
        const height = Display.offsetHeight;

        if (this.fixed) {
            this.canvas.width = width;
            this.canvas.height = height;
            this.canvas.style.width = '100%';
            this.canvas.style.height = '100%';
        } else {
            const canvasWidth = width + (BASE_OFFSET * 2);
            const canvasHeight = height + (BASE_OFFSET * 2);

            this.canvas.width = canvasWidth;
            this.canvas.height = canvasHeight;
            this.canvas.style.width = `${canvasWidth}px`;
            this.canvas.style.height = `${canvasHeight}px`;
        }

        Events.dispatch('canvas.updated');
    }

    private updateLeft(left: number) {
        if (this.fixed) {
            return;
        }

        this.canvasLeftOffset = -(BASE_OFFSET + normalize(left));
        this.canvas.style.left = `${this.canvasLeftOffset}px`;
        this.layer.style.transform = `translate3d(${left}px, ${this.top}px, 0px)`;
    }

    private updateTop(top: number) {
        if (this.fixed) {
            return;
        }

        this.canvasTopOffset = -(BASE_OFFSET + normalize(top));
        this.canvas.style.top = `${this.canvasTopOffset}px`;
        this.layer.style.transform = `translate3d(${this.left}px, ${top}px, 0px)`;
    }
}