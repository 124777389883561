
import {Canvas} from "../../render/layers/Canvas";
import {UiGameOptions} from "./UiGameOptions";
import {UiController} from "../../controllers/UiController";
import {CommonComponentsModule, MenuComponentsModule, View} from "../ComponentsModules";
import {IAbstractView} from "../IAbstractView";
import {Display} from "../../render/layers/Display";

@View([CommonComponentsModule, MenuComponentsModule])
class UiGameOptionsViewSingleton implements IAbstractView {
    ui: UiGameOptions = null;

    private canvas: Canvas;

    constructor() {
    }

    prepare(canvas: Canvas) {
        this.canvas = canvas;
        this.ui = new UiGameOptions();
        UiController.setFocus(this.ui);
    }

    getMapWidth(): number {
        return Display.offsetWidth;
    }

    getWindowHeight(): number {
        return Display.offsetHeight;
    }

    getWindowWidth(): number {
        return Display.offsetWidth;
    }

    draw(canvas?: Canvas) {
        this.ui.draw();
    }
}

export const UiGameOptionsView = new UiGameOptionsViewSingleton();
