
import {EndTurnCommand} from "../../commands/EndTurnCommand";
import {MoveHeroCommand} from "../../commands/MoveHeroCommand";
import {TeleportHeroCommand} from "../../commands/TeleportHeroCommand";
import {SelectObjectCommand} from "../../commands/SelectObjectCommand";
import {CommandHelper} from "../../helpers/CommandHelper";
import {PlayerModel} from "./PlayerModel";

const COMMANDS = {
    'game.endPlayerTurn': {
        command: EndTurnCommand,
        params: {}
    },

    'heroes.move': {
        command: MoveHeroCommand,
        params: {
            id: 'required',
            x: 'required',
            y: 'required'
        }
    },

    'heroes.teleport': {
        command: TeleportHeroCommand,
        params: {
            id: 'required',
            x: 'required',
            y: 'required',
            z: 'required'
        }
    },

    'objects.select': {
        command: SelectObjectCommand,
        params: {
            id: 'required'
        }
    },

    'towns.heroes.reverse': {
        command: null,
        params: {
            id: 'required'
        }
    },

    'towns.heroes.replaceStack': {
        command: null,
        params: {
            stackFrom: 'required',
            stackTo: 'required',
            from: 'required',
            to: 'required'
        }
    },

    'towns.heroes.buyArtifact': {
        command: null,
        params: {
            artifact: 'required',
            id: 'required'
        }
    },

    'towns.heroes.buyCreature': {
        command: null,
        params: {
            creature: 'required',
            stack: 'required',
            id: 'required'
        }
    },

    'towns.build': {
        command: null,
        params: {
            building: 'required',
            id: 'required'
        }
    }
};

export class PlayerCommandsStack {

    owner: PlayerModel;
    stack: CommandHelper[] = [];
    currentStep = 0;
    isRunning = false;

    constructor(owner: PlayerModel) {
        this.owner = owner;
    }

    add(commandName: string, params: any) {
        const command = new COMMANDS[commandName].command(params);

        this.stack.push(command);

        if (!this.isRunning) {
            this.run();
        }

        return this;
    }

    run() {
        this.isRunning = true;

        this.process();
    }

    process() {
        if (!this.isRunning) {
            return;
        }

        const result = <any>this.do();

        if (this.isPromise(result)) {
            return (result as Promise<any>)
                .then(this.process.bind(this), this.stop.bind(this))
                .catch(console.error.bind(console));
        }

        if (result) {
            return this.process();
        }
        return this.stop();
    }

    do(): any {
        this.isRunning = true;
        const command = this.stack[this.currentStep];

        if (this.currentStep >= this.stack.length) {
            return false;
        }

        this.currentStep++;

        return command.execute();
    }

    stop() {
        this.stack.splice(this.currentStep, this.stack.length);
        this.isRunning = false;
    }

    private isPromise(obj: any): boolean {
        return obj
            ? typeof obj.then === 'function'
            : false;
    }
}