
import * as Utils from "../../utils/Utils";
import {HeroObject} from "../objects/hero/HeroObject";
import {IHeroConfig, IHeroSecondaryConfig} from "../../controllers/HeroesData";

const MAX_LEVEL = 3;
const MAX_SKILLS_COUNT = 8;

const SKILLS = {
    "ARCHERY": 1,
    "BALLISTICS": 10,
    "DIPLOMACY": 4,
    "EAGLE_EYE": 11,
    "ESTATES": 13,
    "LEADERSHIP": 6,
    "LOGISTICS": 2,
    "LUCK": 9,
    "MYSTICISM": 8,
    "NAVIGATION": 5,
    "NECROMANCY": 12,
    "PATHFINDING": 0,
    "SCOUTING": 3,
    "WISDOM": 7,
    "FIRE_MAGIC": 14,
    "AIR_MAGIC": 15,
    "WATER_MAGIC": 16,
    "EARTH_MAGIC": 17,
    "SCHOLAR": 18,
    "TACTICS": 19,
    "ARTILLERY": 20,
    "LEARNING": 21,
    "OFFENSE": 22,
    "ARMORER": 23,
    "INTELLIGENCE": 24,
    "SORCERY": 25,
    "RESISTANCE": 26,
    "FIRST_AID": 27
};

export interface IMapDataSecondarySkill {
    level: number;
    id: number;
}

export class SecondaryStorage {

    storage: Map<string, number>;

    constructor(skillsArray: IHeroSecondaryConfig[]) {
        this.storage = new Map();

        for (let {level, name} of skillsArray) {
            this.storage.set(name, level);
        }
    }

    static fromData(data: IMapDataSecondarySkill[]): SecondaryStorage {
        const skillsArray = data.map((...args) => {
            const [id, level] = args[0];
            const name = Object.keys(SKILLS).filter(name => SKILLS[name] === id)[0];

            return {level, name};
        });

        return new SecondaryStorage(skillsArray);
    }

    static fromConfig(config: IHeroConfig): SecondaryStorage {
        const skillsArray = [];

        if (config.skill1 && config.skill1.name) {
            skillsArray.push(config.skill1);
        }
        if (config.skill2 && config.skill2.name) {
            skillsArray.push(config.skill2);
        }

        return new SecondaryStorage(skillsArray);
    }

    static randomizeTwo(hero: HeroObject): string[] {
        let available;
        const storage = hero.secondary;

        if (storage.canAdd()) {
            available = storage.getAvailable().concat(storage.getUpgradable());
        } else {
            available = storage.getUpgradable();
        }

        return Utils.some(available, 2);
    }

    has(secondary: string): boolean {
        return this.storage.has(secondary);
    }

    get(secondary: string): number {
        return this.storage.get(secondary);
    }

    forEach(callback: () => void) {
        this.storage.forEach(callback);
    }

    canAdd(): boolean {
        return this.storage.size < MAX_SKILLS_COUNT;
    }

    getAvailable(): string[] {
        const result = [];

        for (let skill of Array.from(Object.keys(SKILLS))) {
            if (!this.storage.has(skill)) {
                result.push(skill);
            }
        }

        return result;
    }

    getUpgradable(): string[] {
        const result = [];

        this.storage.forEach(function(level, skill) {
            if (level < MAX_LEVEL) {
                result.push(skill);
            }
        });

        return result;
    }

    push(secondary: string): boolean {
        if (this.storage.has(secondary)) {
            let level = this.storage.get(secondary);
            if (level >= MAX_LEVEL) {
                this.storage.set(secondary, MAX_LEVEL);
                return false;
            } else {
                level++;
                this.storage.set(secondary, level);
                return true;
            }
        } else {
            if (this.storage.size >= MAX_SKILLS_COUNT) {
                return false;
            } else {
                this.storage.set(secondary, 1);
                return true;
            }
        }
    }

    getSpriteIndex(secondary: string, level = 1): number {
        return (Object.keys(SKILLS).indexOf(secondary) * 3) + level;
    }
}

