import {Canvas} from "./Canvas";
import * as Utils from "../../utils/Utils";
import {Events, IDragEvent} from "../../controllers/Events";
import {Display} from './Display';
import {requestRenderFrame} from '../requestRenderFrame';
import {FocusedWindow} from "../../controllers/FocusedWindow";
import {UiWindow} from "../../views/common/UiWindow";
import {Inject} from "../../helpers/InjectDectorator";

const normalize = value => (value >> 5) << 5;

export class Layers {

    mapCanvas: Canvas;
    objectsCanvas: Canvas;
    uiCanvas: Canvas;

    private oldLeft = 0;
    private oldTop = 0;
    private mapSize = 0;
    @Inject(FocusedWindow) protected focusedWindow: UiWindow;

    constructor() {
        this.mapCanvas = new Canvas(false);
        this.objectsCanvas = new Canvas(false);
        this.uiCanvas = new Canvas(true);

        Utils.watch(this.mapCanvas, 'canvasLeftOffset', () => Events.dispatch('canvas.updated'));
        Utils.watch(this.mapCanvas, 'canvasTopOffset', () => Events.dispatch('canvas.updated'));
    }

    attachEvents() {
        Events
            .on('leftMousedown', e => this.handleMousedownEvent())
            .on('drag', e => this.handleDragEvent(e))
            .on('mouseWheel', e => this.handleMouseWheelEvent(e));

        this.mapCanvas.attachEvents();
        this.objectsCanvas.attachEvents();
        this.uiCanvas.attachEvents();
    }

    setGreyscale(isSet: boolean) {
        this.mapCanvas.setGreyscale(isSet);
        this.objectsCanvas.setGreyscale(isSet);
    }

    setSize(mapSize: number) {
        this.mapSize = mapSize;
        this.objectsCanvas.setSize(mapSize);
    }

    private handleDragEvent({diffX, diffY}: IDragEvent) {
        if (this.focusedWindow) {
            return true;
        }

        this.setCanvasCords(this.mapCanvas, this.oldLeft + diffX, this.oldTop + diffY);
        this.setCanvasCords(this.objectsCanvas, this.oldLeft + diffX, this.oldTop + diffY);
    }

    private handleMousedownEvent() {
        this.oldLeft = this.mapCanvas.left;
        this.oldTop = this.mapCanvas.top;
    }

    private handleMouseWheelEvent({deltaX, deltaY}: MouseWheelEvent) {
        if (this.focusedWindow) {
            return true;
        }

        const left = this.mapCanvas.left - normalize(deltaX);
        const top = this.mapCanvas.top - normalize(deltaY);

        this.setCanvasCords(this.mapCanvas, left, top);
        this.setCanvasCords(this.objectsCanvas, left, top);
    }

    private setCanvasCords(canvas: Canvas, newLeft: number, newTop: number) {
        const minLeft = Display.offsetWidth >> 1;
        const minTop = Display.offsetHeight >> 1;
        const maxLeft = minLeft - (this.mapSize << 5);
        const maxTop = minTop - (this.mapSize << 5);

        requestRenderFrame(() => {
            canvas.left = Math.max(Math.min(newLeft, minLeft), maxLeft);
            canvas.top = Math.max(Math.min(newTop, minTop), maxTop);
        });
    }
}
