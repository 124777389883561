
export class UrlParamsService {

    params: any = {};
    private callbacks: Function[] = [];

    constructor() {
        this.onChange = this.onChange.bind(this);

        this.params = this.parseHash(location.hash);

        window.onhashchange = () => {
            this.params = this.parseHash(location.hash);
            this.callbacks.map((callback) => callback(this.params));
        }
    }

    parseHash(hash: string): Object {
        const result = {};

        hash.substr(2)
            .split('&')
            .map(queryItem => queryItem.split('='))
            .forEach((...args) => {
                const [key, value] = args[0];
                result[key] = value || true;
            });

        return result;
    }

    subscribe(callback: Function) {
        callback(this.params);
        this.callbacks.push(callback);
    }

    onChange(callback: Function) {
        this.callbacks.push(callback);
    }
}