
import {IAbstractArtifact, IArtifactData} from "../../storages/ArtifactsStorage";
import {ObjectSprite} from "../../../views/sprites/ObjectSprite";
import {TextService} from "../../../services/TextService";
import {ITextures, Textures} from '../../../controllers/Textures';
import {Inject} from '../../../helpers/InjectDectorator';
import {ArtifactsData} from '../../../controllers/ArtifactsData';

export class AmmoCartObject implements IAbstractArtifact {

    artifactData: IArtifactData;
    tip: string;
    iconSprite: ObjectSprite;
    iconSpriteIndex: number;

    @Inject(Textures) private textures: ITextures;
    @Inject(TextService) private textService: TextService;
    @Inject(ArtifactsData) private artifactsMap: Map<number, IArtifactData>;

    constructor() {
        this.artifactData = this.artifactsMap.get(6);

        this.tip = this.textService.getArtifactName(this.artifactData.type);
        this.iconSprite = <ObjectSprite>this.textures.get('artifacts_portraits_sm');
        this.iconSpriteIndex = this.artifactData.spriteIndex;
    }
}