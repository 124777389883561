
import {UiWindow} from "../common/UiWindow";
import {Display} from "../../render/layers/Display";

const DEFAULT_PARAMS = {
    width: Math.min(400, Display.offsetWidth - 50),
    height: 150
};

export class UiChestWindow extends UiWindow {

    constructor(chest, hero) {
        super(DEFAULT_PARAMS);

        this.addTextBlock(chest.state.description);
        this.addButtonsFooter(chest.state.variants);
    }
};