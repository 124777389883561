
import {IUiWindowOptions, UiWindow} from "../../common/UiWindow";
import * as Utils from "../../../utils/Utils";
import {CURSORS} from "../../../constants/CURSORS";
import {UiSettingsWindow} from "../../windows/UiSettingsWindow";
import {UiSpellBookWindow} from "../../windows/UiSpellBookWindow";
import {UiController} from "../../../controllers/UiController";
import {ResourcesController} from "../../../controllers/ResourcesController";
import {TilesCollection} from "../../../models/map/TilesCollection";
import {UiBattleField} from "./UiBattleField";
import {UiComponent} from "../../../services/UiComponentsService";
import {BattleService} from "../../../services/BattleService";
import {MapObject} from "../../../models/MapObject";
import {Observable} from 'rxjs/Observable';
import {UiPanel} from "../../UiPanel";
import {Inject} from "../../../helpers/InjectDectorator";

const DEFAULT_PARAMS = <IUiWindowOptions>{
    width: 800,
    height: 600,
    borders: false,
    modal: true
};

@UiComponent()
export class UiBattleWindow extends UiWindow {
    cursor: string;
    scope: any;

    @Inject(ResourcesController) protected resourcesController: ResourcesController;
    private battleField: UiBattleField;

    constructor(leftSideObject: MapObject, rightSideObject: MapObject) {
        super(Utils.extend({}, DEFAULT_PARAMS));

        this.cursor = CURSORS.DEFAULT;

        this.scope = {
            openSettings() {
                UiController.modal(UiSettingsWindow);
            },

            openSpellBook() {
                UiController.modal(UiSpellBookWindow);
            },

            left: {
                object: leftSideObject,
                picture: {
                    iconSprite: leftSideObject.iconSprite,
                    iconSpriteIndex: leftSideObject.iconSpriteIndex
                },

                win: () => {
                    this.resolve('left');
                    this.removeWnd();
                },

                skip: () => {
                    BattleService.skipTurn();
                },

                wait: () => {
                    BattleService.waitTurn();
                }
            },

            right: {
                object: rightSideObject,
                picture: {
                    iconSprite: rightSideObject.iconSprite,
                    iconSpriteIndex: rightSideObject.iconSpriteIndex
                },

                win: () => {
                    this.resolve('right');
                    return this.removeWnd();
                }
            },

            bgSpriteName: this.getBattlefieldBgSpriteName(rightSideObject)
        };

        Promise.all([
            this.resourcesController.loadUiSprite(this.scope.bgSpriteName),
            this.resourcesController.loadUiSprite('morale_up_effect'),
            this.resourcesController.loadUiSprite('morale_down_effect'),
            this.resourcesController.loadUiSprite('luck_effect')
        ]).then(() => {
            this.setMarkup('game/modals/battle/modal.xml');
            this.battleField = <UiBattleField>this.query('battle-field');
        });

        BattleService.endBattleStream
            .takeUntil(Observable.fromEvent(this.events, 'remove'))
            .subscribe(side => {
                this.resolve(side);
                this.removeWnd();
            });
    }

    getCursor() {
        if (this.battleField && this.battleField.isHovered()) {
            return this.cursor;
        } else {
            return UiPanel.prototype.getCursor.call(this);
        }
    }

    private getBattlefieldBgSpriteName(mapObject): string {
        const {x, y, z} = mapObject;
        const [terrain] = Array.from(TilesCollection.getByCoords(x, y, z));

        return `battlefield_${terrain}_0`;
    }
}
 