
import {GenericVisitableObject} from "./GenericVisitableObject";
import {UiController} from "../../../controllers/UiController";
import {HeroObject} from "../hero/HeroObject";
import {IObjectData} from "../../MapObject";

export class KnowledgeTreeObject extends GenericVisitableObject {

    recordVisits = true;

    constructor(data: IObjectData, id: number) {
        super(data, id);

        this.events.on('action', (hero: HeroObject) => {
            // TODO: made confirmation dialog with resources request
            // Utils.randomize
            //   34: showConfirmation.bind(@, null)
            //   33: showConfirmation.bind(@, {gold: 2000})
            //   32: showConfirmation.bind(@, {gems: 10})
            hero.properties.levelUp();
        });
    }

    // showConfirmation(resources) {
    //     UiController.createConfirmMessage('Some tree story', resources)
    //         .then(() => hero.properties.levelUp());
    // }
}
        