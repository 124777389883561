
import * as Utils from "../../utils/Utils";
import {TextService} from "../../services/TextService";
import {Events} from "../../controllers/Events";
import {CURSORS} from "../../constants/CURSORS";
import {UiButton} from "./UiButton";
import {IUiImageOptions} from "./UiImage";
import {UiSprite} from "../sprites/UiSprite";
import {UiController} from "../../controllers/UiController";
import {UiComponent} from "../../services/UiComponentsService";
import {ITextures, Textures} from '../../controllers/Textures';
import {Inject} from '../../helpers/InjectDectorator';

const DEFAULT_PARAMS = <IUiImageOptions>{
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    sprite: null
};

@UiComponent()
export class UiSpriteButton extends UiButton {

    options: IUiImageOptions;
    sprite: UiSprite;

    @Inject(Textures) protected textures: ITextures;
    @Inject(TextService) private textService: TextService;

    constructor(params: IUiImageOptions) {
        super(Utils.extend(DEFAULT_PARAMS, params));

        this.draw = this.draw.bind(this);

        this.sprite = this.readParam('sprite');

        if (typeof this.sprite === 'string') {
            this.sprite = <UiSprite>this.textures.get(<string>this.sprite);
            let {width, height} = this.sprite;
            this.options.width = width;
            this.options.height = height;
            this.__update();
        }

        this.events.on('rightMousedown', () => {
            const uiTipWindow = UiController.createTipWindow(this.textService.i18n(this.options.tipKey));
            Events.one('rightMouseup', () => UiController.remove(uiTipWindow));
        });
    }

    getCursor(): string {
        return CURSORS.POINTER;
    }

    draw() {
        let left = this.getLeft();
        let top = this.getTop();

        // if @isHovered()
        //   state = UI_STATES.HOVER
        // else
        //   state = UI_STATES.IDLE

        return this.sprite.draw(left, top, this.getState(), this.ctx);
    }
};
