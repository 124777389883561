
import * as Utils from "../../utils/Utils";
import {IUiOptions, UiPanel} from "../UiPanel";
import {UiComponent} from "../../services/UiComponentsService";

export interface IUiTextOptions extends IUiOptions {
    fontSize?: string;
    text?: string;
    align?: string;
    color?: string;
}

const DEFAULT_PARAMS = <IUiTextOptions>{
    left: 0,
    top: 0,
    fontSize: '11px',
    color: '#ffffff',
    align: 'center',
    text: null
};

@UiComponent()
export class UiText extends UiPanel {

    options: IUiTextOptions;

    private lines: string[];
    private lineHeight: number;

    constructor(params: IUiTextOptions) {
        const options = Utils.extend(DEFAULT_PARAMS, params);
        super(options);

        this.draw = this.draw.bind(this);
        this.options.text = this.readParam('text');

        this.lineHeight = ~~(parseInt(this.options.fontSize) * 1.4);

        Utils.watch(this.options, 'text', this.updateTextLines.bind(this));
        this.updateTextLines();
        this.setHeight(this.lines.length * this.lineHeight);
    }

    private getString(value: any): string {
        if (value === null || value === undefined) {
            return '';
        }
        return value.toString();
    }

    private updateTextLines(newText: string = '') {
        this.lineHeight = ~~(parseInt(this.options.fontSize) * 1.4);
        const letterWidth = ~~(parseInt(this.options.fontSize) * .8);
        const lettersInLine = ~~(this.width / letterWidth);
        this.lines = [];
        let textBuffer = this.getString(this.options.text);

        while (textBuffer.length > lettersInLine) {
            const matchedResult = textBuffer.match(new RegExp(`(.{0,${lettersInLine}})\\W.*$`));
            const matchedText = matchedResult
                ? matchedResult[1]
                : textBuffer.substring(0, lettersInLine);

            this.lines.push(textBuffer.substring(0, matchedText.length));
            textBuffer = textBuffer.substring(matchedText.length);
        }

        return this.lines.push(textBuffer);
    };

    draw() {
        let textLeft = 0;
        if (this.options.align === 'center') {
            textLeft = this.width >> 1;
        }
        for (let ind = 0; ind < this.lines.length; ind++) {
            let line = this.lines[ind];

            this.text(Utils.extend(this.options, {
                left: textLeft,
                top: (ind * this.lineHeight),
                text: line
            }));
        }
    }
}