
import {Display} from "../../render/layers/Display";
import {UiWindow} from "../common/UiWindow";
import * as Utils from "../../utils/Utils";
import {SecondaryStorage} from "../../models/storages/SecondaryStorage";
import {TextService} from "../../services/TextService";
import {UiController} from "../../controllers/UiController";
import {UiHeroWindow} from "./UiHeroWindow";
import {Events} from "../../controllers/Events";
import {Inject} from "../../helpers/InjectDectorator";

const DEFAULT_PARAMS = {
    width: Math.min(350, Display.offsetWidth),
    height: 350,
    modal: true
};

export class UiLevelUpWindow extends UiWindow {

    @Inject(TextService) protected textService: TextService;

    constructor(hero) {
        super(DEFAULT_PARAMS);

        let factor = (skillName) => hero.properties.getPrimarySkillFactor(hero, skillName);

        let primarySkill = Utils.randomize({
            [`${factor('attack')}a`]() { return {name: 'attack', spriteIndex: 0}; },
            [`${factor('defence')}d`]() { return {name: 'defence', spriteIndex: 1}; },
            [`${factor('power')}p`]() { return {name: 'power', spriteIndex: 4}; },
            [`${factor('knowledge')}k`]() { return {name: 'knowledge', spriteIndex: 5}; }});
        hero.properties.get(primarySkill.name).push(1);

        let selectedSecondary = null;
        let secondarySkills = SecondaryStorage.randomizeTwo(hero);
        let currentHeroLevel = hero.properties.getValue('level');
        let heroName = this.textService.getHeroName(hero.iconSpriteIndex);
        let heroClass = hero.data.config.class;

        this.scope = {
            hero,
            heroPortraitRightMouseDown() {
                const uiTipWindow = UiController.modal(UiHeroWindow, hero);
                Events.one('rightMouseup', () => UiController.remove(uiTipWindow));

                return false;
            },

            heroPortraitLeftClick() {
                UiController.modal(UiHeroWindow, hero);

                return false;
            },

            close: () => {
                if (selectedSecondary) {
                    hero.secondary.push(selectedSecondary);
                }

                this.removeWnd();
            },

            firstLine: `${heroName} has gained a level.`,
            secondLine: `${heroName} is now a level ${currentHeroLevel} ${heroClass}.`,
            primarySkill,
            thirdLine: `${Utils.capitalize(primarySkill.name)} +1`,

            SECONDARY_LEVELS: {
                [1]: 'Basic',
                [2]: 'Advanced',
                [3]: 'Expert'
            },

            secondarySkills: secondarySkills.map(skill => this.textService.i18n(`SKILLS.SECONDARY.${skill}.SHORT`)),

            getTargetLevel: (index: number): number => {
                const secondary = secondarySkills[index];

                return (hero.secondary.get(secondary) || 0) + 1;
            },

            getSecondarySpriteIndex: (index: number): number => {
                const secondary = secondarySkills[index];
                const targetLevel = this.scope.getTargetLevel(index);

                return SecondaryStorage.prototype.getSpriteIndex(secondary, targetLevel);
            },

            secondaryLeftClick: (index: number) => {
                const choices = this.queryAll('secondary-');
                const currentBtn = choices[index];
                const value = !currentBtn.selected;

                for (index = 0; index < choices.length; index++) {
                    const wnd = choices[index];

                    if (wnd.is(currentBtn)) {
                        if ((wnd.selected = value)) {
                            selectedSecondary = secondarySkills[index];
                            this.setCloseBtnDisabled(false);
                        } else {
                            this.setCloseBtnDisabled(true);
                        }
                    } else {
                        wnd.selected = false;
                    }
                }
            }
        };

        this.setMarkup('game/modals/levelUpWindow.xml');

        if (!this.scope.secondarySkills.length) {
            this.setCloseBtnDisabled(false);
        }
    }

    private setCloseBtnDisabled(value: boolean) {
        this.query('close-btn').disabled = value;
    }
}
