
import {UiWindow} from "../common/UiWindow";
import {HeroesCollection} from "../../models/HeroesCollection";
import {TextService} from "../../services/TextService";
import {UiController} from "../../controllers/UiController";
import {UiHeroWindow} from "./UiHeroWindow";
import {Events} from "../../controllers/Events";
import {UiImage} from "../common/UiImage";
import {UiSprite} from "../sprites/UiSprite";
import {UiText} from "../common/UiText";
import {ITextures, Textures} from '../../controllers/Textures';
import {Inject} from '../../helpers/InjectDectorator';

const DEFAULT_PARAMS = {
    width: 395,
    height: 504,
    borders: false,
    modal: true,
    background: 'tavern_modal_bg'
};

export class UiTavernWindow extends UiWindow {

    selectedIndex = -1;
    heroDescription: UiText;
    heroPortraits = [];

    @Inject(Textures) protected textures: ITextures;
    @Inject(TextService) protected textService: TextService;

    constructor(tavern) {
        super(DEFAULT_PARAMS);

        this.scope = {
            close: () => {
                this.reject(null);
                this.removeWnd();
            },

            buyHero: () => {
                // TODO
                tavern.town.spawnHero(this.selectedIndex);
                HeroesCollection.randomizeTavern(this.selectedIndex);
                this.resolve();
                this.removeWnd();
            }
        };

        this.setMarkup('game/modals/tavernWindow.xml').then(() => {
            this.heroDescription = <UiText>this.query('hero-description');
        });

        this.addGuestPortrait(HeroesCollection.tavern[0].iconSpriteIndex, 0);
        this.addGuestPortrait(HeroesCollection.tavern[1].iconSpriteIndex, 1);
    }

    setPortraitButtonSprite(btn: UiImage, person: number) {
        btn.sprite = <UiSprite>this.textures.get('heroes_portraits_lg');
        btn.options.spriteIndex = person;
    };

    getDescriptionText() {
        if (this.selectedIndex === -1) {
            return 'Select Hero';
        } else {
            let person = this.heroPortraits[this.selectedIndex].options.spriteIndex;
            let hero = HeroesCollection.tavern[this.selectedIndex];
            let heroLevel = hero.properties.getValue('level');
            let heroClass = hero.data.config.class;
            return `${this.textService.getHeroName(person)} ${heroLevel} Level ${heroClass}`;
        }
    };

    addGuestPortrait(person: number, index: number) {
        let leftPositions = [72, 162];
        let hero = HeroesCollection.tavern[index];
        const portraitBtn = new UiImage({
            left: leftPositions[index],
            top: 300,
            sprite: <UiSprite>this.textures.get('heroes_portraits_lg'),
            spriteIndex: 256,
            selectable: true,
            parent: this,
            events: {
                'rightMousedown'() {
                    let uiTipWindow = UiController.modal(UiHeroWindow, hero);
                    Events.one('rightMouseup', () => UiController.remove(uiTipWindow));
                    return false;
                },
                'leftClick': () => {
                    let value = !portraitBtn.selected;
                    for (index = 0; index < this.heroPortraits.length; index++) {
                        let wnd = this.heroPortraits[index];
                        if (wnd.is(portraitBtn)) {
                            if ((wnd.selected = value)) {
                                this.selectedIndex = index;
                                this.query('buyBtn').disabled = false;
                            } else {
                                this.selectedIndex = -1;
                                this.query('buyBtn').disabled = true;
                            }
                        } else {
                            wnd.selected = false;
                        }
                    }
                    this.heroDescription.options.text = this.getDescriptionText();
                    return true;
                }
            }
        });

        this.setPortraitButtonSprite(portraitBtn, person);
        this.heroPortraits.push(portraitBtn);
    }
}
