
import {Display} from "../render/layers/Display";
import {CURSORS} from "../constants/CURSORS";
import {SettingsService} from "./SettingsService";
import {HeroObject} from "../models/objects/hero/HeroObject";
import {StateService} from "./StateService";
import { Inject } from "../helpers/InjectDectorator";
import {ISceneMouse, Mouse} from "../controllers/Mouse";
import {ActivePlayer} from "../controllers/ActivePlayer";
import {PlayerModel} from "../models/player/PlayerModel";

const CURSORS_HAS_PATH = [
    CURSORS.HORSE,
    CURSORS.HORSE_ACT,
    CURSORS.ATTACK,
    CURSORS.CHANGING,
    CURSORS.SHIP,
    CURSORS.SHIP_ACT,
    CURSORS.ASHORE
];

const POPUP_STYLES = {
    borderRadius: '2px',
    background: 'rgba(0, 0, 0, 0.7)',
    marginLeft: '-22px',
    marginTop: '16px',
    textAlign: 'center',
    fontSize: '11px',
    position: 'absolute',
    display: 'none',
    color: 'rgb(255, 242, 172)',
    width: '48px',
    left: 0,
    top: 0
};

const HIDDEN = 'url(data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==), auto';
let isHidden = false;

const hasPath = cursor => ~CURSORS_HAS_PATH.indexOf(cursor);

export class CursorService {

    private lastCursor: string = null;
    private infoBlock: HTMLElement = null;
    @Inject(SettingsService) private settingsService: SettingsService;
    @Inject(StateService) private stateService: StateService;
    @Inject(ActivePlayer) private activePlayer: PlayerModel;
    @Inject(Mouse) private mouse: ISceneMouse;

    constructor() {
        this.stateService.subscribe('center', () => {
            this.hideInfo();
        });
    }

    hideInfo() {
        if (this.infoBlock) {
            this.infoBlock.style.display = 'none';
        }
    }

    private getInfoString(mouse: ISceneMouse, selectedObject: HeroObject): string {
        const cursorPathType = this.settingsService.get('cursorPath');
        const movePoints = selectedObject.properties.getValue('movePoints')|0;
        let pathCost = mouse.pathCost|0;
        const pathItem = mouse.path[mouse.path.length - 1];

        if (pathItem.available && pathCost > movePoints) {
            pathCost = movePoints;
        }

        if (['percent', 'days'].includes(cursorPathType)) {
            let percentValue = ~~((pathCost / movePoints) * 100);

            if (pathCost > movePoints) {
                const maxMovePoints = selectedObject.properties.getMaxMovePoints();
                percentValue = 100;
                pathCost -= movePoints;
                percentValue += ~~((pathCost / maxMovePoints) * 100);
            }

            if (cursorPathType === 'percent') {
                return `${percentValue}%`;
            } else if (cursorPathType === 'days') {
                return `${Math.ceil(percentValue / 100)}`;
            }
        }

        return `${pathCost}/${movePoints}`;
    }

    private canShowPathInfo(): boolean {
        const selectedObject = <any>this.activePlayer.selectedObject;
        const hasPathPoints = (this.mouse.pathCost|0) > 0;
        const canShowPathForCursor = hasPath(this.lastCursor);
        const cursorPathIsVisible = this.settingsService.get('cursorPath') !== 'hidden';

        return canShowPathForCursor && selectedObject.isHero && hasPathPoints && cursorPathIsVisible;
    }

    showInfo() {
        if (!this.infoBlock) {
            this.infoBlock = this.createInfoBlock();
        }
        if (!this.canShowPathInfo()) {
            return;
        }

        const {selectedObject} = this.activePlayer;

        Object.assign(this.infoBlock.style, {
            display: 'block',
            left: `${this.mouse.pos[0]}px`,
            top: `${this.mouse.pos[1]}px`
        });

        this.infoBlock.innerHTML = this.getInfoString(this.mouse, <HeroObject>selectedObject);
    }

    private createInfoBlock(): HTMLElement {
        const div = document.createElement('div');
        Display.children[1].appendChild(div);
        Object.assign(div.style, POPUP_STYLES);
        return div;
    }

    hide() {
        isHidden = true;
        this.set(HIDDEN);
    }

    show() {
        isHidden = false;
        this.set(this.lastCursor);
    }

    set(cursor) {
        if (isHidden) {
            cursor = HIDDEN;
        } else {
            this.lastCursor = cursor;
        }
        Display.style.cursor = cursor;
        return this;
    }
}