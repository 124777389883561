
const STACKS_POSITIONS = {
    [0]: [],
    [1]: [5],
    [2]: [2, 8],
    [3]: [2, 5, 8],
    [4]: [0, 4, 6, 10],
    [5]: [0, 2, 5, 8, 10],
    [6]: [0, 2, 4, 6, 8, 10],
    [7]: [0, 2, 4, 5, 6, 8, 10],
    [8]: [0, 2, 3, 4, 6, 7, 8, 10],
    [9]: [0, 2, 3, 4, 5, 6, 7, 8, 10],
    [10]: [0, 1, 2, 3, 4, 5, 6, 7, 8, 10],
    [11]: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
};

export class UiBattleFieldOppositeMixin {

    lineUpStacks(count: number, x: number): [number, number][] {
        return STACKS_POSITIONS[count].map(y => [x, y]);
    }

    opposite(leftCount: number, rightCount: number): [[number, number][], [number, number][]] {
        return [
            this.lineUpStacks(leftCount, 0),
            this.lineUpStacks(rightCount, 14)
        ];
    }
}
