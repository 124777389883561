
export const debounce = (duration: number = 0) => {
    return function innerDecorator (target, key) {

        Object.defineProperty(target, key, {
            configurable: true,
            enumerable: true,
            value: makeDebounce(target[key], duration)
        });
    }
};

const makeDebounce = (method: Function, timeout: number = 0) => {
    let timeoutId;

    function debounceWrapper (...args) {
        clear();

        timeoutId = setTimeout(() => {
            timeoutId = null;
            method.apply(this, args)
        }, timeout)
    }

    function clear() {
        if (timeoutId) {
            clearTimeout(timeoutId);
            timeoutId = null;
        }
    }

    return debounceWrapper
};