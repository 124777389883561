
import {IObjectData, MapObject} from "../MapObject";
import * as Utils from "../../utils/Utils";
import {UiController} from "../../controllers/UiController";
import {MapComponent} from '../MapComponent';

@MapComponent('sign')
export class SignObject extends MapObject {

    canGoInActionPos = false;
    restrictedPoints = true;
    message: string;

    constructor(data: IObjectData, id: number) {
        super(data, id);

        this.doAction = this.doAction.bind(this);
        this.message = Utils.readAsString(this.data.data.message);
    }

    doAction(hero) {
        UiController.alert(this.message);
        return false;
    }
}
