
import {IUiOptions, UiPanel} from "../UiPanel";
import * as Utils from "../../utils/Utils";
import {Events} from "../../controllers/Events";
import {UiComponent} from "../../services/UiComponentsService";

export interface IUiScrollViewOptions extends IUiOptions {
    minLeft?: number;
    minTop?: number;
    maxLeft?: number;
    maxTop?: number;
    xStep?: number;
    yStep?: number;
}

const DEFAULT_PARAMS = <IUiScrollViewOptions>{
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    minLeft: 0,
    minTop: 0,
    maxLeft: -1000,
    maxTop: -1000,
    xStep: 0,
    yStep: 32
};

@UiComponent()
export class UiScrollView extends UiPanel {

    options: IUiScrollViewOptions;

    private internalCtx: CanvasRenderingContext2D;
    private offsetLeft: number;
    private offsetTop: number;
    private xStep: number;
    private yStep: number;

    constructor(params: IUiScrollViewOptions) {
        super(Utils.extend(DEFAULT_PARAMS, params));

        this.draw = this.draw.bind(this);

        this.internalCtx = Utils.makeCtx(this.width, this.height);

        this.setCtx(this.internalCtx);

        this.events.on('children.update', () => {
            this.setCtx(this.internalCtx);
        });

        this.offsetLeft = 0;
        this.offsetTop = 0;
        this.xStep = parseInt(this.readParam('xStep')) || 0;
        this.yStep = parseInt(this.readParam('yStep')) || 0;

        this.events.on('mouseWheel', e => {
            if (Math.abs(e.deltaX) >= 32) {
                this.onWheelEventX(e.deltaX);
            }
            if (Math.abs(e.deltaY) >= 32) {
                this.onWheelEventY(e.deltaY);
            }
        });

        let isDragged = false;
        let dragStartOffsetLeft = 0;
        let dragStartOffsetTop = 0;

        this.events.on('leftMousedown', () => {
            isDragged = true;
            dragStartOffsetLeft = this.offsetLeft;
            dragStartOffsetTop = this.offsetTop;

            Events.one('leftMouseup', () => {
                isDragged = false;
                dragStartOffsetLeft = 0;
                dragStartOffsetTop = 0;
            });
        });

        this.events.on('drag', e => {
            if (!isDragged) {
                return;
            }

            if (this.xStep) {
                this.offsetLeft = e.diffX + dragStartOffsetLeft;
            }

            if (this.yStep) {
                this.offsetTop = e.diffY + dragStartOffsetTop;
            }

            this.dispatchScrollEvent();
        });

        this.events.on('dragEnd', e => {
            this.offsetLeft = Math.max(Math.min( this.offsetLeft, this.options.minLeft), this.options.maxLeft);
            this.offsetTop = Math.max(Math.min( this.offsetTop, this.options.minTop), this.options.maxTop);

            this.dispatchScrollEvent();
        });
    }

    scrollTo(offsetLeft: number, offsetTop: number) {
        this.offsetLeft = offsetLeft;
        this.offsetTop = offsetTop;

        this.dispatchScrollEvent();
    }

    getLeft(): number {
        return this.absoluteLeft + this.offsetLeft;
    }

    getTop(): number {
        return this.absoluteTop + this.offsetTop;
    }

    draw() {
        if (this.hidden) {
            return;
        }

        this.ctx.canvas.width = this.ctx.canvas.width;

        this.children.forEach(wnd => {
            this.ctx.translate(-this.absoluteLeft, -this.absoluteTop);
            return wnd.draw();
        });

        this.ctx.lineWidth = 1;
        this.ctx.strokeStyle = '#000';
        this.ctx.strokeRect(this.absoluteLeft + .5, this.absoluteTop + .5, this.width - 1, this.height - 1);

        this.rootCtx.drawImage(this.ctx.canvas, this.absoluteLeft, this.absoluteTop);
    }

    private dispatchScrollEvent() {
        this.events.dispatch('scroll', {
            offsetLeft: this.offsetLeft,
            offsetTop: this.offsetTop
        });

        this.__handleChildrenEvent('parent.updateSizes');
    }

    private onWheelEventX(delta: number) {
        if (delta > 0) {
            this.offsetLeft = Math.max(this.offsetTop - this.xStep, this.options.maxLeft);
        } else {
            this.offsetLeft = Math.min(this.offsetTop + this.xStep, this.options.minLeft);
        }

        this.dispatchScrollEvent();
    }

    private onWheelEventY(delta: number) {
        if (delta > 0) {
            this.offsetTop = Math.max(this.offsetTop - this.yStep, this.options.maxTop);
        } else {
            this.offsetTop = Math.min(this.offsetTop + this.yStep, this.options.minTop);
        }

        this.dispatchScrollEvent();
    }
}
