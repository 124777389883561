
import {Display} from "../../render/layers/Display";
import {UiPanel} from "../UiPanel";
import {Events} from "../../controllers/Events";
import {RESOURCES} from "../../constants/RESOURCES";
import {TimeService} from "../../services/TimeService";
import {UiSprite} from "../sprites/UiSprite";
import {UiView} from "./UiView";
import {UiComponent} from "../../services/UiComponentsService";
import {StateService} from '../../services/StateService';
import {inject, Inject} from "../../helpers/InjectDectorator";
import {ActivePlayer} from "../../controllers/ActivePlayer";
import {PlayerModel} from "../../models/player/PlayerModel";
import {ITextures, Textures} from '../../controllers/Textures';
import {ActiveView} from '../../controllers/ActiveView';
import {IAbstractView} from '../IAbstractView';

const DEFAULT_PARAMS = {
    left: 3,
    top() { return Display.offsetHeight - 22; },
    width() { return inject<IAbstractView>(ActiveView).getMapWidth(); },
    height: 22
};

const PADDING = 4;

@UiComponent()
export class UiResourcesPanel extends UiPanel {
    private bluePattern: CanvasPattern;
    private resourcePortraits: UiSprite;
    private resources = {};
    private needResourcesRedraw = true;
    private resourcesDrawGrid: [number, number][] = [];

    @Inject(ActivePlayer) protected activePlayer: PlayerModel;
    @Inject(Textures) protected textures: ITextures;
    @Inject(TimeService) protected timeService: TimeService;
    @Inject(StateService) protected stateService: StateService;
    @Inject(ActiveView) protected activeView: UiView;

    constructor() {
        super(DEFAULT_PARAMS);

        this.bluePattern = this.ctx.createPattern(this.textures.get('res_bar_bg').img, 'repeat');
        this.resourcePortraits = <UiSprite>this.textures.get('resources_portraits_sm');

        this.updateSizes();
        this.updateResources();

        this.events.on('leftClick', () => {
            if (!this.activeView.isMobile) {
                return;
            }

            if (this.activeView.sidebarHidden) {
                this.activeView.showSidebar();
            } else {
                this.activeView.hideSidebar();
            }
        });

        this.stateService.subscribe('*.resources', () => {
            this.updateResources();
        });

        Events
            .on('resize', this.updateSizes.bind(this));
    }

    private updateResources() {
        for (let i in RESOURCES) {
            let resource = RESOURCES[i];
            this.resources[resource.name] = this.activePlayer.resources.getValue(resource.name);
        }
        this.needResourcesRedraw = true;
    }

    private updateSizes() {
        this.setTop(this.ctx.canvas.height - 22 - 3);
        this.setWidth(this.ctx.canvas.width - 6);

        this.needResourcesRedraw = true;
    }

    private drawBluePattern(left, top, width, height) {
        this.ctx.translate(this.absoluteLeft, this.absoluteTop);
        this.ctx.fillStyle = this.bluePattern;
        this.ctx.fillRect(left, top, width, height);
        this.ctx.setTransform(1, 0, 0, 1, 0, 0);
    }

    private drawBg() {
        const height = this.height - PADDING;

        this.drawBluePattern(0, 0, this.width, this.height);

        this.resourcesDrawGrid.forEach(([left, right]: [number, number], index: number) => {
            this.rect({
                left: left,
                top: 1,
                width: right - left - 2,
                height: height
            });

            this.resourcePortraits.draw(this.absoluteLeft + left + 1, this.absoluteTop + 3, index, this.ctx);
        });
    }

    private drawResources() {
        for (let i in RESOURCES) {
            const resource = RESOURCES[i];
            const [left, right] = this.resourcesDrawGrid[i];

            this.text({
                text: this.resources[resource.name],
                left: (left + right + 15) >> 1,
                top: 10,
                align: 'center',
                color: '#FFFFFF'
            });
        }

        this.needResourcesRedraw = false;
    }

    private drawTimePanel(ctx: CanvasRenderingContext2D) {
        const height = this.height - PADDING;

        this.drawBluePattern(this.width - 187, 0, 187, this.height);

        this.rect({
            left: this.width - 187,
            top: 1,
            width: 183,
            height: height
        });

        this.text({
            text: this.timeService.getText(),
            left: this.width - 94,
            top: 10,
            align: 'center',
            color: '#FFFFFF'
        });
    }

    private updateResourcesDrawGrid() {
        const width = this.activeView.isMobile
            ? this.width - (PADDING * 2)
            : this.width - 183 - (PADDING * 2);
        const cellsCount = Object.keys(RESOURCES).length;

        this.resourcesDrawGrid = [];

        for (let i = 0; i < cellsCount; i++) {
            const isLast = i === cellsCount - 1;
            const currentCellWidth = isLast
                ? Math.floor(Math.min(100, width * .19))
                : Math.floor(Math.min(100, width * .135));
            const lastItem = this.resourcesDrawGrid[i - 1] || [PADDING, PADDING];
            const left = lastItem[1];
            const right = left + currentCellWidth;

            this.resourcesDrawGrid.push([left, right]);
        }
    }

    staticDraw() {
        this.updateResourcesDrawGrid();
        this.drawBg();
    }

    draw() {
        // if (this.needResourcesRedraw) {
            this.drawResources();
        // }
        if (!this.activeView.sidebarHidden) {
            this.drawTimePanel(this.ctx);
        }
    }
}