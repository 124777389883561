import {ArtifactsStorage, IArtifactData} from '../../storages/ArtifactsStorage';
import {HeroObject, IArtifactsStorage, IObjectHeroData} from './HeroObject';
import {CatapultObject} from '../battlefield/CatapultObject';
import {Maybe} from '../../../helpers/Maybe';
import {SpellBookObject} from '../battlefield/SpellBookObject';
import {ArtifactsData} from '../../../controllers/ArtifactsData';
import {inject} from '../../../helpers/InjectDectorator';

const SLOTS = [
    'device1',
    'device2',
    'device3',
    'device4',
    'misc1',
    'misc2',
    'misc3',
    'misc4',
    'misc5',
    'left_hand',
    'right_hand',
    'left_ring',
    'right_ring',
    'headwear',
    'neck',
    'shoulders',
    'torso',
    'feet'
];

export class HeroArtifactsStorage extends ArtifactsStorage {

    catapult: CatapultObject;
    spellbook: SpellBookObject;

    constructor(private hero: HeroObject) {
        super();

        this.addCatapult();
        this.addArtifactsFromConfig();
    }

    private addCatapult() {
        this.catapult = new CatapultObject();

        this.add(this.catapult);
    }

    private getArtifactsDataFromConfig(): IArtifactsStorage {
        const data = <IObjectHeroData>this.hero.data;

        return Maybe(data).pluck('data.artifacts.worn').getOrElse(null);
    }

    private addArtifactFromStorage(storage: IArtifactsStorage, slot: string) {
        inject<Map<number, IArtifactData>>(ArtifactsData)
        if (storage[slot] !== undefined) {
            const artifact = {artifactData: this.getDataById(storage[slot])};

            this.addToSlot(artifact, slot);
        }
    };

    private addArtifactsFromConfig() {
        const artifacts = this.getArtifactsDataFromConfig();

        if (!artifacts) {
            return;
        }

        if (artifacts.spellbook !== undefined) {
            this.spellbook = new SpellBookObject();

            this.addToSlot(this.spellbook, 'spellbook');
        }

        SLOTS.forEach(slot => {
            this.addArtifactFromStorage(artifacts, slot);
        });

        if (Array.isArray(artifacts.backpack)) {
            artifacts.backpack.forEach(artifactId => {
                const artifact = {artifactData: this.getDataById(artifactId)};

                this.addToSlot(artifact, 'belt');
            })
        }
    }
}