
import {UiController} from "./UiController";
import {ResourcesController} from "./ResourcesController";
import {Render} from "../render/Render";
import {ControllerHelper} from "../helpers/ControllerHelper";
import {Events} from "./Events";
import {UiMapsListView} from "../views/mainMenu/UiMapsListView";
import {UiWindow} from "../views/common/UiWindow";
import {Inject} from "../helpers/InjectDectorator";
import {FocusedWindow} from "./FocusedWindow";

export class NewGameController extends ControllerHelper {

    ui = UiController;

    @Inject(FocusedWindow) protected focusedWindow: UiWindow;
    @Inject(ResourcesController) private resourcesController: ResourcesController;
    @Inject(Render) private render: Render;

    constructor() {
        super();

        this.resourcesController.storage.set('repositoriesList', this.resourcesController.loadMapsList());

        UiController.bootstrap(UiMapsListView);

        [
            'leftMousedown',
            'leftMouseup',
            'leftClick',
            'mouseWheel',
            'rightMousedown',
            'rightMouseup',
            'rightClick',
            'drag',
            'dragEnd'
        ].forEach(eventType =>
            Events.on(eventType, (e) => {
                if (this.focusedWindow) {
                    this.focusedWindow.events.dispatch(eventType, e);
                }
            })
        );

        this.render.setSource({'ui': [UiController.prepare, UiController.draw]});
    }

    static resolve() {
        return [];
    }
}