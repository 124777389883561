
enum RESPONSE_TYPE {
    TEXT = 'text',
    JSON = 'json'
}

interface IHttpOptions {
    method?: string;
    url: string;
    type?: string;
}

type IResponse = string | {};

export class HttpService {

  load(options: IHttpOptions): Promise<IResponse> {
      const method = options.method || 'GET';
      const url = options.url || '';
      const responseType = options.type || RESPONSE_TYPE.TEXT;

      return fetch(url, {method}).then(response => {
          switch (responseType) {
              case RESPONSE_TYPE.JSON:
                  return response.json();
              case RESPONSE_TYPE.TEXT:
                  return response.text();
          }
      });
  }

}