import {SettingsService} from "../../services/SettingsService";
import { Inject } from "../../helpers/InjectDectorator";

class DisplayController {
    @Inject(SettingsService) private settingsService: SettingsService;

    constructor(public element: HTMLElement) {
        element.setAttribute('data-smoothing', this.settingsService.get('smoothing'));
    }
}

export const Display = new DisplayController(document.querySelector('#display')).element;