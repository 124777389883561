
import {IObjectData, MapObject} from "../../MapObject";
import {ResourcesStorage} from "../../storages/ResourcesStorage";
import {ArtifactsStorage} from "../../storages/ArtifactsStorage";

export class GenericTreasureObject extends MapObject {

    dynamic = true;
    // TODO check this only for disappearable objects
    gridDynamic = true;
    renderDynamic = true;

    resources = new ResourcesStorage();
    artifacts = new ArtifactsStorage();

    constructor(data: IObjectData, id: number) {
        super(data, id);
    }

    flushResources(hero) {
        return this.resources.transfer(hero.owner.resources);
    }
}
