
import {GenericVisitableObject} from "./GenericVisitableObject";
import {IObjectData} from "../../MapObject";

export class LearningStoneObject extends GenericVisitableObject {

    recordVisits = true;

    constructor(data: IObjectData, id: number) {
        super(data, id);

        this.events.on('action', (hero) => {
            hero.properties.get('experience').push(1000);
        });
    }
}
