
import {UrlParamsService} from "../../services/UrlParamsService";
import {IObjectData, MapObject} from "../MapObject";
import {MapDrawer} from "../../render/MapDrawer";
import {ObjectsCollection} from "../ObjectsCollection";
import {Inject} from "../../helpers/InjectDectorator";
import {MapComponent} from '../MapComponent';

@MapComponent('event')
export class EventObject extends MapObject {

    canGoInActionPos = true;

    private debug = false;

    @Inject(MapDrawer) protected mapDrawer: MapDrawer;
    @Inject(UrlParamsService) protected urlParamsService: UrlParamsService;
    @Inject(ObjectsCollection) protected objectsCollection: ObjectsCollection;

    constructor(data: IObjectData, id) {
        super(data, id);

        this.urlParamsService.subscribe(() => {
            this.debug = this.urlParamsService.params.hasOwnProperty('debug');
        });

        this.events.on('action', hero => {
            console.log('EventObject: event fired', this);
            this.objectsCollection.remove(this);
        });
    }

    get passableMode(): boolean {
        return true;
    }

    draw(ctx: CanvasRenderingContext2D) {
        if (this.debug) {
            const left = this.mapDrawer.left(this.objectLeft);
            const top = this.mapDrawer.top(this.objectTop);

            ctx.fillStyle = "rgba(76,27,84,0.8)";
            ctx.fillRect(left, top, 32, 32);

            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.font = "14 Verdana";
            ctx.fillStyle = 'white';
            return ctx.fillText('Event', left + 16, top + 16);
        } else {
            return true;
        }
    }
};