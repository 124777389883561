
import {GenericVisitableObject} from "./GenericVisitableObject";
import {ResourcesStorage} from "../../storages/ResourcesStorage";
import {UiController} from "../../../controllers/UiController";
import * as Utils from "../../../utils/Utils";
import {IObjectData} from "../../MapObject";
import {StateService} from '../../../services/StateService';
import {Inject} from "../../../helpers/InjectDectorator";

export class MysticalGardenObject extends GenericVisitableObject {

    recordVisits = true;
    clearVisitsEveryWeek = true;
    resources = new ResourcesStorage();

    @Inject(StateService) protected stateService: StateService;

    constructor(data: IObjectData, id: number) {
        super(data, id);

        this.initRandomStuff = this.initRandomStuff.bind(this);

        this.events.on('action', hero => {
            if (this.hasResources()) {
                UiController.statusWindow.resourceTransferMessage(this.resources);
                this.flushResources(hero);
                return true;
            } else {
                return false;
            }
        });

        this.initRandomStuff();

        this.stateService.subscribe('time.week', () => {
            this.initRandomStuff();
        });
    }

    initRandomStuff() {
        Utils.randomize({
            [50]: () => this.resources.set({gold: 500}),
            [49]: () => this.resources.set({gems: 5})
        });
    }

    flushResources(hero) {
        this.resources.transfer(hero.owner.resources);
    }

    hasResources(): boolean {
        return !this.resources.isEmpty();
    }
}
