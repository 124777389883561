
import {TERRAINS} from "./TERRAINS";

export const MAP_COLORS = {
    [TERRAINS.DIRT]: [[132, 115, 49], [99, 82, 33]], 
    [TERRAINS.SAND]: [[222, 206, 140], [165, 156, 107]], 
    [TERRAINS.GRASS]: [[0, 66, 0], [0, 49, 0]], 
    [TERRAINS.SNOW]: [[181, 198, 198], [140, 156, 156]], 
    [TERRAINS.SWAM]: [[74, 132, 107], [33, 90, 66]], 
    [TERRAINS.STONES]: [[132, 115, 49], [99, 82, 33]], 
    [TERRAINS.DUNGEON]: [[132, 49, 0], [90, 8, 0]], 
    [TERRAINS.LAVA]: [[74, 74, 74], [41, 41, 41]], 
    [TERRAINS.WATER]: [[8, 82, 148], [0, 41, 107]], 
    [TERRAINS.EDGE]: [[0, 0, 0], [0, 0, 0]]
};

