
import {UiPanel} from "./UiPanel";

export class UiPanelQueryMixin {

    query(selector: string): UiPanel {
        return this.queryByChildren(<UiPanel><any>this, selector)[0];
    }

    queryAll(selector: string): UiPanel[] {
        return this.queryByChildren(<UiPanel><any>this, selector);
    }

    wndMatches(wnd: UiPanel, selector: string): boolean {
        return !!~wnd.id.indexOf(selector);
    }

    queryByChildren(wnd: UiPanel, selector: string): UiPanel[] {
        let results = [];
        wnd.children.forEach(child => {
            if (this.wndMatches(child, selector)) {
                results.push(child);
            }
            results.push(...this.queryByChildren(child, selector));
        });

        return results;
    }
}
