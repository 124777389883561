
import {UiWindow} from "../common/UiWindow";
import {Display} from "../../render/layers/Display";
import {CursorService} from "../../services/CursorService";
import {CURSORS} from "../../constants/CURSORS";
import * as Utils from "../../utils/Utils";
import {ResourcesController} from "../../controllers/ResourcesController";
import {UiSprite} from "../sprites/UiSprite";
import {UiPanel} from "../UiPanel";
import {UiText} from "../common/UiText";
import {UiCheckbox} from "../common/UiCheckbox";
import {UiController} from "../../controllers/UiController";
import {ITextures, Textures} from '../../controllers/Textures';
import {Inject} from '../../helpers/InjectDectorator';

interface IFilterConfig {
    title: string;
    check: (filterValue: any, item: any) => boolean;
    getter: (value: any) => any;
    sort: (a: any, b: any) => number;
    formatter: (value: any) => any;
    values: Set<any>;
}

interface IFilters {
    [key: string]: IFilterConfig;
}

const FILTERS = <IFilters>{
    version: {
        title: 'Version',
        check(filterValue, item) { return filterValue === item.version; },
        getter(value) { return String(value); },
        sort(a, b) { return a < b ? 1 : -1; },
        formatter(value) { return value; },
        values: new Set()
    },
    map_size: {
        title: 'Map size',
        check(filterValue, item) { return filterValue === item.map_size; },
        getter(value) { return Number(value); },
        sort(a, b) { return a < b ? 1 : -1; },
        formatter(value) { return value; },
        values: new Set()
    },
    has_caves: {
        title: 'Has caves',
        check(filterValue, item) { return filterValue === item.has_caves; },
        getter(value) { return Number(value); },
        sort(a, b) { return a < b ? 1 : -1; },
        formatter(value) { return ['No', 'Yes'][+value]; },
        values: new Set()
    },
    win_condition: {
        title: 'Victory condition',
        check(filterValue, item) { return filterValue === item.win_condition.type; },
        getter(value) { return Number(value.type); },
        sort(a, b) { return a < b ? 1 : -1; },
        formatter(value) { return value; },
        values: new Set()
    },
    lose_condition: {
        title: 'Lose condition',
        check(filterValue, item) { return filterValue === item.lose_condition.type; },
        getter(value) { return Number(value.type); },
        sort(a, b) { return a < b ? 1 : -1; },
        formatter(value) { return value; },
        values: new Set()
    },
    difficulty: {
        title: 'Difficulty',
        check(filterValue, item) { return filterValue === item.difficulty; },
        getter(value) { return Number(value); },
        sort(a, b) { return a < b ? 1 : -1; },
        formatter(value) { return ['Very Simple', 'Simple', 'Trivial', 'Normal', 'Hard', 'Imposible'][+value]; },
        values: new Set()
    },
    repository: {
        title: 'Repository',
        check(filterValue, item) { return filterValue === item.repository; },
        getter(value) { return Number(value); },
        sort(a, b) { return a < b ? 1 : -1; },
        formatter(value) { return value; },
        values: new Set()
    }
};

const BUTTON_HEIGHT = 26;

export class UiNewGame extends UiWindow {
    private uiWidth: number;
    private uiHeight: number;
    private appliedFilters: {};
    private mainmenubg: UiSprite;
    private filters: IFilters;
    private filterElements: UiPanel[];
    private uiMapsList: UiPanel;
    private uiSubHeader: UiPanel;
    private uiRightSide: UiPanel;

    scope: any;
    private fullMapsList: any;
    @Inject(Textures) protected textures: ITextures;
    @Inject(CursorService) protected cursorService: CursorService;
    @Inject(ResourcesController) private resourcesController: ResourcesController;

    constructor() {
        const uiWidth = Math.min(Display.offsetWidth, 900);
        const uiHeight = Math.min(Display.offsetHeight, 800);
        const options = {
            width: uiWidth,
            height: uiHeight,
            centered: true,
            pattern: 'panel_colored_bg'
        };

        super(options);

        this.draw = this.draw.bind(this);
        this.uiWidth = uiWidth;
        this.uiHeight = uiHeight;

        this.appliedFilters = {};

        this.scope = {
            getWidth() { return Math.min(Display.offsetWidth, 900); },
            getHeight() { return Math.min(Display.offsetHeight, 800); },

            mapsList: [],

            header: {
                height: 100,
                mapsCount: `Maps: ${0}`
            },

            leftSide: {
                isHidden: () => {
                    return Display.offsetWidth < 800;
                },
                getRight: () => {
                    if (Display.offsetWidth >= 800) {
                        return 265;
                    } else {
                        return 15;
                    }
                }
            }
        };

        this.mainmenubg = <UiSprite>this.textures.get('main_menu_bg');
        this.filters = null;
        this.filterElements = [];

        this.cursorService.set(CURSORS.DEFAULT);

        this.setMarkup('mainMenu/newGame.xml').then(() => {
            this.uiMapsList = this.query('maps-list');
            this.uiSubHeader = this.query('maps-count');
            this.uiRightSide = this.query('right-side');
            return this.loadMapsList();
        });
    }

    draw() {
        const left = (Display.offsetWidth - 1920) / 2;
        const top = (Display.offsetHeight - 1080) / 2;
        this.mainmenubg.draw(left, top);

        UiWindow.prototype.draw.call(this);

        this.line({
            left: 12,
            top: 98,
            width: this.uiWidth - 24
        });
    }

    private getFilters(): IFilters {
        const result = Utils.clone<IFilters>(FILTERS);

        this.scope.mapsList.forEach(({props}) => {
            for (let key in result) {
                let filter = result[key];
                filter.values.add(filter.getter(props[key]));
            }
        });

        return result;
    }

    private toggleFilter(filter, value) {
        if (this.appliedFilters[filter] === value) {
            delete this.appliedFilters[filter];
        } else {
            this.appliedFilters[filter] = value;
        }

        this.scope.mapsList = this.fullMapsList.filter(({props}) => {
            for (let key in this.appliedFilters) {
                value = this.appliedFilters[key];
                if (FILTERS[key].getter(props[key]) !== value) { return false; }
            }
            return true;
        });
        this.updateSubHeader();
        this.uiMapsList.events.dispatch('listUpdated');
    }

    private updateSubHeader() {
        let text = `Maps: ${this.scope.mapsList.length}, Filters: ${JSON.stringify(this.appliedFilters)}`;

        Object.assign(this.uiSubHeader.options, {text});
    }

    private updateFilters() {
        this.updateSubHeader();

        this.filters = this.getFilters();
        this.filterElements.forEach(UiController.remove);
        this.filterElements = [];
        let filterItemsCount = 0;

        for (let key in this.filters) {
            let filterItem = this.filters[key];

            if (filterItem.values.size < 2) {
                continue;
            }

            const button = new UiText({
                left: 20,
                right: 0,
                top: (BUTTON_HEIGHT * filterItemsCount) + 12,
                height: BUTTON_HEIGHT,
                text: filterItem.title,
                parent: this.uiRightSide,
                align: 'left',
                fontSize: '14px',
                color: '#f8e57e'
            });

            this.filterElements.push(button);
            filterItemsCount++;

            const values = Array.from(filterItem.values).sort(filterItem.sort);

            values.forEach(value => {
                const checkbox = new UiCheckbox({
                    checked: false,
                    group: filterItem.title,
                    left: 20,
                    right: 20,
                    top: (BUTTON_HEIGHT * filterItemsCount),
                    height: BUTTON_HEIGHT,
                    text: filterItem.formatter(value),
                    parent: this.uiRightSide,
                    events: {
                        'leftClick': this.toggleFilter.bind(this, key, value)
                    }
                });

                this.filterElements.push(checkbox);
                filterItemsCount++;
            });
        }
    }

    private loadMapsList() {
        const repositories = <any[]>this.resourcesController.storage.get('repositoriesList');

        repositories.forEach(repository => {
            repository.promise
                .then(data => {
                    const additionalMapsList = data.map(item => Object.assign(item, {repository}));
                    this.fullMapsList = this.scope.mapsList = this.scope.mapsList.concat(additionalMapsList);

                    this.uiMapsList.events.dispatch('listUpdated');
                    this.updateFilters();
                })
                .catch(error => console.error(error));
        });
    }
}