
import {UiController} from "../../controllers/UiController";
import * as Utils from "../../utils/Utils";
import {ObjectsCollection} from "../ObjectsCollection";
import {IObjectData, MapObject} from "../MapObject";
import {EventsStorage} from "../storages/EventsStorage";
import {inject} from "../../helpers/InjectDectorator";

export class TeleportGateMixin {
    canGoInActionPos = true;
    targetGateClass: number;
    objectSubClass: number;
    data: IObjectData;

    events: EventsStorage;

    initGate() {
        this.events.on('action', hero => {
            const gate = this.findGate();

            if (gate) {
                this.transferTo(hero, gate);
            } else {
                UiController.alert('Unfortunately, but this gate is broken');
            }
        });
    }

    isGateMatches(gate: MapObject): boolean {
        return (gate.objectClass === this.targetGateClass)
            && (gate.objectSubClass === this.objectSubClass)
            && !gate.is(<MapObject><any>this);
    }

    findGate(): MapObject {
        const availableGates = [];
        inject<ObjectsCollection>(ObjectsCollection).forEach('generic_visitable', gate => {
            if (this.isGateMatches(gate)) {
                return availableGates.push(gate);
            }
        });
        let result = null;
        if (availableGates.length) {
            result = Utils.something(availableGates);
        }
        return result;
    }

    getActionPointOffset(): number {
        const bitwiseMask = this.data.actions[this.data.actions.length - 1];

        return Math.log2(bitwiseMask) - 6;
    }

    transferTo(hero, gate) {
        let {x, y, z} = gate;
        const {id} = hero;

        x += this.getActionPointOffset();
        hero.owner.command('heroes.teleport', {id, x, y, z});
    }
}