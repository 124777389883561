
import * as Utils from "../utils/Utils";
import {UiPanel} from "./UiPanel";
import {UiComponentsService} from "../services/UiComponentsService";
import {Inject} from '../helpers/InjectDectorator';
import {MarkupData} from '../controllers/MarkupData';

enum TAG {
    NAME = '#name',
    OPTIONS = '#options',
    CHILDREN = '#children',
}

interface ITagOptions {
    [key: string]: any;
}

interface ITag {
    [TAG.NAME]: string;
    [TAG.OPTIONS]: ITagOptions,
    [TAG.CHILDREN]: ITag[]
}

interface IMarkup {
    [key: string]: ITag;
}

const DEFAULTS = {
    [TAG.NAME]: 'uiPanel',
    [TAG.OPTIONS]: {},
    [TAG.CHILDREN]: []
};

export class TemplateParser {

    @Inject(MarkupData) private markupData: IMarkup;

    setMarkup(element: UiPanel, filename: string): Promise<void> {
        element.rootContext = element;

        const config = this.markupData[filename];

        this.parseSequence(config['#children'], element);

        return Promise.resolve();
    }

    private parseSequence(sequence: ITag[], parent: UiPanel, additionalScope = {}) {
        if (parent.canHaveChildren) {
            for (let item of sequence) {
                this.parseItem(item, parent, additionalScope);
            }
        }
    }

    private getTagName(tag: ITag): string {
        return Utils.capitalize(tag[TAG.NAME]);
    }

    private getTagChildren(tag: ITag): ITag[] {
        return tag['#children'] || [];
    }

    private getTagOptions(tag: ITag): ITagOptions {
        return this.interpolateOptions(tag[TAG.OPTIONS]) || {};
    }

    private parseChildren(tag: ITag, wnd: UiPanel) {
        if (Array.isArray(wnd.repeatItems)) {
            for (let $index = 0; $index < wnd.repeatItems.length; $index++) {
                let $item = wnd.repeatItems[$index];
                this.parseSequence(this.getTagChildren(tag), wnd, {$item, $index});
            }
        } else {
            this.parseSequence(this.getTagChildren(tag), wnd);
        }
    }

    private parseItem(tag: ITag, parent: UiPanel, additionalScope = {}) {
        tag = Utils.extend(DEFAULTS, tag);
        const Constructor = UiComponentsService.get(this.getTagName(tag));
        const options = this.getTagOptions(tag);

        if (typeof Constructor !== 'function') {
            throw Error(`Markup error: Constructor for tag ${tag['#name']} is not found`);
        }

        options.scope = additionalScope;
        options.parent = parent;
        let wnd = new Constructor(options);
        this.bindEventsFromOptions(wnd, options);
        if (this.getTagChildren(tag).length) {
            this.parseChildren(tag, wnd);
            wnd.__update();

            if (options.updatesOn) {
                wnd.events.on(options.updatesOn, () => {
                    wnd.removeChildren();
                    this.parseChildren(tag, wnd);
                    wnd.__update();
                });
            }
        }
        return true;
    }

    private parseTypes(sequence: ITag[]): string[] {
        let set = new Set();
        for (let item of Array.from(sequence)) {
            let tagName = this.getTagName(item);
            if (tagName !== 'Root') {
                set.add(tagName);
            }
            if (Array.isArray(this.getTagChildren(item))) {
                this.parseTypes(this.getTagChildren(item)).forEach(set.add.bind(set));
            }
        }
        let results = [];
        set.forEach(item => results.push(item));
        return results;
    }

    private isExpression(value: string): boolean {
        if (typeof value === 'string') {
            return value.startsWith('{') && value.endsWith('}');
        } else {
            return false;
        }
    }

    private getExpressionBody(value: string): string {
        return value.substring(1, value.length - 1);
    }

    private interpolationHandler(expression: string): any {
        return function() {
            let result;
            try {
                result = window['$w']((<UiPanel><any>this).rootContext.scope, expression);
            } catch (e) {
                console.error(e, (<UiPanel><any>this).rootContext.scope);
                throw new Error(`[templateParser]: An Error occurred for expression {${expression}}`);
            }
            return result;
        };
    }

    private interpolateOptions(options: ITagOptions): ITagOptions {
        for (let key in options) {
            let value = options[key];
            value = typeof value === 'string' ? value.trim() : value;
            if (this.isExpression(value)) {
                let expression = this.getExpressionBody(value);
                options[key] = this.interpolationHandler(expression);
            }
        }
        return options;
    }

    private bindEventsFromOptions(wnd: UiPanel, options: ITagOptions) {
        for (let key in options) {
            let value = options[key];

            if (key.indexOf(':') === 0) {
                let eventName = key[1].toLowerCase() + key.slice(2);

                wnd.events.on(eventName, value.bind(wnd));
            }
        }
    }
}