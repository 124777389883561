
import {Events} from "../../controllers/Events";
import {UiWindow} from "../common/UiWindow";
import * as Utils from "../../utils/Utils";
import {IAbstractView} from '../IAbstractView';
import {inject} from '../../helpers/InjectDectorator';
import {ActiveView} from '../../controllers/ActiveView';

export class UiTownTipWindow extends UiWindow {

    constructor(town, options = {}) {
        let state;
        let windowWidth = 194;
        let windowHeight = 186;
        const activeView = inject<IAbstractView>(ActiveView);

        let mousePos = Events.getMousePos();
        let maxLeft = ~~(windowWidth / 2) + 30;
        let maxRight = activeView.getWindowWidth() - maxLeft;
        let maxTop = ~~(windowHeight / 2) + 30;
        let maxBottom = activeView.getWindowHeight() - maxTop;
        let centerLeft = Math.min(Math.max(mousePos.x, maxLeft), maxRight);
        let centerTop = Math.min(Math.max(mousePos.y, maxTop), maxBottom);

        let left = ~~(centerLeft - (windowWidth / 2));
        let top = ~~(centerTop - (windowHeight / 2));

        super(Utils.extend({
                left,
                top,
                width: windowWidth,
                height: windowHeight,
                centered: false,
                borders: false,
                background: 'town_modal',
                hideCursor: true
            }
            , options)
        );

        if (town.hasFort()) {
            state = 2;
        } else {
            state = 0;
        }

        let goldIncrement = 0;

        this.scope = {
            interface: {
                info: {
                    iconSpriteIndex: ((town.race || 0) * 4) + state,
                    name: town.name,
                    goldIncrement
                }
            },

            getStorages() {
                return [
                    (town.heroes[1] && town.heroes[1].creatures) || town.creatures
                ];
            }
        };

        setTimeout(() => {
            this.setMarkup('game/popups/town.xml');
        })
    }
}
