
import {ObjectSprite} from "./ObjectSprite";
import {Layers} from "../../render/layers/Layers";
import {Inject} from '../../helpers/InjectDectorator';

// FIXME: looks like better way is extending from base Sprite class
export class UiSprite extends ObjectSprite {

    @Inject(Layers) protected layers: Layers;

    constructor(key: string, path: string = null) {
        super(key, path);
        this.draw = this.draw.bind(this);
    }

    draw(x: number, y: number, spriteIndex = 0, targetCtx: CanvasRenderingContext2D = null) {
        if (targetCtx === null) {
            targetCtx = this.layers.uiCanvas.ctx
        }

        spriteIndex = spriteIndex || this.spriteIndex;
        let dims = this.getSpriteDims(spriteIndex);
        let sx = (dims.x * this.width);
        let sy = dims.y * this.height;
        let sw = this.width;
        let sh = this.height;
        let dx = x;
        let dy = y;
        let dw = this.width;
        let dh = this.height;

        targetCtx.drawImage(this.img, sx, sy, sw, sh, dx, dy, dw, dh);
    }
}
