
import {SecondaryStorage} from "../../storages/SecondaryStorage";
import * as Utils from "../../../utils/Utils";
import {HeroPropertiesStorage} from "../../storages/HeroPropertiesStorage";
import {CreaturesStorage} from "../../storages/CreaturesStorage";
import {HeroObject, IObjectHeroData} from "./HeroObject";
import {HeroArtifactsStorage} from './HeroArtifactsStorage';

export class HeroStoragesMixin {

    properties: HeroPropertiesStorage;
    race: number;
    artifacts: HeroArtifactsStorage;
    secondary: SecondaryStorage;
    creatures: CreaturesStorage;

    __initStorages(hero: HeroObject) {
        const data = <IObjectHeroData>hero.data;

        this.race = data.config.race;
        this.artifacts = new HeroArtifactsStorage(hero);
        this.creatures = new CreaturesStorage(this.race);

        if (data.data.creatures) {
            this.creatures.fromConfig(data.data.creatures);
        } else {
            const heroData = (this as any).heroesData[data.data.person];
            const stacks = Utils.some(heroData.stacks, 2);

            this.creatures.randomizeFromConfig(stacks);
            this.artifacts.fromCreaturesConfig(stacks);
        }

        const secondarySkills = data.data.secondary_skills;

        if (secondarySkills && secondarySkills.length) {
            this.secondary = SecondaryStorage.fromData(secondarySkills);
        } else {
            this.secondary = SecondaryStorage.fromConfig(data.config);
        }

        this.properties = new HeroPropertiesStorage(hero);
    }
}
    