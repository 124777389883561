
import {DIRECTIONS} from "../../constants/DIRECTIONS";
import {Events} from "../../controllers/Events";
import {MapObject} from "../MapObject";
import {CURSORS} from "../../constants/CURSORS";
import {HeroObject} from "./hero/HeroObject";
import {ObjectSprite} from "../../views/sprites/ObjectSprite";
import {MapModel} from "../map/MapModel";
import {MapComponent} from '../MapComponent';

@MapComponent('boat')
export class ShipObject extends MapObject {

    direction = DIRECTIONS.EE;
    defaultAnimation = `${DIRECTIONS.EE}_IDLE`;
    passenger: HeroObject;
    canGoInActionPos = false;
    dynamic = true;
    gridDynamic = true;
    renderDynamic = true;
    isBoat = true;
    defaultSprite: ObjectSprite;
    movementType = 'water';

    constructor(data, id) {
        super(data, id);
        this.doAction = this.doAction.bind(this);
        this.draw = this.draw.bind(this);
        this.defaultSprite = this.sprite;
        if (this.defaultSprite) {
            this.defaultSprite.setAnimation(this.defaultAnimation);
        }
    }

    setPassenger(passenger) {
        this.passenger = passenger;
        if (!this.passenger) {
            // FIXME this is bad solution
            setTimeout(() => {
                    MapModel.grid.dynamic.set(this.x - 1, this.y, this.id);
                    Events.dispatch('dynamicObjectsChanged');
                }
                , 1000);
        }
        Events.dispatch('dynamicObjectsChanged');
    }

    doAction(hero) {
        if (this.passenger) {
            if (!this.passenger.is(hero)) {
                return this.passenger.events.dispatch('action', this);
            } else {
                return false;
            }
        } else {
            hero.attachTransport(this);
            this.setPassenger(hero);
            return true;
        }
    }

    draw(ctx) {
        if (!this.passenger) {
            return MapObject.prototype.draw.call(this, ctx);
        }
    }

    getActionCursor() {
        return CURSORS.SHIP_ACT;
    }
}