
import {UiWindow} from "../common/UiWindow";
import {Display} from "../../render/layers/Display";
import {CreaturesStorage} from '../../models/storages/CreaturesStorage';
import {Inject} from '../../helpers/InjectDectorator';
import {TextService} from '../../services/TextService';
import {ICreatureData} from '../../controllers/MonstersData';

interface ICreatureWindowConfig {
    creatures: CreaturesStorage;
}

interface ICreatureItem {
    name: string;
    data: ICreatureData;
    quantity: number;
}

const DEFAULT_PARAMS = {
    width: Math.min(620, Display.offsetWidth),
    height: Math.min(595, Display.offsetHeight),
    modal: true
};

export class UiCreatureWindow extends UiWindow {

    @Inject(TextService) protected textService: TextService;

    constructor(config: ICreatureWindowConfig) {
        super(DEFAULT_PARAMS);

        const creatureItems = this.getCreatureItems(config.creatures);

        this.scope = {
            header: 'Recruit',
            creatures: creatureItems,
            selectedItem: creatureItems[creatureItems.length - 1],

            close: () => this.removeWnd()
        };

        this.setMarkup('game/modals/creature.xml');
    }

    private getCreatureItems(creaturesStorage: CreaturesStorage): ICreatureItem[] {
        const result: ICreatureItem[] = [];

        creaturesStorage.forEach((position: number, creatureId: number, quantity: number) => {
            result.push({
                name: this.textService.i18n(`MONSTERS.${creatureId}.PLURAL`),
                data: creaturesStorage.getCreatureData(creatureId),
                quantity
            });
        });

        result.sort((a: ICreatureItem, b: ICreatureItem) => {
            return a.data.aICost > b.data.aICost ? 1 : -1;
        });

        return result;
    }
}
