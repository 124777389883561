
/* virtual class to make simple links to often user resources */
import {IPath} from "../services/PathFinderService";

export class Mouse {

}

export interface ISceneMouse {
    pos: [number, number];
    tileCoords: [number, number, number];
    pathLength: number;
    pathCost: number;
    path: IPath;
}