
import {UiController} from "../../../controllers/UiController";
import {GenericVisitableObject} from "./GenericVisitableObject";
import {ResourcesStorage} from "../../storages/ResourcesStorage";
import * as Utils from "../../../utils/Utils";
import {IObjectData} from "../../MapObject";

export class LeanToObject extends GenericVisitableObject {

    recordVisits = true;
    resources = new ResourcesStorage();

    constructor(data: IObjectData, id: number) {
        super(data, id);

        const resourceName = Utils.something(['wood', 'mercury', 'stone', 'sulfur', 'crystals', 'gems']);
        this.resources.set({[resourceName]: Utils.something([1, 2, 3, 4, 5])});

        this.events.on('action', hero => {
            if (this.hasResources()) {
                UiController.statusWindow.resourceTransferMessage(this.resources);
                this.flushResources(hero);
                return true;
            } else {
                return false;
            }
        });
    }

    flushResources(hero) {
        this.resources.transfer(hero.owner.resources);
    }

    hasResources(): boolean {
        return !this.resources.isEmpty();
    }
}
