
import * as Utils from "../utils/Utils";

const LS_KEY = 'settings';

const AVAILABLE = {
    smoothing: ['pixelated', 'crispEdges', 'smooth'],
    cursorPath: ['digits', 'percent', 'days', 'hidden']
};

interface ISettings {
    smoothing: string;
    cursorPath: string;
}

const DEFAULTS:ISettings = {
    smoothing: 'pixelated',
    cursorPath: 'digits'
};

export class SettingsService {

    private data: ISettings = <ISettings>{};

    constructor() {
        this.data = this.fetchAll();
        this.sendAll();
    }

    private validate(data: any): ISettings {
        return Utils.extend(DEFAULTS, data);
    }

    private fetchAll(): ISettings {
        return this.validate(JSON.parse(window.localStorage.getItem(LS_KEY)));
    }

    private sendAll() {
        window.localStorage.setItem(LS_KEY, JSON.stringify(this.data));
    }

    get(name: string): any {
        return this.data[name];
    }

    set(name: string, value: any) {
        this.data[name] = value;
        this.sendAll();
    }
}