
import * as Utils from "../utils/Utils";
import {UiPanel} from "./UiPanel";
import {Sprite} from "./sprites/Sprite";

interface ILineParams {
    left: number;
    top: number;
    width: number;
    horizontal: boolean;
}

interface IRectParams {
    left: number;
    top: number;
    width: number;
    height: number;
}

interface IRectParams {
    left: number;
    top: number;
    width: number;
    height: number;
    fontSize: string;
    color: string;
    align: string;
    text: string;
}

interface IIconParams {
    left: number;
    top: number;
    width: number;
    height: number;
    sprite: Sprite;
    spriteIndex: number;
}

export class UiPanelDrawingUtilsMixin {

    ctx: CanvasRenderingContext2D;

    line(params: ILineParams) {
        const options = Utils.extend<ILineParams>({
            left: 0,
            top: 0,
            width: 0,
            horizontal: true
        }, params);

        const FIRST_COLOR = '#a78c42';
        const SECOND_COLOR = '#000400';

        const absoluteLeft = (<UiPanel><any>this).getLeft() + options.left;
        const absoluteTop = (<UiPanel><any>this).getTop() + options.top;

        this.ctx.fillStyle = FIRST_COLOR;
        if (options.horizontal) {
            this.ctx.fillRect(absoluteLeft, absoluteTop, options.width, 1);
        } else {
            this.ctx.fillRect(absoluteLeft, absoluteTop, 1, options.width);
        }

        this.ctx.fillStyle = SECOND_COLOR;
        if (options.horizontal) {
            this.ctx.fillRect(absoluteLeft + 1, absoluteTop + 1, options.width - 2, 1);
        } else {
            this.ctx.fillRect(absoluteLeft + 1, absoluteTop + 1, 1, options.width - 2);
        }

        return this;
    }

    rect(params: IRectParams) {
        const options = Utils.extend<IRectParams>({
            left: 0,
            top: 0,
            width: 0,
            height: 0
        }, params);

        const absoluteLeft = (<UiPanel><any>this).getLeft() + options.left;
        const absoluteTop = (<UiPanel><any>this).getTop() + options.top;

        this.ctx.fillStyle = 'rgba(0, 0, 0, 0.2)';
        this.ctx.fillRect(absoluteLeft + 1, absoluteTop + 1, options.width - 2, options.height);

        this.line({
            left: options.left,
            top: options.top,
            width: options.width,
            horizontal: true
        });

        this.line({
            left: options.left,
            top: options.top + options.height,
            width: options.width,
            horizontal: true
        });

        this.line({
            left: options.left,
            top: options.top,
            width: options.height,
            horizontal: false
        });

        this.line({
            left: options.left + options.width,
            top: options.top,
            width: options.height,
            horizontal: false
        });
    }

    text(params: IRectParams) {
        let options = Utils.extend<IRectParams>({
            left: 0,
            top: 0,
            width: 0,
            height: 0,
            fontSize: '11px',
            color: '#000000',
            align: 'left'
        }, params);

        this.ctx.textAlign = options.align;
        this.ctx.textBaseline = 'middle';
        this.ctx.font = `${options.fontSize} Verdana`;

        let absoluteLeft = (<UiPanel><any>this).getLeft() + options.left;
        let absoluteTop = (<UiPanel><any>this).getTop() + options.top;

        let text = String(options.text).split("").join(String.fromCharCode(8202));

        this.ctx.fillStyle = 'rgba(0, 0, 0, .5)';
        this.ctx.fillText(text, absoluteLeft + 1.5, absoluteTop + 1.5);

        this.ctx.fillStyle = options.color;
        this.ctx.fillText(text, absoluteLeft + .5, absoluteTop + .5);

        return this;
    }

    icon(params: IIconParams) {
        let options = Utils.extend<IIconParams>({
            left: 0,
            top: 0,
            width: 0,
            height: 0,
            sprite: null,
            spriteIndex: 0
        }, params);

        let absoluteLeft = (<UiPanel><any>this).getLeft() + options.left;
        let absoluteTop = (<UiPanel><any>this).getTop() + options.top;

        options.sprite.draw(absoluteLeft, absoluteTop, options.spriteIndex, this.ctx);

        return this;
    }

    useCtx(targetCtx, callback) {
        const ctxBefore = this.ctx;
        this.ctx = targetCtx;

        callback.call(this);

        this.ctx = ctxBefore;
        return this;
    }
}