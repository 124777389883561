
import {Events} from "../controllers/Events";

let controller = null;

export abstract class ControllerHelper {
    constructor() {
        controller = this;
        Events.dispatch('stateChangeSuccess');
    }

    abstract ui: any;
}
