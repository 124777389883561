
import {GenericTreasureObject} from "./GenericTreasureObject";
import {UiController} from "../../../controllers/UiController";
import * as Utils from "../../../utils/Utils";
import {ArtifactObject, IObjectArtifactData} from "../ArtifactObject";
import {UiChestWindow} from "../../../views/windows/UiChestWindow";
import {ObjectsCollection} from "../../ObjectsCollection";
import {Inject} from "../../../helpers/InjectDectorator";
import {ActivePlayer} from "../../../controllers/ActivePlayer";
import {PlayerModel} from "../../player/PlayerModel";

interface IStateVariant {
    title: string;
    action: () => void;
}

interface IState {
    description: string;
    generate: () => void;
    variants: IStateVariant[];
}

interface IStatesConfig {
    [key: string]: IState;
}

export class ChestObject extends GenericTreasureObject {

    experience = 0;
    state: IState;
    STATES: IStatesConfig;
    artifact: IObjectArtifactData;

    @Inject(ActivePlayer) protected activePlayer: PlayerModel;
    @Inject(ObjectsCollection) protected objectsCollection: ObjectsCollection;

    constructor(data, id) {
        super(data, id);
        let hero;
        this.initRandomResources = this.initRandomResources.bind(this);
        this.initRandomArtifact = this.initRandomArtifact.bind(this);
        this.setGoldAndExperience = this.setGoldAndExperience.bind(this);
        this.initGoldOrExperience = this.initGoldOrExperience.bind(this);

        this.events.on('action', hero => {
            UiController.modal(UiChestWindow, this, hero).onClose(() => {
                this.objectsCollection.remove(this);
            });
        });

        this.STATES = <IStatesConfig>{
            GOLD_OR_EXP: {
                description: 'Do you want get the gold or grow you level?',
                generate: this.initGoldOrExperience,
                variants: [
                    {
                        title: 'Get gold',
                        action: () => {
                            hero = this.activePlayer.selectedObject;
                            UiController.statusWindow.resourceTransferMessage(this.resources);
                            this.resources.transfer(hero.owner.resources);
                        }
                    },
                    {
                        title: "Get experience",
                        action: () => {
                            hero = this.activePlayer.selectedObject;
                            hero.properties.get('experience').push(this.experience);
                        }
                    }
                ]
            },
            RESOURCES: {
                description: 'You found some resorces',
                generate: this.initRandomResources,
                variants: [
                    {
                        title: 'Ok',
                        action: () => {
                            hero = this.activePlayer.selectedObject;
                            UiController.statusWindow.resourceTransferMessage(this.resources);
                            this.resources.transfer(hero.owner.resources);
                        }
                    }
                ]
            },
            ARTIFACT: {
                description: 'You found an artifact',
                generate: this.initRandomArtifact,
                variants: [
                    {
                        title: 'Ok',
                        action: () => {
                            hero = this.activePlayer.selectedObject;
                            UiController.statusWindow.artifactMessage(this.artifact.object_subclass);
                            hero.artifacts.add(this.artifact);
                        }
                    }
                ]
            }
        };

        this.initRandomStuff();
    }

    initRandomStuff() {
        return Utils.randomize({
            [75]: this.setState.bind(this, this.STATES.GOLD_OR_EXP),
            [18]: this.setState.bind(this, this.STATES.RESOURCES),
            [5]: this.setState.bind(this, this.STATES.ARTIFACT)
        });
    }

    setState(state: IState) {
        this.state = state;
        this.state.generate();
    }

    initRandomResources() {
        this.resources.randomize(1500);
        this.state.description = `You found some resources: ${this.resources.asTextString()}`;
    }

    initRandomArtifact() {
        this.artifact = ArtifactObject.getRandomData(1);
    }

    setGoldAndExperience(goldValue, expValue) {
        this.resources.set('gold', goldValue);
        this.experience = expValue;
        this.state.description = `Do you want get ${goldValue} gold or ${expValue} experience?`;
    }

    initGoldOrExperience() {
        Utils.randomize({
            [32]: this.setGoldAndExperience.bind(this, 1000, 500),
            [33]: this.setGoldAndExperience.bind(this, 1500, 1000),
            [31]: this.setGoldAndExperience.bind(this, 2000, 1500)
        });
    }
}
