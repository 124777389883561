
import {OwnershipObject} from "./OwnershipObject";
import {ObjectAttackableMixin} from "./ObjectAttackableMixin";
import {CreaturesStorage} from "../storages/CreaturesStorage";
import {HeroObject} from "./hero/HeroObject";
import {UiController} from "../../controllers/UiController";
import {Events} from "../../controllers/Events";
import {Pointer} from "../Pointer";
import {ObjectsCollection} from "../ObjectsCollection";
import {CURSORS} from "../../constants/CURSORS";
import {TextService} from "../../services/TextService";
import * as Utils from "../../utils/Utils";
import {HeroesCollection} from "../HeroesCollection";
import {MapModel} from "../map/MapModel";
import {MapDrawer} from "../../render/MapDrawer";
import {IObjectData} from "../MapObject";
import {UiTownTipWindow} from "../../views/windows/UiTownTipWindow";
import {UiTownWindow} from "../../views/windows/UiTownWindow";
import {BattleSide} from "../../services/BattleService";
import {inject, Inject} from "../../helpers/InjectDectorator";
import {ActivePlayer} from "../../controllers/ActivePlayer";
import {PlayerModel} from "../player/PlayerModel";
import {ITextures, Textures} from '../../controllers/Textures';
import {MapDataReader} from '../map/MapDataReader';
import {MapData} from '../../controllers/MapData';
import {ITownData, TownsData} from "../../controllers/TownsData";
import {Mixin} from '../../utils/Utils';
import {MapComponent} from '../MapComponent';

interface IObjectTownDataData {
    has_fort: boolean;
    name: number[] | string;
    owner: number;
    type: string;
}

export interface IObjectTownData extends IObjectData<IObjectTownDataData> {
    townData: ITownData;
}

const RANDOM_TOWN = 77;

@MapComponent('town')
@Mixin(ObjectAttackableMixin)
export class TownObject extends OwnershipObject implements ObjectAttackableMixin {

    creatures: CreaturesStorage;

    canGoInActionPos = true;
    isSelectable = true;
    isTown = true;
    isHero = false;

    race: number;
    townType: number;
    name: string;

    heroes: HeroObject[] = [];
    guestPoints: Pointer[];

    @Inject(MapDrawer) private mapDrawer: MapDrawer;
    @Inject(ActivePlayer) protected activePlayer: PlayerModel;
    @Inject(Textures) protected textures: ITextures;
    @Inject(MapData) protected mapData: MapDataReader;
    @Inject(TextService) protected textService: TextService;
    @Inject(ObjectsCollection) protected objectsCollection: ObjectsCollection;

    constructor(data, id) {
        super(data, id);
        let state;
        this.draw = this.draw.bind(this);

        this.creatures = new CreaturesStorage(this.objectSubClass);

        if (this.data.data.creatures) {
            this.creatures.fromConfig(this.data.data.creatures);
        } else {
            if (!this.owner) {
                this.creatures.randomizeForTown(this.objectSubClass);
            }
        }

        this.race = this.objectSubClass;
        this.townType = data.townData.type;
        this.tip = (this.name = data.data.name);
        this.iconSprite = this.textures.get('towns_portraits_lg');

        if (this.hasFort()) {
            state = 2;
        } else {
            state = 0;
        }
        this.iconSpriteIndex = ((this.race || 0) * 4) + state;

        this.events
            .on('action', hero => this.onAction(hero))
            .on('exit', hero => {
                if (this.heroes[0]) {
                    this.setGuest(0, null);
                }
            })
            .on('leftClick', () => {
                if (this.isSelected()) {
                    this.showTownWindow();
                }
            })
            .on('dblClick', () => {
                if (this.owner && this.owner.is(this.activePlayer)) {
                    this.showTownWindow();
                }
            })
            .off('rightMousedown')
            .on('rightMousedown', () => {
                let uiTipWindow = UiController.modal(UiTownTipWindow, this);
                Events.one('rightMouseup', () => UiController.remove(uiTipWindow));
                return false;
            })
            .on('setOwner', () => {
                this.owner.updateMapForObject(this);
            });

        this.heroes = [null, null];
        this.guestPoints = [
            new Pointer(this.x - 1, this.y),
            new Pointer(this.x - 1, this.y - 1)
        ];

        this.onLoseBattle = () => {
            this.creatures.clear();
            this.setOwner(this.activePlayer);
        };

        this.checkHeroesSpawnedInTown();
    }

    private onAction(hero: HeroObject) {
        if (this.owner && this.owner.is(hero.owner)) {
            if (!this.heroes[0]) {
                this.setGuest(0, hero);
                this.showTownWindow();
            }
        } else {
            if (this.hasGarrison()) {
                this.startBattleWith(hero);
            } else {
                this.setOwner(hero.owner);
                this.setGuest(0, hero);
                this.showTownWindow();
            }
        }
        return true;
    }

    private checkHeroesSpawnedInTown() {
        if (this.heroes[0]) {
            return false;
        }
        setTimeout(() => {
            this.objectsCollection.forEach('hero', (hero: HeroObject) => {
                if ((hero.x === (this.x - 1)) && (hero.y === this.y) && (hero.z === this.z)) {
                    this.setGuest(0, hero);
                }
            });
        });
    }

    setGuest(position: number, hero: HeroObject) {
        this.heroes[position] = hero;
    }

    reverseGuests() {
        if (this.heroes[0]) {
            if (!this.heroes[0].creatures.transfer(this.creatures)) {
                UiController.alert("Unable to merge armies");
            }
        }
        this.heroes.reverse();
        this.heroes.forEach((hero, index) => {
            if (hero) {
                hero.x = this.guestPoints[index].x;
                hero.y = this.guestPoints[index].y;
                hero.hidden = index === 1;

                MapModel.updateRect(this.getRect());
            }
        });
        if (this.heroes[0]) {
            this.heroes[0].owner.selectObject(this.heroes[0].id);
        }
    }

    canSpawnHero() {
        // TODO
        return true;
    }

    spawnHero(ind: number) {
        const newHero = HeroesCollection.tavern[ind];
        const oldLeft = newHero.renderTree.left;
        const oldTop = newHero.renderTree.top;
        newHero.hidden = false;
        newHero.setOwner(this.owner);
        this.setGuest(0, newHero);
        newHero.x = this.guestPoints[0].x;
        newHero.y = this.guestPoints[0].y;
        const newLeft = newHero.x << 5;
        const newTop = newHero.y << 5;
        newHero.setLeft(newLeft);
        newHero.setTop(newTop);
        newHero.renderTree = newHero.renderTree.tree.move(oldLeft, oldTop, newLeft, newTop);
        newHero.zIndex = newTop;
        this.owner.selectObject(newHero.id);
    }

    showTownWindow() {
        UiController.modal(UiTownWindow, this);
    }

    hasFort() {
        return this.data.data.has_fort;
    }

    draw(ctx: CanvasRenderingContext2D) {
        if (this.isSelected()) {
            const x = this.mapDrawer.left(this.getLeft()) - 48;
            const y = this.mapDrawer.top(this.getTop()) - 10;
            const radius = 75;

            ctx.beginPath();
            ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
            ctx.arc(x, y - 2, radius - 5, 0, 2 * Math.PI, false);
            ctx.strokeStyle = 'rgba(255, 180, 5, 0.7)';
            ctx.stroke();
        }
        OwnershipObject.prototype.draw.call(this, ctx);
    }

    getCursor() {
        if (this.owner && this.owner.is(this.activePlayer)) {
            return CURSORS.TOWN;
        } else {
            return CURSORS.DEFAULT;
        }
    }

    hasGarrison() {
        return !this.creatures.isEmpty()
            || this.heroes[0]
            || this.heroes[1];
    }

    getActionCursor() {
        if (this.owner && this.owner.is(this.activePlayer)) {
            return CURSORS.HORSE_ACT;
        } else {
            if (this.hasGarrison()) {
                return CURSORS.ATTACK;
            } else {
                return CURSORS.HORSE_ACT;
            }
        }
    }

    getScoutingRadius() {
        return 7;
    }

    prepare(data: IObjectTownData): IObjectTownData {

        if (data.object_class === RANDOM_TOWN) {
            const player = inject<MapDataReader>(MapData).players[data.data && data.data.owner];

            if (player) {
                data.object_subclass = player.race;
            } else {
                data.object_subclass = Utils.something(player.availableRaces);
            }
        }
        const townData = inject<ITownData[]>(TownsData)[data.object_subclass];
        data.townData = townData;

        if (data.data.has_fort) {
            data.texture = townData.textures[1];
        } else {
            data.texture = townData.textures[0];
        }

        if (data.data.name) {
            data.data.name = Utils.readAsString(<number[]>data.data.name);
        } else {
            data.data.name = Utils.something(this.textService.i18n('MAP.TOWNS.RANDOM_NAMES'));
        }
        return data;
    }

    onLoseBattle() {}
    startBattleWith(opposite: BattleSide) {}
    transferWinnerProfitIfItPossible(fromSide: BattleSide, toSide: BattleSide) {}
}