
import {Layers} from "./layers/Layers";
import {Display} from "./layers/Display";
import * as Utils from "../utils/Utils";
import {UrlParamsService} from "../services/UrlParamsService";
import {StateService} from "../services/StateService";
import {setMainRenderFunction} from './requestRenderFrame';
import {inject, Inject} from '../helpers/InjectDectorator';

const NOOP = () => true;
const layers = inject<Layers>(Layers);

interface IDrawersScheme {
    terrain?: [Function, Function];
    objects?: [Function, Function];
    ui?: [Function, Function];
}

const DEFAULT_DRAWERS = <IDrawersScheme>{
    terrain: [NOOP, NOOP],
    objects: [NOOP, NOOP],
    ui: [NOOP, NOOP]
};

const CTX_LIST = {
    terrain: layers.mapCanvas,
    objects: layers.objectsCanvas,
    ui: layers.uiCanvas
};

let performanceCounters = [];
let drawers = [];
let totalFrameTime = 0;

const baseProductionDrawer: Function = renderFunction => renderFunction;
const baseDebugDrawer: Function = renderFunction => () => {
    const t = performance.now();
    renderFunction();
    const result = performance.now() - t;
    performanceCounters.push(result);
    totalFrameTime += result;
};

export class Render {

    private source: IDrawersScheme = Utils.extend(DEFAULT_DRAWERS, {});
    private debug: boolean = false;

    @Inject(Layers) private layers: Layers;
    @Inject(StateService) private stateService: StateService;
    @Inject(UrlParamsService) private urlParamsService: UrlParamsService;

    constructor() {

        this.urlParamsService.subscribe(params => {
            this.debug = params.hasOwnProperty('debug');
        });

        setMainRenderFunction(this.draw.bind(this));
    }

    setSource(newSource: IDrawersScheme) {
        let hasChanges = false;

        Object.keys(newSource).forEach(ctx => {
            const currentPreparerSource = this.source[ctx];
            const [preparer] = newSource[ctx];

            if (currentPreparerSource !== preparer) {
                hasChanges = true;

                CTX_LIST[ctx].clear();
                preparer(CTX_LIST[ctx]);
            }
        });

        if (hasChanges) {
            const drawers = <IDrawersScheme>Utils.extend(DEFAULT_DRAWERS, newSource);
            Object.keys(drawers).forEach(ctx => {
                const [preparer, drawer] = drawers[ctx];
                this.source[ctx] = drawer.bind(undefined, CTX_LIST[ctx]);
            });
            return true;
        } else {
            return false;
        }
    }

    hitCamera(x: number, y: number) {
        this.layers.mapCanvas.left += x;
        this.layers.mapCanvas.top += y;
        this.layers.objectsCanvas.left += x;
        this.layers.objectsCanvas.top += y;
    }

    setCenter(x: number, y: number, z: number = 0) {
        const mapWidth = Display.offsetWidth >= 800
            ? Display.offsetWidth - 190
            : Display.offsetWidth;
        const mapHeight = Display.offsetHeight;
        const mapLeft = (mapWidth >> 1) - x;
        const mapTop = (mapHeight >> 1) - y;

        this.stateService.set('center.x', x);
        this.stateService.set('center.y', y);
        this.stateService.set('center.z', z);

        this.layers.mapCanvas.left = mapLeft;
        this.layers.mapCanvas.top = mapTop;
        this.layers.objectsCanvas.left = mapLeft;
        this.layers.objectsCanvas.top = mapTop;
    }

    private draw() {
        performanceCounters.length = 0;
        totalFrameTime = 0;

        const baseDrawer = this.debug
            ? baseDebugDrawer
            : baseProductionDrawer;

        drawers = [
            baseDrawer(this.source.terrain),
            baseDrawer(this.source.objects),
            baseDrawer(this.source.ui)
        ];

        if (this.debug === true) {
            drawers.push(this.debugDrawer.bind(this));
        }

        this.runDrawers();
    }

    private runDrawers() {
        while (drawers.length) {
            drawers.shift().call();
        }
    }

    private debugDrawer() {
        const {ctx} = this.layers.uiCanvas;

        ctx.textBaseline = 'middle';
        ctx.textAlign = 'left';
        ctx.font = "12px Verdana";
        ctx.fillStyle = 'rgba(0, 0, 0, 0.8)';
        ctx.fillRect(45, 40, 200, 100);
        ctx.fillStyle = "#c00";
        ctx.textAlign = "left";
        ctx.fillText(`terrain: ${performanceCounters[0].toFixed(2)}ms`, 50, 70);
        ctx.fillText(`objects: ${performanceCounters[1].toFixed(2)}ms`, 50, 90);
        ctx.fillText(`ui: ${performanceCounters[2].toFixed(2)}ms`, 50, 110);
        ctx.fillText(`Total frame time: ${totalFrameTime.toFixed(2)}ms`, 50, 130);
    }
}