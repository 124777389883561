
class UiComponentsServiceClass {

    private components = new Map<string, any>();

    register(key: string, component: any) {
        this.components.set(key, component);
    }

    get(key: string): any {
        return this.components.get(key);
    }
}

export const UiComponentsService = new UiComponentsServiceClass();

export function UiComponent(key: string = null) {
    return function (target) {
        const componentName = key || target.name;

        UiComponentsService.register(componentName, target)
    }
}