
import {IObjectData, MapObject} from "../MapObject";
import {MapComponentsService} from '../MapComponent';
import {Inject} from '../../helpers/InjectDectorator';

/* Should be implemented */
// 'pandoras_box': PandorasBoxObject
// 'ocean_bottle': OceanBottleObject
// 'event': EventObject,
// 'abandoned_mine': AbandonedMineObject
// 'monolith_two_way': MonolithTwoWayObject
// 'prison': PrisonObject
// 'random_dwelling': RandomDwellingObject
// 'shrine': ShrineObject
// 'spell_scroll': SpellScrollObject
// 'witch_hut': WitchHutObject
// 'seers_hut': SeersHutObject
// 'scholar': ScholarObject
// 'hero_placeholder': HeroPlaceholderObject
// 'grail': GrailObject

/* Already implemented */
// 'dwelling': DwellingObject,
// 'lighthouse': LightHouseObject,
// 'subterranean_gate': SubterraneanGateObject,
// 'generic_visitable': GenericVisitableFabric,
// 'quest_guard': QuestGuardObject,
// 'artifact': ArtifactObject,
// 'resource': ResourceObject,
// 'garrison': GarrisonObject,
// 'monster': MonsterObject,
// 'resource_generator': ResourceGeneratorObject,
// 'generic_treasure': GenericTreasureFabric,
// 'sign': SignObject,
// 'town': TownObject,
// 'hero': HeroObject,
// 'boat': ShipObject,
// 'shipyard': ShipyardObject


export class ObjectsFabric {

    @Inject(MapComponentsService) private mapComponentsService: MapComponentsService;

    getData(objectData: IObjectData, id: number = null) {
        const {type} = objectData.data;
        const constructor = this.mapComponentsService.get(type) || MapObject;

        if (constructor.isFabric) {
            return constructor.getData(objectData, id);
        }

        return constructor.prototype.prepare(objectData);
    }

    get(objectData: IObjectData, id: number = null) {
        const {type} = objectData.data;
        const constructor = this.mapComponentsService.get(type) || MapObject;

        if (constructor.isFabric) {
            return constructor.get(objectData, id);
        }

        return new constructor(objectData, id);
    }
}
