
import {UiController} from "../../../controllers/UiController";
import {GenericVisitableObject} from "./GenericVisitableObject";
import {IObjectData} from "../../MapObject";

export class KeymastersTentObject extends GenericVisitableObject {

    restrictedPoints = true;

    constructor(data: IObjectData, id: number) {
        super(data, id);

        const keyId = this.objectSubClass;

        this.events.on('action', hero => {
            const {keys} = hero.owner;
            if (keys.has(keyId)) {
                UiController.alert('You already has this key');
            } else {
                keys.add(keyId);
                UiController.alert('Key successfully added');
            }
        });
    }
}
