
import * as Utils from "../utils/Utils";
import {UrlParamsService} from "../services/UrlParamsService";
import {DataService} from "../services/DataService";
import {ObjectSprite} from "../views/sprites/ObjectSprite";
import {UiSprite} from "../views/sprites/UiSprite";
import {TileSprite} from "../views/sprites/TileSprite";
import {MapDataReader} from "../models/map/MapDataReader";
import {IArtifactData} from '../models/storages/ArtifactsStorage';
import {Inject, setInject} from "../helpers/InjectDectorator";
import {ITextures, Textures} from './Textures';
import {I18nData} from './I18nData';
import {MapData} from './MapData';
import {MonstersData} from "./MonstersData";
import {HeroesData} from "./HeroesData";
import {ITownData, TownsData} from "./TownsData";
import {MarkupData} from './MarkupData';
import {ArtifactsData} from './ArtifactsData';

const IMG_FOLDER = 'img/';

const TERRAINS = {
    "terrain_0": "terrain/terr_00.jpg",
    "terrain_1": "terrain/terr_01.jpg",
    "terrain_2": "terrain/terr_02.jpg",
    "terrain_3": "terrain/terr_03.jpg",
    "terrain_4": "terrain/terr_04.jpg",
    "terrain_5": "terrain/terr_05.jpg",
    "terrain_6": "terrain/terr_06.jpg",
    "terrain_7": "terrain/terr_07.jpg",
    "terrain_8": "terrain/terr_08.jpg",
    "terrain_9": "terrain/terr_09.jpg",
    "road_1": "terrain/roads/dirt_road.png",
    "road_2": "terrain/roads/cobble_road.png",
    "road_3": "terrain/roads/stone_road.png",
    "river_0": "terrain/rivers/river_00.png",
    "river_1": "terrain/rivers/river_00.png",
    "river_2": "terrain/rivers/river_00.png"
};

const BASIC_UI_TEXTURES = [
    'main_menu_bg',
    'version_icons',
    'victory_conditions',
    'lose_conditions',
    'heroes_portraits_lg',
    'heroes_portraits_sm',
    'resources_portraits_lg',
    'artifacts_portraits_md',
    'artifacts_portraits_sm',
    'towns_portraits_lg',
    'towns_portraits_sm',
    'dialog_borders',
    'corner_gems',
    'panel_bg',
    'panel_colored_bg',
    'player_flag'
];

const GAME_UI_TEXTURES = [
    'mana_points',
    'move_points',
    'res_bar_bg',
    'bottom_level_btn',
    'schedule_btn',
    'function_btn',
    'top_level_btn',
    'system_btn',
    'day_end_btn',
    'sleep_btn',
    'magic_btn',
    'wake_btn',
    'hero_btn',
    'city_btn',
    'go_btn',
    'town_modal_bg',
    'town_modal_footer',
    'tavern_modal_bg',
    'spellbook_modal_bg',
    'creatures_portraits_sm',
    'creatures_portraits_md',
    'town_modal',
    'hero_modal',
    'hero_wnd',
    'primary_portraits_md',
    'secondary_portraits_sm',
    'creature_popup_bg',
    'town_type_icon',
    'specialities_sm',
    'specialities_md',
    'resources_portraits_sm',
    'new_day'
];

const ADDITIONAL_OBJECTS_TEXTURES = [
    'fog',
    'path_arrow',
    'creatures_sm',
    'AVXboat1.def'
];

const MAPS_REPOSITORIES = [
    // 'http://homm_maps.lekzd.ru/'
    '/maps/'
];

const ASSETS_REPOSITORIES = [
    // 'http://homm3.rodrigosantellan.com/'
    '/public/'
];

export class ResourcesController {
    language: string;
    storage: Map<string, {}>;
    assetsRepository: string;

    loaders: Promise<any>[] = [];
    loadedCount = 0;

    @Inject(Textures) private textures: ITextures;
    @Inject(DataService) private dataService: DataService;
    @Inject(MapData) private mapData: MapDataReader;
    @Inject(TownsData) private townsData: ITownData[];
    @Inject(UrlParamsService) private urlParamsService: UrlParamsService;

    constructor() {
        this.language = this.urlParamsService.params.lang || 'en';
        this.storage = new Map();
        this.assetsRepository = ASSETS_REPOSITORIES[0];
    }

    private loadData(key: string, loader: (url: string) => Promise<any>): Promise<any> {
        const promise = loader('/public/');

        this.addLoader(promise);

        return promise
            .then(response => {
                return this.storage.set(key, response);
            });
    }

    clearLoaders() {
        this.loaders.length = 0;
        this.loadedCount = 0;
    }

    addLoader(promise: Promise<any>) {
        this.loaders.push(promise);
        promise.then(() => this.loadedCount++);
    }

    loadObjectsData(): Promise<any> {
        return this.loadData('objectsData', this.dataService.getObjectsData.bind(this.dataService));
    }

    loadArtifactsData(): Promise<any> {
        return this.loadData('artifactsData', this.dataService.getArtifactsData.bind(this.dataService))
            .then(data => {
                const artifactsMap = new Map();

                Utils.forEach(data.get('artifactsData'), (item: IArtifactData) => {
                    artifactsMap.set(item.id, item);
                });

                setInject(ArtifactsData, artifactsMap);
            });
    }

    loadHeroesData(): Promise<any> {
        return Promise.all([
            this.loadData('heroesData', this.dataService.getHeroesData.bind(this.dataService))
                .then(data => {
                    setInject(HeroesData, data.get('heroesData'));
                }),
            this.loadData('heroesClassesData', this.dataService.getHeroesClassesData.bind(this.dataService))
        ]);
    }

    loadMonstersData(): Promise<any> {
        return this.loadData('monstersData', this.dataService.getMonstersData.bind(this.dataService))
            .then(data => {
                setInject(MonstersData, data.get('monstersData'));
            });
    }

    loadTownsData(): Promise<any> {
        return this.loadData('townsData', this.dataService.getTownsData.bind(this.dataService))
            .then(data => {
                setInject(TownsData, data.get('townsData'));
            });
    }

    loadI18nData(): Promise<any> {
        return this.loadData('i18n', this.dataService.getI18n.bind(this.dataService, this.language))
            .then(data => {
                setInject(I18nData, data.get('i18n'));
            });
    }

    loadMarkup(): Promise<any> {
        return this.loadData('markup', this.dataService.getMarkup.bind(this.dataService))
            .then(data => {
                setInject(MarkupData, data.get('markup'));
            });
    }

    loadMapData(mapUrl: string): Promise<any> {
        const promise = this.dataService.getMapData(mapUrl);

        this.addLoader(promise);

        return promise
            .then(response => {
                const mapData = new MapDataReader(response);

                this.storage.set('mapData', mapData);
                setInject(MapData, mapData);
            });
    }

    loadMapsList() {
        return MAPS_REPOSITORIES.map((url) => {
            return {
                url,
                error: false,
                loaded: false,
                promise: this.dataService.getMapsList(url)
            }
        });
    }

    loadUiTextures(): Promise<any> {
        const promises = BASIC_UI_TEXTURES.map(texture => {
            const sprite = new UiSprite(texture);

            this.textures.set(sprite.key.toLowerCase(), sprite);
            this.addLoader(sprite.promise);

            return sprite.promise
        });
        return Promise.all(promises);
    }

    loadGameUiTextures(): Promise<any> {
        const promises = GAME_UI_TEXTURES.map(texture => {
            const sprite = new UiSprite(texture);

            this.textures.set(sprite.key.toLowerCase(), sprite);
            this.addLoader(sprite.promise);

            return sprite.promise
        });
        return Promise.all(promises);
    }

    loadObjectTexture(texture: string): ObjectSprite {
        const fileName = `${IMG_FOLDER}objects/` + texture.replace('.def', '.png');
        const sprite = new ObjectSprite(texture, fileName);

        this.textures.set(sprite.key.toLowerCase(), sprite);

        return sprite;
    }

    loadUiSprite(spriteName: string): Promise<UiSprite> {
        const sprite = new UiSprite(spriteName);

        this.textures.set(spriteName.toLowerCase(), sprite);
        this.addLoader(sprite.promise);

        return sprite.promise;
    }

    loadMapTextures(): Promise<ObjectSprite[]> {
        let promises: Promise<any>[] = [];

        promises = Object.keys(TERRAINS)
            .filter(terrain => !this.textures.has(terrain))
            .map(terrain => {
                const fileName = TERRAINS[terrain];
                const sprite = new TileSprite(terrain, `${IMG_FOLDER}${fileName}`);

                this.textures.set(sprite.key.toLowerCase(), sprite);
                this.addLoader(sprite.promise);

                return sprite.promise;
            });

        promises = Object.keys(this.mapData.objects)
            .filter(id => this.mapData.objects[id])
            .map(id => {
                const {texture} = this.mapData.objects[id];
                const sprite = this.loadObjectTexture(texture);

                this.addLoader(sprite.promise);

                return sprite.promise;
            })
            .concat(promises);

        for (let key in this.townsData) {
            const town = this.townsData[key];
            for (let texture of town.textures) {
                if (texture) {
                    ADDITIONAL_OBJECTS_TEXTURES.push(texture);
                }
            }
        }

        promises = ADDITIONAL_OBJECTS_TEXTURES
            .filter(textureName => !this.textures.has(textureName))
            .map(textureName => {
                const fileName = `${IMG_FOLDER}objects/` + textureName.replace('.def', '.png');
                const sprite = new ObjectSprite(textureName, fileName);

                this.textures.set(sprite.key.toLowerCase(), sprite);
                this.addLoader(sprite.promise);

                return sprite.promise;
            })
            .concat(promises);

        return Promise.all(promises);
    }
}