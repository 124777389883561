
import {Events} from "../../controllers/Events";
import {MapModel} from "../map/MapModel";
import {Grid} from "../../helpers/Grid";
import {Pointer} from "../Pointer";
import * as Utils from "../../utils/Utils";
import {PlayerCommandsStack} from "./PlayerCommandsStack";
import {ResourcesStorage} from "../storages/ResourcesStorage";
import {HeroObject} from "../objects/hero/HeroObject";
import {TownObject} from "../objects/TownObject";
import {IMapDataPlayer} from "../map/MapDataReader";
import {ObjectsCollection} from "../ObjectsCollection";
import {IStateEvent, StateService} from "../../services/StateService";
import {debounce} from "../../helpers/Debounce";
import {OwnershipObject} from "../objects/OwnershipObject";
import {Maybe} from "../../helpers/Maybe";
import {Inject} from "../../helpers/InjectDectorator";

const DEFAULT_RESOURCES = {
    gold: 20000,
    wood: 20,
    stone: 20,
    mercury: 10,
    crystals: 10,
    sulfur: 10,
    gems: 10
};

type ISelectableObject = HeroObject | TownObject;

export class PlayerModel {

    keys: Set<number> = new Set();
    heroes: HeroObject[] = [];
    towns: TownObject[] = [];
    race: number;
    resources: ResourcesStorage;
    stack: PlayerCommandsStack;

    colorIndex: number;
    color: string;
    isHuman: boolean = false;
    canBeHuman: boolean;

    private fog: [Grid<number>, Grid<number>];
    private maskFog: Grid<number>;
    private data: any;
    private selectedObjectRef: ISelectableObject;

    @Inject(StateService) private stateService: StateService;
    @Inject(ObjectsCollection) private objectsCollection: ObjectsCollection;

    constructor(data: IMapDataPlayer) {
        this.data = data;
        this.race = data.race;

        this.fog = [new Grid(MapModel.size), new Grid(MapModel.size)];
        this.maskFog = new Grid(MapModel.size);

        this.color = data.color;
        this.canBeHuman = data.canBeHuman;
        this.isHuman = data.isHuman;

        this.resources = new ResourcesStorage(DEFAULT_RESOURCES);

        for (let key in DEFAULT_RESOURCES) {
            this.resources.watch(key, () => {
                this.handleResourceItemUpdated();
            });
        }

        this.stack = new PlayerCommandsStack(this);

        this.stateService.subscribe('*.data.owner', (event: IStateEvent) => {
            const [, objectId] = event.dispatch.match(/objects\.(\d+)\./);
            const changedObject = this.objectsCollection.getById(parseInt(objectId));

            if (changedObject === undefined) {
                this.updateHeroes();
                return;
            }

            if (changedObject.type === 'hero') {
                this.updateHeroes();
                return;
            }

            if (changedObject.type === 'town') {
                this.updateTowns();
                return;
            }
        });

        Events.on('sceneInit', () => {
            this.updateTowns();
            this.updateHeroes();
            this.initFog();
        });
    }

    get selectedObject(): ISelectableObject {
        return this.selectedObjectRef;
    }

    @debounce(100)
    private handleResourceItemUpdated() {
        this.setState('resources', this.resources.asObject());
    }

    private updateHeroes() {
        this.heroes = [];

        this.objectsCollection.forEach('hero', (object: HeroObject) => {
            if (object.ownerId === this.colorIndex) {
                this.heroes.push(object);
            }
        });

        this.setState('heroes', this.heroes.map(hero => hero.id));
    }

    private updateTowns() {
        this.towns = [];

        this.objectsCollection.forEach('town', (object: TownObject) => {
            if (object.ownerId === this.colorIndex) {
                this.towns.push(object);
            }
        });

        this.setState('towns', this.towns.map(town => town.id));
    }

    stopCommands() {
        this.stack.stop();
    }

    isActive() {
        return this.data.active;
    }

    moveHeroToDirection(direction: number) {
        const hero = <HeroObject>this.selectedObject;
        const objectPos = new Pointer(hero.x - 1, hero.y);

        objectPos.moveTo(direction);
        hero.pathFinder.pathTo(objectPos.x, objectPos.y);

        this.moveHero(objectPos.x, objectPos.y);
    }

    moveHero(x: number, y: number) {
        const {items} = (this.selectedObject as HeroObject).pathFinder;

        for (let pathItem of items) {
            if (!pathItem.available) {
                break;
            }
            this.stack.add('heroes.move', {
                id: this.selectedObject.id,
                x: pathItem.point.x,
                y: pathItem.point.y
            });
        }
    }

    selectObject(id: number) {
        this.stack.add('objects.select', {id});
        this.setState('selectedObject', id);
    }

    walkByPath() {
        const {items} = (this.selectedObject as HeroObject).pathFinder;
        const lastPathItem = items[items.length - 1];

        this.moveHero(lastPathItem.point.x, lastPathItem.point.y);
    }

    selectNextHero() {
        let selectedIndex = -1;

        for (let index = 0; index < this.heroes.length; index++) {
            const hero = this.heroes[index];
            if (hero.isSelected()) {
                selectedIndex = index;
                break;
            }
        }
        selectedIndex = (selectedIndex + 1) % this.heroes.length;

        this.selectObject(this.heroes[selectedIndex].id);
    }

    selectNextTown() {
        let selectedIndex = -1;

        for (let index = 0; index < this.towns.length; index++) {
            const town = this.towns[index];
            if (town.isSelected()) {
                selectedIndex = index;
                break;
            }
        }
        selectedIndex = (selectedIndex + 1) % this.towns.length;

        this.selectObject(this.towns[selectedIndex].id);
    }

    setSelectedObject(object: ISelectableObject) {
        this.selectedObjectRef = object;

        if (object) {
            object.events.dispatch('focus')
        }
    }

    getSelectedObject(): ISelectableObject {
        return this.selectedObject;
    }

    is(player: PlayerModel): boolean {
        if (player) {
            return this.color === player.color;
        }
        return false;
    }

    command(commandName: string, params: Object = null) {
        this.stack.add(commandName, params);
    }

    updateMapFog() {
        MapModel.fog.update(this.fog[this.stateService.get('center.z')]);
    }

    selectMainObject() {
        const objectToSelect = this.heroes[0] || this.towns[0];

        if (objectToSelect === undefined) {
            console.log('Game over');
        }

        this.selectObject(objectToSelect.id);
    }

    startTurn() {
        if (this.isHuman) {
            this.selectMainObject();
            this.updateMapFog();
        } else {
            this.command('game.endPlayerTurn');
        }
    }

    clearFogForObject(object: OwnershipObject) {
        const radius = object.getScoutingRadius() + 0.5;
        const { z } = object;

        this.maskFog.clear();

        this.fog[z].forEach((oldFogValue, tX, tY) => {
            const {x, y} = object.getCenter();
            const oX = (x >> 5) + 1;
            const oY = (y >> 5) + 1;
            const distance = Utils.distance(tX, tY, oX, oY);

            if (distance <= (radius + 2)) {
                this.maskFog.set(tX, tY, oldFogValue);
            }

            if (distance <= radius) {
                this.fog[z].set(tX, tY, 0);
                this.maskFog.set(tX, tY, 0);
            }
        });

        const data = this.fog[z].getRawData();
        this.setState(`fog.${z}`, data);

        MapModel.fog.update(this.maskFog);
    }

    updateMapForObject(object: ISelectableObject) {
        this.clearFogForObject(object);

        MapModel.updateByMask(this.maskFog);
    }

    initFog() {
        this.fillLevelFog(0);
        this.fillLevelFog(1);
        this.heroes.forEach(this.clearFogForObject.bind(this));

        this.towns.forEach(this.clearFogForObject.bind(this));
    }

    private fillLevelFog(z: number) {
        const {size} = MapModel;
        const savedFogData = <number[]>Maybe(this.data).pluck(`fog.${z}`).getOrElse(null);

        if (savedFogData) {
            this.fog[z].setRawData(savedFogData);
            return;
        }

        for (let x = 0, end = size, asc = 0 <= end; asc ? x < end : x > end; asc ? x++ : x--) {
            for (let y = 0, end1 = size, asc1 = 0 <= end1; asc1 ? y < end1 : y > end1; asc1 ? y++ : y--) {
                this.fog[z].set(x, y, 1);
            }
        }
    }

    private setState(key: string, value: any) {
        this.stateService.set(`players.${this.colorIndex}.${key}`, value);
    }
}