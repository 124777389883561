
import {CommandHelper} from "../helpers/CommandHelper";
import {SceneController} from "../controllers/SceneController";
import {Inject} from "../helpers/InjectDectorator";
import {ActivePlayer} from "../controllers/ActivePlayer";
import {PlayerModel} from "../models/player/PlayerModel";

export class EndTurnCommand extends CommandHelper {

    @Inject(ActivePlayer) private activePlayer: PlayerModel;

    constructor() {
        super();

        const prevActivePlayerIndex = this.activePlayer.colorIndex;

        this.setExecute(() => SceneController.setActivePlayer());

        this.setUndo(() => SceneController.setActivePlayer(prevActivePlayerIndex));
    }
}
