
import {UiPanel} from "../UiPanel";
import * as Utils from "../../utils/Utils";
import {MapDataReader} from "../../models/map/MapDataReader";
import {UiSprite} from "../sprites/UiSprite";
import {ITextures, Textures} from '../../controllers/Textures';
import {Inject} from '../../helpers/InjectDectorator';

const VERSIONS = {
    'RoE': 1,
    'AB': 0,
    'SoD': 2
};

const VICTORY_SPRITE = {
    [255]: 11,
    [0]: 0,
    [1]: 1,
    [2]: 2,
    [3]: 3,
    [4]: 4,
    [5]: 5,
    [6]: 6,
    [7]: 7,
    [8]: 8,
    [9]: 9,
    [10]: 10
};

const LOSE_SPRITE = {
    [255]: 1,
    [0]: 0,
    [1]: 2,
    [2]: 3
};

const PADDING_LEFT = 45;

const DEFAULT_PARAMS = {
    left: 0,
    top: 0,
    height: 50,
    map: null
};

export class UiGameItem extends UiPanel {
    mapData: MapDataReader;

    private mapSizeStr: string;
    private versionIcon: UiSprite;
    private victoryIcon: UiSprite;
    private loseIcon: UiSprite;
    @Inject(Textures) protected textures: ITextures;

    constructor(params) {
        super(Utils.extend(DEFAULT_PARAMS, params));
        this.draw = this.draw.bind(this);

        let map = this.readParam('map');

        this.mapData = new MapDataReader(map);
        this.mapSizeStr = `Size: ${this.mapData.props.size}x${this.mapData.props.size}`;

        this.versionIcon = <UiSprite>this.textures.get('version_icons');
        this.victoryIcon = <UiSprite>this.textures.get('victory_conditions');
        this.loseIcon = <UiSprite>this.textures.get('lose_conditions');
    }

    draw() {
        this.ctx.fillStyle = 'rgba(0, 0, 0, 0.3)';
        this.ctx.fillRect(this.absoluteLeft + 5, this.absoluteTop + 2, this.width - 10, 46);

        if (this.isHovered()) {
            this.ctx.lineWidth = 1;
            this.ctx.strokeStyle = 'rgba(255, 255, 255, 0.5)';
            this.ctx.strokeRect(this.absoluteLeft + 4.5, this.absoluteTop + 1.5, this.width - 10, 46);
        }

        this.icon({
            left: 10,
            top: 13,
            sprite: this.versionIcon,
            spriteIndex: VERSIONS[this.mapData.props.version]});

        this.icon({
            left: this.width - 85,
            top: 13,
            sprite: this.victoryIcon,
            spriteIndex: VICTORY_SPRITE[this.mapData.props.win_condition.type]});

        this.icon({
            left: this.width - 45,
            top: 13,
            sprite: this.loseIcon,
            spriteIndex: LOSE_SPRITE[this.mapData.props.lose_condition.type]});

        this.text({
            text: this.mapData.props.name,
            left: PADDING_LEFT,
            top: 15,
            fontSize: '13px',
            color: '#FFFFFF'
        });

        this.text({
            text: this.mapSizeStr,
            left: PADDING_LEFT,
            top: 35,
            fontSize: '11px',
            color: '#f8e57e'
        });
    }
}