
import {GenericVisitableObject} from "./GenericVisitableObject";
import {HeroObject} from "../hero/HeroObject";

export class FountainObject extends GenericVisitableObject {

    constructor(data, id) {
        super(data, id);

        this.events.on('action', (hero: HeroObject) => {
            hero.properties.get('manaPoints').max();
        });
    }
}