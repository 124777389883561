
import {Display} from "../../render/layers/Display";
import {UiWindow} from "../common/UiWindow";
import {SettingsService} from "../../services/SettingsService";
import {Router} from "../../controllers/Router";
import {UiController} from "../../controllers/UiController";
import {UiSaveWindow} from "./UiSaveWindow";
import {StateService} from "../../services/StateService";
import { Inject } from "../../helpers/InjectDectorator";

const DEFAULT_PARAMS = {
    width: Math.min(400, Display.offsetWidth),
    height: 400,
    modal: true
};

export class UiSettingsWindow extends UiWindow {
    @Inject(SettingsService) protected settingsService: SettingsService;
    @Inject(StateService) protected stateService: StateService;
    @Inject(Router) protected router: Router;

    constructor() {
        super(DEFAULT_PARAMS);

        this.scope = {
            smoothing: ['pixelated', 'crispEdges', 'smooth'],
            cursorPath: ['digits', 'percent', 'days', 'hidden'],

            mainMenu: () => {
                this.router.go('new_game');
            },

            close: () => {
                this.removeWnd();
            },

            openSaveModal: () => {
                UiController.modal(UiSaveWindow).promise.then(saveName => {
                    this.stateService.saveState(saveName || 'Untitled');
                });
            },

            openLoadModal: () => {
                UiController.modal(UiSaveWindow, true).promise.then(saveName => {
                    this.stateService.loadState(saveName);
                    this.router.go('game');
                });
            },

            getSmoothingValue: () => {
                return this.settingsService.get('smoothing');
            },

            getCursorPathValue: () => {
                return this.settingsService.get('cursorPath');
            },

            setSmoothing: (value: string) => {
                this.settingsService.set('smoothing', value);
                Display.setAttribute('data-smoothing', this.settingsService.get('smoothing'));
            },

            setCursorPath: (value: string) => {
                this.settingsService.set('cursorPath', value);
            }
        };

        this.setMarkup('game/modals/settingsWindow.xml');
    }
}
