
import {CommandHelper} from "../helpers/CommandHelper";
import {HERO_STATES} from "../constants/HERO_STATES";
import * as Utils from "../utils/Utils";
import {Events} from "../controllers/Events";
import {AsyncSequence} from "../helpers/AsyncSequence";
import {MapModel} from "../models/map/MapModel";
import {Render} from "../render/Render";
import {ObjectsCollection} from "../models/ObjectsCollection";
import {HeroObject} from "../models/objects/hero/HeroObject";
import {TilesCollection} from "../models/map/TilesCollection";
import {requestRenderFrame} from '../render/requestRenderFrame';
import {Inject} from '../helpers/InjectDectorator';

const TICK_TIME = 16;
const TICK_DISTANCE = 8;
let moveEndTimeout = -1;

export class MoveHeroCommand extends CommandHelper {
    @Inject(Render) private render: Render;
    @Inject(ObjectsCollection) protected objectsCollection: ObjectsCollection;

    constructor({id, x, y}) {
        super();

        let hero = <HeroObject>this.objectsCollection.dynamic.get(id);
        let prevPosition = hero.getCoords();
        let pathItem = null;
        let targetLeft = (x + 1) << 5;
        let targetTop = y << 5;

        let initMovement = () => {
            clearTimeout(moveEndTimeout);

            let movePointsProperty = hero.properties.get('movePoints');
            if (movePointsProperty.get()) {
                pathItem = hero.pathFinder.items.shift();
                movePointsProperty.push(-pathItem.movementCost);
                return Promise.resolve(true);
            } else {
                return Promise.resolve(false);
            }
        };

        let startMoving = () => {
            hero.setDirection(pathItem.direction)
                .setState(HERO_STATES.GO)
                .events.dispatch('move');
            if (MapModel.canGoToPos(x, y, hero.z)) {
                if (!hero.pathFinder.items.length) {
                    TilesCollection.doAction(x, y, hero.z, hero);
                }
                return Promise.resolve(true);
            } else {
                return Promise.resolve(false);
            }
        };

        let animateTick = () => {
            return new Promise((resolve) => {
                let {objectLeft, objectTop} = hero;
                if (Utils.distance(targetLeft, targetTop, objectLeft, objectTop) < TICK_DISTANCE) {
                    resolve(false);
                } else {
                    setTimeout(() => {
                        requestRenderFrame(() => {
                            if (targetLeft !== objectLeft) {
                                if (targetLeft > objectLeft) {
                                    objectLeft += TICK_DISTANCE;
                                } else {
                                    objectLeft -= TICK_DISTANCE;
                                }
                            }
                            if (targetTop !== objectTop) {
                                if (targetTop > objectTop) {
                                    objectTop += TICK_DISTANCE;
                                } else {
                                    objectTop -= TICK_DISTANCE;
                                }
                            }
                            hero.setLeft(objectLeft)
                                .setTop(objectTop);

                            this.render.setCenter(objectLeft - 32, objectTop - 16, hero.z);

                            resolve(true);
                        });
                    }, TICK_TIME);
                }
            });
        };

        let endAnimation = () => {
            let oldLeft = hero.renderTree.left;
            let oldTop = hero.renderTree.top;
            let newLeft = hero.x << 5;
            let newTop = (hero.y << 5) - 32;
            hero.setLeft(targetLeft)
                .setTop(targetTop);
            hero.renderTree = hero.renderTree.tree.move(oldLeft, oldTop, newLeft, newTop);
            hero.zIndex = newTop;

            return Promise.resolve(true);
        };

        let replaceHero = () => {
            let oldX = hero.x - 1;
            let oldY = hero.y;
            hero.x = x + 1;
            hero.y = y;
            MapModel.updatePoints(oldX, oldY, x, y, hero);
            MapModel.dispatchExitInPos(oldX, oldY, hero.z, hero);
            hero.owner.updateMapForObject(hero);
            hero.updateTransport();

            return Promise.resolve(true);
        };

        let doMapActions = () => {
            if (!hero.pathFinder.items.length) {
                MapModel.doActionInPos(x, y, hero.z, hero);
            }

            return Promise.resolve(true);
        };

        let endMovement = () => {
            moveEndTimeout = setTimeout(() => {
                Events.dispatch('dynamicObjectsChanged');
                hero.setState(HERO_STATES.IDLE);
                hero.events.dispatch('moveEnd');
            }, 10);

            return Promise.resolve(true);
        };

        this.setExecute(() =>
            AsyncSequence.from([
                initMovement,
                [
                    startMoving,
                    [
                        animateTick,
                        animateTick,
                        animateTick,
                        animateTick,
                        animateTick,
                        animateTick,
                        animateTick
                    ],
                    endAnimation,
                    replaceHero
                ],
                doMapActions,
                endMovement
            ])
        );

        this.setUndo(() => {
            ({x, y} = prevPosition);
            Object.assign(hero, {x, y});
            return true;
        });
    }
}
