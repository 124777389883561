
import {UiWindow} from "../common/UiWindow";
import {UiTavernWindow} from "./UiTavernWindow";
import {UiHeroWindow} from "./UiHeroWindow";
import {UiGarissonStack} from "../common/UiGarissonStack";
import * as Utils from "../../utils/Utils";
import {UiImage} from "../common/UiImage";
import {UiController} from "../../controllers/UiController";
import {UiSprite} from "../sprites/UiSprite";
import {HeroObject} from "../../models/objects/hero/HeroObject";
import {UiPanel} from "../UiPanel";
import {TownObject} from "../../models/objects/TownObject";
import {Inject} from "../../helpers/InjectDectorator";
import {ActivePlayer} from "../../controllers/ActivePlayer";
import {PlayerModel} from "../../models/player/PlayerModel";
import {ITextures, Textures} from '../../controllers/Textures';

const DEFAULT_PARAMS = {
    width: 830,
    height: 630,
    modal: true
};

const EMPTY_SLOT = 256;

export class UiTownWindow extends UiWindow {

    private uiGarrison: UiGarissonStack;
    private stackTopPositions = [498, 402];
    private heroPortraits: UiImage[] = [];
    @Inject(ActivePlayer) private activePlayer: PlayerModel;
    @Inject(Textures) protected textures: ITextures;

    scope: any;

    constructor(private town: TownObject) {
        super(DEFAULT_PARAMS);

        let goldIncrement = 0;
        const state = town.hasFort()
            ? 2
            : 0;

        this.scope = {
            close: () => {
                this.resolve();
                this.removeWnd();
            },

            field: {
                bgSpriteIndex: town.race
            },

            getTowns: () => {
                return this.activePlayer.towns;
            },

            setTown: town => {
                if (town.is(this)) {
                    return false;
                }
                this.resolve();
                this.removeWnd();
                town.owner.selectObject(town.id);
                town.showTownWindow();
            },

            interface: {
                info: {
                    iconSpriteIndex: ((town.race || 0) * 4) + state,
                    name: town.name,
                    goldIncrement
                }
            },

            openTavernWnd: () => {
                UiController.modal(UiTavernWindow, {town}).promise.then(() => this.updateStacks());
            },

            getStorages: () => {
                const internalGarrison = town.heroes[1]
                    ? town.heroes[1].creatures
                    : town.creatures;

                const guestGarrison = town.heroes[0]
                    ? town.heroes[0].creatures
                    : null;

                return [internalGarrison, guestGarrison];
            }
        };

        this.setMarkup('game/modals/townWindow.xml');

        this.addGuestPortrait(town.heroes[0], 0);
        this.addGuestPortrait(town.heroes[1], 1);

        this.uiGarrison = <UiGarissonStack>this.query('garisson');
    }

    getHeroPortraitIndex(hero: HeroObject): number {
        return hero.getProperty('portrait') || hero.getProperty('person');
    }

    getGuestIndex(currWnd: UiPanel): number {
        for (let index = 0; index < this.heroPortraits.length; index++) {
            let wnd = this.heroPortraits[index];
            if (currWnd.is(wnd)) {
                return index;
            }
        }
        return null;
    }

    setPortraitButtonSprite(btn: UiImage, hero: HeroObject) {
        if (hero) {
            btn.sprite = <UiSprite>this.textures.get('heroes_portraits_lg');
            btn.options.spriteIndex = this.getHeroPortraitIndex(hero);
        } else {
            btn.sprite = <UiSprite>this.textures.get('player_flag');
            btn.options.spriteIndex = this.town.owner.colorIndex;
        }
    }

    updateStacks() {
        this.uiGarrison.storages = this.scope.getStorages();
        this.uiGarrison.updateStacks();
        for (let index = 0; index < this.heroPortraits.length; index++) {
            let wnd = this.heroPortraits[index];
            this.setPortraitButtonSprite(wnd, this.town.heroes[index]);
            wnd.selected = false;
        }
    }

    reverseHeroes() {
        this.town.reverseGuests();
        this.updateStacks();
    }

    addGuestPortrait(hero: HeroObject, index: number) {
        const portraitBtn = new UiImage({
            left: 256,
            top: this.stackTopPositions[index],
            sprite: <UiSprite>this.textures.get('heroes_portraits_lg'),
            spriteIndex: EMPTY_SLOT,
            selectable: true,
            draggable: true,
            dropZones: () => Utils.filter(this.heroPortraits, portrait => !portrait.is(portraitBtn)),
            parent: this
        });

        portraitBtn.events
            .on('rightMousedown', () => {
                const hero = this.town.heroes[index];

                if (hero) {
                    hero.events.dispatch('rightMousedown');
                }
            })
            .on('dblClick', () => {
                const hero = this.town.heroes[index];

                if (hero) {
                    UiController.modal(UiHeroWindow, hero).promise.then(() => {
                        this.updateStacks();
                    });
                }
            })
            .on('leftClick', () => {
                let currentIndex = this.getGuestIndex(portraitBtn);
                let otherIndex = 0;
                if (currentIndex === 0) {
                    otherIndex = 1;
                }
                if (!portraitBtn.selected && this.heroPortraits[otherIndex].selected) {
                    return this.reverseHeroes();
                } else {
                    return portraitBtn.selected = !portraitBtn.selected;
                }
            })
            .on('drop', () => {
                return this.reverseHeroes();
            });

        portraitBtn['index'] = index;
        this.setPortraitButtonSprite(portraitBtn, hero);
        this.heroPortraits.push(portraitBtn);
    }
}
