
export const COLORS = {
    BLACK: [0, 0, 0],
    RED: [255, 0, 0],
    BLUE: [49, 82, 255],
    TAN: [156, 115, 82],
    GREEN: [66, 148, 41],
    ORANGE: [255, 132, 0],
    PURPLE: [140, 41, 165],
    TEAL: [8, 156, 165],
    PINK: [198, 123, 140],
    GREY: [125, 125, 125]
};
