
import * as Utils from "../../utils/Utils";
import {
    IMapDataPropsLoseCondition, IMapDataPropsRestrictions,
    IMapDataPropsWinCondition,
    ISourceDataPlayer,
    ISourceMapData
} from "./MapSourceTypes";
import {IObjectData} from "../MapObject";
import {IObjectHeroData} from "../objects/hero/HeroObject";
import {IObjectTownData} from "../objects/TownObject";

const ROE = 'RoE';
const AB = 'AB';
const SOD = 'SoD';

const RACES_ALL = [0, 1, 2, 3, 4, 5, 6, 7, 8];
const RACES_AB_ROE = [0, 1, 2, 3, 4, 5, 6, 7];

export interface IMapDataProps {
    name: string;
    descr: string;
    size: number;
    map_size: string;
    version: string;
    difficulty: number;
    has_caves: number;
    lose_condition: IMapDataPropsLoseCondition;
    win_condition: IMapDataPropsWinCondition;
    restrictions: IMapDataPropsRestrictions;
}

export interface IMapDataPlayer {
    index: number;
    color: string;
    race: number;
    canBeHuman: boolean;
    isHuman: boolean;
    canBeComputer: boolean;
    availableRaces: number[];
    mainHeroSpriteIndex: number;
    mainTownSpriteIndex: number;

    selectedRace?: number;
    selectedHero?: number;
    fog?: {[key: number]: number[]};
}

export const SPRITE_INDEXES = {
    RANDOM_TOWN: 2,
    NO_TOWN: 3,
    RANDOM_HERO: 174,
    NO_HERO: 175
};

export class MapDataReader {

    objects: IObjectData[];
    tiles: any[];
    props: IMapDataProps;
    players: IMapDataPlayer[];

    constructor(mapData: ISourceMapData) {
        this.parsePlayerData = this.parsePlayerData.bind(this);
        const data = Utils.clone<ISourceMapData>(mapData, true);
        this.objects = data.objects;
        this.tiles = data.tiles;
        this.props = this.parseProps(data);
        this.players = this.parsePlayers(data);
    }

    getVersionSupport(version: string): boolean {
        const versionsSequence = [ROE, AB, SOD];
        const currentVersionIndex = versionsSequence.indexOf(this.props.version);
        const checkVersionIndex = versionsSequence.indexOf(version);

        return checkVersionIndex <= currentVersionIndex
    }

    private parseProps(data: ISourceMapData): IMapDataProps {
        return Object.assign(data.props, {
            name: Utils.readAsString(data.props.name) || "[no name]",
            descr: Utils.readAsString(data.props.descr) || "",
            size: Number(data.props.map_size)
        });
    }

    private parsePlayers(data: ISourceMapData): IMapDataPlayer[] {
        return (data.players = data.players || [])
            .map((item, index) => Object.assign(item, {index}))
            .filter(({active}) => active)
            .map(this.parsePlayerData);
    }

    private parsePlayerData(playerData: ISourceDataPlayer): IMapDataPlayer {
        return {
            index: playerData.index,
            race: playerData.race,
            color: playerData.color,
            canBeHuman: playerData.can_be_human,
            isHuman: false,
            canBeComputer: playerData.can_be_computer,
            availableRaces: this.getAvailableRaces(playerData),
            mainHeroSpriteIndex: this.getMainHeroSpriteIndex(playerData.index),
            mainTownSpriteIndex: this.getMainTownSpriteIndex(playerData.index)
        };
    }

    private getFirstHero(playerIndex: number): IObjectHeroData {
        return <IObjectHeroData>this.objects.find((object: IObjectHeroData) => {

            return (object.data.type === 'hero') && (object.data.owner === playerIndex);
        });
    }

    private getFirstTown(playerIndex: number): IObjectTownData {
        return <IObjectTownData>this.objects.find((object: IObjectTownData) => {

            return (object.data.type === 'town') && (object.data.owner === playerIndex);
        });
    }

    private getMainHeroSpriteIndex(playerIndex: number): number {
        const hero = this.getFirstHero(playerIndex);

        if (!hero) {
            return SPRITE_INDEXES.NO_HERO;
        }

        const person = hero.data && hero.data.person;

        if (person !== 255) {
            return person;
        } else {
            return SPRITE_INDEXES.RANDOM_HERO;
        }
    }

    private getMainTownSpriteIndex(playerIndex: number): number {
        const town = this.getFirstTown(playerIndex);

        if (town) {
            if (town.object_class !== 77) {
                return (town.object_subclass * 4) + 6;
            } else {
                return SPRITE_INDEXES.RANDOM_TOWN;
            }
        } else {
            return SPRITE_INDEXES.NO_TOWN;
        }
    }

    private getAvailableRaces(playerData: ISourceDataPlayer): number[] {
        let availableRaces = playerData.town_conflux
            ? RACES_ALL
            : RACES_AB_ROE;

        if (playerData.town_types !== 255 && this.getVersionSupport(SOD)) {
            const binaryMask = Utils.readAsBinaryMask([playerData.town_types]);
            availableRaces = Utils.filterByBinaryMask(availableRaces, binaryMask);
        }
        return availableRaces;
    }
}
