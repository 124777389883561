
import {Render} from "./render/Render";
import {Spinner} from "./views/Spinner";
import {UrlParamsService} from "./services/UrlParamsService";
import {Router} from "./controllers/Router";
import {StateService} from "./services/StateService";
import {GameController} from './controllers/GameController';
import {GameOptionsController} from './controllers/GameOptionsController';
import {NewGameController} from './controllers/NewGameController';

import "rxjs/Observable";
import "rxjs/BehaviorSubject";
import "rxjs/Subject";
import "rxjs/add/observable/fromEvent";
import "rxjs/add/operator/takeUntil";
import "rxjs/add/observable/timer";

import "./models/objects/EventObject";
import "./models/objects/SubterraneanGateObject";
import "./models/objects/genericVisitable/GenericVisitableFabric";
import "./models/objects/GarrisonObject";
import "./models/objects/ResourceObject";
import "./models/objects/ArtifactObject";
import "./models/objects/QuestGuardObject";
import "./models/objects/genericTreasure/GenericTreasureFabric";
import "./models/objects/hero/HeroObject";
import "./models/objects/ShipObject";
import "./models/objects/MonsterObject";
import "./models/objects/ResourceGeneratorObject";
import "./models/objects/SignObject";
import "./models/objects/TownObject";
import "./models/objects/ShipyardObject";
import "./models/objects/LightHouseObject";
import "./models/objects/DwellingObject";
import {Inject} from './helpers/InjectDectorator';

const MAP_URL = '/maps/big_test.h3m.json';
// MAP_URL = '/maps/test_caves_emty.h3m.json'
// MAP_URL = '/maps/Hatchet_Axe_and_Saw.h3m.json'
// MAP_URL = '/maps/Good_to_Go.h3m.json'
// MAP_URL = '/maps/A_Viking_We_Shall_Go.h3m.json'

const STATES = {
    game: GameController,
    new_game: NewGameController,
    game_options: GameOptionsController
};

class App {
    @Inject(Router) private router: Router;
    @Inject(Render) private render: Render;
    @Inject(Spinner) private spinner: Spinner;
    @Inject(StateService) private stateService: StateService;
    @Inject(UrlParamsService) private urlParamsService: UrlParamsService;

    constructor() {
        window['state'] = this.stateService;
        console.log('StateService', this.stateService);

        this.render.setSource({ui: [this.spinner.prepare, this.spinner.draw]});

        const state = this.urlParamsService.params.state || 'new_game';
        const stateParams = this.urlParamsService.params || {};

        this.router.states = STATES;

        this.router.promise.then(() => this.router.go(state, stateParams));
    }
}

new App();
