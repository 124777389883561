
import {IUiWindowOptions, UiWindow} from "../common/UiWindow";
import {Events} from "../../controllers/Events";
import * as Utils from "../../utils/Utils";
import {MAP_COLORS} from "../../constants/MAP_COLORS";
import {COLORS} from "../../constants/COLORS";
import {Render} from "../../render/Render";
import {Canvas} from "../../render/layers/Canvas";
import {PlayerModel} from "../../models/player/PlayerModel";
import {Display} from "../../render/layers/Display";
import {MapModel} from "../../models/map/MapModel";
import {Layers} from "../../render/layers/Layers";
import {TilesCollection} from "../../models/map/TilesCollection";
import {UiComponent} from "../../services/UiComponentsService";
import {Pointer} from "../../models/Pointer";
import {IStateEvent, StateService} from "../../services/StateService";
import {ObjectsCollection} from "../../models/ObjectsCollection";
import {requestRenderFrame} from '../../render/requestRenderFrame';
import {Inject} from '../../helpers/InjectDectorator';

const PADDING = 25;
const DOUBLE_PADDING = PADDING * 2;

@UiComponent()
export class UiMap extends UiWindow {
    private ctxSize: number;
    private ratio: number;
    private mapCanvas: Canvas;
    private mapRatio: number;
    private baseTerrainMap: CanvasRenderingContext2D;
    private dynamicMap: CanvasRenderingContext2D;
    private owner: PlayerModel;

    @Inject(Layers) protected layers: Layers;
    @Inject(Render) protected render: Render;
    @Inject(StateService) protected stateService: StateService;
    @Inject(ObjectsCollection) protected objectsCollection: ObjectsCollection;

    constructor({parent}: IUiWindowOptions) {
        super({
            left: 0,
            right: 0,
            top: 0,
            width: 190,
            height: 190,
            shadow: false,
            centered: false,
            parent
        });
        this.draw = this.draw.bind(this);

        let isDragged = false;

        this.events.on('leftMousedown', e => {
            this.updateCameraPos(e.pointers[0]);
            isDragged = true;
            Events.one('leftMouseup', () => isDragged = false);
        });

        this.events.on('drag', e => {
            if (!isDragged) { return; }

            requestRenderFrame(() => {
                this.updateCameraPos(e.pointers[0]);
            });
        });

        this.stateService.subscribe('*.data.owner', (event: IStateEvent) => {
            const [, objectId] = event.dispatch.match(/objects\.(\d+)\./);
            const changedObject = this.objectsCollection.getById(+objectId);

            if (changedObject === undefined || changedObject.renderDynamic) {
                this.drawDynamicObjects();
            }
        });

        Events.on('dynamicObjectsChanged', () => {
            this.drawDynamicObjects();
        });

        Events.on('game.startPlayerTurn', () => {
            setTimeout(() => {
                this.drawDynamicObjects();
            });
        });

        this.stateService.subscribe('center.z', () => {
            this.drawBaseMap();
            this.drawDynamicObjects();
        });

        let mapSize = parseInt(MapModel.props.map_size);
        this.ctxSize = this.width - DOUBLE_PADDING;
        this.ratio = this.ctxSize / mapSize;

        this.mapCanvas = this.layers.mapCanvas;
        this.mapRatio = this.ctxSize / (mapSize * 32);

        this.baseTerrainMap = Utils.makeCtx(this.ctxSize, this.ctxSize);
        this.dynamicMap = Utils.makeCtx(this.ctxSize, this.ctxSize);

        this.drawBaseMap();
        this.drawDynamicObjects();
    }

    private drawBaseMap() {
        const z = this.stateService.get('center.z');
        const rectSize = Math.ceil(this.ratio);

        TilesCollection.forEach(z, (...args) => {
            const [x, y, z, terrain] = Array.from(args[0]);
            const objectsInPos = MapModel.getObjectInPos(x, y, z);
            let rgbColor = MAP_COLORS[terrain][0];

            if (objectsInPos.length) {
                objectsInPos.forEach(function(object) {
                    if (object.renderDynamic) {
                        return false;
                    }
                    rgbColor = MAP_COLORS[terrain][1];
                    if (object.owner !== undefined) {
                        if (object.owner === null) {
                            rgbColor = COLORS.GREY;
                            return false;
                        }
                        const ownerColor = this.owner && this.owner.color;
                        const colorName = ownerColor ? ownerColor.toUpperCase() : null;

                        if (colorName) {
                            rgbColor = COLORS[colorName];
                            return false;
                        }
                    }
                });
            }

            this.baseTerrainMap.fillStyle = `rgb(${rgbColor.join()})`;
            let rectX = ~~(x * this.ratio);
            let rectY = ~~(y * this.ratio);

            this.baseTerrainMap.fillRect(rectX, rectY, rectSize, rectSize);
        });
    }

    private getPositionColor(x: number, y: number, z: number, terrain: number): [number, number, number] {
        const objectsInPos = MapModel.getObjectInPos(x, y, z);
        let rgbColor = null;

        objectsInPos.forEach((object: any) => {
            if (!(object && object.renderDynamic)) {
                return;
            }
            rgbColor = MAP_COLORS[terrain][1];

            if (object.owner || object.passenger !== undefined) {
                if (object.owner === null) {
                    rgbColor = COLORS.GREY;
                    return;
                }
                const owner = object.passenger
                    ? object.passenger.owner
                    : object.owner || {};
                const colorName = owner.color;

                if (colorName) {
                    rgbColor = COLORS[colorName.toUpperCase()];
                    return;
                }
            }
        });

        return rgbColor;
    }

    private drawDynamicObjects() {
        this.dynamicMap.canvas.width = this.dynamicMap.canvas.width;

        let {fog} = MapModel;
        const z = this.stateService.get('center.z');
        let rectSize = Math.ceil(this.ratio);

        TilesCollection.forEach(z, ([x, y, z, terrain]) => {
            let rgbColor = null;
            if (fog.has(x, y)) {
                rgbColor = COLORS.BLACK;
            } else {
                rgbColor = this.getPositionColor(x, y, z, terrain);
            }
            if (rgbColor) {
                this.dynamicMap.fillStyle = `rgb(${rgbColor.join()})`;
            } else {
                this.dynamicMap.fillStyle = "transparent";
            }
            x = Math.floor(x * this.ratio);
            y = Math.floor(y * this.ratio);
            this.dynamicMap.fillRect(x, y, rectSize, rectSize);
        });
    }

    draw() {
        let frameWidth;
        UiWindow.prototype.draw.call(this);

        let left = this.getLeft() + PADDING;
        let top = this.getTop() + PADDING;

        this.ctx.drawImage(this.baseTerrainMap.canvas, left, top, this.ctxSize, this.ctxSize);
        this.ctx.drawImage(this.dynamicMap.canvas, left, top, this.ctxSize, this.ctxSize);

        if (Display.offsetWidth >= 800) {
            frameWidth = (Display.offsetWidth - 190) * this.mapRatio;
        } else {
            frameWidth = Display.offsetWidth * this.mapRatio;
        }
        let frameHeight = window.innerHeight * this.mapRatio;
        let leftMax = this.ctxSize - frameWidth;
        let topMax = this.ctxSize - frameHeight;

        let rectLeft = (this.mapCanvas.canvasLeftOffset + 64) * this.mapRatio;
        let rectTop = (this.mapCanvas.canvasTopOffset + 64) * this.mapRatio;
        let rectWidth = frameWidth + Math.min(0, rectLeft) + Math.min(0, leftMax - rectLeft);
        let rectHeight = frameHeight + Math.min(0, rectTop) + Math.min(0, topMax - rectTop);

        this.ctx.strokeStyle = '#FF9103';
        this.ctx.lineWidth = 2;
        this.ctx.setLineDash([4, 4]);
        this.ctx.strokeRect(Math.max(left, left + rectLeft), Math.max(top, top + rectTop), rectWidth, rectHeight);

        this.ctx.setLineDash([0, 0]);
    }

    private updateCameraPos(pointer: Pointer) {
        const mouseX = pointer.x - this.getLeft() - PADDING;
        const mouseY = pointer.y - this.getTop() - PADDING;
        const mapCenterLeft = ~~(mouseX / this.mapRatio);
        const mapCenterTop = ~~(mouseY / this.mapRatio);

        this.render.setCenter(mapCenterLeft, mapCenterTop, this.stateService.get('center.z'));
    }
}