
import {GenericVisitableObject} from "./GenericVisitableObject";
import {UiController} from "../../../controllers/UiController";
import {ObjectsCollection} from "../../ObjectsCollection";
import {IObjectData} from "../../MapObject";
import {Inject} from "../../../helpers/InjectDectorator";

export class KeyGuardObject extends GenericVisitableObject {

    dynamic = true;
    gridDynamic = true;
    renderDynamic = true;
    restrictedPoints = false;

    @Inject(ObjectsCollection) protected objectsCollection: ObjectsCollection;

    constructor(data: IObjectData, id: number) {
        super(data, id);

        const keyId = this.objectSubClass;

        this.events.on('action', hero => {
            const {keys} = hero.owner;
            if (keys.has(keyId)) {
                //TODO: add confirmation
                setTimeout(() => {
                    this.objectsCollection.remove(this);
                });
            } else {
                UiController.alert('You need key to enter');
            }
        });
    }
}
