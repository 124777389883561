
import {Display} from "../../render/layers/Display";
import {UiWindow} from "../common/UiWindow";
import {ISavedState, StateService} from "../../services/StateService";
import * as Utils from "../../utils/Utils";
import {Inject} from "../../helpers/InjectDectorator";

const DEFAULT_PARAMS = {
    width: Math.min(800, Display.offsetWidth),
    height: 470,
    modal: true,
    pattern: 'panel_colored_bg'
};

interface ISavesList {
    name: string;
    data: ISavedState;
}

export class UiSaveWindow extends UiWindow {

    scope: any;

    @Inject(StateService) protected stateService: StateService;

    constructor(isLoadMode: boolean = false) {
        super(DEFAULT_PARAMS);

        this.scope = {
            selected: '',

            get title() {
                return isLoadMode
                    ? 'Загрузить игру'
                    : 'Сохранить игру';
            },

            close: () => {
                this.removeWnd();
            },

            saves: this.getSaves(),

            chooseAndClose: () => {
                this.applySave(this.scope.selected);
            },

            selectItem: (name: string) => {
                this.scope.selected = name;
            }
        };

        setTimeout(() => {
            this.setMarkup('game/modals/saveWindow.xml');
        })
    }

    private getSaves() {
        const savesData = this.stateService.getSavesData();
        const savesList = [];

        Utils.forEach(savesData, (data, name) => {
            savesList.push({name, data});
        });

        return savesList;
    }

    private applySave(name: string) {
        this.resolve(name);
    }
}
