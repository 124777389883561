
/* virtual class to make simple links to often user resources */
export class TownsData {

}

export interface ITownData {
    type: string[];
    terrain: number;
    textures: [string, string, string]
}

