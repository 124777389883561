
/* virtual class to make simple links to often user resources */
export class MonstersData {

}

export interface IPriceData {
    wood: number;
    mercury: number;
    ore: number;
    sulfur: number;
    crystal: number;
    gems: number;
    gold: number;
}

export interface ICreatureData {
    id: number;
    type: string;
    race: number;
    level: number;
    price: IPriceData;
    fightCost: number;
    aICost: number;
    growth: number;
    hordeGrowth: number;
    damage: {
        hitPoints: number;
        speed: number;
        attack: number;
        defense: number;
        melee: [number, number];
        shots: number;
        spellPower: number;
        magic: [number, number];
    };
    abilityText: string;
    attributes: string;
    spriteId: number;
    upgraded: boolean;
    upgradeId: number;
    dwelling: number;
}