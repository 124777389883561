
import {Sprite} from "./Sprite";

let BASE_FPS = 20;
let frameTime = 1000 / BASE_FPS;

export class AnimatedSprite extends Sprite {

    animationLength = 0;
    animationCounter = 0;
    animationInterval: number;
    animationSpeed: number;
    animated: boolean;
    spriteIndex = 0;
    currentAnimation: any;
    delay = 0;
    delayTimer = null;
    delayCallback = null;
    animateOnce: boolean;

    animationPromise: Promise<void>;
    private animationResolve: Function;

    constructor(key: string, path: string = null) {
        super(key, path);

        this.animationLength = 0;
        this.animationCounter = 0;
        this.animationInterval = null;
        this.animationSpeed = (this.settings && this.settings.animationSpeed) || 1;

        this.animated = false;
        this.spriteIndex = 0;
        this.currentAnimation = null;

        this.delay = 0;
        this.delayTimer = null;
        this.delayCallback = null;
        this.animateOnce = false;
    }

    getAnimationFrame(animation: string, index: number): number {
        return this.getAnimation(animation)[index];
    }

    getAnimation(animation: string): number[] {
        const animations = this.settings.animations || {};
        return animations[animation];
    }

    getAnimationLength(animation: string): number {
        return this.getAnimation(animation).length;
    }

    __setAnimation(animationName: string, forceRestart = false) {
        if (!this.getAnimation(animationName)) {
            this.animationResolve();
            return;
        }
        if (forceRestart) {
            clearTimeout(this.delayTimer);
            this.delayCallback = null;
        }
        if ((animationName !== this.currentAnimation) || forceRestart) {
            this.currentAnimation = animationName;
            this.animationLength = this.getAnimationLength(animationName);

            clearInterval(this.animationInterval);

            if (this.animationLength > 1) {
                // this.animationCounter = 0;
                this.animated = true;
                this.animationInterval = setInterval(() => {
                    if (this.animateOnce && (this.animationCounter >= (this.animationLength - 1))) {
                        this.animationResolve();
                    } else {
                        const frame = ~~(this.animationCounter % this.animationLength);
                        this.spriteIndex = this.getAnimationFrame(animationName, frame);
                        this.animationCounter += this.animationSpeed;
                    }
                }, frameTime);
            } else {
                this.animated = false;
                this.spriteIndex = this.getAnimationFrame(animationName, 0);
            }
        }
    }

    setAnimationOnce(animationName: string, forceRestart = false) {
        this.animateOnce = true;
        this.setAnimation(animationName, forceRestart)
            .animationPromise.then(() => {
                this.animateOnce = false;
                this.spriteIndex = this.getAnimationFrame(this.currentAnimation, 0);
                this.animated = false;
                this.animationCounter = 0;
                this.currentAnimation = null;
                clearInterval(this.animationInterval);
            });
        return this;
    }

    setAnimation(animationName: string, forceRestart = false) {
        this.animationPromise = new Promise(animationResolve => {
            this.animationResolve = animationResolve;
        });
        if (this.delay && !forceRestart) {
            this.__setDelayCallback(() => {
                this.__setAnimation(animationName);
            });
        } else {
            this.__setAnimation(animationName);
        }
        return this;
    }

    __setDelayCallback(delayCallback) {
        this.delayCallback = delayCallback;
    }

    setDelay(delayValue: number) {
        if (delayValue > 0) {
            clearTimeout(this.delayTimer);
            this.delayTimer = setTimeout(() => {
                if (typeof this.delayCallback === 'function') {
                    this.delayCallback(this.delayTimer);
                }
                return this.delayCallback = null;
            }, delayValue);
        }

        return this;
    }

    draw(x: number, y: number, spriteIndex = 0, targetCtx: CanvasRenderingContext2D = null) {}
}