
import {GenericVisitableObject} from "./GenericVisitableObject";

export class FountainOfYouthObject extends GenericVisitableObject {

    recordVisits = true;
    clearVisitsEveryDay = true;

    constructor(data, id) {
        super(data, id);

        this.events.on('action', function(hero) {
            // replace with effect on next battle
            hero.properties.get('morale').push(1);
            hero.properties.get('movePoints').push(400);
        });
    }
}
