
import {ISpriteOptions, SPRITES} from "../../constants/SPRITES";
import * as Utils from "../../utils/Utils";

export abstract class Sprite {

    key: string;
    path: string;
    settings: ISpriteOptions;
    isLoaded: boolean;
    img: HTMLImageElement;

    width: number;
    spriteWidth: number;
    height: number;
    spriteHeight: number;
    isUsed: boolean;

    promise: Promise<this>;

    constructor(key: string, path: string = null) {
        this.key = key;
        this.path = path;
        this.settings = SPRITES[this.key] || {};
        let baseUrl = '/public/';
        let imgUrl = baseUrl + String((this.settings && this.settings.path) || this.path).toLowerCase();

        this.isLoaded = false;
        this.img = new Image();
        this.width = this.settings.width || 0;
        this.height = this.settings.height || 0;
        this.isUsed = false;

        this.promise = new Promise(resolve => {
            const onLoad = () => {
                this.img.onload = null;
                this.isLoaded = true;
                this.width = this.width || this.img.width;
                this.height = this.height || this.img.height;

                this.spriteWidth = this.img.width / this.width;
                this.spriteHeight = this.img.height / this.height;

                return resolve(this);
            };
            this.img.onload = onLoad;
            this.img.src = imgUrl;
            if (this.img.complete) {
                return onLoad();
            }
        });
    }

    abstract draw(x: number, y: number, spriteIndex?: number, additional?: any);

    getSpriteDims(index: number): {x: number, y: number} {
        const y = ~~(index / this.spriteWidth);

        return {
            y,
            x: index - (y * this.spriteWidth)
        };
    }

    getSpriteFrame(frameIndex: number): Promise<HTMLImageElement> {
        let ctx = Utils.makeCtx(this.width, this.height);

        let newImage = new Image();

        let dims = this.getSpriteDims(frameIndex);
        let sx = (dims.x * this.width);
        let sy = dims.y * this.height;
        let sw = this.width;
        let sh = this.height;
        let dx = 0;
        let dy = 0;
        let dw = this.width;
        let dh = this.height;

        ctx.drawImage(this.img, sx, sy, sw, sh, dx, dy, dw, dh);

        newImage.src = ctx.canvas.toDataURL();
        return new Promise(resolve => {
            newImage.onload = () => resolve(newImage)
        });
    }
}
