
export const SPECIALITIES = [
    "ANIMATE_DEAD",
    "ARCHERY",
    "BALLISTA",
    "BASILISKS",
    "BEHEMOTHS",
    "BEHOLDERS",
    "BLESS",
    "CAVALIERS",
    "CH._LIGHTNING",
    "CRYSTAL",
    "CURE",
    "CYCLOPES",
    "DEATH_RIPPLE",
    "ARMORER",
    "DEMONS",
    "DEVILS",
    "SERPENT_FLIES",
    "BLACK_KNIGHTS",
    "DRAGONS",
    "DISRUPTING_RAY",
    "DWARVES",
    "EAGLE_EYE",
    "EARTH_ELEMENTALS",
    "EFREET",
    "ENCHANTERS",
    "ESTATES",
    "FIRE_ELEMENTALS",
    "INFERNO",
    "FIRST_AID",
    "FIRE_WALL",
    "FIRE_MAGIC",
    "FORTUNE",
    "FROST_RING",
    "GARGOYLES",
    "GEMS",
    "GENIES",
    "GNOLLS",
    "GOBLINS",
    "GOGS",
    "GOLD",
    "GOLEMS",
    "GORGONS",
    "GRIFFINS",
    "HARPIES",
    "HASTE",
    "HELL_HOUNDS",
    "HYPNOTIZE",
    "ICE_BOLT",
    "IMPS",
    "INTELLIGENCE",
    "LICHES",
    "LIZARDMEN",
    "LOGISTICS",
    "ARCHERS",
    "BLOODLUST",
    "MAGI",
    "MANTICORES",
    "MAGIC_ARROW",
    "MERCURY",
    "METEOR_SHOWER",
    "MINOTAURS",
    "MONKS",
    "MYSTICISM",
    "NAGAS",
    "NAVIGATION",
    "NECROMANCY",
    "OFFENSE",
    "OGRES",
    "ORCS",
    "PEGASI",
    "PSYCHIC_ELEMENTALS",
    "PIT_FIENDS",
    "PRAYER",
    "PRECISION",
    "RESISTANCE",
    "RESURRECTION",
    "ROCS",
    "SHARPSHOOTERS",
    "SKELETONS",
    "SLAYER",
    "SORCERY",
    "SPEED",
    "FIREBALL",
    "STONE_SKIN",
    "SULFUR",
    "SWORDSMEN",
    "HASTE",
    "STONE_SKIN",
    "DENDROIDS",
    "TROGLODYTES",
    "UNICORNS",
    "VAMPIRES",
    "WEAKNESS",
    "ELVES",
    "WATER_ELEMENTALS",
    "WIGHTS",
    "WOLF_RIDERS",
    "WYVERNS",
    "WALKING_DEAD"
];
