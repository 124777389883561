
import {Maybe} from "../helpers/Maybe";
import * as Utils from "../utils/Utils";
import {IMapDataPlayer, IMapDataProps} from "../models/map/MapDataReader";
import {UrlParamsService} from "./UrlParamsService";
import {Inject} from "../helpers/InjectDectorator";

export interface IStateEvent {
    type: string;
    dispatch: string;
}

export interface ISavedState {
    mapUrl: string;
    props: IMapDataProps;
    players: IMapDataPlayer[];
    objects: {[key: number]: any};
}

export interface ISavesData {
    [key: string]: ISavedState;
}

const LS_KEY = 'saves';

export class StateService {

    private state: any = {};
    private subscribers = new Map<string, Function[]>();
    private debug = false;

    @Inject(UrlParamsService) private urlParamsService: UrlParamsService;

    constructor() {
        this.urlParamsService.subscribe(params => {
            this.debug = params.hasOwnProperty('debug');

            if (params.hasOwnProperty('mapUrl')) {
                this.state.mapUrl = params['mapUrl'];
            }
        });
    }

    get(path: string): any {
        return Maybe(this.state).pluck(path).get();
    }

    remove(path: string) {
        const isChanged = this.setByPath(path, undefined);

        if (isChanged) {
            this.dispatch(path, 'remove');
        }
    }

    set(path: string, value: any) {
        const isChanged = this.setByPath(path, value);

        if (isChanged) {
            this.dispatch(path, 'set');
        }
    }

    forEach(path: string, callback: (item: any) => void) {
        const node = Maybe(this.state).pluck(path).getOrElse(null);

        if (node) {
            Utils.forEach(node, callback);

            this.dispatch(path, 'set');
        }
    }

    subscribe(path: string, callback: (event: IStateEvent) => void) {
        if (this.subscribers.has(path) === false) {
            this.subscribers.set(path, []);
        }

        this.subscribers.get(path).push(callback);
    }

    saveState(name: string) {
        const savedData = this.getSavesData();

        savedData[name] = this.state;

        const outData = JSON.stringify(savedData);

        console.log('Data saved to', name, outData.length);

        Maybe(localStorage)
            .pluck('setItem')
            .evaluate(localStorage, LS_KEY, outData);
    }

    loadState(name: string) {
        const savedData = this.getSavesData();

        this.state = savedData[name];
    }

    getSavesData(): ISavesData {
        return Maybe(localStorage)
            .pluck('getItem')
            .evaluate(localStorage, LS_KEY)
            .map(JSON.parse)
            .getOrElse({});
    }

    private dispatch(path: string, eventType: string) {
        this.subscribers.forEach((callbacks, key) => {

            let matchFunction = 'startsWith';

            if (key.startsWith('*')) {
                key = key.substr(1);
                matchFunction = 'endsWith';
            }

            if (path[matchFunction](key) === true) {
                const callbacksList = callbacks.slice(0);
                const event = <IStateEvent>{
                    type: eventType,
                    dispatch: path
                };

                while (callbacksList.length) {
                    callbacksList.shift()(event);
                }
            }
        });

        this.logDebug(path, eventType);
    }

    private setByPath(path: string, value: any): boolean {
        const pathToParent = path.split('.');
        const targetKey = pathToParent.pop();

        if (value === undefined) {
            const targetNode = this.tryGetNodeByPath(pathToParent);

            this.removeItemByKey(targetNode, targetKey);

            return true;
        } else {
            const targetNode = this.makeStructureByPathAndGetNode(pathToParent);
            const clonnedValue = Utils.clone(value);

            if (targetNode[targetKey] !== clonnedValue) {
                targetNode[targetKey] = clonnedValue;

                return true;
            }

            return false;
        }
    }

    private removeItemByKey(targetNode: any, targetKey: string) {
        if (targetNode === undefined) {
            return;
        }

        if (Utils.isObject(targetNode)) {
            delete targetNode[targetKey];
            return;
        }

        if (Utils.isArray(targetNode)) {
            const index = parseInt(targetKey);

            (targetNode as Array<any>).splice(index, 1);
            return;
        }
    }

    private tryGetNodeByPath(path: string[]): any {
        return path
            .reduce(((parentNode, key) => {
                return parentNode && parentNode[key];
            }), this.state)
    }

    private makeStructureByPathAndGetNode(path: string[]): any {
        return path
            .reduce(((parentNode, key) => {
                if (parentNode.hasOwnProperty(key) === false) {
                    parentNode[key] = {};
                }

                return parentNode[key];
            }), this.state)
    }

    private logDebug(path: string, eventType: string) {
        if (!this.debug) {
            return;
        }

        console.log(`StateService.${eventType}`, path, this.state);
    }
}