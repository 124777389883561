
import {Events, IKeyPressEventData, IMouseEventData} from "./Events";
import {TimeService} from "../services/TimeService";
import {KEYS} from "../constants/KEYS";
import {CursorService} from "../services/CursorService";
import {CURSORS} from "../constants/CURSORS";
import * as Utils from "../utils/Utils";
import {MapModel} from "../models/map/MapModel";
import {MapDrawer} from "../render/MapDrawer";
import {PlayerModel} from "../models/player/PlayerModel";
import {HeroesCollection} from "../models/HeroesCollection";
import {Render} from "../render/Render";
import {ObjectsCollection} from "../models/ObjectsCollection";
import {TilesCollection} from "../models/map/TilesCollection";
import {IGridStorageQueryResult} from "../models/storages/GridStorage";
import {IPath} from "../services/PathFinderService";
import {MapObject} from "../models/MapObject";
import {HeroObject} from "../models/objects/hero/HeroObject";
import {StateService} from '../services/StateService';
import {Inject, setInject} from "../helpers/InjectDectorator";
import {ActivePlayer} from "./ActivePlayer";
import {ISceneMouse, Mouse} from "./Mouse";
import {UiWindow} from "../views/common/UiWindow";
import {FocusedWindow} from "./FocusedWindow";
import {MapDataReader} from '../models/map/MapDataReader';
import {MapData} from './MapData';
import {Players} from './Players';

class SceneControllerSingleton {

    private playersByTurnOrder = [];
    private currentPlayerIndex = 0;
    private playersCount = 0;
    @Inject(MapDrawer) private mapDrawer: MapDrawer;
    @Inject(ActivePlayer) private activePlayer: PlayerModel;
    @Inject(FocusedWindow) protected focusedWindow: UiWindow;
    @Inject(Mouse) private mouse: ISceneMouse;
    @Inject(Players) private players: Map<number, PlayerModel>;
    @Inject(Render) private render: Render;
    @Inject(CursorService) private cursorService: CursorService;

    private underCursor = <IGridStorageQueryResult>{
        actions: [],
        impassable: [],
        passable: [],
        fog: false,
        terrain: '',
        road: ''
    };

    @Inject(MapData) private mapData: MapDataReader;
    @Inject(TimeService) private timeService: TimeService;
    @Inject(StateService) private stateService: StateService;
    @Inject(ObjectsCollection) private objectsCollection: ObjectsCollection;

    constructor() {
        setInject(Mouse, {
            pos: [0, 0],
            tileCoords: [0, 0, 0],
            pathLength: 0,
            pathCost: 0,
            path: <IPath>[]
        })
    }

    get selectedObject(): MapObject {
        return this.activePlayer.selectedObject;
    }

    private updateObjectUnderCursor(x: number, y: number, z: number) {
        this.underCursor = MapModel.get(x, y, z);
    }

    bootstrap() {
        this.initPlayers();

        setInject(ActivePlayer, this.playersByTurnOrder[this.currentPlayerIndex]);

        this.objectsCollection.generate(MapModel.objectsData);
        Events.dispatch('sceneInit');
        this.handleEvents();
        this.timeService.bootstrap();
        this.activePlayer.startTurn();
    }

    private initPlayers() {
        if (!this.mapData.players.find(player => player.isHuman)) {
            const player = this.mapData.players.find(player => player.canBeHuman);

            if (!player) {
                throw Error('[SceneService]: Unable to find a player then can be human');
            }

            player.isHuman = true;
        }

        this.mapData.players
            .forEach(playerData => {
                const player = new PlayerModel(playerData);
                player.colorIndex = playerData.index;

                this.players.set(playerData.index, player);
                this.playersCount++;

                if (player.isHuman) {
                    this.playersByTurnOrder.unshift(player);
                } else {
                    this.playersByTurnOrder.push(player);
                }
            });
    }

    setActivePlayer(index: number = null) {
        if (this.activePlayer.colorIndex === index) {
            return false;
        }

        if (index === null) {
            this.currentPlayerIndex = (this.currentPlayerIndex + 1) % this.playersCount;
        } else {
            this.currentPlayerIndex = index % this.playersCount;
        }

        setInject(ActivePlayer, this.playersByTurnOrder[this.currentPlayerIndex]);

        if (this.currentPlayerIndex === 0) {
            Events.dispatch('game.newTurn', this.activePlayer);
        }

        this.activePlayer.startTurn();

        Events.dispatch('game.startPlayerTurn', this.activePlayer);
    }

    private updatePathLength(dX: number, dY: number) {
        const hero = <HeroObject>this.selectedObject;

        if (hero && hero.isHero) {
            const path = hero.pathFinder.getPathTo(dX, dY);
            this.mouse.pathLength = path.length;
            this.mouse.pathCost = path.totalCost;
            this.mouse.path = path;
        }
    };

    private getMapCursor(): string {
        const hero = <HeroObject>this.selectedObject;

        if (hero && hero.isHero && this.mouse.pathLength) {
            const [action] = this.underCursor.actions;
            if (action) {
                const mapObject = this.objectsCollection.getById(+action);
                if (mapObject) {
                    return mapObject.getActionCursor();
                }
            }
        }

        const [impassable] = this.underCursor.impassable;

        if (impassable) {
            const mapObject = this.objectsCollection.getById(+impassable);

            if (mapObject) {
                return mapObject.getCursor();
            }
        }

        if (this.mouse.pathLength) {
            return TilesCollection.getCursor(this.mouse.pathLength);
        }

        return CURSORS.DEFAULT;
    };

    private handleMapClick(e: IMouseEventData) {
        const [x, y] = Array.from(this.mouse.tileCoords);
        const hero = <HeroObject>this.selectedObject;

        if (hero && hero.isHero) {
            if (this.mouse.pathLength && !hero.isMoving) {
                const lastPathItem = hero.pathFinder.items[hero.pathFinder.items.length - 1];

                if (lastPathItem && lastPathItem.point.is(x, y)) {
                    this.activePlayer.moveHero(x, y);
                } else {
                    hero.pathFinder.pathTo(x, y);
                }

                return;
            }
        }

        this.dispatchEventOnMap('leftClick', e);
    };

    private handleMouseStopMoving() {
        if (Events.isDrag()) {
            return;
        }
        const [x, y, z] = Array.from(this.mouse.tileCoords);

        if (MapModel.isPointInMap(this.mouse.pos[0], this.mouse.pos[1])) {
            this.updateObjectUnderCursor(x, y, z);
            this.updatePathLength(x, y);
            this.cursorService.set(this.getMapCursor());
        } else {
            this.underCursor = {
                actions: [],
                impassable: [],
                passable: [],
                fog: false,
                terrain: '',
                road: ''
            };
            this.mouse.pathLength = 0;
            this.mouse.pathCost = 0;
            this.mouse.path = null;
            this.cursorService.set(CURSORS.DEFAULT);
        }
    };

    private handleMapDblClick(e) {
        const [x, y] = this.mouse.tileCoords;
        const hero = <HeroObject>this.selectedObject;

        if (hero && hero.isHero) {
            if (this.mouse.pathLength && !hero.isMoving) {
                hero.pathFinder.pathTo(x, y);
                this.activePlayer.moveHero(x, y);

                return false;
            }
        }
        this.dispatchEventOnMap('dblClick', e);
    };

    private updateMapMousePos(e: IMouseEventData) {
        let mapOffset = this.mapDrawer.getMapOffset();
        let left = e.pointers[0].x - mapOffset.left;
        let top = e.pointers[0].y - mapOffset.top;
        const z = this.stateService.get('center.z');
        this.mouse.pos = [left, top];
        this.mouse.tileCoords = [left >> 5, top >> 5, z];
    };

    private dispatchEventOnMap(eventType: string, e: IMouseEventData | IMouseEventData) {
        if (this.underCursor.fog) {
            TilesCollection.events.dispatch(`fog_${eventType}`, e);
            return;
        }

        const impassableObject = this.objectsCollection.getById(+this.underCursor.impassable[0]);

        if (impassableObject) {
            impassableObject.events.dispatch(eventType, e);
            return;
        }

        const passableObject = this.objectsCollection.getById(+this.underCursor.passable[0]);

        if (passableObject) {
            passableObject.events.dispatch(eventType, e);
            return;
        }

        TilesCollection.events.dispatch(eventType, e);
    };

    private debugObjectsList(ids) {
        this.objectsCollection.list(ids).forEach(object =>
            Utils.consoleLog(
                [`[${object.x}, ${object.y}]`, "3180E6"],
                [`{${object.type}}`, "19A297"],
                [`(class:${object.objectClass}:${object.objectSubClass})`, "B671F3"],
                object
            )
        );
    };

    private handleDblClick(e: Event) {
        if (this.focusedWindow) {
            this.focusedWindow.events.dispatch('dblClick', e);
        } else {
            this.handleMapDblClick(e);
        }
    }

    private handleMouseStop(e: IMouseEventData) {
        if (!this.focusedWindow) {
            this.handleMouseStopMoving();

            if (!e.touch) {
                this.cursorService.showInfo();
            }
        }
    }

    private handleRightMouseDown(e: IMouseEventData) {
        if (this.focusedWindow) {
            this.focusedWindow.events.dispatch('rightMousedown', e);
        } else {
            this.updateMapMousePos(e);
            this.handleMouseStopMoving();
            this.dispatchEventOnMap('rightMousedown', e);
        }
    }

    private handleMouseMove(e: IMouseEventData) {
        if (Events.isDrag()) {
            this.cursorService.set(CURSORS.DRAG);
        }

        this.updateMapMousePos(e);
        this.cursorService.hideInfo();

        if (this.focusedWindow) {
            this.focusedWindow.events.dispatch('mousemove', e);
            this.cursorService.set(this.focusedWindow.getCursor());
        }
    }

    private handleLeftClick(e: IMouseEventData) {
        if (this.focusedWindow) {
            this.focusedWindow.events.dispatch('leftClick', e);
        } else {
            this.updateMapMousePos(e);
            this.handleMouseStopMoving();
            this.handleMapClick(e);

            const [x, y, z] = Array.from(this.mouse.tileCoords);
            const mapQueryResult = MapModel.grid.get(x, y);
            const {actions, impassable, passable} = mapQueryResult;
            const pressedKeys = Events.getKeys();

            if (pressedKeys.has(KEYS.K_ALT)) {
                console.log(`GridStorage cell [${this.mouse.tileCoords.join()}]:`, mapQueryResult);
            }

            if (pressedKeys.has(KEYS.K_CTRL)) {
                console.groupCollapsed(`ACTIONS (${actions.length})`);
                this.debugObjectsList(actions);
                console.groupEnd();
                console.groupCollapsed(`IMPASSABLE (${impassable.length})`);
                this.debugObjectsList(impassable);
                console.groupEnd();
                console.groupCollapsed(`PASSABLE (${passable.length})`);
                this.debugObjectsList(passable);
                console.groupEnd();
            }
        }
    }

    private handleEventByType(eventType: string) {
        Events.on(eventType, (e) => {
            if (this.focusedWindow) {
                this.focusedWindow.events.dispatch(eventType, e);
            } else {
                this.dispatchEventOnMap(eventType, e);
            }
        })
    }

    private handleKeyPress(e: IKeyPressEventData) {
        const hero = <HeroObject>this.selectedObject;

        if (this.focusedWindow) {
            this.focusedWindow.events.dispatch('keypress', e);
        } else {
            if (hero && hero.isHero) {
                hero.handleInput();
            }
        }

        if (e.keys.has(KEYS.K_CTRL)) {

            // right
            if (e.keys.has(KEYS.K_RIGHT)) {
                this.render.hitCamera(-32, 0);
            }

            // left
            if (e.keys.has(KEYS.K_LEFT)) {
                this.render.hitCamera(32, 0);
            }

            // top
            if (e.keys.has(KEYS.K_UP)) {
                this.render.hitCamera(0, 32);
            }

            // bottom
            if (e.keys.has(KEYS.K_DOWN)) {
                this.render.hitCamera(0, -32);
            }
        }
    }

    private handleEvents() {
        this.stateService.subscribe('time.week', () => {
            HeroesCollection.initTavern()
        });

        Events.on('dblClick', (e) => this.handleDblClick(e));

        Events.on('mousestop', (e) => this.handleMouseStop(e));

        Events.on('rightMousedown', (e) => this.handleRightMouseDown(e));

        Events.on('mousemove', (e) => this.handleMouseMove(e));

        Events.on('touchstart',(e) => this.updateMapMousePos(e));

        Events.on('leftClick', (e) => this.handleLeftClick(e));

        [
            'leftMousedown',
            'leftMouseup',
            'mouseWheel',
            'rightMousedown',
            'rightMouseup',
            'drag',
            'dragEnd',
            'touchstart',
            'touchend'
        ].forEach(eventType => this.handleEventByType(eventType));

        Events.on('keypress', (e) => this.handleKeyPress(e));
    }
}

export const SceneController = new SceneControllerSingleton();