
import {UiWindow} from "../common/UiWindow";
import {Display} from "../../render/layers/Display";
import {IMapDataPlayer, MapDataReader} from "../../models/map/MapDataReader";
import {Router} from "../../controllers/Router";
import {UiTownHeroWindow} from "../windows/UiTownHeroWindow";
import {CursorService} from "../../services/CursorService";
import {CURSORS} from "../../constants/CURSORS";
import {UiSprite} from "../sprites/UiSprite";
import {UiCheckbox} from "../common/UiCheckbox";
import {UiController} from "../../controllers/UiController";
import {StateService} from "../../services/StateService";
import {ITextures, Textures} from '../../controllers/Textures';
import {Inject} from '../../helpers/InjectDectorator';
import {MapData} from '../../controllers/MapData';

const BUTTON_HEIGHT = 26;

export class UiGameOptions extends UiWindow {
    private mainmenubg: UiSprite;
    private uiWidth: number;
    private uiHeight: number;

    private selectedPlayer: IMapDataPlayer;
    private selectablePlayers: IMapDataPlayer[];
    @Inject(Textures) protected textures: ITextures;
    @Inject(MapData) protected mapData: MapDataReader;
    @Inject(Router) protected router: Router;
    @Inject(CursorService) protected cursorService: CursorService;
    @Inject(StateService) private stateService: StateService;

    scope: any;

    constructor() {
        const uiWidth = Math.min(Display.offsetWidth, 900);
        const uiHeight = Math.min(Display.offsetHeight, 800);
        const options = {
            width: uiWidth,
            height: uiHeight,
            centered: true,
            pattern: 'panel_colored_bg'
        };

        super(options);

        this.draw = this.draw.bind(this);
        this.uiWidth = uiWidth;
        this.uiHeight = uiHeight;

        const {props, players} = this.mapData;

        this.setDefaultPlayerSettings();

        this.scope = {
            getWidth() {
                return Math.min(Display.offsetWidth, 900);
            },
            getHeight() {
                return Math.min(Display.offsetHeight, 800);
            },

            getMapHeader() {
                return props.name;
            },

            players,

            goBack: () => {
                this.router.go('new_game');
            },

            startGame: () => {
                this.router.go('game');
            },

            isSelectable: (player: IMapDataPlayer): boolean => {
                return this.selectablePlayers.includes(player);
            },

            selectPlayer: (player: IMapDataPlayer) => {
                if (!this.scope.isSelectable(player)) {
                    return;
                }
                this.selectedPlayer = player;

                this.stateService.forEach('players', (srcPlayer: IMapDataPlayer) => {
                    srcPlayer.isHuman = srcPlayer === player;
                });

                this.queryAll('group-players')
                    .forEach((item: UiCheckbox, index) => item.checked = players[index] === player);
            },

            selectTownAndHero: (player: IMapDataPlayer) => {
                UiController.modal(UiTownHeroWindow, player)
                    .promise.then(() => {
                        this.events.dispatch('updatePlayersList');
                        setTimeout(() => this.scope.selectPlayer(this.scope.selectedPlayer));
                    });
            },

            header: {
                height: 100,
                mapsCount: `Maps: ${0}`
            },

            leftSide: {
                isHidden: () => {
                    return Display.offsetWidth < 800;
                },
                getRight: () => {
                    if (Display.offsetWidth >= 800) {
                        return 265;
                    } else {
                        return 15;
                    }
                }
            }
        };

        this.mainmenubg = <UiSprite>this.textures.get('main_menu_bg');
        this.cursorService.set(CURSORS.DEFAULT);

        this.setMarkup('mainMenu/gameOptions.xml').then(() => {
            this.scope.selectPlayer(this.selectedPlayer);
        });
    }

    draw() {
        let left = (Display.offsetWidth - 1920) / 2;
        let top = (Display.offsetHeight - 1080) / 2;
        this.mainmenubg.draw(left, top);

        UiWindow.prototype.draw.call(this);

        this.line({
            left: 12,
            top: 98,
            width: this.uiWidth - 24
        });
    }

    private getTownRace(townSpriteIndex: number): number {
        return ((townSpriteIndex - 2) >> 2) - 1;
    }

    private setDefaultPlayerSettings() {
        const {players} = this.mapData;

        this.selectablePlayers = players.filter(({canBeHuman}) => canBeHuman);
        this.selectedPlayer = this.selectablePlayers[0];
        this.selectedPlayer.isHuman = true;

        this.stateService.set('players', players);

        this.stateService.forEach('players', (player: IMapDataPlayer) => {
            player.selectedRace = this.getTownRace(player.mainTownSpriteIndex);
            player.selectedHero = player.mainHeroSpriteIndex;
        });
    }
}
