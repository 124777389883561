
import {IUiOptions, UiPanel} from "../../UiPanel";
import {Display} from "../../../render/layers/Display";
import {Events} from "../../../controllers/Events";
import {UiSpellBookWindow} from "../../windows/UiSpellBookWindow";
import {UiSettingsWindow} from "../../windows/UiSettingsWindow";
import {HERO_STATES} from "../../../constants/HERO_STATES";
import {UiController} from "../../../controllers/UiController";
import {UiStatusWindow} from "../statusWindow/UiStatusWindow";
import {UiComponent} from "../../../services/UiComponentsService";
import * as Utils from "../../../utils/Utils";
import {StateService} from "../../../services/StateService";
import {HeroObject} from "../../../models/objects/hero/HeroObject";
import {Inject} from "../../../helpers/InjectDectorator";
import {ActivePlayer} from "../../../controllers/ActivePlayer";
import {PlayerModel} from "../../../models/player/PlayerModel";

const DEFAULT_WIDTH = 190;

@UiComponent()
export class UiSidebar extends UiPanel {

    WIDTH = DEFAULT_WIDTH;
    scope: any;

    private buttons: any;
    @Inject(ActivePlayer) private activePlayer: PlayerModel;
    @Inject(StateService) protected stateService: StateService;

    constructor() {
        super({
            left: Display.offsetWidth - DEFAULT_WIDTH - 3,
            top: 3,
            width: DEFAULT_WIDTH,
            height: () => Display.offsetHeight - 25
        });

        this.options = Utils.extend(this.options, <IUiOptions>{
            left: () => Display.offsetWidth - this.WIDTH - 3,
            width: () => this.WIDTH
        });

        this.updateButtons = this.updateButtons.bind(this);

        this.buttons = {};

        this.scope = {
            width: this.WIDTH,

            middlePanel: {
                height: this.ctx.canvas.height - (this.WIDTH * 2) - 25
            },

            getHeroes: () => {
                return this.activePlayer.heroes.filter(hero => !hero.hidden);
            },

            getTowns: () => {
                return this.activePlayer.towns;
            },

            buttons: [
                [0, 0, 'city_btn', this.selectNextTown.bind(this)],
                [1, 0, 'bottom_level_btn', this.switchTerrainLevel.bind(this)],
                [0, 1, 'schedule_btn', this.btnCallback.bind(this)],
                [1, 1, 'sleep_btn', this.btnCallback.bind(this)],
                [0, 2, 'go_btn', this.walkByPath.bind(this)],
                [1, 2, 'magic_btn', this.spellBookBtnClick.bind(this)],
                [0, 3, 'function_btn', this.btnCallback.bind(this)],
                [1, 3, 'system_btn', this.showSettingsWindow.bind(this)],
                [0, 4, 'hero_btn', this.selectNextHero.bind(this)],
                [0, 5, 'day_end_btn', this.endTurn.bind(this)]
            ].map(([x, y, id, callback]: [number, number, string, any]) => {
                return {
                    left: x * 33,
                    top: y * 33,
                    sprite: id.toLowerCase(),
                    id,
                    callback,
                    tipKey: `UI.GAME.SIDEBAR.FUNCTIONS_PANEL.${id.toUpperCase()}`
                };
            })
        };

        this.setMarkup('game/sidebar.xml');

        UiController.statusWindow = <UiStatusWindow>this.query('statusWindow');
        this.scope.buttons.forEach(btn => {
            return this.buttons[btn.id] = this.query(btn.id);
        });
        this.updateButtons();
        Events
            .on('hero.changeState', this.updateButtons)
            .on('hero.changePath', this.updateButtons);

        this.stateService.subscribe('*.selectedObject', () => {
            this.updateButtons();
        });

        this.stateService.subscribe('*.heroes', () => {
            this.events.dispatch('updateHeroes');
        });

        this.stateService.subscribe('*.towns', () => {
            this.events.dispatch('updateTowns');
        });
    }

    private selectNextHero() {
        this.activePlayer.selectNextHero();
    }

    private selectNextTown() {
        this.activePlayer.selectNextTown();
    }

    private walkByPath() {
        this.activePlayer.walkByPath();
    }

    private showSettingsWindow() {
        UiController.modal(UiSettingsWindow);
    }

    private endTurn() {
        this.activePlayer.command('game.endPlayerTurn');
    }

    private spellBookBtnClick() {
        UiController.modal(UiSpellBookWindow);
    }

    private switchTerrainLevel() {
        if (this.stateService.get('center.z') === 1) {
            this.stateService.set('center.z', 0);
        } else {
            this.stateService.set('center.z', 1);
        }
    }

    private btnCallback() {
        return console.log('btnCallback');
    }

    private updateButtons() {
        const {selectedObject} = this.activePlayer;
        const hero = <HeroObject>selectedObject;

        for (let btnName in this.buttons) {
            let btn = this.buttons[btnName];

            btn.setEnabled((hero && hero.state) !== HERO_STATES.GO);
        }

        if ((hero && hero.state) !== HERO_STATES.GO) {
            let {hero_btn, go_btn, sleep_btn, magic_btn} = this.buttons;
            hero_btn
                .setEnabled(this.activePlayer.heroes.length);
            go_btn
                .setEnabled(hero && hero.isHero && hero.pathFinder.items.length);
            sleep_btn
                .setEnabled(hero && hero.isHero);
            magic_btn
                .setEnabled(hero && hero.isHero);
        }
    }

    show() {
        this.width = (this.WIDTH = DEFAULT_WIDTH);
        this.hidden = false;
        this.__update();
    }

    hide() {
        this.WIDTH = 0;
        this.width = 0;
        this.hidden = true;
        this.__update();
    }
}