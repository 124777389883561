
import {Grid} from "../../helpers/Grid";
import {Events} from "../../controllers/Events";
import * as Utils from "../../utils/Utils";
import {RenderTree} from "../../render/RenderTree";
import {Rect} from "../../helpers/Rect";
import {Layers} from "../../render/layers/Layers";
import {Canvas} from "../../render/layers/Canvas";
import {UiSprite} from "../../views/sprites/UiSprite";
import {ITextures, Textures} from '../../controllers/Textures';
import {Inject} from '../../helpers/InjectDectorator';

const TERRAIN_SECTION_SIZE = 8;
const TILE_SIZE = 32;

export class FogModel {
    size: number;
    renderTree: RenderTree;

    private data: Grid<number>;
    private renderSections: Grid<HTMLCanvasElement>;
    @Inject(Textures) private textures: ITextures;
    @Inject(Layers) private layers: Layers;

    constructor(size: number) {
        this.size = size;
        this.data = new Grid<number>(this.size);
        for (let x = 0; x < this.size; x++) {
            for (let y = 0; y < this.size; y++) {
                this.data.set(x, y, -1);
            }
        }

        this.renderTree = new RenderTree(32, 32);

        Events.on('texturesLoaded', () => {
            this.prepareRenderTree();
        });
    }

    private drawFogTile(x: number, y: number, fogSprite: UiSprite, ctx: CanvasRenderingContext2D) {
        const fog = this.data.get(x, y);
        if (fog === -1) {
            return;
        }
        const left = x << 5;
        const top = y << 5;
        fogSprite.draw(left, top, fog, ctx);
    }

    private prepareRenderTree() {
        this.renderTree.clear();
        const fogSprite = this.textures.get('fog');
        this.data.forEach((fog, x, y) => {
            const left = x << 5;
            const top = y << 5;
            this.renderTree.add(top, left, this.drawFogTile.bind(this, x, y, fogSprite));
        });

        this.makeRenderSections();
    }

    private getSpriteIndex(maskGrid: Grid, x: number, y: number): number {
        const get = function(tx, ty) {
            if (maskGrid.get(x + tx, y + ty) === 0) {
                return 0;
            } else {
                return 1;
            }
        };

        if (!get(0, 0)) {
            return -1;
        }

        // Sorry, but it is a fastest way to make integer from bitwise sequence
        // Bits gets from 3x3 matrix of graph links
        // 0 1 2      0 1 1
        // 7 x 3  =>  0 x 0  =>  01100010
        // 6 5 4      1 0 0
        let result = (get(-1, -1)<<7) | (get(0, -1)<<6) | (get(1, -1)<<5) | (get(1, 0)<<4) | (get(1, 1)<<3) | (get(0, 1)<<2) | (get(-1, 1)<<1) | get(-1, 0);

        // If all fog tile neighbours is filled
        if (result === 255) {
            // Get sprite index from x and y
            result += (Math.abs(y * this.size) + Math.abs(x)) % 5;
        }

        return result;
    }

    private updateRenderSections(maskGrid: Grid) {
        const {mapCanvas} = this.layers;
        const alreadyUpdated = new Grid(maskGrid.size);
        const sectionSize = TERRAIN_SECTION_SIZE * TILE_SIZE;

        maskGrid.forEach((hasFog, x, y) => {
            const xOffset = ~~((x * TILE_SIZE) / sectionSize);
            const yOffset = ~~((y * TILE_SIZE) / sectionSize);
            const canvas = this.renderSections.get(xOffset, yOffset);

            if (!alreadyUpdated.has(xOffset, yOffset) && canvas) {
                canvas.width = canvas.width;
                const ctx = canvas.getContext('2d');
                const left = xOffset * TERRAIN_SECTION_SIZE * TILE_SIZE;
                const top = yOffset * TERRAIN_SECTION_SIZE * TILE_SIZE;
                const sectionLeftOffset = left - mapCanvas.canvasLeftOffset;
                const sectionTopOffset = top - mapCanvas.canvasTopOffset;

                ctx.translate(-(sectionLeftOffset), -(sectionTopOffset));

                const rect = <Rect>{
                    left,
                    top,
                    right: left + (TERRAIN_SECTION_SIZE * TILE_SIZE),
                    bottom: top + (TERRAIN_SECTION_SIZE * TILE_SIZE) + TILE_SIZE
                };

                this.renderTree.drawRect(rect, ctx);

                alreadyUpdated.set(xOffset, yOffset, true);
            }
        });
    }

    private makeRenderSections() {
        const maxSize = Math.ceil(this.size / TERRAIN_SECTION_SIZE);
        const sectionCanvasSize = TERRAIN_SECTION_SIZE * TILE_SIZE;
        this.renderSections = new Grid(maxSize);
        const {mapCanvas} = this.layers;

        for (let yOffset = 0; yOffset <= maxSize; yOffset++) {
            for (let xOffset = 0; xOffset <= maxSize; xOffset++) {
                const ctx = Utils.makeCtx(sectionCanvasSize, sectionCanvasSize);
                const left = xOffset * TERRAIN_SECTION_SIZE * TILE_SIZE;
                const top = yOffset * TERRAIN_SECTION_SIZE * TILE_SIZE;
                const sectionLeftOffset = left - mapCanvas.canvasLeftOffset;
                const sectionTopOffset = top - mapCanvas.canvasTopOffset;

                ctx.translate(-(sectionLeftOffset), -(sectionTopOffset));

                const rect = <Rect>{
                    left,
                    top,
                    right: left + (TERRAIN_SECTION_SIZE * TILE_SIZE),
                    bottom: top + (TERRAIN_SECTION_SIZE * TILE_SIZE) + TILE_SIZE
                };

                this.renderTree.drawRect(rect, ctx);

                this.renderSections.set(xOffset, yOffset, ctx.canvas);
            }
        }
    }

    update(maskGrid: Grid<number>) {
        maskGrid.forEach((hasFog, x, y) => {
            this.data.set(x, y, this.getSpriteIndex(maskGrid, x, y));
        });

        if (this.renderSections) {
            this.updateRenderSections(maskGrid);
        }
    }

    has(x: number, y: number): boolean {
        return this.data.get(x, y) > -1;
    }

    draw(canvas: Canvas) {
        const sectionSize = TERRAIN_SECTION_SIZE * TILE_SIZE;
        const {mapCanvas} = this.layers;

        this.renderSections.forEach((sectionCanvas, xOffset, yOffset) => {
            const drawLeft = (xOffset * sectionSize) - mapCanvas.canvasLeftOffset;
            const drawTop = (yOffset * sectionSize) - mapCanvas.canvasTopOffset;

            canvas.ctx.drawImage(sectionCanvas, drawLeft, drawTop);
        });
    }
}