
import {IObjectData} from "../../MapObject";
import {KeyGuardObject} from "./KeyGuardObject";
import {KeymastersTentObject} from "./KeymastersTentObject";
import {CorpseObject} from "./CorpseObject";
import {MarlettoTowerObject} from "./MarlettoTowerObject";
import {FountainObject} from "./FountainObject";
import {FountainOfYouthObject} from "./FountainOfYouthObject";
import {GardenOfRevelationObject} from "./GardenOfRevelationObject";
import {LeanToObject} from "./LeanToObject";
import {MonolithOneWayObject} from "./MonolithOneWayObject";
import {MonolithTwoWayObject} from "./MonolithTwoWayObject";
import {MagicSpringObject} from "./MagicSpringObject";
import {MagicWellObject} from "./MagicWellObject";
import {TrainingCampObject} from "./TrainingCampObject";
import {MysticalGardenObject} from "./MysticalGardenObject";
import {RedwoodObservatoryObject} from "./RedwoodObservatoryObject";
import {StarAxisObject} from "./StarAxisObject";
import {LearningStoneObject} from "./LearningStoneObject";
import {KnowledgeTreeObject} from "./KnowledgeTreeObject";
import {WaterWheelObject} from "./WaterWheelObject";
import {WindMillObject} from "./WindMillObject";
import {GenericVisitableObject} from "./GenericVisitableObject";
import {MapComponent} from '../../MapComponent';

const TYPES = {
    // 2: ScaffoldObject
    // 4: CollizeumObject
    // 7: MarketObject
    [9]: KeyGuardObject,
    [10]: KeymastersTentObject,
    // 11: OceanBottleObject
    // 14: LuckGeneratorObject
    // 16: MonstersHouseObject
    [22]: CorpseObject,
    [23]: MarlettoTowerObject,
    // 28: LuckGeneratorObject
    [30]: FountainObject,
    [31]: FountainOfYouthObject,
    [32]: GardenOfRevelationObject,
    // 38: IdolObject
    [39]: LeanToObject,
    // 41: AbandonedTempleObject
    [43]: MonolithOneWayObject,
    [45]: MonolithTwoWayObject,
    // 47: MagicTowerObject
    [48]: MagicSpringObject,
    [49]: MagicWellObject,
    [51]: TrainingCampObject,
    [55]: MysticalGardenObject,
    [58]: RedwoodObservatoryObject,
    [60]: RedwoodObservatoryObject,
    [61]: StarAxisObject,
    // 63: PyramidObject
    // 64: RallyFlagObject
    // 78: RefugeeCampObject
    // 84: AbandonedTempleObject
    // 85: AbandonedShipObject
    // 92: SirensObject
    // 94: StablesObject
    // 96: TempleObject
    // 97: ThiefHouseObject
    [100]: LearningStoneObject,
    [102]: KnowledgeTreeObject,
    // 105: WagonTrainObject
    // 106: WorkshopObject
    [109]: WaterWheelObject,
    // 111: WhirlpoolObject
    [112]: WindMillObject
    // 137: WarAcademyObject
    // 221: MarketObject
};

@MapComponent('generic_visitable')
export class GenericVisitableFabric {
    static isFabric = true;

    static getData(data: IObjectData) {
        const constructor = TYPES[data.object_class] || GenericVisitableObject;

        return constructor.prototype.prepare(data);
    }

    static get(data: IObjectData, id: number) {
        const constructor = TYPES[data.object_class] || GenericVisitableObject;

        return new constructor(data, id);
    }
}
