
import * as Utils from "../../utils/Utils";
import {IUiOptions, UiPanel} from "../UiPanel";
import {UiComponent} from "../../services/UiComponentsService";

const DEFAULT_PARAMS = <IUiOptions>{
    left: 0,
    top: 0
};

@UiComponent()
export class UiGroup extends UiPanel {

    private bordered = false;

    constructor(params: IUiOptions) {
        super(Utils.extend(DEFAULT_PARAMS, params));

        this.draw = this.draw.bind(this);
        this.bordered = Boolean(this.readParam('bordered'));

        if (this.options.hasOwnProperty('if')) {
            this.canHaveChildren = Boolean(this.readParam('if'));
        }
    }

    draw() {
        if (this.bordered) {
            this.rect({
                left: 0,
                top: 0,
                width: this.width,
                height: this.height
            });
        }

        this.children.forEach(wnd => wnd.draw());
    }
}