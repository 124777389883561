
import {UiPanel} from "../../UiPanel";
import {IStatusWindowState} from "./IStatusWindowState";
import {UiHeroTipWindow} from "../../windows/UiHeroTipWindow";
import {UiTownTipWindow} from "../../windows/UiTownTipWindow";
import {UiWindow} from "../../common/UiWindow";
import {UiController} from "../../../controllers/UiController";
import {Inject} from "../../../helpers/InjectDectorator";
import {ActivePlayer} from "../../../controllers/ActivePlayer";
import {PlayerModel} from "../../../models/player/PlayerModel";

export class SelectedObjectScreen extends UiPanel implements IStatusWindowState {
    stateParams: { [key: string]: any };

    private embededWnd: UiWindow;
    @Inject(ActivePlayer) protected activePlayer: PlayerModel;

    prepare() {

        let {selectedObject} = this.activePlayer;
        let WndConstructor;
        if ((selectedObject as any).isHero) {
            WndConstructor = UiHeroTipWindow;
        } else {
            WndConstructor = UiTownTipWindow;
        }

        this.embededWnd = new WndConstructor(selectedObject, {
            left: 0,
            top: 0,
            shadow: false,
            hideCursor: false,
            parent: this
        });
    }

    exit() {
        UiController.remove(this.embededWnd);
    }

    draw() {
        this.embededWnd.draw();
    }
}