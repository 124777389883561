
import * as Utils from "../../../utils/Utils";
import {UiController} from "../../../controllers/UiController";
import {ResourcesStorage} from "../../storages/ResourcesStorage";
import {GenericTreasureObject} from "./GenericTreasureObject";
import {IObjectData} from "../../MapObject";
import {ObjectsCollection} from "../../ObjectsCollection";
import {Inject} from "../../../helpers/InjectDectorator";

export class CampfireObject extends GenericTreasureObject {

    resources: ResourcesStorage;

    @Inject(ObjectsCollection) protected objectsCollection: ObjectsCollection;

    constructor(data: IObjectData, id: number) {
        super(data, id);

        const params = {
            gold: Utils.something([400, 500, 600])
        };
        const secondResource = Utils.something(['wood', 'mercury', 'stone', 'sulfur', 'crystals', 'gems']);

        params[secondResource] = Utils.something([4, 5, 6]);

        this.resources.set(params);

        this.events.on('action', hero => {
            UiController.statusWindow.resourceTransferMessage(this.resources);
            this.flushResources(hero);
            setTimeout(() => {
                this.objectsCollection.remove(this);
            });
        });
    }
}
