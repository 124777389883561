
import {IUiOptions, UiPanel} from "../../UiPanel";
import * as Utils from "../../../utils/Utils";
import {UiSprite} from "../../sprites/UiSprite";
import {CURSORS} from "../../../constants/CURSORS";
import {UiComponent} from "../../../services/UiComponentsService";
import {StateService} from "../../../services/StateService";
import {ITextures, Textures} from '../../../controllers/Textures';
import {Inject} from '../../../helpers/InjectDectorator';

const DEFAULT_PARAMS = {
    left: 0,
    top: 0,
    width: 62,
    height: 32,
    hero: null
};

@UiComponent()
export class UiSidebarHero extends UiPanel {
    private portraitSprite: UiSprite;
    private manaPointsSprite: UiSprite;
    private movePointsSprite: UiSprite;
    private portrait: number;
    private isSelected: boolean;
    private movePoints: number;
    private manaPoints: number;

    @Inject(Textures) protected textures: ITextures;
    @Inject(StateService) protected stateService: StateService;

    constructor(params: IUiOptions) {
        super(Utils.extend(DEFAULT_PARAMS, params));

        this.setMovePoints = this.setMovePoints.bind(this);
        this.setManaPoints = this.setManaPoints.bind(this);
        this.draw = this.draw.bind(this);

        const hero = this.readParam('hero');

        this.portraitSprite = <UiSprite>this.textures.get('heroes_portraits_sm');
        this.manaPointsSprite = <UiSprite>this.textures.get('mana_points');
        this.movePointsSprite = <UiSprite>this.textures.get('move_points');
        this.setMovePoints(hero.properties.getValue('movePoints'));
        this.setManaPoints(hero.properties.getValue('manaPoints'));

        this.portrait = hero.getProperty('person');

        this.events
            .on('rightMousedown', () => hero.events.dispatch('rightMousedown'));

        this.isSelected = hero.isSelected();
        this.stateService.subscribe('*.selectedObject', () => {
            this.isSelected = hero.isSelected();
        });

        hero.properties.watch('movePoints', this.setMovePoints);
        hero.properties.watch('manaPoints', this.setManaPoints);
    }

    private setMovePoints(value: number) {
        this.movePoints = Math.min(~~(value / 100), 25);
    }

    private setManaPoints(value: number) {
        this.manaPoints = Math.min(value, 25);
    }

    getCursor(): string {
        return CURSORS.POINTER;
    }

    draw() {
        const left = this.getLeft();
        const top = this.getTop();

        this.ctx.fillStyle = '#000';
        this.ctx.fillRect(left, top, this.width, this.height);

        this.movePointsSprite.draw(left, top + 1, this.movePoints, this.ctx);
        this.portraitSprite.draw(left + 7, top, this.portrait, this.ctx);
        this.manaPointsSprite.draw(left + 56, top + 1, this.manaPoints, this.ctx);

        if (this.isSelected) {
            this.ctx.lineWidth = 1;
            this.ctx.strokeStyle = '#cc0';
            this.ctx.strokeRect(left + 7.5, top + 1.5, 47, this.height - 3);
        }
    }
}
