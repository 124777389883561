
import * as Utils from "../../utils/Utils";
import {BallistaObject} from "../objects/battlefield/BallistaObject";
import {AmmoCartObject} from "../objects/battlefield/AmmoCartObject";
import {FirstAidTentObject} from "../objects/battlefield/FirstAidTentObject";
import {inject, Inject} from "../../helpers/InjectDectorator";
import {ICreatureData, MonstersData} from "../../controllers/MonstersData";
import {IHeroStackConfig} from "../../controllers/HeroesData";
import {ArtifactsData} from '../../controllers/ArtifactsData';

export interface IAbstractArtifact {
    artifactData: IArtifactData
}

interface IArtifactsStorage {
    belt: IAbstractArtifact[],
    spellbook: IAbstractArtifact,
    headwear: IAbstractArtifact,
    neck: IAbstractArtifact,
    shoulders: IAbstractArtifact,
    right_hand: IAbstractArtifact,
    left_hand: IAbstractArtifact,
    torso: IAbstractArtifact,
    right_ring: IAbstractArtifact,
    left_ring: IAbstractArtifact,
    feet: IAbstractArtifact,
    misc1: IAbstractArtifact,
    misc2: IAbstractArtifact,
    misc3: IAbstractArtifact,
    misc4: IAbstractArtifact,
    misc5: IAbstractArtifact,
    device1: IAbstractArtifact,
    device2: IAbstractArtifact,
    device3: IAbstractArtifact,
    device4: IAbstractArtifact
}

export type artifactSlotType = 'spellbook'
    | 'headwear' | 'neck' | 'shoulders' | 'right_hand' | 'left_hand' | 'feet'
    | 'misc' | 'ring'
    | 'device1' | 'device2' | 'device3' | 'device4';

const DEFAULT = <IArtifactsStorage>{
    "belt": [],
    "spellbook": null,
    "headwear": null,
    "neck": null,
    "shoulders": null,
    "right_hand": null,
    "left_hand": null,
    "torso": null,
    "right_ring": null,
    "left_ring": null,
    "feet": null,
    "misc1": null,
    "misc2": null,
    "misc3": null,
    "misc4": null,
    "misc5": null,
    "device1": null,
    "device2": null,
    "device3": null,
    "device4": null
};

export interface IArtifactData {
    id: number;
    type: string;
    texture: string,
    system: number;
    level: number;
    slot: artifactSlotType;
    spriteIndex: number;
}

export class ArtifactsStorage {

    storage: IArtifactsStorage = null;

    @Inject(MonstersData) private monstersData: ICreatureData[];

    constructor() {
        this.storage = Utils.clone(DEFAULT, true);
    }

    isEmpty(): boolean {
        return false;
    }

    transfer(storage: ArtifactsStorage, artifacts: IAbstractArtifact[] = []): boolean {
        return true;
    }

    add(artifact: IAbstractArtifact) {
        switch (artifact.artifactData.slot) {
            case 'misc': {
                if (this.findAvailableMiscSlot()) {
                    this.addToMiscSlot(artifact);
                    return;
                }
            }
            case 'ring': {
                if (this.findAvailableRingSlot()) {
                    this.addToRingSlot(artifact);
                    return;
                }
            }
            default: {
                const targetSlot = artifact.artifactData.slot;
                if (this.isSlotAvailable(targetSlot)) {
                    this.storage[targetSlot] = artifact;
                } else {
                    this.storage['belt'].push(artifact);
                }
            }
        }
    }

    addToSlot(artifact: IAbstractArtifact, slot: string) {
        if (slot === 'belt') {
            this.storage['belt'].push(artifact);
            return;
        }

        this.storage[slot] = artifact;
    }

    getDataById(id: number): IArtifactData {
        const artifactsDataMap = inject<Map<number, IArtifactData>>(ArtifactsData);

        return artifactsDataMap.get(id);
    }

    getRandomData(level: number = null): IArtifactData {
        const artifactsDataMap = inject<Map<number, IArtifactData>>(ArtifactsData);
        const artifactsDataArray = [...artifactsDataMap].map(([, value]) => value);

        const filteredList = artifactsDataArray.filter(artifactData => {
            if (level) {
                return (artifactData.level === level) && !artifactData.system;
            } else {
                return !artifactData.system;
            }
        });

        return Utils.something(filteredList);
    }

    fromCreaturesConfig(creaturesConfig: IHeroStackConfig[]) {
        const creaturesData = this.monstersData;

        creaturesConfig.forEach(({type}) => {
            const creatureItem = <ICreatureData>Utils.find(creaturesData, creature => creature.type === type);

            if (!creatureItem || !creatureItem.attributes) {
                return;
            }

            if (creatureItem.attributes.includes('SIEGE_WEAPON')) {
                this.addSiegeWeapon(creatureItem);
            }
        });
    }

    private addSiegeWeapon(config: {type: string}) {
        switch (config.type) {
            case 'Ballista':
                this.add(new BallistaObject());
                return;
            case 'AmmoCart':
                this.add(new AmmoCartObject());
                return;
            case 'FirstAidTent':
                this.add(new FirstAidTentObject());
                return;
            default:
                throw new Error(`ArtifactsStorage: unknown siege weapon type ${config.type}`);
        }
    }

    private findAvailableMiscSlot(): string {
        return ['misc1', 'misc2', 'misc3', 'misc4', 'misc5'].find(slot => this.isSlotAvailable(slot)) || null;
    }

    private addToMiscSlot(artifact: IAbstractArtifact) {
        const targetSlot = this.findAvailableMiscSlot();

        this.storage[targetSlot] = artifact;
    }

    private findAvailableRingSlot(): string {
        return ['left_ring', 'right_ring'].find(slot => this.isSlotAvailable(slot)) || null;
    }

    private addToRingSlot(artifact: IAbstractArtifact) {
        const targetSlot = this.findAvailableRingSlot();

        this.storage[targetSlot] = artifact;
    }

    private isSlotAvailable(targetSlot: string): boolean {
        return this.storage[targetSlot] === null;
    }
}
          
