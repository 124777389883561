
import * as Utils from "../../utils/Utils";
import {UiComponent} from "../../services/UiComponentsService";
import {UiGameItem} from "../common/UiGameItem";

const DEFAULT_PARAMS = {
    left: 0,
    top: 0,
    height: 50,
    map: null,
    name: null
};

@UiComponent()
export class UiSavedGameItem extends UiGameItem {

    constructor(params) {
        super(Utils.extend(DEFAULT_PARAMS, params));

        const map = this.readParam('map');
        const name = this.readParam('name');
    }
}