
import {CommandHelper} from "../helpers/CommandHelper";
import {DIRECTIONS} from "../constants/DIRECTIONS";
import {MapModel} from "../models/map/MapModel";
import {Render} from "../render/Render";
import {ObjectsCollection} from "../models/ObjectsCollection";
import {HeroObject} from "../models/objects/hero/HeroObject";
import {Inject} from '../helpers/InjectDectorator';

export class TeleportHeroCommand extends CommandHelper {

    @Inject(Render) private render: Render;
    @Inject(ObjectsCollection) protected objectsCollection: ObjectsCollection;

    constructor({id, x, y, z}) {
        super();

        let hero = <HeroObject>this.objectsCollection.dynamic.get(id);
        let prevPosition = hero.getCoords();

        this.setExecute(() => {
            return new Promise(resolve => {

                hero.x = x;
                hero.y = y;
                hero.z = z;

                MapModel.updatePoints(prevPosition.x - 1, prevPosition.y, hero.x - 1, hero.y, hero);
                let oldLeft = hero.renderTree.left;
                let oldTop = hero.renderTree.top;
                let newLeft = hero.x << 5;
                let newTop = (hero.y << 5) - 32;
                hero.renderTree = hero.renderTree.tree.move(oldLeft, oldTop, newLeft, newTop);
                hero.zIndex = newTop;

                hero
                    .setLeft(newLeft)
                    .setTop(newTop + 32)
                    .setDirection(DIRECTIONS.WW);

                let objectLeft = hero.getLeft();
                let objectTop = hero.getTop();

                this.render.setCenter(objectLeft - 32, objectTop - 16, z);

                hero.owner.updateMapForObject(hero);
                return resolve();
            });
        });

        this.setUndo(() => {
            hero.x = prevPosition.x;
            hero.y = prevPosition.y;
            hero.z = prevPosition.z;
        });
    }
};