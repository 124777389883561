
import {UiPanel} from "../UiPanel";
import * as Utils from "../../utils/Utils";
import {UiSprite} from "../sprites/UiSprite";
import {UiImage} from "./UiImage";
import {UiComponent} from "../../services/UiComponentsService";
import {
    artifactSlotType,
    ArtifactsStorage,
    IAbstractArtifact
} from "../../models/storages/ArtifactsStorage";
import {IDropEvent} from "../UiPanelDraggableMixin";
import {Events} from "../../controllers/Events";
import {TextService} from "../../services/TextService";
import {UiController} from "../../controllers/UiController";
import { UiSpellBookWindow } from "../windows/UiSpellBookWindow";
import {ITextures, Textures} from '../../controllers/Textures';
import {Inject} from '../../helpers/InjectDectorator';

const DEFAULT_PARAMS = {
    left: 0,
    top: 0,
    storage: null
};

const STACK_SIZE = 44;

const POSITIONS = {
    "belt": [22, 336],
    "spellbook": [229, 282],
    "headwear": [128, 1],
    "neck": [128, 51],
    "shoulders": [187, 213],
    "right_hand": [2, 40],
    "left_hand": [181, 155],
    "torso": [128, 102],
    "right_ring": [50, 40],
    "left_ring": [230, 155],
    "feet": [135, 266],
    "misc1": [2, 114],
    "misc2": [18, 164],
    "misc3": [34, 215],
    "misc4": [0, 266],
    "misc5": [50, 266],
    "device1": [183, 1],
    "device2": [229, 1],
    "device3": [229, 47],
    "device4": [229, 93]
};

@UiComponent()
export class UiArtifactsStack extends UiPanel {

    sprite: UiSprite;
    private artifacts: ArtifactsStorage;
    private stacks: {[key: string]: UiImage} = {};
    private selectedStack: UiImage;

    @Inject(Textures) protected textures: ITextures;
    @Inject(TextService) protected textService: TextService;

    constructor(params) {
        super(Utils.extend(DEFAULT_PARAMS, params));

        this.artifacts = this.readParam('storage');

        this.sprite = <UiSprite>this.textures.get('artifacts_portraits_sm');
        this.selectedStack = null;

        Object.keys(this.artifacts.storage).forEach(slot => {
            this.addStack(slot);
        });

        this.updateStacks();
    }

    draw() {
        this.children.forEach(wnd => wnd.draw());
    }

    private updateStacks() {
        this.unselectStacks();

        for (let slot in this.stacks) {
            let icon = this.stacks[slot];
            (icon as any).slot = slot;
            let slotData = this.artifacts.storage[slot];

            if (slotData) {
                if (Array.isArray(slotData)) {
                    (icon as any).data = slotData[0];
                    icon.options.spriteIndex = slotData[0] ? (slotData[0] as any).artifactData.spriteIndex : 256;
                } else {
                    icon.data = slotData;
                    icon.options.spriteIndex = (slotData as any).artifactData.spriteIndex;
                }
            } else {
                icon.data = null;
                icon.options.spriteIndex = 256;
            }
        }
    }

    private unselectStacks() {
        for (let slot in this.stacks) {
            let icon = this.stacks[slot];
            icon.selected = false;
        }
    }

    private onStackClick(currentImage: UiImage) {
        const selected = !currentImage.selected;
        const slot = <string>(currentImage as any).slot;
        const hasArtifact = !!(currentImage as any).data;

        this.unselectStacks();

        if (slot === 'spellbook' && hasArtifact) {
            UiController.modal(UiSpellBookWindow);
            return;
        }

        if ((currentImage.selected = selected)) {
            if (this.selectedStack && this.canDragToPanel(this.selectedStack, currentImage)) {
                const fromSlot = <string>(this.selectedStack as any).slot;
                const toSlot = <string>(currentImage as any).slot;

                this.moveStack(fromSlot, toSlot);
            }
            this.selectedStack = currentImage;
        } else {
            this.selectedStack = null;
        }
    }

    private getSlotBaseType(slotName: string): artifactSlotType {
        switch (slotName) {
            case 'misc1':
            case 'misc2':
            case 'misc3':
            case 'misc4':
            case 'misc5': {
                return 'misc';
            }
            case 'left_ring':
            case 'right_ring': {
                return 'ring';
            }
            default:
                return <artifactSlotType>slotName;
        }
    }

    private slotsAreRelative(firstSlot: string, secondSlot: string): boolean {
        const slotsAreEqual = this.getSlotBaseType(firstSlot) === this.getSlotBaseType(secondSlot);

        return slotsAreEqual || firstSlot === 'belt' || secondSlot === 'belt' ;
    }

    private canDragToPanel(from: UiImage, to: UiImage): boolean {
        const draggedArtifact = (from as any).data as IAbstractArtifact;

        if (!draggedArtifact) {
            return false;
        }

        const hasArtifact = !!(to as any).data;

        if (to === from || hasArtifact) {
            return;
        }

        const {slot} = to as any;

        if (!this.slotsAreRelative(slot, draggedArtifact.artifactData.slot)) {
            return;
        }

        return true;
    }

    private getStackDropZones(currentImage: UiImage): UiImage[] {
        return Object.values(this.stacks).filter(image => {
            return this.canDragToPanel(currentImage, image);
        });
    }

    private addStack(slot: string) {
        const [left, top] = POSITIONS[slot];
        this.width = Math.max(this.width, left + STACK_SIZE);
        this.height = Math.max(this.height, top + STACK_SIZE);
        const currentIcon = new UiImage({
            left,
            top,
            sprite: this.sprite,
            spriteIndex: 256,
            selectable: true,
            draggable: true,
            dropZones: () => this.getStackDropZones(currentIcon),
            parent: <any>this,
            events: {
                rightMousedown: () => this.onRightMouseDown(currentIcon),
                'leftClick': () => this.onStackClick(currentIcon),
                'drop': e => this.onArtifactDrop(e)
            }
        });

        this.stacks[slot] = currentIcon;
    }

    private onArtifactDrop(event: IDropEvent) {
        const fromSlot = <string>(event.from as any).slot;
        const toSlot = <string>(event.to as any).slot;

        this.moveStack(fromSlot, toSlot);
    }

    private moveStack(fromSlot: string, toSlot: string) {
        this.selectedStack = null;

        const artifact = this.artifacts.storage[fromSlot];
        this.artifacts.storage[toSlot] = artifact;
        this.artifacts.storage[fromSlot] = null;

        this.updateStacks();
    }

    private onRightMouseDown(currentImage: UiImage) {
        const artifact = (currentImage as any).data as IAbstractArtifact;

        if (!artifact) {
            return false;
        }

        const {type, spriteIndex} = artifact.artifactData;
        const text = this.textService.getArtifactName(type);
        const uiTipWindow = UiController.createTipWindow(text, this.sprite, spriteIndex);

        Events.one('rightMouseup', () => UiController.remove(uiTipWindow));

        return false;
    }
}