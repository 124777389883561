
import {Events} from "../controllers/Events";
import {ObjectsFabric} from "./objects/ObjectsFabric";
import {IObjectData, MapObject} from "./MapObject";
import {StateService} from '../services/StateService';
import {Inject} from '../helpers/InjectDectorator';

export class ObjectsCollection {

    private lastId: number = 0;

    dynamic: Map<number, MapObject> = new Map();
    static: Map<number, MapObject> = new Map();

    @Inject(ObjectsFabric) private objectsFabric: ObjectsFabric;
    @Inject(StateService) private stateService: StateService;

    constructor() {}

    forEach(type: string | any, iterate?: (item: MapObject) => void) {
        if (arguments.length === 1) {
            this.static.forEach(arguments[0]);
            this.dynamic.forEach(arguments[0]);
        } else {
            this.static.forEach(function(item) {
                if (item.type === type) {
                    iterate(item);
                }
            });
            this.dynamic.forEach(function(item) {
                if (item.type === type) {
                    iterate(item);
                }
            });
        }
    }

    list(ids: string[]): MapObject[] {
        return ids
            .map(id => this.getById(+id))
            .filter(object => !!object);
    }

    getById(id: number): MapObject {
        return this.static.get(id) || this.dynamic.get(id);
    }

    remove(objectToRemove) {
        const object = this.getById(objectToRemove.id);
        if (object) {
            object.events.dispatch('remove');
            this.dynamic.delete(object.id);
        }
    }

    getData(objectData: IObjectData) {
        return this.objectsFabric.getData(objectData);
    }

    spawn(objectData: IObjectData, id: number): MapObject {
        const object = this.objectsFabric.get(objectData, id);

        if (object.gridDynamic) {
            this.dynamic.set(id, object);
        } else {
            this.static.set(id, object);
        }

        this.lastId = id;

        return object;
    }

    spawnDynamicObject(objectData: IObjectData): MapObject {
        const id = this.lastId + 1;
        const object = this.objectsFabric.get(objectData, id);

        if (object.gridDynamic) {
            this.dynamic.set(id, object);
        } else {
            this.static.set(id, object);
        }

        this.lastId = id;

        this.stateService.set(`objects.${id}`, objectData);

        Events.dispatch('dynamicObjectSpawned', object);

        return object;
    }

    generate(objectsArray: IObjectData[]) {
        this.dynamic = new Map();
        this.static = new Map();

        for (let id in objectsArray) {
            const objectData = objectsArray[id];

            if (objectData) {
                this.spawn(objectData, parseInt(id));
            }
        }
    }

    bootstrap(objectsArray: IObjectData[]) {
        for (let id in objectsArray) {
            const objectData = objectsArray[id];

            if (objectData) {
                this.objectsFabric.getData(objectData);
            }
        }
    }
}