
import * as Utils from "../../utils/Utils";
import {IResourceGeneratorType, RESOURCES} from "../../constants/RESOURCES";
import {Events} from "../../controllers/Events";
import {UiController} from "../../controllers/UiController";
import {OwnershipObject} from "./OwnershipObject";
import {MapComponent} from '../MapComponent';

const TIPS = {
    [0]: 'Sawmill',
    [1]: 'Alchemy lab',
    [2]: 'Stone mine',
    [3]: 'Sulfur mine',
    [4]: 'Crystals mine',
    [5]: 'Gems mine',
    [6]: 'Gold mine'
};

@MapComponent('resource_generator')
export class ResourceGeneratorObject extends OwnershipObject {

    private increasingValue: number;
    private resourceType: IResourceGeneratorType;

    constructor(data, id) {
        super(data, id);

        if (this.objectSubClass < 3) {
            Utils.randomize({
                [60]: () => this.increasingValue = 1,
                [40]: () => this.increasingValue = 2
            });
        } else {
            Utils.randomize({
                [90]: () => this.increasingValue = 1,
                [10]: () => this.increasingValue = 2
            });
        }

        this.resourceType = RESOURCES[this.objectSubClass];
        this.tip = TIPS[this.objectSubClass];

        Events.on('game.newTurn', () => {
            if (this.owner) {
                const amount = this.increasingValue * this.resourceType.rate;

                this.owner.resources.get(this.resourceType.name).push(amount);
            }
        });

        this.events.on('action', hero => {
            if (!hero.owner.is(this.owner)) {
                this.setOwner(hero.owner);
                UiController.alert(`${this.increasingValue * this.resourceType.rate} ${this.resourceType.name} per day`);
            }
        });
    }
}
