
import {UiWindow} from "../common/UiWindow";
import {Events} from "../../controllers/Events";
import {UiSprite} from "../sprites/UiSprite";
import {IAbstractView} from '../IAbstractView';
import {ActiveView} from '../../controllers/ActiveView';
import {inject} from '../../helpers/InjectDectorator';

export class UiTipWindow extends UiWindow {

    private __text: string;
    private __iconSprite: UiSprite;
    private __iconSpriteIndex: number;
    private windowWidth: number;
    private textTop: number;
    private iconLeft: number;

    constructor(text, iconSprite = null, iconSpriteIndex = null) {

        const windowWidth = 300;
        const windowHeight = 90;
        const activeView = inject<IAbstractView>(ActiveView);

        let mousePos = Events.getMousePos();
        let maxLeft = ~~(windowWidth / 2) + 30;
        let maxRight = activeView.getWindowWidth() - maxLeft;
        let maxTop = ~~(windowHeight / 2) + 30;
        let maxBottom = activeView.getWindowHeight() - maxTop;
        let centerLeft = Math.min(Math.max(mousePos.x, maxLeft), maxRight);
        let centerTop = Math.min(Math.max(mousePos.y, maxTop), maxBottom);

        super({
            left: ~~(centerLeft - (windowWidth / 2)),
            top: ~~(centerTop - (windowHeight / 2)),
            width: windowWidth,
            height: windowHeight,
            centered: false,
            hideCursor: true
        });

        this.__text = text;
        this.__iconSprite = iconSprite;
        this.__iconSpriteIndex = iconSpriteIndex;
        this.windowWidth = windowWidth;
        this.textTop = windowHeight / 2;

        if (this.__iconSprite) {
            const iconHeight = this.__iconSprite.height;
            this.iconLeft = Math.floor((this.windowWidth - this.__iconSprite.width) / 2);
            this.textTop += 10 + iconHeight;

            this.setHeight(windowHeight + 10 + iconHeight);
        }

        this.draw = this.draw.bind(this);
    }

    draw() {
        UiWindow.prototype.draw.call(this);

        if (this.__iconSprite) {
            this.icon({
                left: this.iconLeft,
                top: 40,
                sprite: this.__iconSprite,
                spriteIndex: this.__iconSpriteIndex
            });
        }

        this.text({
            text: this.__text,
            left: this.windowWidth / 2,
            top: this.textTop,
            align: 'center',
            color: '#FFFFFF'
        });
    }
};
