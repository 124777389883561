
import {IUiOptions, UiPanel} from "../UiPanel";
import {CreaturesStorage} from "../../models/storages/CreaturesStorage";
import * as Utils from "../../utils/Utils";
import {UiSprite} from "../sprites/UiSprite";
import {UiText} from "./UiText";
import {UiController} from "../../controllers/UiController";
import {Events} from "../../controllers/Events";
import {UiCreatureTipWindow} from "../windows/UiCreatureTipWindow";
import {UiImage} from "./UiImage";
import {UiComponent} from "../../services/UiComponentsService";
import {ITextures, Textures} from '../../controllers/Textures';
import {Inject} from '../../helpers/InjectDectorator';
import {ICreatureData, MonstersData} from "../../controllers/MonstersData";

export interface IUiGarissonStackOptions extends IUiOptions {
    stackWidth?: number;
    stackHeight?: number;
    stackSprites?: string;
    stackPositions?: any[];
    storages?: CreaturesStorage[];
    stackIconsSmall?: boolean;
}

const DEFAULT_PARAMS = <IUiGarissonStackOptions>{
    left: 0,
    top: 0,
    stackWidth: 58,
    stackHeight: 64,
    stackSprites: 'creatures_portraits_md',
    stackPositions: [],
    storages: [],
    stackIconsSmall: false
};

@UiComponent()
export class UiGarissonStack extends UiPanel {

    options: IUiGarissonStackOptions;
    storages: CreaturesStorage[];
    private __sprite: UiSprite;
    private stacks: any[];
    private selectedStack: [number, number];
    @Inject(Textures) protected textures: ITextures;
    @Inject(MonstersData) protected monstersData: ICreatureData[];

    constructor(params: IUiGarissonStackOptions) {
        const options = Utils.extend(DEFAULT_PARAMS, params);
        super(options);
        this.draw = this.draw.bind(this);

        this.storages = this.readParam('storages');
        this.options.stackWidth = Number(this.readParam('stackWidth'));
        this.options.stackHeight = Number(this.readParam('stackHeight'));
        this.options.stackPositions = this.readParam('stackPositions');

        this.__sprite = <UiSprite>this.textures.get(this.options.stackSprites);

        this.stacks = [];
        this.selectedStack = [-1, -1];

        for (let stack of this.storages) {
            this.stacks.push(new Array(7));
        }

        for (let y = 0; y < this.stacks.length; y++) {
            let line = this.stacks[y];
            for (let x = 0; x < line.length; x++) {
                this.addStack(x ,y);
            }
        }

        this.updateStacks();
    }

    private onStackClick(stackIcon: UiImage, xTo: number, yTo: number) {
        const value = !stackIcon.selected;
        const [xFrom, yFrom] = this.selectedStack;

        if (~xFrom && ~yFrom && !stackIcon.selected) {
            this.moveStack(xFrom, yFrom, xTo, yTo);
        } else {
            this.unselectStacks();
            if ((stackIcon.selected = value)) {
                this.selectedStack = [xTo, yTo];
            } else {
                this.selectedStack = [-1, -1];
            }
        }
    }

    private getStackDropZones(stackIcon: UiImage) {
        let result = [];
        if (~stackIcon.data[0]) {
            for (let y = 0; y < this.stacks.length; y++) {
                const line = this.stacks[y];
                if (this.storages[y]) {
                    const subResult = Utils.filter(line, ([icon]) => !icon.is(stackIcon));
                    result = result.concat(subResult.map(item => item[0]));
                }
            }
        }
        return result;
    }

    private getTextLabel(left: number, top: number) {
        if (this.options.stackIconsSmall) {
            return new UiText({
                left,
                top: top + 38,
                width: this.options.stackWidth,
                text: '',
                fontSize: '10px',
                parent: this
            });
        } else {
            return new UiText({
                left: left + 55,
                top: top + 55,
                width: this.options.stackWidth,
                align: 'right',
                text: '',
                fontSize: '14px',
                parent: this
            });
        }
    }

    private addStack(x: number, y: number) {
        const [top, left] = this.options.stackPositions[y][x];
        this.width = Math.max(this.width, +left + this.options.stackWidth);
        this.height = Math.max(this.height, +top + this.options.stackHeight);

        this.stacks[y][x] = [
            this.getImageLabel(left, top, x, y),
            this.getTextLabel(left, top)
        ];
    }

    private moveStack(xFrom: number, yFrom: number, xTo: number, yTo: number) {
        if ((xFrom === xTo) && (yFrom === yTo)) {
            return true;
        }
        const stackFrom = this.storages[yFrom];
        const stackTo = this.storages[yTo];
        const [creatureIdFrom, quantityFrom] = Array.from(stackFrom.storage[xFrom]);
        const [creatureIdTo, quantityTo] = Array.from(stackTo.storage[xTo]);

        if (creatureIdTo === creatureIdFrom) {
            stackFrom.set(xFrom, -1, 0);
            stackTo.set(xTo, creatureIdFrom, quantityFrom + quantityTo);
        } else {
            stackFrom.set(xFrom, creatureIdTo, quantityTo);
            stackTo.set(xTo, creatureIdFrom, quantityFrom);
        }
        this.selectedStack = [-1, -1];
        this.updateStacks();
    }

    updateStacks() {
        this.unselectStacks();

        for (let y = 0; y < this.stacks.length; y++) {

            const line = this.stacks[y];
            const creatures = this.storages[y];

            for (let x = 0; x < line.length; x++) {

                let creatureId, quantity;
                const [icon, label] = line[x];
                const creature = creatures && creatures.storage[x];

                if (creature) {
                    [creatureId, quantity] = creature;
                }

                if (creatureId >= 0) {
                    quantity = quantity || '';
                    icon.data = [creatureId, quantity];
                    const creatureData = Utils.find(this.monstersData, monster => Number(monster.id) === creatureId);
                    icon.options.spriteIndex = creatureData.spriteId;
                    label.options.text = quantity;
                } else {
                    icon.data = [-1, 0];
                    icon.options.spriteIndex = 256;
                    label.options.text = '';
                }

                icon.position = [x, y];
            }
        }
    }

    unselectStacks() {
        for (let y = 0; y < this.stacks.length; y++) {
            let line = this.stacks[y];
            for (let x = 0; x < line.length; x++) {
                let [icon, text] = line[x];
                icon.selected = false;
            }
        }
    }

    draw() {
        this.children.forEach(wnd => wnd.draw());
    }

    private getImageLabel(left: number, top: number, x: number, y: number): UiImage {
        const currentIcon = new UiImage({
            left,
            top,
            sprite: this.__sprite,
            spriteIndex: 256,
            selectable: true,
            draggable: true,
            dropZones: () => this.getStackDropZones(currentIcon),
            parent: this,
            events: {
                'leftClick': () => this.onStackClick(currentIcon, x, y),
                'drop': e => {
                    let [xFrom, yFrom] = Array.from(e.from.position);
                    let [xTo, yTo] = Array.from(e.to.position);
                    if (~xFrom && ~yFrom) {
                        this.moveStack(xFrom, yFrom, xTo, yTo);
                    }
                },
                'rightMousedown': () => {
                    const [creatureId, quantity] = Array.from(currentIcon.data);

                    if (quantity === 0) {
                        return false;
                    }

                    const object = {creatureId, quantity};
                    const uiTipWindow = UiController.modal(UiCreatureTipWindow, object);

                    Events.one('rightMouseup', () => UiController.remove(uiTipWindow));
                    return false;
                }
            }
        });

        return currentIcon;
    }
}