
import {OwnershipObject} from "./OwnershipObject";
import {CreaturesStorage} from "../storages/CreaturesStorage";
import {Inject} from "../../helpers/InjectDectorator";
import {ActivePlayer} from "../../controllers/ActivePlayer";
import {PlayerModel} from "../player/PlayerModel";
import {MapComponent} from '../MapComponent';

@MapComponent('garrison')
export class GarrisonObject extends OwnershipObject {

    canGoInActionPos = true;
    creatures = new CreaturesStorage();
    @Inject(ActivePlayer) protected activePlayer: PlayerModel;

    constructor(data, id) {
        super(data, id);

        if (this.data.data.creatures) {
            this.creatures.fromConfig(this.data.data.creatures);
        }

        this.events.on('action', hero => {
            this.setOwner(hero.owner);
        });
    }

    get passableMode(): boolean {
        return this.activePlayer === this.owner;
    }
}
