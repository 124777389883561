
import {GenericVisitableObject} from "./GenericVisitableObject";
import {UiController} from "../../../controllers/UiController";
import {ResourcesStorage} from "../../storages/ResourcesStorage";
import * as Utils from "../../../utils/Utils";
import {HeroObject} from "../hero/HeroObject";
import {StateService} from '../../../services/StateService';
import {Inject} from "../../../helpers/InjectDectorator";

export class WindMillObject extends GenericVisitableObject {

    recordVisits = true;
    clearVisitsEveryWeek = true;
    resources = new ResourcesStorage();

    @Inject(StateService) protected stateService: StateService;

    constructor(data, id) {
        super(data, id);
        this.initRandomStuff = this.initRandomStuff.bind(this);

        this.events.on('action', hero => {
            if (this.hasResources()) {
                UiController.statusWindow.resourceTransferMessage(this.resources);
                this.flushResources(hero);
                return true;
            } else {
                return false;
            }
        });

        this.initRandomStuff();

        this.stateService.subscribe('time.week', () => {
            this.initRandomStuff();
        });
    }

    initRandomStuff() {
        const resource = Utils.something(['mercury', 'stone', 'sulfur', 'crystals', 'gems']);
        const params = {};
        params[resource] = Utils.something([3, 4, 5, 6]);

        this.resources.set(params);
    }

    flushResources(hero: HeroObject) {
        this.resources.transfer(hero.owner.resources);
    }

    hasResources(): boolean {
        return !this.resources.isEmpty();
    }
}
