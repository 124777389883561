
import {CURSORS} from "../../constants/CURSORS";
import {Grid} from "../../helpers/Grid";
import {EventsStorage} from "../storages/EventsStorage";
import {UiController} from "../../controllers/UiController";
import {TextService} from "../../services/TextService";
import {Events} from "../../controllers/Events";
import {ObjectsCollection} from "../ObjectsCollection";
import {HeroObject} from "../objects/hero/HeroObject";
import {StateService} from "../../services/StateService";
import {Inject} from "../../helpers/InjectDectorator";
import {ISceneMouse, Mouse} from "../../controllers/Mouse";
import {ActivePlayer} from "../../controllers/ActivePlayer";
import {PlayerModel} from "../player/PlayerModel";
import {MapDataReader} from './MapDataReader';
import {MapData} from '../../controllers/MapData';

// initializing of tile appearance format constants
const FLAG_MASK = 0b1;
const MIRROR_MASK = 0b11;
const MOORABLE_OFFSET = 6;
const MOORABLE_MASK = (FLAG_MASK << MOORABLE_OFFSET);
const ROAD_OFFSET = 4;
const ROAD_MASK = (MIRROR_MASK << ROAD_OFFSET);
const RIVER_OFFSET = 2;
const RIVER_MASK = (MIRROR_MASK << RIVER_OFFSET);
const GROUND_OFFSET = 0;
const GROUND_MASK = (MIRROR_MASK << GROUND_OFFSET);

// States definitions
const ATTACKABLE = 0;

class TilesCollectionSingleton {

    states: Grid[];
    events: EventsStorage;

    @Inject(Mouse) private mouse: ISceneMouse;
    @Inject(MapData) private mapData: MapDataReader;
    @Inject(ActivePlayer) private activePlayer: PlayerModel;
    @Inject(TextService) private textService: TextService;
    @Inject(StateService) private stateService: StateService;
    @Inject(ObjectsCollection) private objectsCollection: ObjectsCollection;

    constructor() {
        let uiTipWindow;
        this.doAction = this.doAction.bind(this);
        this.events = new EventsStorage();
        this.events
            .on('rightMousedown', e => {
                const [terrain] = Array.from(this.getTileUnderCursor());
                uiTipWindow = UiController.createTipWindow(this.textService.getTileTip(terrain));
                Events.one('rightMouseup', () => UiController.remove(uiTipWindow));

                return false;
            })
            .on('fog_rightMousedown', e => {
                uiTipWindow = UiController.createTipWindow(this.textService.getFogTip());
                Events.one('rightMouseup', () => UiController.remove(uiTipWindow));

                return false;
            });
    }

    private parseTileData(data) {
        let [
            terrain,
            sprite,
            riverType,
            riverSpriteIndex,
            roadType,
            roadSpriteIndex,
            appearance,
            x,
            y,
            z
        ] = Array.from(data);
        let mirrorType = (appearance & GROUND_MASK) >> GROUND_OFFSET;
        let riverMirrorType = (appearance & RIVER_MASK) >> RIVER_OFFSET;
        let roadMirrorType = (appearance & ROAD_MASK) >> ROAD_OFFSET;
        let isMoorable = Boolean(appearance & MOORABLE_MASK);
        return [ x, y, z, terrain, isMoorable, sprite, mirrorType, riverType, riverSpriteIndex, riverMirrorType, roadType, roadSpriteIndex, roadMirrorType ];
    }

    bootstrap(tilesData: any[]): TilesCollectionSingleton {
        const {size} = this.mapData.props;

        this[0] = new Grid(size);
        this[1] = new Grid(size);
        this.states = [new Grid(size), new Grid(size)];

        for (let item of Array.from(tilesData)) {
            const data = this.parseTileData(item);
            const [x, y, z] = data;
            this[z].set(x, y, data);
        }

        return this;
    }

    // [ terrain, isMoorable, sprite, mirrorType, riverType, riverSpriteIndex, riverMirrorType, roadType, roadSpriteIndex, roadMirrorType ]
    getByCoords(x, y, z = 0) {
        const tileData = this[z].get(x, y) || '';
        return tileData.slice(3);
    }

    // [ x, y, z, terrain, isMoorable, sprite, mirrorType, riverType, riverSpriteIndex, riverMirrorType, roadType, roadSpriteIndex, roadMirrorType ]
    forEach(z, iterate: Function) {
        return this[z].forEach(iterate);
    }

    has(x: number, y: number, z: number): boolean {
        return this[z].has(x, y);
    }

    private getTileUnderCursor() {
        const [x, y] = this.mouse.pos;
        const tileX = x >> 5;
        const tileY = y >> 5;
        const tileZ = this.stateService.get('center.z');
        return this.getByCoords(tileX, tileY, tileZ);
    }

    getCursor(pathLength: number): string {
        const [x, y] = this.mouse.pos;
        const tileX = x >> 5;
        const tileY = y >> 5;
        const tileZ = this.stateService.get('center.z');
        const WATER = 8;

        if (!pathLength) {
            return CURSORS.DEFAULT;
        }

        if (this.getAttackableId(tileX, tileY, tileZ)) {
            return CURSORS.ATTACK;
        }

        const [terrain, isMoorable] = this.getByCoords(tileX, tileY, tileZ);

        if (terrain === WATER) {
            return CURSORS.SHIP;
        }

        const selectedObject = <HeroObject>this.activePlayer.selectedObject;

        if (isMoorable && selectedObject && selectedObject.isHero && selectedObject.pathFinder.getGridType() === 'water') {
            return CURSORS.ASHORE;
        }

        return CURSORS.HORSE;
    }

    getAttackableId(x: number, y: number, z: number): number {
        const tileState = this.states[z].get(x, y) || [];

        return tileState[ATTACKABLE];
    }

    setAttackable(x: number, y: number, z: number, id: number) {
        if (this.states[z].has(x, y)) {
            this.states[z].get(x, y)[ATTACKABLE] = id;
            return;
        }
        const state = [];
        state[ATTACKABLE] = id;

        this.states[z].set(x, y, state);
    }

    doAction(x, y, z, hero) {
        const targetId = this.getAttackableId(x, y, z);

        if (targetId) {
            const object = this.objectsCollection.dynamic.get(targetId);
            if (object) {
                object.events.dispatch('action', hero);
                hero.owner.stopCommands();
            }
        }

        let [, , , , isMoorable] = this[z].get(x, y);

        if (isMoorable) {
            hero.detachTransport();
        }
    }
}

export const TilesCollection = new TilesCollectionSingleton();