
import {IPath, PathFinderService} from "../../../services/PathFinderService";
import {Events} from "../../../controllers/Events";
import {TilesCollection} from "../../map/TilesCollection";
import {HeroObject} from "./HeroObject";
import {GraphNode} from "../../../helpers/GraphNode";

// horizontal -> scouting level
// vertical -> ground type
const TERRAINS_SCOUTING_TAXES = [
    [1,    1,    1,    1],
    [1,    1,    1,    1],
    [1,    1,    1,    1],
    [1,    1,    1,    1],
    [1.25, 1,    1,    1],
    [1.5,  1.25, 1,    1],
    [1.5,  1.25, 1,    1],
    [1.75, 1.5,  1.25, 1],
    [1,    1,    1,    1]
];

export class HeroPathfinder {

    items = [];

    constructor(private hero: HeroObject) {

    }

    getPathTo(dX: number, dY: number): IPath {
        const {x, y} = this.hero;

        return PathFinderService.getPath(x - 1, y, dX, dY, this.hero, this.walkCost.bind(this));
    }

    pathTo(dX: number, dY: number) {
        this.clearPath();
        this.items = this.getPathTo(dX, dY);
        Events.dispatch('hero.changePath', {path: this.items, hero: this});
    }

    updatePath() {
        if (this.items.length) {
            const {x, y} = this.items[this.items.length - 1].point;
            this.pathTo(x, y);
        }
    }

    getGridType(): string {
        return (this.hero.transport && this.hero.transport.movementType) || this.hero.movementType;
    }

    private clearPath() {
        this.items = [];
    }

    private walkCost(point: GraphNode, x: number, y: number): number {
        let taxes;
        let heroScoutingValue = 0;
        let weight = point.weight || 1;
        let [terrain] = TilesCollection.getByCoords(point.x, point.y);
        if (this.hero.creatures.isNative && (terrain === this.hero.data.raceConfig.terrain)) {
            taxes = 1;
        } else {
            taxes = TERRAINS_SCOUTING_TAXES[terrain][heroScoutingValue];
        }
        return weight * taxes;
    }
}
