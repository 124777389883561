
import {GenericVisitableObject} from "./GenericVisitableObject";
import {TeleportGateMixin} from "../TeleportGateMixin";
import {IObjectData, MapObject} from "../../MapObject";
import * as Utils from "../../../utils/Utils";
import {Mixin} from '../../../utils/Utils';

const MONOLITH_EXIT_OBJECT = 44;

@Mixin(TeleportGateMixin)
export class MonolithOneWayObject extends GenericVisitableObject implements TeleportGateMixin {

    targetGateClass = MONOLITH_EXIT_OBJECT;
    restrictedPoints = false;

    constructor(data: IObjectData, id: number) {
        super(data, id);

        this.initGate();
    }

    initGate() {}
    findGate(): MapObject { return null }
    isGateMatches(gate: MapObject): boolean { return null }
    getActionPointOffset(): number { return null }
    transferTo(hero, gate) {}
}