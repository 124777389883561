
import * as ArrayUtils from "./ArrayUtils";

Function.prototype['clone'] = function() {
    let fct = this;

    let clone = function() {
        return fct.apply(this, arguments);
    };

    clone.prototype = fct.prototype;
    for (let property in fct) {
        property = property;
        if (fct.hasOwnProperty(property) && (property !== 'prototype')) {
            clone[property] = fct[property];
        }
    }
    return clone;
};

interface Function {
    prototype: Function;
    clone(): Function;
}

function registerWatcher(scope, field) {
    scope.__watchers = scope.__watchers || {};
    scope.__watchers[field] = scope.__watchers[field] || [];

    scope.__cache = scope.__cache || {};
    scope.__cache[field] = scope.__cache[field] || scope[field];

    scope.__data = scope.__data || {};
    scope.__data[field] = scope.__data[field] || scope[field];

    delete scope[field];
    Object.defineProperty(scope, field, {
        get() {
            return scope.__data[field];
        },

        set: val => {
            scope.__data[field] = val;
            runWatchers(scope, field);
            return val;
        },

        enumerable: true,
        configurable: true
    });
}

function runWatchers(scope, field) {
    let watchers = scope.__watchers[field];
    let cache = scope.__cache[field];
    let data = scope.__data[field];

    if (!watchers.length) {
        return false;
    }

    if (JSON.stringify(cache) !== JSON.stringify(data)) {
        for (let watcher of watchers) {
            watcher(data, cache);
        }
        scope.__cache[field] = clone(data);
    }
    return true;
}

function deepArrayClone(array: any[]): any[] {
    let result = [];
    for (let item of Array.from(array)) {
        result.push(clone(item, true));
    }
    return result;
}

function deepObjectClone(object: {}) {
    if (isObject(object)) {
        return clone(object, true);
    } else {
        return object;
    }
}

export function watch(scope: any, field: string, callback: (value: any) => void) {
    if (typeof callback !== 'function') {
        return false;
    }
    const watchers = scope && scope.__watchers || {};
    if (!watchers[field]) {
        registerWatcher(scope, field);
    }
    return scope.__watchers[field].push(callback);
}

export function isObject(obj): boolean {
    return obj && ("object" === typeof obj);
}

export function extend<T>(src, dest): T {
    let result = <T>{};
    for (let attrname in src) {
        result[attrname] = src[attrname];
    }
    for (let attrname in dest) {
        result[attrname] = dest[attrname];
    }
    return result;
}

export function clone<T>(any: any, deepCloning?: boolean): T {
    let objCopy;
    if (deepCloning == null) { deepCloning = false; }
    if (!isObject(any)) {
        return any;
    }
    if (ArrayUtils.isArray(any)) {
        if (deepCloning) {
            return <T><any>deepArrayClone(any);
        } else {
            return any.slice(0);
        }
    }
    if (typeof any === 'function') {
        return any.clone();
    } else {
        objCopy = {};
    }
    for (let attr in any) {
        if (any.hasOwnProperty(attr)) {
            if (deepCloning) {
                objCopy[attr] = deepObjectClone(any[attr]);
            } else {
                objCopy[attr] = any[attr];
            }
        }
    }
    return objCopy;
}