
import {UiWindow} from "../../common/UiWindow";
import * as Utils from "../../../utils/Utils";
import {TextService} from "../../../services/TextService";
import {Events} from "../../../controllers/Events";
import {UiController} from "../../../controllers/UiController";
import {UiComponent} from "../../../services/UiComponentsService";
import {ResourcesStorage} from "../../../models/storages/ResourcesStorage";
import {UiSprite} from "../../sprites/UiSprite";
import {Property} from "../../../helpers/Property";
import {RESOURCES} from "../../../constants/RESOURCES";
import {ArtifactsStorage} from "../../../models/storages/ArtifactsStorage";
import {IStatusWindowState} from "./IStatusWindowState";
import {MessageScreen} from "./MessageScreen";
import {SelectedObjectScreen} from "./SelectedObjectScreen";
import {NewDayScreen} from "./NewDayScreen";
import {StateService} from "../../../services/StateService";
import {ITextures, Textures} from '../../../controllers/Textures';
import {Inject} from '../../../helpers/InjectDectorator';

const TIP_KEY = "UI.GAME.SIDEBAR.STATUS_WINDOW.TIP";

const STATES_SWITCH_RING = [
    'SELECTED_OBJECT',
    'NEW_DAY'
];

const DEFAULT_PARAMS = {
    shadow: false,
    centered: false
};

@UiComponent()
export class UiStatusWindow extends UiWindow {

    private currentState: string;
    private STATES: {[key: string]: IStatusWindowState};

    private stateController: IStatusWindowState;

    @Inject(Textures) protected textures: ITextures;
    @Inject(TextService) protected textService: TextService;
    @Inject(StateService) protected stateService: StateService;

    constructor(params) {
        super(Utils.extend(DEFAULT_PARAMS, params));
        this.draw = this.draw.bind(this);

        this.events.on('leftClick', () => {
            const index = (STATES_SWITCH_RING.indexOf(this.currentState) + 1) % STATES_SWITCH_RING.length;

            this.setState(STATES_SWITCH_RING[index]);
        });

        this.events.on('rightMousedown', () => {
            if (this.currentState === 'SELECTED_OBJECT') {
                return;
            }
            const uiTipWindow = UiController.createTipWindow(this.textService.i18n(TIP_KEY));

            Events.one('rightMouseup', () => UiController.remove(uiTipWindow));
        });

        this.stateService.subscribe('*.selectedObject', () => {
            if (this.currentState === 'SELECTED_OBJECT') {
                this.setState(this.currentState);
            }
        });

        this.stateService.subscribe('time.day', () => {
            this.setState('NEW_DAY');
        });

        this.STATES = <any>{
            NEW_DAY: NewDayScreen,
            MESSAGE: MessageScreen,
            SELECTED_OBJECT: SelectedObjectScreen
        };


        this.currentState = null;
        this.setState('SELECTED_OBJECT');
    }

    setState(newState: string, stateParams = {}) {
        if (this.stateController) {
            this.stateController.exit();
            UiController.remove(this.stateController);
        }

        this.currentState = newState;
        const StateConstructor = <any><IStatusWindowState>this.STATES[newState];

        this.stateController = new StateConstructor({
            left: 0,
            top: 0,
            parent: this
        });

        this.stateController.stateParams = stateParams;
        this.stateController.prepare();
    }

    message(text: string, iconSprite: UiSprite, iconSpriteIndex: number) {
        this.setState('MESSAGE', {text, iconSprite, iconSpriteIndex});
    }

    artifactMessage(id: number) {
        const iconSprite = this.textures.get('artifacts_portraits_sm');
        const artifactData = ArtifactsStorage.prototype.getDataById(id);
        const iconSpriteIndex = artifactData.spriteIndex;
        const text = this.textService.getArtifactName(artifactData.type);

        this.setState('MESSAGE', {
            text,
            iconSprite,
            iconSpriteIndex
        });
    }

    resourceTransferMessage(storage: ResourcesStorage) {
        const nonEmpty = [];

        storage.all().forEach((property, name) => {
            const value = property instanceof Property
                ? property.get()
                : parseInt(property) || 0;

            if (value) {
                nonEmpty.push(name);
            }
        });

        if (nonEmpty.length === 0) {
            return;
        } else if (nonEmpty.length === 1) {
            const iconSprite = this.textures.get('resources_portraits_lg');
            let iconSpriteIndex = -1;
            Utils.forEach(RESOURCES, (item, key) => {
                if (item.name === nonEmpty[0]) {
                    iconSpriteIndex = key;
                }
            });

            this.setState('MESSAGE', {
                text: storage.asTextString(),
                iconSprite,
                iconSpriteIndex
            });
        } else {
            this.setState('MESSAGE', {
                text: storage.asTextString()
            });
        }
    }

    draw() {
        UiWindow.prototype.draw.call(this);
        
        this.stateController.draw();
    }
}
