
import {UiPanel} from "./UiPanel";
import {Maybe} from "../helpers/Maybe";

export class UiPanelLayoutMixin {

    left = 0;
    right = 0;
    top = 0;
    bottom = 0;
    width = 0;
    height = 0;
    absoluteLeft = 0;
    absoluteTop = 0;

    children = new Set<UiPanel>();

    __initLayout() {
        this.left = 0;
        this.top = 0;
        this.width = 0;
        this.height = 0;
    }

    isPointInside(x: number, y: number): boolean {
        let right = this.width + this.absoluteLeft;
        let bottom = this.height + this.absoluteTop;

        return (this.absoluteLeft <= x) && (this.absoluteTop <= y) && (right >= x) && (bottom >= y);
    }

    getChildInPoint(x: number, y: number): UiPanel {
        if (!this.isPointInside(x, y)) {
            return null;
        }
        let result = <UiPanel><any>this;

        this.children.forEach(function(wnd) {
            let child;
            if (child = wnd.getChildInPoint(x, y)) {
                return result = child;
            }
        });
        return result;
    }

    setMetrics(left: number, top: number, width: number, height: number) {
        [this.left, this.top, this.width, this.height] = Array.from(arguments);

        this.absoluteLeft = this.calculateLeft();
        this.absoluteTop = this.calculateTop();

        (<UiPanel><any>this).events.dispatch('resize');

        return this.__updateChildren();
    }

    setWidth(width: number) {
        this.width = width;
        (<UiPanel><any>this).events.dispatch('resize');
        return this.__updateChildren();
    }

    setHeight(height: number) {
        this.height = height;
        (<UiPanel><any>this).events.dispatch('resize');
        return this.__updateChildren();
    }

    setLeft(left: number) {
        this.left = left;
        this.absoluteLeft = this.calculateLeft();
        (<UiPanel><any>this).events.dispatch('resize');
        return this.__updateChildren();
    }

    setTop(top: number) {
        this.top = top;
        this.absoluteTop = this.calculateTop();
        (<UiPanel><any>this).events.dispatch('resize');
        return this.__updateChildren();
    }

    getWidth(): number {
        return this.width;
    }

    getHeight(): number {
        return this.height;
    }

    getLeft(): number {
        return this.absoluteLeft;
    }

    getTop(): number {
        return this.absoluteTop;
    }

    calculateLeft(): number {
        const parentOffset = (<UiPanel><any>this).parent && (<UiPanel><any>this).parent.getLeft() || 0;
        const parentWidth = (<UiPanel><any>this).parent && (<UiPanel><any>this).parent.getWidth() || 0;

        if (this.right && this.left) {
            return parentOffset + this.left;
        } else if (this.right && !this.left) {
            return (parentOffset + parentWidth) - this.right - this.getWidth();
        } else if (!this.right && this.left) {
            return parentOffset + this.left;
        } else {
            return parentOffset;
        }
    }

    calculateWidth() {
        const left = this.left || 0;
        const right = this.right || 0;
        return ((<UiPanel><any>this).parent && (<UiPanel><any>this).parent.getWidth() || 0) - right - left;
    }

    calculateTop() {
        const parentOffset = (<UiPanel><any>this).parent && (<UiPanel><any>this).parent.getTop() || 0;
        const parentHeight = (<UiPanel><any>this).parent && (<UiPanel><any>this).parent.getHeight() || 0;
        if (this.bottom) {
            return (parentOffset + parentHeight) - this.bottom - this.getHeight();
        } else {
            return this.top + parentOffset;
        }
    }

    calculateHeight() {
        const top = this.top || 0;
        const bottom = this.bottom || 0;
        return ((<UiPanel><any>this).parent && (<UiPanel><any>this).parent.getHeight() || 0) - top - bottom;
    }

    __updateChildren() {
        (<UiPanel><any>this).__handleChildrenEvent('parent.updateSizes');
    }

    __update() {
        [
            'left',
            'right',
            'top',
            'bottom',
            'width',
            'height'
        ].forEach(attr => {
            return this[attr] = Maybe((<UiPanel><any>this).options[attr])
                .evaluate(this, (<UiPanel><any>this).parent || this)
                .toInt()
                .getOrElse(null);
        });

        if (!(<UiPanel><any>this).options['width']) {
            this.width = this.calculateWidth();
        }

        if (!(<UiPanel><any>this).options['height']) {
            this.height = this.calculateHeight();
        }

        this.absoluteLeft = this.calculateLeft();
        this.absoluteTop = this.calculateTop();

        this.__updateChildren();
    }
};
