
import {Layers} from "../../render/layers/Layers";
import {Events} from "../../controllers/Events";
import {PathFinderService} from "../../services/PathFinderService";
import * as Utils from "../../utils/Utils";
import {ObjectsCollection} from "../ObjectsCollection";
import {TilesCollection} from "./TilesCollection";
import {FogModel} from "./FogModel";
import {IObjectData, MapObject} from "../MapObject";
import {GridStorage, IGridStorageQueryResult} from "../storages/GridStorage";
import {IMapDataPlayer, IMapDataProps, MapDataReader} from "./MapDataReader";
import {Rect} from "../../helpers/Rect";
import {MapData} from '../../controllers/MapData';
import {Inject} from '../../helpers/InjectDectorator';
import {StateService} from '../../services/StateService';

const WATER = 8;
const ROCK = 9;

const WALL = 0;
const PASSABLE = 1;
const ROADS = {
    [1]: 0.75,
    [2]: 0.65,
    [3]: 0.5
};

class MapModelSingleton {
    grid: GridStorage;
    getEarthPassability: Function;
    getWaterPassability: Function;
    getSwimPassability: Function;

    props: IMapDataProps;
    size: number;
    objectsData: IObjectData[];
    playersData: IMapDataPlayer[];
    fog: FogModel;

    @Inject(ObjectsCollection) protected objectsCollection: ObjectsCollection;
    @Inject(StateService) protected stateService: StateService;
    @Inject(MapData) private mapData: MapDataReader;
    @Inject(Layers) private layers: Layers;

    constructor() {
        this.updatePoints = this.updatePoints.bind(this);
        this.updateRect = this.updateRect.bind(this);
        this.grid = new GridStorage();

        this.getEarthPassability = this.getPassability.bind(this, 'earth');
        this.getWaterPassability = this.getPassability.bind(this, 'water');
        this.getSwimPassability = this.getPassability.bind(this, 'swim');
    }

    bootstrap() {
        this.props = this.mapData.props;
        this.size = this.props.size;

        this.layers.setSize(this.size);
        this.objectsCollection.bootstrap(this.mapData.objects);
        TilesCollection.bootstrap(this.mapData.tiles);

        this.objectsData = this.mapData.objects;
        this.playersData = this.mapData.players;
        this.fog = new FogModel(this.size);

        Events.on('texturesLoaded', () => {
            this.grid.init();
            PathFinderService.bootstrap();
        });

        Events.on('dynamicObjectSpawned', object => {
            this.updateRect(object.getRect());
        });

        this.stateService.subscribe('objects', ({type, dispatch}) => {
            if (type !== 'remove') {
                return;
            }

            const matches = dispatch.match(/^objects\.(\d+)$/);
            const id = matches ? matches[1] : null;

            if (id && !isNaN(parseInt(id))) {
               this.onMapObjectRemoved(parseInt(id));
            }
        });
    }

    getObjectInPos(x: number, y: number, z = 0): MapObject[] {
        const {impassable} = this.grid.get(x, y);

        return this.objectsCollection.list(impassable);
    }

    doActionInPos(x: number, y: number, z = 0, fromObject = null) {
        let object;
        let {actions} = this.grid.get(x, y);
        actions = Utils.filter(actions, id => fromObject.id !== id);
        let actionId = parseInt(actions.shift());

        if (object = this.objectsCollection.getById(actionId)) {
            object.events.dispatch('action', fromObject);
            return (typeof object.doAction === 'function' ? object.doAction(fromObject) : undefined);
        }
        return false;
    }

    dispatchExitInPos(x, y, z = 0, fromObject = null) {
        let object;
        let {actions} = this.grid.get(x, y);
        actions = Utils.filter(actions, id => fromObject.id !== id);
        const action = parseInt(actions.shift());

        if (object = this.objectsCollection.getById(action)) {
            return object.events.dispatch('exit', fromObject);
        }
        return false;
    }

    canGoToPos(x: number, y: number, z = 0): boolean {
        const {actions, impassable} = this.grid.get(x, y);
        const action = parseInt(actions.shift());

        if (action) {
            const object = this.objectsCollection.getById(action);
            return object && object.canGoInActionPos;
        }
        return !impassable.length;
    }

    tryPathToPos(type, x, y, z = 0) {
        let [terrain, isMoorable] = TilesCollection.getByCoords(x, y, z);

        if ((type === 'swim') && (terrain === WATER)) {
            return 0;
        }
        let {actions, impassable} = this.grid.get(x, y);

        if (actions.length) {
            return 1;
        }
        if ((type === 'water') && isMoorable) {
            return 1;
        }
        if (!impassable.length && TilesCollection.getAttackableId(x, y, z)) {
            return 1;
        }

        return this.getPassability(type, x, y, z);
    }

    getPassability(type, x, y, z = 0) {
        if (this.fog.has(x, y)) {
            return WALL;
        }
        const {actions, impassable, terrain, road} = this.grid.get(x, y);
        if (TilesCollection.getAttackableId(x, y, z)) {
            return WALL;
        }
        if (+terrain === ROCK) {
            return WALL;
        }
        if ((type === 'water') && (+terrain !== WATER)) {
            return WALL;
        }
        if (actions.length) {
            const action = parseInt(actions.shift());
            const actionObject = this.objectsCollection.getById(action);

            if (actionObject && actionObject.passableMode) {
                return PASSABLE;
            } else {
                return WALL;
            }
        }
        if (impassable.length) {
            return WALL;
        }
        if (+terrain === WATER) {
            if ((type === 'water') || (type === 'swim')) {
                return PASSABLE;
            } else {
                return WALL;
            }
        }
        if (+road) {
            return ROADS[road];
        }
        return PASSABLE;
    }

    isPointInMap(x?: number, y?: number) {
        if (arguments.length === 1) {
            ({x, y} = arguments[0]);
        } else {
            [x, y] = Array.from(arguments);
        }
        const mapSize = this.size << 5;
        return (x >= 0) && (y >= 0) && (x < mapSize) && (y < mapSize);
    }

    updatePoints(p1x: number, p1y: number, p2x: number, p2y: number, object: MapObject, object2: MapObject = null) {
        this.grid.updatePoints(p1x, p1y, p2x, p2y, object, object2);
        PathFinderService.updateRect(<Rect>{
            left: p1x - 1,
            top: p1y,
            right: p1x + p2x,
            bottom: p1y + p2y
        });
    }

    updateRect(rect) {
        this.grid.update();
        PathFinderService.updateRect(rect);
    }

    updateByMask(maskGrid) {
        this.fog.update(maskGrid);
        PathFinderService.updateByMask(maskGrid);
    }

    get(x: number, y: number, z): IGridStorageQueryResult {
        if (this.fog.has(x, y)) {
            return {
                actions: [],
                impassable: [],
                passable: [],
                road: '',
                terrain: '',
                fog: true
            };
        }
        return this.grid.get(x, y);
    }

    update() {
        this.grid.init();
    }

    private onMapObjectRemoved(id: number) {
        const object = this.objectsCollection.dynamic.get(id);
        const rect = object.getRect();

        setTimeout(() => this.updateRect(rect));
    }
}

export const MapModel = new MapModelSingleton();