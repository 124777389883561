
import {GenericVisitableObject} from "./GenericVisitableObject";
import {ArtifactObject, IObjectArtifactData} from "../ArtifactObject";
import * as Utils from "../../../utils/Utils";
import {UiController} from "../../../controllers/UiController";

export class CorpseObject extends GenericVisitableObject {

    recordVisits = true;
    restrictedPoints = false;
    artifact: IObjectArtifactData;

    constructor(data, id) {
        super(data, id);

        let generateRandomArtifact = () => {
            this.artifact = ArtifactObject.getRandomData(1);
        };

        let generateNothing = () => true;

        Utils.randomize({
            [20]: generateRandomArtifact,
            [80]: generateNothing
        });

        this.events.on('action', hero => {
            if (this.artifact) {
                UiController.statusWindow.artifactMessage(this.artifact.object_subclass);
                hero.artifacts.add(this.artifact);
                this.artifact = null;
            } else {
                UiController.alert('You tried to find something, but it was unsuccessful');
            }
        });
    }
}
