
import {Display} from "../render/layers/Display";
import {Pointer} from "../models/Pointer";
import {EventsStorage} from "../models/storages/EventsStorage";
import * as Utils from "../utils/Utils";
import {Maybe} from "../helpers/Maybe";

const MOUSE_EVENTS = [
    'drag',
    'dragEnd',
    'click',
    'dblClick',
    'leftClick',
    'leftMousedown',
    'leftMouseup',
    'mouseWheel',
    'mousedown',
    'mouseup',
    'rightClick',
    'rightMousedown',
    'rightMouseup',
    'touchstart',
    'touchend'
];

export interface IKeyPressEventData {
    keys: Set<number>;
}

interface IPointer {
    x: number;
    y: number;
}

export interface IMouseEventData {
    pointers: IPointer[];
    touch: boolean;
    deltaX?: number;
    deltaY?: number;
    delta?: number;
}

export interface IDragEvent {
    pointers: IPointer[];
    startPointers: IPointer[];
    diffX: number;
    diffY: number;
}

class EventsSingleton extends EventsStorage {

    private keys: Set<number> = new Set();
    private pointerPos: [Pointer] = [new Pointer(0, 0)];
    private startDragPos = [];
    private isMouseDown = false;
    private _isDrag = false;

    private isTouchDown = false;
    private isLongTouch = false;
    private touchDownTimeout = null;

    private lastTouchTime: number = (new Date()).getTime();

    private mouseStopTimeout = null;

    private lastWheelTimeout = null;
    private wheelDeltaX = 0;
    private wheelDeltaY = 0;

    constructor() {
        super();

        Display.addEventListener('mousedown', this.handleMouseDown.bind(this));
        Display.addEventListener('mouseup', this.handleMouseUp.bind(this));
        Display.addEventListener('mousemove', this.handleMouseMove.bind(this));
        Display.addEventListener('click', this.handleLeftClick.bind(this));
        Display.addEventListener('dblclick', this.handleDoubleClick.bind(this));
        Display.addEventListener('mousewheel', this.handleMouseWheel.bind(this));
        Display.addEventListener('contextmenu', this.handleContextMenu.bind(this));

        Display.addEventListener('touchstart', this.handleTouchStart.bind(this));
        Display.addEventListener('touchend', this.handleTouchEnd.bind(this));
        Display.addEventListener('touchmove', this.handleTouchMove.bind(this));

        this.handleKeyPress();
        this.handleWindowEvents();
    }

    private dispatchDragEvent() {
        const eventData: IDragEvent = {
            pointers: this.pointerPos,
            startPointers: this.startDragPos,
            diffX: this.getDragDiffX(),
            diffY: this.getDragDiffY()
        };

        this.dispatch('drag', eventData);
    };

    private handleTouchStart(e) {
        if ((e.touches != null ? e.touches.length : 0) > 1) {
            return;
        }
        e.preventDefault();
        this.updatePointers(e);
        this.updateDragPointers();
        this.dispatch('mousedown', this.getPointersEventData(e));
        this.dispatch('touchstart', this.getPointersEventData(e));
        if ((e.touches != null ? e.touches.length : 0) === 1) {
            this.dispatch('leftMousedown', this.getPointersEventData(e));
            this.isMouseDown = true;
            this.isTouchDown = true;
            clearTimeout(this.touchDownTimeout);
            this.touchDownTimeout = setTimeout(() => {
                if (this.isTouchDown && !this._isDrag) {
                    this.isLongTouch = true;
                    this.dispatch('rightMousedown', this.getPointersEventData(e));
                }
            }, 700);
        }
    };

    private handleMouseDown(e) {
        e.preventDefault();
        this.updatePointers(e);
        this.updateDragPointers();
        this.dispatch('mousedown', this.getPointersEventData(e));
        if (e.button === 0) {
            this.dispatch('leftMousedown', this.getPointersEventData(e));
            this.isMouseDown = true;
        }
        if (e.button === 2) {
            this.dispatch('rightMousedown', this.getPointersEventData(e));
        }
    };

    private handleTouchEnd(e) {
        if ((e.touches != null ? e.touches.length : 0) > 1) {
            return true;
        }
        e.preventDefault();
        if (this._isDrag) {
            this.dispatch('dragEnd');
        }
        this.isMouseDown = false;
        this.isTouchDown = false;
        clearTimeout(this.touchDownTimeout);
        setTimeout(() => this._isDrag = false);
        this.dispatch('mouseup', this.getPointersEventData(e));
        this.dispatch('touchend', this.getPointersEventData(e));
        if (!this._isDrag) {
            this.dispatch('leftMouseup', this.getPointersEventData(e));
            this.dispatch('rightMouseup', this.getPointersEventData(e));
            if (this.isLongTouch) {
                this.isLongTouch = false;
            } else {
                if (((new Date()).getTime() - this.lastTouchTime) > 300) {
                    this.dispatch('leftClick', this.getPointersEventData(e));
                } else {
                    this.dispatch('dblClick', this.getPointersEventData(e));
                }
            }
            this.lastTouchTime = (new Date()).getTime();
        }
    };

    private handleMouseUp(e) {
        e.preventDefault();
        if (this._isDrag) {
            this.dispatch('dragEnd');
        }
        this.isMouseDown = false;
        setTimeout(() => this._isDrag = false);
        this.dispatch('mouseup', this.getPointersEventData(e));
        if ((e.button === 0) || (e.touches != null ? e.touches.length : 0)) {
            this.dispatch('leftMouseup', this.getPointersEventData(e));
        }
        if (e.button === 2) {
            this.dispatch('rightMouseup', this.getPointersEventData(e));
        }
    };

    private handleMouseMove(e) {
        if ((e.touches != null ? e.touches.length : 0) > 1) {
            return;
        }
        e.preventDefault();
        this.updatePointers(e);
        clearTimeout(this.mouseStopTimeout);
        this.mouseStopTimeout = setTimeout(() => {
            this.dispatch('mousestop', this.getPointersEventData(e));
        }, 150);
        this.dispatch('mousemove', this.getPointersEventData(e));
        if (!this._isDrag) {
            this.updateDragPointers();
        }
        if (this.isMouseDown) {
            setTimeout(() => {
                if (this.isMouseDown) {
                    this._isDrag = true;
                    this.dispatchDragEvent();
                }
            }, 100);
        }
    };

    private handleLeftClick(e) {
        if (this._isDrag) { return false; }
        e.preventDefault();
        this.updatePointers(e);
        this.dispatch('leftClick', this.getPointersEventData(e));
    };

    private handleDoubleClick(e) {
        e.preventDefault();
        this.updatePointers(e);
        this.dispatch('dblClick', this.getPointersEventData(e));
    };

    private clearWheelData () {
        this.lastWheelTimeout = null;
        this.wheelDeltaX = 0;
        this.wheelDeltaY = 0;
    };

    private handleMouseWheel (e) {
        e.preventDefault();
        if (this.lastWheelTimeout) {
            clearTimeout(this.lastWheelTimeout);
        }
        this.wheelDeltaX += e.deltaX || 0;
        this.wheelDeltaY += e.deltaY || e.delta || 0;
        this.lastWheelTimeout = setTimeout(this.clearWheelData, 2000);
        if ((Math.abs(this.wheelDeltaX) >= 32) || (Math.abs(this.wheelDeltaY) >= 32)) {
            this.updatePointers(e);
            this.dispatch('mouseWheel', this.getWheelEventData(e, this.wheelDeltaX, this.wheelDeltaY));
            this.dispatch('mousemove', this.getPointersEventData(e));
            this.clearWheelData();
        }
    };

    private handleContextMenu(e) {
        e.preventDefault();
        this.updatePointers(e);
        this.dispatch('rightClick', this.getPointersEventData(e));
    };

    private handleTouchMove(e) {
        if ((e.touches != null ? e.touches.length : 0) === 1) {
            e.preventDefault();
            this.handleMouseMove(e);
        }
    };


    isDrag() {
        return this._isDrag;
    }

    isMouseEvent(type: string): boolean {
        return !!~MOUSE_EVENTS.indexOf(type);
    }

    getKeys(): Set<number> {
        return this.keys;
    }

    getMousePos() {
        return this.pointerPos[0];
    }

    private handleWindowEvents() {
        let resizeTimeout = null;

        window.addEventListener('resize', () => {
            if (resizeTimeout) {
                clearTimeout(resizeTimeout);
            }

            resizeTimeout = setTimeout(() => {
                this.dispatch('resize');
                resizeTimeout = null;
            }, 100);
        });
    }

    private handleKeyPress() {
        window.addEventListener('keydown', e => {
            this.keys.add(e.keyCode);
            if (this.keys.size) {
                return this.dispatch('keypress', this.getKeyPressEventData(this.keys));
            }
        });

        window.addEventListener('keyup', e => {
            this.keys.delete(e.keyCode);
        });
    }

    private getKeyPressEventData(keys: Set<number>): IKeyPressEventData {
        return {keys};
    }

    private getDragDiffX(): number {
        return this.pointerPos[0].x - this.startDragPos[0].x;
    }

    private getDragDiffY(): number {
        return this.pointerPos[0].y - this.startDragPos[0].y;
    }

    private getDragDistance() {
        const diffX = this.getDragDiffX();
        const diffY = this.getDragDiffY();

        return Math.abs(Math.max(diffX, diffY));
    }


    private getPointersEventData(e: Event): IMouseEventData {
        return {
            pointers: this.pointerPos,
            touch: Maybe(e).pluck('touches.length').toBoolean().get()
        }
    }

    private getWheelEventData(e: Event, wheelDeltaX: number, wheelDeltaY: number): IMouseEventData {
        return Object.assign({}, this.getPointersEventData(e), {
            deltaX: wheelDeltaX,
            deltaY: wheelDeltaY,
            delta: wheelDeltaY
        });
    }

    private updateDragPointers() {
        this.startDragPos = Utils.clone(this.pointerPos);
    }

    private updatePointers(e: TouchEvent) {
        const eventSource = Maybe(e).pluck('touches.0').getOrElse(e);
        const x = eventSource.clientX;
        const y = eventSource.clientY + document.body.scrollTop;
        this.pointerPos = [new Pointer(x, y)];
    }
}

export const Events = new EventsSingleton();